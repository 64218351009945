export const TRANSLATIONS_FR = {
    "common": {
        "Open": "Ouvert",
        "Close": "Fermer",
        "Add": "Ajouter",
        "Edit": "Editer",
        "Save": "Sauvegarder",
        "Delete": "Supprimer",
        "Actived": "Activé",
        "Disabled": "Désactivé",
        "Created": "Créé le",
        "Updated": "Modifié le",
        "Back": "Retour",
    },
    "events": {
        "EventsManager": "Event Manager",
        "EventsFinder": "Liste des Events trouvé",
        "EventsList": "Liste des Events",
        "Event": "Events",
        "Status": "Status",
        "Action": "Action",
        "Edit": "Editer",
    },
    "customers": {
        "CustomersList": "Liste des artiste",
        "Customer": "Customer",
        "Status": "Statut",
        "NewCustomer": "New customer",
        "EditCustomer": "Customer edit",
        "CustomerName": "Customer name",
        "CustomerGenre": "Genre",
        "CustomerType": "Type",
        "TranslatedName": "Translate name",
        "Action": "Action",
        "Edit": "Edit",
        "DeleteThisCustomer": "Delete this customer"
    },
    "venues": {
        "VenuesList": "Liste des salles",
        "Venue": "Salle",
        "Status": "Statut",
        "NewVenue": "Nouvelle salle",
        "EditVenue": "Editer salle",
        "VenueName": "Nom de la salle",
        "VenueAliasName": "Nom alias",
        "VenueDescription": "Description",
        "VenueCapacity": "Capacité",
        "VenueType": "Type",
        "CityArea": "Ville zone",
        "IsoCode": "ISO code (ex: en, fr...)",
        "TranslatedName": "Nom traduit",
        "Action": "Action",
        "Edit": "Editer",
        "DeleteThisVenue": "Supprimer cette salle"
    },
    "maps": {
        "MapsList": "Liste des plans",
    },
    "categories": {
        "CategoriesList": "Categories list",
        "Category": "Category",
        "Status": "Status",
        "NewCategory": "New category",
        "EditCategory": "Edit category",
        "CategoryName": "Category name",
        "CategoryParent": "Categorie parent",
        "TranslatedName": "Translated name",
        "Action": "Action",
        "Edit": "Edit",
        "DeleteThisCategory": "Delete this category"
    },
    "tickets": {
        "TicketsList": "Tickets list",
        "Ticket": "Ticket",
        "Status": "Status",
        "NewTicket": "New ticket",
        "EditTicket": "Edit ticket",
        "TicketName": "Ticket name",
        "TranslatedName": "Translated name",
        "Action": "Action",
        "Edit": "Edit",
        "DeleteThisTicket": "Delete this ticket"
    },
    "cities": {
        "CitiesList": "Liste des villes",
        "City": "Ville",
        "Status": "Statut",
        "NewCity": "Nouvelle ville",
        "EditCity": "Editer ville",
        "CityName": "Nom de la ville",
        "CountryArea": "Pays zone",
        "IsoCode": "ISO code (ex: en, fr...)",
        "TranslatedName": "Nom traduit",
        "Action": "Action",
        "Edit": "Editer",
        "DeleteThisCity": "Supprimer cette ville"
    },
    "countries": {
        "CountriesList": "Liste des pays",
        "Country": "Pays",
        "Status": "Statut",
        "NewCountry": "Nouveau pays",
        "EditCountry": "Editer pays",
        "CountryName": "Nom du pays",
        "CountryISO": "Code ISO du pays",
        "MarketArea": "Zone de marché",
        "IsoCode": "Code ISO (ex: en, fr...)",
        "TranslatedName": "Nom traduit",
        "Action": "Action",
        "Edit": "Editer",
        "DeleteThisCountry": "Supprimer ce pays"
    },
    "markets": {
        "MarketsList": "Liste des marchés",
        "Market": "Marché",
        "Status": "Statut",
        "NewMarket": "Nouveau marché",
        "EditMarket": "Edition marché",
        "MarketName": "Nom du marché",
        "IsoCode": "Code ISO (ex: en, fr...)",
        "TranslatedName": "Nom traduit",
        "Action": "Action",
        "Edit": "Editer",
        "DeleteThisMarket": "Supprimer ce marché"
    },
    "notes": {
        "NotesList": "Liste des notes",
        "Status": "Statut",
        "Title": "Titre",
        "Note": "Note",
        "Owner": "Auteur",
        "Edit": "Editer",
        "Completed": "Terminé",
        "NewNote": "Nouvelle note",
        "EditNote": "Edition note",
        "AssignedTo": "Assigné à",
    },
    "users": {
        "UsersList": "Liste des utilisateurs",
        "Username": "Utilisateurs",
        "Roles": "Rôles",
        "Edit": "Editer",
        "NewUser": "Nouvel utilisateur",
        "EditUser": "Edition utilisateur",
        "Password": "Mot de passe",
        "AssignedRoles": "Rôles assignés",
        "Active": "Active",
    },
    "dash": {
        "Logout": "Déconnexion",
        "Welcome": "Bonjour",
        "Users": "Utilisateurs",
        "Notes": "Notes",
        "Markets": "Marchés",
        "Countries": "Pays",
        "Cities": "Villes",
        "Venues": "Salles",
        "Maps": "Plans",
        "Tickets": "Tickets",
        "Customers": "Artistes",
        "Sources": "Sources",
        "Tools": "Outils",
    },
}