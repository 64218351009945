import { useRef, useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setCredentials } from './authSlice'
import { useLoginMutation } from './authApiSlice'
import usePersist from '../../hooks/usePersist'
import useTitle from '../../hooks/useTitle'
import PulseLoader from 'react-spinners/PulseLoader'

import {
    Grid,
    AbsoluteCenter,
    Box,
    Button,
    Checkbox,
    Container,
    Divider,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Input,
    Link as Linker,
    Stack,
    Text,
} from '@chakra-ui/react'


const Login = () => {
    useTitle('Employee Login')

    const userRef = useRef()
    const errRef = useRef()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [errMsg, setErrMsg] = useState('')
    const [persist, setPersist] = usePersist()

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [login, { isLoading }] = useLoginMutation()

    useEffect(() => {
        userRef.current.focus()
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [username, password])


    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const { accessToken } = await login({ username, password }).unwrap()
            dispatch(setCredentials({ accessToken }))
            setUsername('')
            setPassword('')
            navigate('/dash')
        } catch (err) {
            if (!err.status) {
                setErrMsg('No Server Response');
            } else if (err.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg(err.data?.message);
            }
            errRef.current.focus();
        }
    }

    const handleUserInput = (e) => setUsername(e.target.value)
    const handlePwdInput = (e) => setPassword(e.target.value)
    const handleToggle = () => setPersist(prev => !prev)

    const errClass = errMsg ? "errmsg" : "offscreen"

    if (isLoading) return <PulseLoader color={"#FFF"} />

    const content = (
        <>
            <Grid
                w='100vw'
                h='100vh'
                bg='#45413C'
            >
                <AbsoluteCenter
                    p='0'
                    color='white'
                    axis='both'
                >
                    <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
                        <Stack spacing="8">
                            <Stack spacing="6">
                                <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                                    <Heading size={{ base: 'xs', md: 'sm' }}>Log in to your account</Heading>
                                    <Text color="fg.muted">
                                        Don't have an account? <Linker href="#">Sign up</Linker>
                                    </Text>
                                    <Text
                                        color="red"
                                        ref={errRef}
                                        aria-live="assertive"
                                    >
                                        {errMsg}
                                    </Text>
                                </Stack>
                            </Stack>
                            <Box
                                py={{ base: '0', sm: '8' }}
                                px={{ base: '4', sm: '10' }}
                                bg={{ base: 'transparent', sm: 'bg.surface' }}
                                boxShadow={{ base: 'none', sm: 'md' }}
                                borderRadius={{ base: 'none', sm: 'xl' }}
                                bg='white'
                                color="black"
                            >
                                <Stack spacing="6">
                                    <Stack spacing="5">
                                        <FormControl>
                                            <FormLabel htmlFor="username">Username</FormLabel>
                                            <Input
                                                type="text"
                                                id="username"
                                                ref={userRef}
                                                value={username}
                                                onChange={handleUserInput}
                                                autoComplete="off"
                                                required
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel htmlFor="password">Password</FormLabel>
                                            <Input
                                                type="password"
                                                id="password"
                                                onChange={handlePwdInput}
                                                value={password}
                                                required
                                            />                                </FormControl>
                                    </Stack>
                                    <HStack justify="space-between">
                                        <Checkbox
                                            defaultChecked
                                            type="checkbox"
                                            id="persist"
                                            onChange={handleToggle}
                                            checked={persist}
                                        >
                                            Remember me
                                        </Checkbox>
                                        <Button variant="text" size="sm">
                                            Forgot password?
                                        </Button>
                                    </HStack>
                                    <Stack spacing="6">
                                        <Button className="form__submit-button" onClick={handleSubmit}>Sign In</Button>

                                        <HStack>
                                            <Divider />
                                            <Text textStyle="sm" whiteSpace="nowrap" color="fg.muted">
                                                or continue with
                                            </Text>
                                            <Divider />
                                        </HStack>
                                    </Stack>
                                </Stack>
                            </Box>
                        </Stack>
                    </Container>
                </AbsoluteCenter>
            </Grid>

        </>
    )

    return content
}
export default Login