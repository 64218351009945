import { useState, useEffect, useRef } from "react";

import {
	useGetAccountsQuery,
	useGetAccountQuery,
	useAddNewAccountsMutation,
} from "./accountsApiSlice";

import { useNavigate, Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useLang from "../../hooks/useLang";
import useTranslate from "../../hooks/useTranslate";
import useTitle from "../../hooks/useTitle";
import PulseLoader from "react-spinners/PulseLoader";

// import `ChakraProvider` component
import {
	Box,
	Grid,
	GridItem,
	Center,
	HStack,
	VStack,
	Container,
	Flex,
	SimpleGrid,
	Skeleton,
	FormControl,
	FormLabel,
	Textarea,
	Checkbox,
	Input,
	Switch,
	Badge,
	Tag,
	TagLabel,
	TagCloseButton,
	Avatar,
	Toast,
	Icon,
	IconButton,
	Image,
	Stack,
	Alert,
	AlertIcon,
	Select,
	Button,
	useRadioGroup,
	useRadio,
	RadioGroup,
	Radio,
	Text,
	useDisclosure,
	useToast,
	Spacer,
} from "@chakra-ui/react";
import {
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	TableCaption,
	TableContainer,
} from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import {
	Drawer,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
} from "@chakra-ui/react";

// import `ChakraProvider` icon
import { AddIcon } from "@chakra-ui/icons";

import {
	BsHourglassSplit,
	BsPlusCircleFill,
	BsRecordCircleFill,
	BsToggleOn,
	BsSearch,
	BsPencilFill,
} from "react-icons/bs";
import {
	FiMenu,
	FiChevronRight,
	FiCalendar,
	FiMapPin,
	FiMinusSquare,
	FiPlusSquare,
	FiEdit,
	FiCopy,
	FiSave,
	FiStar,
	FiPlus,
	FiMinus,
	FiPause,
	FiPlay,
	FiGlobe,
	FiAward,
	FiUpload,
} from "react-icons/fi";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faLocationCrosshairs,
	faSnowman,
	faList,
} from "@fortawesome/free-solid-svg-icons";

const ProfilyList = () => {
	useTitle("BROKY: Inventory Manager");

	const { username, isManager, isAdmin } = useAuth();

	const { lang } = useLang();
	const { T } = useTranslate(lang);

	const navigate = useNavigate();

	const toastInfo = useToast({
		position: "bottom-left",
		variant: "left-accent",
	});

	const accountData = [
		{
			accountId: "#87363",
			accountSupportId: "Ticketmaster.fr",
			accountLogin: "john.doe@gmail.com",
			accountOrderNb: "3",
			accountLastUse: "2023.01.15, 19:20",
			accountLastCrawl: "2023.01.15, 19:20",
		},
		{
			accountId: "#87362",
			accountSupportId: "Ticketmaster.fr",
			accountLogin: "isa.belle@gmail.com",
			accountOrderNb: "2",
			accountLastUse: "2023.01.15, 19:20",
			accountLastCrawl: "2023.01.15, 19:20",
		},
		{
			accountId: "#87361",
			accountSupportId: "Ticketmaster.fr",
			accountLogin: "lucas.pass@gmail.com",
			accountOrderNb: "5",
			accountLastUse: "2023.01.15, 19:20",
			accountLastCrawl: "2023.01.15, 19:20",
		},
	];

	const isLoading = false;

	{
		/* ACCOUNTS CREATE */
	}
	const accountsCreateData = [
		{
			accountsSupportId: "",
			accountsGroupId: "",
			accountsCreateNb: 0,
			profileSexe: "all",
			profileFirstname: "all",
			profileLastname: "all",
			profileAge: "all",
			profileCountry: "all",
			profileCity: "all",
			profilePostalCode: "",
			profileDomaineName: "domainName_list01",
			profilePhoneType: "fakeMobilePhone_random",
		},
	];
	let [canCreateAccounts, setCanCreateAccounts] = useState(false);
	let [accountsCreateGroupId, setAccountsCreateGroupId] = useState("");
	const checkCanCreateAccounts = () => {
		{
			setCanCreateAccounts(true);
			formFieldsAccountsCreateData.map((form) => {
				if (
					form.accountsCreateNb === 0 ||
					form.accountSupportId === "" ||
					form.accountGroupId === ""
				) {
					setCanCreateAccounts(false);
				}
			});
		}
	};
	const [formFieldsAccountsCreateData, setFormFieldsAccountsCreateData] =
		useState(accountsCreateData);
	const accountCreateFormChange = (event, index) => {
		let data = [...formFieldsAccountsCreateData];
		Object.keys(data[index]).forEach((key) => {
			if (key === event.target.name) {
				data[index] = { ...data[index], [key]: event.target.value };
			}
		});
		setFormFieldsAccountsCreateData(data);
		checkCanCreateAccounts();
	};
	const onSaveCreateAccountsClicked = async (e) => {
		e.preventDefault();
		if (canCreateAccounts) {
			formFieldsAccountsCreateData.map((form) => {
				if (
					form.accountsCreateSupportId === "" ||
					form.accountsCreateGroupId === ""
				) {
					setCanAddAccounts(false);
				}
			});

			formFieldsAccountsCreateData.forEach((field) => {
				alert("field = " + JSON.stringify(field));
				alert(
					"field.accountsCreateNb = " + JSON.stringify(field.accountsCreateNb)
				);
				//                marketNameByLang[field.lang] = field.marketName
			});

			//await addNewAccounts({ accountsData: formFieldsAccountsCreateData })
		}
	};
	const [isOpenAccountsCreate, setIsOpenAccountsCreate] = useState();
	const drawerIsOpenAccountsCreate = () => {
		setIsOpenAccountsCreate((prevState) => !prevState);
	};
	const addAccountCreate = () => {
		let data = [...formFieldsAccountsCreateData];
		Object.keys(data[0]).forEach((key) => {
			if (key === "accountsCreateNb") {
				if (data[0].accountsCreateNb >= 50) {
					data[0] = {
						...data[0],
						["accountsCreateNb"]: data[0].accountsCreateNb + 10,
					};
				} else if (data[0].accountsCreateNb >= 10) {
					data[0] = {
						...data[0],
						["accountsCreateNb"]: data[0].accountsCreateNb + 5,
					};
				} else {
					data[0] = {
						...data[0],
						["accountsCreateNb"]: data[0].accountsCreateNb + 1,
					};
				}
				if (data[0].accountsCreateNb >= 100) {
					data[0] = { ...data[0], ["accountsCreateNb"]: 100 };
				}
			}
		});
		setFormFieldsAccountsCreateData(data);
		checkCanCreateAccounts();
	};
	const delAccountCreate = () => {
		let data = [...formFieldsAccountsCreateData];
		Object.keys(data[0]).forEach((key) => {
			if (key === "accountsCreateNb") {
				if (data[0].accountsCreateNb > 50) {
					data[0] = {
						...data[0],
						["accountsCreateNb"]: data[0].accountsCreateNb - 10,
					};
				} else if (data[0].accountsCreateNb > 10) {
					data[0] = {
						...data[0],
						["accountsCreateNb"]: data[0].accountsCreateNb - 5,
					};
				} else {
					data[0] = {
						...data[0],
						["accountsCreateNb"]: data[0].accountsCreateNb - 1,
					};
				}
				if (data[0].accountsCreateNb < 0) {
					data[0] = { ...data[0], ["accountsCreateNb"]: 0 };
				}
			}
		});
		setFormFieldsAccountsCreateData(data);
		checkCanCreateAccounts();
	};

	{
		/* ACCOUNTS ADD */
	}
	const {
		data: accountsGetData,
		isLoading: isLoadingAccountsGet,
		isSuccess: isSuccessAccountsGet,
		isError: isErrorAccountsGet,
		error: errorAccountsGet,
	} = useGetAccountsQuery("accountsList", {
		pollingInterval: 15000,
		refetchOnFocus: true,
		refetchOnMountOrArgChange: true,
	});
	let accountsGetDataArray = {};
	if (isSuccessAccountsGet) {
		accountsGetDataArray = Object.values(accountsGetData?.entities || {});
	}
	const [
		addNewAccountsAdd,
		{
			data: accountAddData,
			isLoading: isLoadingAccountsAdd,
			isSuccess: isSuccessAccountsAdd,
			isError: isErrorAccountsAdd,
			error: errorAccountsAdd,
		},
	] = useAddNewAccountsMutation();

	const accountsAddData = [{ login: "", password: "" }];
	let [accountsAddSupportId, setAccountsAddSupportId] = useState("");
	let [accountsAddGroupId, setAccountsAddGroupId] = useState("");
	let [accountsAddDataNb, setAccountsAddDataNb] = useState(
		accountsAddData.length
	);
	let [canAddAccounts, setCanAddAccounts] = useState(false);

	const checkCanAddAccounts = () => {
		{
			setCanAddAccounts(true);
			if (accountsAddSupportId === "") {
				setCanAddAccounts(false);
			}
			formFieldsAccountsAddData.map((form) => {
				if (form.login === "" || form.password === "") {
					setCanAddAccounts(false);
				}
			});
		}
	};
	const [formFieldsAccountsAddData, setFormFieldsAccountsAddData] =
		useState(accountsAddData);
	const accountAddSupportChange = (event) => {
		setAccountsAddSupportId(event.target.value);
	};
	const accountAddGroupChange = (event) => {
		setAccountsAddGroupId(event.target.value);
	};
	const accountAddFormChange = (event, index) => {
		let data = [...formFieldsAccountsAddData];
		data[index][event.target.name] = event.target.value;
		setFormFieldsAccountsAddData(data);
		checkCanAddAccounts();
	};
	const onSaveAddAccountsClicked = async (e) => {
		e.preventDefault();

		if (canAddAccounts) {
			let output = [];
			let marketNameByLang = {};

			for (const field of formFieldsAccountsAddData) {
				const accountData = [{ login: field.login, password: field.password }];

				try {
					// Appeler addNewAccountsAdd et attendre la réponse
					const response = await addNewAccountsAdd({
						accountsAddSupportId,
						accountsAddGroupId,
						accountsAddDataNb,
						accountsData: accountData,
					});

					if (response.data.success) {
						toastInfo({
							title: "ACCOUNT : " + response.data?.message,
							status: "success",
						});
					} else {
						toastInfo({
							title: "ACCOUNT : " + response.data?.message,
							status: "error",
						});
					}
				} catch (error) {
					// Gérer les erreurs exceptionnelles ici
					console.error("An unexpected error occurred:", error);
				}
			}

			setAccountsAddSupportId("");
			setAccountsAddGroupId("");
			setFormFieldsAccountsAddData([{ login: "", password: "" }]);
			setAccountsAddDataNb(accountsAddData.length);
			setIsOpenAccountsAdd((prevState) => !prevState);
		}
	};
	const [isOpenAccountsAdd, setIsOpenAccountsAdd] = useState();
	const drawerIsOpenAccountsAdd = () => {
		setIsOpenAccountsAdd((prevState) => !prevState);
	};
	const addAccountAdd = () => {
		let object = {
			login: "",
			password: "",
		};
		setFormFieldsAccountsAddData([...formFieldsAccountsAddData, object]);
		setAccountsAddDataNb(formFieldsAccountsAddData.length + 1);
		//setCanSave(false)
	};
	const delAccountAdd = (index) => {
		let data = [...formFieldsAccountsAddData];
		data.splice(index, 1);
		setFormFieldsAccountsAddData(data);
		setAccountsAddDataNb(data.length);
		//checkCanSave()
	};

	{
		/* ACCOUNTS EDIT */
	}
	const [accountId, setAccountId] = useState(0);

	const accountEditData2 = [
		{
			accountSupportId: "accorarenacom",
			accountGroupId: "group02",
			accountLogin: "john.doe@gmail.com",
			accountPassword: "Azerty16*",
			accountState: true,
			accountOrderNb: "5",
			accountLastUse: "2023.01.15, 19:20",
			accountLastCrawl: "2023.01.15, 19:20",
		},
	];

	const {
		data: accountEditData,
		isLoading: isLoadingAccountGet,
		isSuccess: isSuccessAccountGet,
		isError: isErrorAccountGet,
		error: errorAccountGet,
		refetch: refetchAccountData,
	} = useGetAccountQuery({
		accountId: accountId,
		refetchOnMountOrArgChange: true,
		refetchOnFocus: true,
	});

	if (isSuccessAccountGet) {
		//console.log('accountEditData = ' + JSON.stringify(accountEditData))
	}
	const [isOpenAccountsEdit, setIsOpenAccountsEdit] = useState();
	const [formFieldsAccountEditData, setFormFieldsAccountEditData] = useState(
		[]
	);

	//drawerIsOpenAccountsEdit
	//setIsOpenAccountsEdit(true)
	async function AccountDetails(accountId) {
		setAccountId(accountId);
		// Appeler addNewAccountsAdd et attendre la réponse
		//await refetchAccountData();
		drawerIsOpenAccountsEdit();

		if (isSuccessAccountGet && accountEditData) {
			setFormFieldsAccountEditData([accountEditData]);
			//drawerIsOpenAccountsEdit()
			console.log("accountEditData = " + JSON.stringify(accountEditData));
			//drawerIsOpenAccountsEdit()
			//console.log('response.data = ' + JSON.stringify(response.data))
			//toastInfo({ title: 'ACCOUNT ' + accountEditData.accountLogin + ' EDIT' });
		}
	}

	let [canEditAccounts, setCanEditAccounts] = useState(false);
	const checkCanEditAccounts = () => {
		{
			setCanEditAccounts(true);
			formFieldsAccountEditData.map((form) => {
				if (
					form.accountSupportId === "" ||
					form.accountGroupId === "" ||
					form.accountLogin === "" ||
					form.accountPassword === ""
				) {
					setCanEditAccounts(false);
				}
			});
		}
	};
	//const [formFieldsAccountEditData, setFormFieldsAccountEditData] = useState(accountEditData2 || [])
	const accountEditFormChange = (event, index) => {
		let data = [...formFieldsAccountEditData];

		console.log(
			"formFieldsAccountEditData isFrozen : " +
				Object.isFrozen(formFieldsAccountEditData.accountGroupId)
		); // Vérifie si l'objet est gelé
		console.log("data isFrozen : " + Object.isFrozen(data.accountGroupId)); // Vérifie si l'objet est gelé

		alert("data = " + JSON.stringify(data));
		alert(
			"data[index].accountSupportId = " +
				JSON.stringify(data[index].accountSupportId)
		);
		alert("event.target.type = " + event.target.type);
		alert("event.target.name = " + event.target.name);
		alert("event.target.value = " + event.target.value);
		if (event.target.type === "checkbox") {
			data[index][event.target.name] = event.target.checked;
		} else {
			data[index][event.target.name] = event.target.value;
		}
		alert("data = " + JSON.stringify(data));
		setFormFieldsAccountEditData(data);
		checkCanEditAccounts();
	};
	const onSaveEditAccountsClicked = async (e) => {
		e.preventDefault();
	};
	//const [isOpenAccountsEdit, setIsOpenAccountsEdit] = useState();
	const drawerIsOpenAccountsEdit = () => {
		setIsOpenAccountsEdit((prevState) => !prevState);
	};

	//    useEffect(() => {
	//        if (isSuccessAccountGet) {
	//            console.log('accountEditData = ' + JSON.stringify(accountEditData));
	//            setIsOpenAccountsEdit(true)
	//drawerIsOpenAccountsEdit()
	//        }
	//    }, [isSuccessAccountGet]);

	//useEffect(() => {
	//setIsOpenAccountsEdit((prevState) => !prevState)
	//setIsOpenAccountsEdit((prevState) => !prevState)
	//    drawerIsOpenAccountsEdit()
	//}, setFormFieldsAccountEditData);

	//useEffect(() => {
	//    setIsOpenAccountsEdit((prevState) => !prevState)
	//}, accountEditData);

	useEffect(() => {
		checkCanCreateAccounts();
		checkCanAddAccounts();

		/*
    if (isSuccessAccountsAdd && !toastOpenedRef.current) {
        toastOpenedRef.current = true;
        toastInfo({ title: 'ACCOUNT : ' + accountAddData?.message + ' successfully', status: 'success' });
    
        // Si nécessaire, effectuez une navigation ici
        // navigate('/dash/profily');
    }
    
    if (isErrorAccountsAdd && !toastOpenedRef.current) {
        toastOpenedRef.current = true;
        toastInfo({ title: 'ACCOUNT : Add error (' + errorAccountsAdd?.data?.message + ')', status: 'error' });
    }
    */
	}, [checkCanCreateAccounts, checkCanAddAccounts]);

	let menuContent;
	if (isManager || isAdmin) {
		menuContent = (
			<>
				<Link to="/dash/carts/new">
					<Button
						size="sm"
						leftIcon={<AddIcon />}
						colorScheme="red"
						variant="solid"
					>
						<Text
							color="white"
							display={{ base: "none", sm: "flex" }}
							fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }}
							fontWeight={600}
						>
							{T.common.Add}
						</Text>
					</Button>
				</Link>
			</>
		);
	}

	let content;
	content = (
		<>
			<Center bg="#45413C" axis="both">
				<Grid
					w="95vw"
					minH="100vh"
					maxW="95vw"
					templateColumns="repeat(12, 1fr)"
				>
					<GridItem
						colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}
					>
						<Grid p="5" gap="5" templateColumns="repeat(12, 1fr)">
							<GridItem
								colSpan={{
									base: "6",
									sm: "6",
									md: "6",
									lg: "6",
									xl: "6",
									"2xl": "6",
								}}
								textAlign={["left"]}
							>
								<Text
									color={"white"}
									fontSize={{
										base: "24",
										sm: "24",
										md: "24",
										lg: "24",
										xl: "28",
									}}
									fontWeight={600}
								>
									<FontAwesomeIcon icon={faLocationCrosshairs} />{" "}
									{T.profily.Profily} : {T.profily.Manager}
								</Text>
							</GridItem>
							<GridItem
								colSpan={{
									base: "6",
									sm: "6",
									md: "6",
									lg: "6",
									xl: "6",
									"2xl": "6",
								}}
								textAlign={["right"]}
							>
								{menuContent}
							</GridItem>
						</Grid>

						<Box borderRadius="xl" p={2} textAlign={["center"]} bg="white">
							<Grid
								p="2"
								gap="4"
								w="100%"
								maxW="100vw"
								templateColumns="repeat(12, 1fr)"
							>
								{/* PROFILES */}
								<GridItem
									color="black"
									p="2"
									colSpan={{
										base: "12",
										sm: "12",
										md: "12",
										lg: "12",
										xl: "12",
										"2xl": "4",
									}}
								>
									<HStack>
										<Box p="2" textAlign={["left"]} w="100%" color="black">
											<Text fontSize={25} fontWeight={800}>
												Profiles
											</Text>
										</Box>
										<Link to="/dash/carts/new">
											<Button
												size="sm"
												leftIcon={<AddIcon />}
												colorScheme="red"
												variant="solid"
											>
												<Text
													color="white"
													display={{ base: "none", sm: "flex" }}
													fontSize={{
														base: "18",
														sm: "18",
														md: "18",
														lg: "18",
														xl: "18",
														"2xl": "18",
													}}
													fontWeight={500}
												>
													{T.common.Add}
												</Text>
											</Button>
										</Link>
									</HStack>
									<Box
										p="2"
										textAlign={["left"]}
										w="100%"
										minH="100px"
										color="black"
										bg="#F5F5F5"
										borderRadius="xl"
										border="1px"
										borderColor="gray.300"
										borderStyle={"solid"}
									>
										<TableContainer>
											<Table size="sm">
												<Thead>
													<Tr>
														<Th>#ID</Th>
														<Th>LASTNAME</Th>
														<Th>FIRSTNAME</Th>
														<Th>EMAIL</Th>
														<Th>USE</Th>
														<Th isNumeric>CREATE</Th>
													</Tr>
												</Thead>
												<Tbody>
													<Tr
														_hover={{
															bg: "gray.100",
															border: "2px",
															borderColor: "gray.400",
															borderStyle: "solid",
														}}
													>
														<Td>6</Td>
														<Td>DOE</Td>
														<Td>John</Td>
														<Td>john.doe@gmail.com</Td>
														<Td>0</Td>
														<Td isNumeric>2023.01.15, 19:20</Td>
													</Tr>
													<Tr>
														<Td>5</Td>
														<Td>DOE</Td>
														<Td>John</Td>
														<Td>john.doe@gmail.com</Td>
														<Td>0</Td>
														<Td isNumeric>2023.01.15, 19:20</Td>
													</Tr>
													<Tr>
														<Td>4</Td>
														<Td>DOE</Td>
														<Td>John</Td>
														<Td>john.doe@gmail.com</Td>
														<Td>0</Td>
														<Td isNumeric>2023.01.15, 19:20</Td>
													</Tr>
													<Tr>
														<Td>3</Td>
														<Td>DOE</Td>
														<Td>John</Td>
														<Td>john.doe@gmail.com</Td>
														<Td>0</Td>
														<Td isNumeric>2023.01.15, 19:20</Td>
													</Tr>
													<Tr>
														<Td>2</Td>
														<Td>DOE</Td>
														<Td>John</Td>
														<Td>john.doe@gmail.com</Td>
														<Td>0</Td>
														<Td isNumeric>2023.01.15, 19:20</Td>
													</Tr>
													<Tr>
														<Td>1</Td>
														<Td>DOE</Td>
														<Td>John</Td>
														<Td>john.doe@gmail.com</Td>
														<Td>0</Td>
														<Td isNumeric>2023.01.15, 19:20</Td>
													</Tr>
												</Tbody>
											</Table>
										</TableContainer>
									</Box>
								</GridItem>

								{/* ACCOUNTS */}
								<GridItem
									color="black"
									p="2"
									colSpan={{
										base: "12",
										sm: "12",
										md: "12",
										lg: "12",
										xl: "12",
										"2xl": "4",
									}}
								>
									<HStack>
										<Box p="2" textAlign={["left"]} w="100%" color="black">
											<Text fontSize={25} fontWeight={800}>
												Accounts
											</Text>
										</Box>
										<Spacer />
										<Button
											w="160px"
											size="sm"
											leftIcon={<BsSearch />}
											colorScheme="red"
											variant="solid"
											onClick={() => drawerIsOpenAccountsAdd()}
										>
											<Text
												color="white"
												display={{ base: "none", sm: "flex" }}
												fontSize={{
													base: "18",
													sm: "18",
													md: "18",
													lg: "18",
													xl: "18",
													"2xl": "18",
												}}
												fontWeight={500}
											>
												{T.common.Search}
											</Text>
										</Button>
										<Button
											w="300px"
											size="sm"
											leftIcon={<BsHourglassSplit />}
											colorScheme="red"
											variant="outline"
										>
											<Text
												color="red.400"
												display={{ base: "none", sm: "flex" }}
												fontSize={{
													base: "18",
													sm: "18",
													md: "18",
													lg: "18",
													xl: "18",
													"2xl": "18",
												}}
												fontWeight={500}
											>
												Creating :
												<Text ml="2" fontWeight={700}>
													0
												</Text>
											</Text>
										</Button>
										<Button
											w="120px"
											size="sm"
											leftIcon={<BsPlusCircleFill />}
											colorScheme="red"
											variant="solid"
											onClick={() => drawerIsOpenAccountsAdd()}
										>
											<Text
												color="white"
												display={{ base: "none", sm: "flex" }}
												fontSize={{
													base: "18",
													sm: "18",
													md: "18",
													lg: "18",
													xl: "18",
													"2xl": "18",
												}}
												fontWeight={500}
											>
												{T.common.Add}
											</Text>
										</Button>
										<Button
											w="140px"
											size="sm"
											leftIcon={<BsRecordCircleFill />}
											colorScheme="red"
											variant="solid"
											onClick={() => drawerIsOpenAccountsCreate()}
										>
											<Text
												color="white"
												display={{ base: "none", sm: "flex" }}
												fontSize={{
													base: "18",
													sm: "18",
													md: "18",
													lg: "18",
													xl: "18",
													"2xl": "18",
												}}
												fontWeight={500}
											>
												{T.common.Create}
											</Text>
										</Button>
									</HStack>
									<Box
										p="2"
										textAlign={["left"]}
										w="100%"
										minH="100px"
										color="black"
										bg="#F5F5F5"
										borderRadius="xl"
										border="1px"
										borderColor="gray.300"
										borderStyle={"solid"}
									>
										<TableContainer>
											<Table size="sm">
												<Thead>
													<Tr>
														<Th></Th>
														<Th>SUPPORT</Th>
														<Th>LOGIN</Th>
														<Th>ORDER</Th>
														<Th>LAST USE</Th>
														<Th>LAST CRAWL</Th>
													</Tr>
												</Thead>
												<Tbody>
													{!isLoadingAccountsGet &&
														isSuccessAccountsGet &&
														accountsGetDataArray.map((row, index) => (
															<Tr
																key={index}
																_hover={{
																	bg: "gray.100",
																	border: "2px",
																	borderColor: "gray.400",
																	borderStyle: "solid",
																}}
																cursor={"pointer"}
																onClick={() => AccountDetails(row._id)}
															>
																<Td
																	color={
																		row.accountState ? "green.400" : "red.400"
																	}
																>
																	<BsToggleOn fontSize="20px" />
																</Td>
																<Td>{row.accountSupportId}</Td>
																<Td>{row.accountLogin}</Td>
																<Td>{row.accountOrderNb}</Td>
																<Td>
																	{row.accountLastUse !== null &&
																	row.accountLastUse !== undefined
																		? row.accountLastUse
																		: "-"}
																</Td>
																<Td>
																	{row.accountLastCrawl !== null &&
																	row.accountLastCrawl !== undefined
																		? row.accountLastCrawl
																		: "-"}
																</Td>
															</Tr>
														))}
												</Tbody>
											</Table>
										</TableContainer>
									</Box>

									{/* Drawer Accounts Create */}
									{formFieldsAccountsCreateData.map(
										(formFieldsAccountsCreateDataRow, index) => {
											return (
												<Drawer
													key="index"
													isOpen={isOpenAccountsCreate ?? false}
													onClose={() => drawerIsOpenAccountsCreate()}
													size="lg"
												>
													<DrawerOverlay />
													<DrawerContent>
														<DrawerCloseButton />
														<DrawerHeader>
															<HStack>
																<BsRecordCircleFill fontSize={25} />
																<Text fontSize={25} fontWeight={800}>
																	{" "}
																	CREATE ACCOUNTS
																</Text>
															</HStack>
														</DrawerHeader>
														<DrawerBody>
															{/* Support setup */}
															<Box
																bg="#ffffff"
																borderRadius="xl"
																borderWidth="1px"
																borderColor="gray.400"
																shadow="lg"
																position="relative"
																mt="5"
																textAlign={["center"]}
																_hover={{
																	bg: "gray.50",
																	borderWidth: "2px",
																	borderColor: "red.400",
																}}
															>
																<Grid
																	w="100%"
																	p="1"
																	templateColumns="repeat(12, 1fr)"
																>
																	{/* Support set */}
																	<GridItem
																		color="black"
																		colSpan={{
																			base: "12",
																			sm: "12",
																			md: "12",
																			lg: "12",
																			xl: "12",
																			"2xl": "12",
																		}}
																	>
																		<Box p={1} textAlign={["left"]}>
																			<Grid
																				w="100%"
																				gap={2}
																				p="2"
																				templateColumns="repeat(12, 1fr)"
																			>
																				<GridItem colSpan={12}>
																					<Text fontSize={16} fontWeight={700}>
																						SUPPORT
																					</Text>
																				</GridItem>
																				<GridItem colSpan={12}>
																					<RadioGroup defaultValue="">
																						<Tabs
																							variant="soft-rounded"
																							isFitted
																						>
																							<TabList overflowX="auto">
																								<Tab
																									bg="gray.50"
																									_selected={{
																										color: "white",
																										bg: "red.400",
																									}}
																									_hover={{
																										bg: "gray.300",
																										color: "black",
																										borderColor: "gray.300",
																									}}
																									m={1}
																									fontSize={18}
																									fontWeight={700}
																								>
																									FR
																								</Tab>
																								<Tab
																									bg="gray.50"
																									_selected={{
																										color: "white",
																										bg: "red.400",
																									}}
																									_hover={{
																										bg: "gray.300",
																										color: "black",
																										borderColor: "gray.300",
																									}}
																									m={1}
																									fontSize={18}
																									fontWeight={700}
																								>
																									ES
																								</Tab>
																								<Tab
																									bg="gray.50"
																									_selected={{
																										color: "white",
																										bg: "red.400",
																									}}
																									_hover={{
																										bg: "gray.300",
																										color: "black",
																										borderColor: "gray.300",
																									}}
																									m={1}
																									fontSize={18}
																									fontWeight={700}
																								>
																									PT
																								</Tab>
																								<Tab
																									bg="gray.50"
																									_selected={{
																										color: "white",
																										bg: "red.400",
																									}}
																									_hover={{
																										bg: "gray.300",
																										color: "black",
																										borderColor: "gray.300",
																									}}
																									m={1}
																									fontSize={18}
																									fontWeight={700}
																								>
																									IT
																								</Tab>
																								<Tab
																									bg="gray.50"
																									_selected={{
																										color: "white",
																										bg: "red.400",
																									}}
																									_hover={{
																										bg: "gray.300",
																										color: "black",
																										borderColor: "gray.300",
																									}}
																									m={1}
																									fontSize={18}
																									fontWeight={700}
																								>
																									BE
																								</Tab>
																								<Tab
																									bg="gray.50"
																									_selected={{
																										color: "white",
																										bg: "red.400",
																									}}
																									_hover={{
																										bg: "gray.300",
																										color: "black",
																										borderColor: "gray.300",
																									}}
																									m={1}
																									fontSize={18}
																									fontWeight={700}
																								>
																									CH
																								</Tab>
																								<Tab
																									bg="gray.50"
																									_selected={{
																										color: "white",
																										bg: "red.400",
																									}}
																									_hover={{
																										bg: "gray.300",
																										color: "black",
																										borderColor: "gray.300",
																									}}
																									m={1}
																									fontSize={18}
																									fontWeight={700}
																								>
																									DE
																								</Tab>
																								<Tab
																									bg="gray.50"
																									_selected={{
																										color: "white",
																										bg: "red.400",
																									}}
																									_hover={{
																										bg: "gray.300",
																										color: "black",
																										borderColor: "gray.300",
																									}}
																									m={1}
																									fontSize={18}
																									fontWeight={700}
																								>
																									LU
																								</Tab>
																							</TabList>

																							<TabPanels minH="350px">
																								{/* FR */}
																								<TabPanel>
																									<VStack p="4" direction="row">
																										{/* Ticketmaster.fr */}
																										<Box
																											w="100%"
																											textAlign={["left"]}
																											borderRadius="xl"
																											borderWidth="1px"
																											borderColor={
																												formFieldsAccountsCreateDataRow.accountsSupportId ===
																												"ticketmasterfr"
																													? "red.400"
																													: "gray.200"
																											}
																											fontSize={20}
																											fontWeight={400}
																											color={
																												formFieldsAccountsCreateDataRow.accountsSupportId ===
																												"ticketmasterfr"
																													? "white"
																													: "black"
																											}
																											bg={
																												formFieldsAccountsCreateDataRow.accountsSupportId ===
																												"ticketmasterfr"
																													? "red.400"
																													: "gray.100"
																											}
																											_hover={{
																												bg: "red.50",
																												color: "#000",
																												borderWidth: "1px",
																												borderColor: "red.400",
																											}}
																										>
																											<Radio
																												p="2"
																												size="lg"
																												bg="white"
																												value="ticketmasterfr"
																												w="100%"
																												id="accountsSupportId"
																												name="accountsSupportId"
																												onChange={(event) =>
																													accountCreateFormChange(
																														event,
																														index
																													)
																												}
																											>
																												<HStack>
																													<Box ml="5">
																														<Text
																															fontSize={20}
																															fontWeight={700}
																														>
																															Ticketmaster.fr
																														</Text>
																													</Box>
																													<Spacer />
																												</HStack>
																											</Radio>
																										</Box>

																										{/* AccorArena.com */}
																										<Box
																											w="100%"
																											textAlign={["left"]}
																											borderRadius="xl"
																											borderWidth="1px"
																											borderColor={
																												formFieldsAccountsCreateDataRow.accountsSupportId ===
																												"accorarenacom"
																													? "red.400"
																													: "gray.200"
																											}
																											fontSize={20}
																											fontWeight={400}
																											color={
																												formFieldsAccountsCreateDataRow.accountsSupportId ===
																												"accorarenacom"
																													? "white"
																													: "black"
																											}
																											bg={
																												formFieldsAccountsCreateDataRow.accountsSupportId ===
																												"accorarenacom"
																													? "red.400"
																													: "gray.100"
																											}
																											_hover={{
																												bg: "red.50",
																												color: "#000",
																												borderWidth: "1px",
																												borderColor: "red.400",
																											}}
																										>
																											<Radio
																												p="2"
																												size="lg"
																												bg="white"
																												value="accorarenacom"
																												w="100%"
																												id="accountsSupportId"
																												name="accountsSupportId"
																												onChange={(event) =>
																													accountCreateFormChange(
																														event,
																														index
																													)
																												}
																											>
																												<HStack>
																													<Box ml="5">
																														<Text
																															fontSize={20}
																															fontWeight={700}
																														>
																															AccorArena.com
																														</Text>
																													</Box>
																													<Spacer />
																												</HStack>
																											</Radio>
																										</Box>

																										{/* Fnacspectacle.com */}
																										<Box
																											w="100%"
																											textAlign={["left"]}
																											borderRadius="xl"
																											borderWidth="1px"
																											borderColor={
																												formFieldsAccountsCreateDataRow.accountsSupportId ===
																												"fnacspectaclecom"
																													? "red.400"
																													: "gray.200"
																											}
																											fontSize={20}
																											fontWeight={400}
																											color={
																												formFieldsAccountsCreateDataRow.accountsSupportId ===
																												"fnacspectaclecom"
																													? "white"
																													: "black"
																											}
																											bg={
																												formFieldsAccountsCreateDataRow.accountsSupportId ===
																												"fnacspectaclecom"
																													? "red.400"
																													: "gray.100"
																											}
																											_hover={{
																												bg: "red.50",
																												color: "#000",
																												borderWidth: "1px",
																												borderColor: "red.400",
																											}}
																										>
																											<Radio
																												p="2"
																												size="lg"
																												bg="white"
																												value="fnacspectaclecom"
																												w="100%"
																												id="accountsSupportId"
																												name="accountsSupportId"
																												onChange={(event) =>
																													accountCreateFormChange(
																														event,
																														index
																													)
																												}
																											>
																												<HStack>
																													<Box ml="5">
																														<Text
																															fontSize={20}
																															fontWeight={700}
																														>
																															Fnacspectacle.com
																														</Text>
																													</Box>
																													<Spacer />
																												</HStack>
																											</Radio>
																										</Box>

																										{/* StadeFrance.com */}
																										<Box
																											w="100%"
																											textAlign={["left"]}
																											borderRadius="xl"
																											borderWidth="1px"
																											borderColor={
																												formFieldsAccountsCreateDataRow.accountsSupportId ===
																												"stadefrancecom"
																													? "red.400"
																													: "gray.200"
																											}
																											fontSize={20}
																											fontWeight={400}
																											color={
																												formFieldsAccountsCreateDataRow.accountsSupportId ===
																												"stadefrancecom"
																													? "white"
																													: "black"
																											}
																											bg={
																												formFieldsAccountsCreateDataRow.accountsSupportId ===
																												"stadefrancecom"
																													? "red.400"
																													: "gray.100"
																											}
																											_hover={{
																												bg: "red.50",
																												color: "#000",
																												borderWidth: "1px",
																												borderColor: "red.400",
																											}}
																										>
																											<Radio
																												p="2"
																												size="lg"
																												bg="white"
																												value="stadefrancecom"
																												w="100%"
																												id="accountsSupportId"
																												name="accountsSupportId"
																												onChange={(event) =>
																													accountCreateFormChange(
																														event,
																														index
																													)
																												}
																											>
																												<HStack>
																													<Box ml="5">
																														<Text
																															fontSize={20}
																															fontWeight={700}
																														>
																															StadeFrance.com
																														</Text>
																													</Box>
																													<Spacer />
																												</HStack>
																											</Radio>
																										</Box>

																										{/* OlympiaHall.com */}
																										<Box
																											w="100%"
																											textAlign={["left"]}
																											borderRadius="xl"
																											borderWidth="1px"
																											borderColor={
																												formFieldsAccountsCreateDataRow.accountsSupportId ===
																												"olympiahallcom"
																													? "red.400"
																													: "gray.200"
																											}
																											fontSize={20}
																											fontWeight={400}
																											color={
																												formFieldsAccountsCreateDataRow.accountsSupportId ===
																												"olympiahallcom"
																													? "white"
																													: "black"
																											}
																											bg={
																												formFieldsAccountsCreateDataRow.accountsSupportId ===
																												"olympiahallcom"
																													? "red.400"
																													: "gray.100"
																											}
																											_hover={{
																												bg: "red.50",
																												color: "#000",
																												borderWidth: "1px",
																												borderColor: "red.400",
																											}}
																										>
																											<Radio
																												p="2"
																												size="lg"
																												bg="white"
																												value="olympiahallcom"
																												w="100%"
																												id="accountsSupportId"
																												name="accountsSupportId"
																												onChange={(event) =>
																													accountCreateFormChange(
																														event,
																														index
																													)
																												}
																											>
																												<HStack>
																													<Box ml="5">
																														<Text
																															fontSize={20}
																															fontWeight={700}
																														>
																															OlympiaHall.com
																														</Text>
																													</Box>
																													<Spacer />
																												</HStack>
																											</Radio>
																										</Box>
																									</VStack>
																								</TabPanel>

																								{/* ES */}
																								<TabPanel>
																									<Text>No support !</Text>
																								</TabPanel>

																								{/* PT */}
																								<TabPanel>
																									<VStack p="4" direction="row">
																										{/* Ticketline.pt */}
																										<Box
																											w="100%"
																											textAlign={["left"]}
																											borderRadius="xl"
																											borderWidth="1px"
																											borderColor={
																												formFieldsAccountsCreateDataRow.accountsSupportId ===
																												"ticketlinept"
																													? "red.400"
																													: "gray.200"
																											}
																											fontSize={20}
																											fontWeight={400}
																											color={
																												formFieldsAccountsCreateDataRow.accountsSupportId ===
																												"ticketlinept"
																													? "white"
																													: "black"
																											}
																											bg={
																												formFieldsAccountsCreateDataRow.accountsSupportId ===
																												"ticketlinept"
																													? "red.400"
																													: "gray.100"
																											}
																											_hover={{
																												bg: "red.50",
																												color: "#000",
																												borderWidth: "1px",
																												borderColor: "red.400",
																											}}
																										>
																											<Radio
																												p="2"
																												size="lg"
																												bg="white"
																												value="ticketlinept"
																												w="100%"
																												id="accountsSupportId"
																												name="accountsSupportId"
																												onChange={(event) =>
																													accountCreateFormChange(
																														event,
																														index
																													)
																												}
																											>
																												<HStack>
																													<Box ml="5">
																														<Text
																															fontSize={20}
																															fontWeight={700}
																														>
																															Ticketline.pt
																														</Text>
																													</Box>
																													<Spacer />
																												</HStack>
																											</Radio>
																										</Box>

																										{/* Blueticket.meo.pt */}
																										<Box
																											w="100%"
																											textAlign={["left"]}
																											borderRadius="xl"
																											borderWidth="1px"
																											borderColor={
																												formFieldsAccountsCreateDataRow.accountsSupportId ===
																												"blueticketmeopt"
																													? "red.400"
																													: "gray.200"
																											}
																											fontSize={20}
																											fontWeight={400}
																											color={
																												formFieldsAccountsCreateDataRow.accountsSupportId ===
																												"blueticketmeopt"
																													? "white"
																													: "black"
																											}
																											bg={
																												formFieldsAccountsCreateDataRow.accountsSupportId ===
																												"blueticketmeopt"
																													? "red.400"
																													: "gray.100"
																											}
																											_hover={{
																												bg: "red.50",
																												color: "#000",
																												borderWidth: "1px",
																												borderColor: "red.400",
																											}}
																										>
																											<Radio
																												p="2"
																												size="lg"
																												bg="white"
																												value="blueticketmeopt"
																												w="100%"
																												id="accountsSupportId"
																												name="accountsSupportId"
																												onChange={(event) =>
																													accountCreateFormChange(
																														event,
																														index
																													)
																												}
																											>
																												<HStack>
																													<Box ml="5">
																														<Text
																															fontSize={20}
																															fontWeight={700}
																														>
																															Blueticket.meo.pt
																														</Text>
																													</Box>
																													<Spacer />
																												</HStack>
																											</Radio>
																										</Box>
																									</VStack>
																								</TabPanel>

																								{/* IT */}
																								<TabPanel>
																									<Text>No support !</Text>
																								</TabPanel>
																							</TabPanels>
																						</Tabs>
																					</RadioGroup>
																				</GridItem>
																			</Grid>
																		</Box>
																	</GridItem>
																</Grid>
															</Box>

															{/* Group setup */}
															<Box
																bg="#ffffff"
																borderRadius="xl"
																borderWidth="1px"
																borderColor="gray.400"
																shadow="lg"
																position="relative"
																mt="5"
																textAlign={["center"]}
																_hover={{
																	bg: "gray.50",
																	borderWidth: "2px",
																	borderColor: "red.400",
																}}
															>
																<Grid
																	w="100%"
																	p="1"
																	templateColumns="repeat(12, 1fr)"
																>
																	<GridItem
																		color="black"
																		colSpan={{
																			base: "12",
																			sm: "12",
																			md: "12",
																			lg: "12",
																			xl: "12",
																			"2xl": "12",
																		}}
																	>
																		<Box p={1} textAlign={["left"]}>
																			<RadioGroup defaultValue="">
																				<Grid
																					w="100%"
																					gap={2}
																					p="2"
																					templateColumns="repeat(12, 1fr)"
																				>
																					<GridItem colSpan={6}>
																						<Text
																							fontSize={16}
																							fontWeight={700}
																						>
																							GROUP
																						</Text>
																					</GridItem>
																					<GridItem
																						colSpan={6}
																						textAlign={["right"]}
																					></GridItem>
																					<GridItem colSpan={4}>
																						<Box
																							w="100%"
																							textAlign={["left"]}
																							borderRadius="xl"
																							borderWidth="1px"
																							borderColor={
																								formFieldsAccountsCreateDataRow.accountsGroupId ===
																								"group01"
																									? "red.400"
																									: "gray.200"
																							}
																							fontSize={20}
																							fontWeight={400}
																							color={
																								formFieldsAccountsCreateDataRow.accountsGroupId ===
																								"group01"
																									? "white"
																									: "black"
																							}
																							bg={
																								formFieldsAccountsCreateDataRow.accountsGroupId ===
																								"group01"
																									? "red.400"
																									: "gray.100"
																							}
																							_hover={{
																								bg: "red.50",
																								color: "#000",
																								borderWidth: "1px",
																								borderColor: "red.400",
																							}}
																						>
																							<Radio
																								p="2"
																								size="lg"
																								bg="white"
																								value="group01"
																								w="100%"
																								id="accountsGroupId"
																								name="accountsGroupId"
																								onChange={(event) =>
																									accountCreateFormChange(
																										event,
																										index
																									)
																								}
																							>
																								<Box ml="5">
																									<Text
																										fontSize={16}
																										fontWeight={500}
																									>
																										Group_01
																									</Text>
																								</Box>
																							</Radio>
																						</Box>
																					</GridItem>
																					<GridItem colSpan={4}>
																						<Box
																							w="100%"
																							textAlign={["left"]}
																							borderRadius="xl"
																							borderWidth="1px"
																							borderColor={
																								formFieldsAccountsCreateDataRow.accountsGroupId ===
																								"group02"
																									? "red.400"
																									: "gray.200"
																							}
																							fontSize={20}
																							fontWeight={400}
																							color={
																								formFieldsAccountsCreateDataRow.accountsGroupId ===
																								"group02"
																									? "white"
																									: "black"
																							}
																							bg={
																								formFieldsAccountsCreateDataRow.accountsGroupId ===
																								"group02"
																									? "red.400"
																									: "gray.100"
																							}
																							_hover={{
																								bg: "red.50",
																								color: "#000",
																								borderWidth: "1px",
																								borderColor: "red.400",
																							}}
																						>
																							<Radio
																								p="2"
																								size="lg"
																								bg="white"
																								value="group02"
																								w="100%"
																								id="accountsGroupId"
																								name="accountsGroupId"
																								onChange={(event) =>
																									accountCreateFormChange(
																										event,
																										index
																									)
																								}
																							>
																								<Box ml="5">
																									<Text
																										fontSize={16}
																										fontWeight={500}
																									>
																										Group_02
																									</Text>
																								</Box>
																							</Radio>
																						</Box>
																					</GridItem>
																					<GridItem colSpan={4}>
																						<Box
																							w="100%"
																							textAlign={["left"]}
																							borderRadius="xl"
																							borderWidth="1px"
																							borderColor={
																								formFieldsAccountsCreateDataRow.accountsGroupId ===
																								"group03"
																									? "red.400"
																									: "gray.200"
																							}
																							fontSize={20}
																							fontWeight={400}
																							color={
																								formFieldsAccountsCreateDataRow.accountsGroupId ===
																								"group03"
																									? "white"
																									: "black"
																							}
																							bg={
																								formFieldsAccountsCreateDataRow.accountsGroupId ===
																								"group03"
																									? "red.400"
																									: "gray.100"
																							}
																							_hover={{
																								bg: "red.50",
																								color: "#000",
																								borderWidth: "1px",
																								borderColor: "red.400",
																							}}
																						>
																							<Radio
																								p="2"
																								size="lg"
																								bg="white"
																								value="group03"
																								w="100%"
																								id="accountsGroupId"
																								name="accountsGroupId"
																								onChange={(event) =>
																									accountCreateFormChange(
																										event,
																										index
																									)
																								}
																							>
																								<Box ml="5">
																									<Text
																										fontSize={16}
																										fontWeight={500}
																									>
																										Group_03
																									</Text>
																								</Box>
																							</Radio>
																						</Box>
																					</GridItem>
																				</Grid>
																			</RadioGroup>
																		</Box>
																	</GridItem>
																</Grid>
															</Box>

															{/* Profily setup */}
															<Box
																bg="#ffffff"
																borderRadius="xl"
																borderWidth="1px"
																borderColor="gray.400"
																shadow="lg"
																position="relative"
																mt="5"
																textAlign={["center"]}
																_hover={{
																	bg: "gray.50",
																	borderWidth: "2px",
																	borderColor: "red.400",
																}}
															>
																<Grid
																	w="100%"
																	p="1"
																	templateColumns="repeat(12, 1fr)"
																>
																	{/* Profile set */}
																	<GridItem
																		color="black"
																		colSpan={{
																			base: "12",
																			sm: "12",
																			md: "12",
																			lg: "12",
																			xl: "12",
																			"2xl": "12",
																		}}
																	>
																		<Box p={1} textAlign={["left"]}>
																			<Grid
																				w="100%"
																				gap={2}
																				p="2"
																				templateColumns="repeat(12, 1fr)"
																			>
																				<GridItem colSpan={12}>
																					<Text fontSize={16} fontWeight={700}>
																						PROFILE
																					</Text>
																				</GridItem>
																				<GridItem colSpan={3}>
																					<Text
																						color={"gray.400"}
																						fontSize={15}
																						fontWeight={400}
																					>
																						Sexe
																					</Text>
																					<Select
																						id="profileSexe"
																						name="profileSexe"
																						value={
																							formFieldsAccountsCreateDataRow.profileSexe
																						}
																						onChange={(event) =>
																							accountCreateFormChange(
																								event,
																								index
																							)
																						}
																						bg="white"
																					>
																						<option value="all">All</option>
																						<option value="male">Male</option>
																						<option value="female">
																							Female
																						</option>
																					</Select>
																				</GridItem>
																				<GridItem colSpan={3}>
																					<Text
																						color={"gray.400"}
																						fontSize={15}
																						fontWeight={400}
																					>
																						Lastname
																					</Text>
																					<Select
																						id="profileLastname"
																						name="profileLastname"
																						value={
																							formFieldsAccountsCreateDataRow.profileLastname
																						}
																						onChange={(event) =>
																							accountCreateFormChange(
																								event,
																								index
																							)
																						}
																						bg="white"
																					>
																						<option value="all">All</option>
																						<option value="english">
																							English
																						</option>
																						<option value="french">
																							French
																						</option>
																						<option value="hispanic">
																							Hispanic
																						</option>
																						<option value="american">
																							American
																						</option>
																						<option value="asian">Asian</option>
																						<option value="arabic">
																							Arabic
																						</option>
																					</Select>
																				</GridItem>
																				<GridItem colSpan={3}>
																					<Text
																						color={"gray.400"}
																						fontSize={15}
																						fontWeight={400}
																					>
																						Firstname
																					</Text>
																					<Select
																						id="profileFirstname"
																						name="profileFirstname"
																						value={
																							formFieldsAccountsCreateDataRow.profileFirstname
																						}
																						onChange={(event) =>
																							accountCreateFormChange(
																								event,
																								index
																							)
																						}
																						bg="white"
																					>
																						<option value="all">All</option>
																						<option value="english">
																							English
																						</option>
																						<option value="french">
																							French
																						</option>
																						<option value="hispanic">
																							Hispanic
																						</option>
																						<option value="american">
																							American
																						</option>
																						<option value="asian">Asian</option>
																						<option value="arabic">
																							Arabic
																						</option>
																					</Select>
																				</GridItem>
																				<GridItem colSpan={3}>
																					<Text
																						color={"gray.400"}
																						fontSize={15}
																						fontWeight={400}
																					>
																						Age
																					</Text>
																					<Select
																						id="profileAge"
																						name="profileAge"
																						value={
																							formFieldsAccountsCreateDataRow.profileAge
																						}
																						onChange={(event) =>
																							accountCreateFormChange(
																								event,
																								index
																							)
																						}
																						bg="white"
																					>
																						<option value="all">All</option>
																						<option value="18-25">
																							18-25 years
																						</option>
																						<option value="26-40">
																							26-40 years
																						</option>
																						<option value="41-65">
																							41-65 years
																						</option>
																					</Select>
																				</GridItem>
																			</Grid>
																		</Box>
																	</GridItem>

																	{/* Location set */}
																	<GridItem
																		color="black"
																		colSpan={{
																			base: "12",
																			sm: "12",
																			md: "12",
																			lg: "12",
																			xl: "12",
																			"2xl": "12",
																		}}
																	>
																		<Box p={1} textAlign={["left"]}>
																			<Grid
																				w="100%"
																				gap={2}
																				p="2"
																				templateColumns="repeat(12, 1fr)"
																			>
																				<GridItem colSpan={12}>
																					<Text fontSize={16} fontWeight={700}>
																						LOCATION
																					</Text>
																				</GridItem>
																				<GridItem colSpan={4}>
																					<Text
																						color={"gray.400"}
																						fontSize={15}
																						fontWeight={400}
																					>
																						Country
																					</Text>
																					<Select
																						id="profileCountry"
																						name="profileCountry"
																						value={
																							formFieldsAccountsCreateDataRow.profileCountry
																						}
																						onChange={(event) =>
																							accountCreateFormChange(
																								event,
																								index
																							)
																						}
																						bg="white"
																					>
																						<option value="all">All</option>
																						<option value="fr">France</option>
																					</Select>
																				</GridItem>
																				<GridItem colSpan={4}>
																					<Text
																						color={"gray.400"}
																						fontSize={15}
																						fontWeight={400}
																					>
																						City
																					</Text>
																					<Select
																						id="profileCity"
																						name="profileCity"
																						value={
																							formFieldsAccountsCreateDataRow.profileCity
																						}
																						onChange={(event) =>
																							accountCreateFormChange(
																								event,
																								index
																							)
																						}
																						bg="white"
																					>
																						<option value="all">All</option>
																						<option value="paris">Paris</option>
																					</Select>
																				</GridItem>
																				<GridItem colSpan={4}>
																					<Text
																						color={"gray.400"}
																						fontSize={15}
																						fontWeight={400}
																					>
																						Postal code
																					</Text>
																					<Input
																						id="profilePostalCode"
																						name="profilePostalCode"
																						value={
																							formFieldsAccountsCreateDataRow.profilePostalCode
																						}
																						onChange={(event) =>
																							accountCreateFormChange(
																								event,
																								index
																							)
																						}
																						bg="white"
																					/>
																				</GridItem>
																			</Grid>
																		</Box>
																	</GridItem>

																	{/* Contact set */}
																	<GridItem
																		color="black"
																		colSpan={{
																			base: "12",
																			sm: "12",
																			md: "12",
																			lg: "12",
																			xl: "12",
																			"2xl": "12",
																		}}
																	>
																		<Box p={1} textAlign={["left"]}>
																			<Grid
																				w="100%"
																				gap={2}
																				p="2"
																				templateColumns="repeat(12, 1fr)"
																			>
																				<GridItem colSpan={12}>
																					<Text fontSize={16} fontWeight={700}>
																						CONTACT
																					</Text>
																				</GridItem>
																				<GridItem colSpan={6}>
																					<Text
																						color={"gray.400"}
																						fontSize={15}
																						fontWeight={400}
																					>
																						Domain
																					</Text>
																					<Select
																						id="profileDomaineName"
																						name="profileDomaineName"
																						value={
																							formFieldsAccountsCreateDataRow.profileDomaineName
																						}
																						onChange={(event) =>
																							accountCreateFormChange(
																								event,
																								index
																							)
																						}
																						bg="white"
																					>
																						<option value="domainName_list01">
																							domainName_list01
																						</option>
																						<option value="domainName_list02">
																							domainName_list02
																						</option>
																						<option value="domainName_list03">
																							domainName_list03
																						</option>
																						<option value="domainName_list04">
																							domainName_list04
																						</option>
																					</Select>
																				</GridItem>
																				<GridItem colSpan={6}>
																					<Text
																						color={"gray.400"}
																						fontSize={15}
																						fontWeight={400}
																					>
																						Phone
																					</Text>
																					<Select
																						id="profilePhoneType"
																						name="profilePhoneType"
																						value={
																							formFieldsAccountsCreateDataRow.profilePhoneType
																						}
																						onChange={(event) =>
																							accountCreateFormChange(
																								event,
																								index
																							)
																						}
																						bg="white"
																					>
																						<option value="fakeMobilePhone_random">
																							fakeMobilePhone_random
																						</option>
																						<option value="realMobilePhone_list01">
																							realMobilePhone_list01
																						</option>
																						<option value="realMobilePhone_list02">
																							realMobilePhone_list02
																						</option>
																						<option value="realMobilePhone_list03">
																							realMobilePhone_list03
																						</option>
																					</Select>
																				</GridItem>
																			</Grid>
																		</Box>
																	</GridItem>
																</Grid>
															</Box>

															{/* Quantity setup */}
															<Box
																bg="#ffffff"
																borderRadius="xl"
																borderWidth="1px"
																borderColor="gray.400"
																shadow="lg"
																position="relative"
																mt="5"
																textAlign={["left"]}
																_hover={{
																	bg: "gray.50",
																	borderWidth: "2px",
																	borderColor: "red.400",
																}}
															>
																<Grid
																	w="100%"
																	p="1"
																	templateColumns="repeat(12, 1fr)"
																>
																	{/* Quantity set */}
																	<GridItem
																		color="black"
																		colSpan={{
																			base: "12",
																			sm: "12",
																			md: "12",
																			lg: "12",
																			xl: "12",
																			"2xl": "12",
																		}}
																	>
																		<Grid
																			w="100%"
																			gap={2}
																			p="2"
																			templateColumns="repeat(12, 1fr)"
																		>
																			<GridItem colSpan={12}>
																				<Text fontSize={16} fontWeight={700}>
																					QUANTITY
																				</Text>
																			</GridItem>
																			<GridItem colSpan={12}>
																				<VStack>
																					<HStack>
																						<IconButton
																							isRound={true}
																							variant="solid"
																							color="red.400"
																							bg="gray.100"
																							aria-label="Done"
																							fontSize="30px"
																							icon={<FiMinus />}
																							_hover={{
																								bg: "red.400",
																								color: "white",
																							}}
																							onClick={() => delAccountCreate()}
																						/>
																						<Text
																							ml="5"
																							mr="5"
																							color="red.400"
																							fontSize={35}
																							fontWeight={500}
																						>
																							{
																								formFieldsAccountsCreateDataRow.accountsCreateNb
																							}
																						</Text>
																						<IconButton
																							isRound={true}
																							variant="solid"
																							color="red.400"
																							bg="gray.100"
																							aria-label="Done"
																							fontSize="30px"
																							icon={<FiPlus />}
																							_hover={{
																								bg: "red.400",
																								color: "white",
																							}}
																							onClick={() => addAccountCreate()}
																						/>
																					</HStack>
																					<Text
																						mt="-3"
																						color="red.400"
																						fontSize={20}
																						fontWeight={500}
																					>
																						ACCOUNT
																						{formFieldsAccountsCreateDataRow.accountsCreateNb >
																						1 ? (
																							<>S</>
																						) : null}
																					</Text>
																				</VStack>
																			</GridItem>
																		</Grid>
																	</GridItem>
																</Grid>
															</Box>

															{/* Button */}
															<Button
																w="100%"
																h="75px"
																mt="5"
																bg={canCreateAccounts ? "white" : "white"}
																color={
																	canCreateAccounts ? "red.400" : "gray.400"
																}
																borderColor={
																	canCreateAccounts ? "red.400" : "gray.400"
																}
																borderWidth="2px"
																_hover={
																	canCreateAccounts
																		? {
																				bg: "red.400",
																				color: "white",
																				borderWidth: "2px",
																				borderColor: "red.400",
																		  }
																		: {
																				bg: "gray.400",
																				color: "white",
																				borderWidth: "2px",
																				borderColor: "gray.400",
																		  }
																}
																onClick={onSaveCreateAccountsClicked}
																disabled={!canCreateAccounts}
															>
																<Text
																	fontSize={{
																		base: "16",
																		sm: "16",
																		md: "16",
																		lg: "16",
																		xl: "22",
																	}}
																	fontWeight={600}
																>
																	CREATE ACCOUNT
																	{formFieldsAccountsCreateDataRow.accountsCreateNb >
																	1 ? (
																		<>S</>
																	) : null}
																</Text>
															</Button>

															{/* Debug Box */}
															<Box
																mt="5"
																p="1"
																color="blue.600"
																bg="gray.100"
																borderRadius="xl"
																borderWidth="1px"
																borderColor="gray.400"
															>
																<Text mb="1" fontSize={18} fontWeight={700}>
																	DEBUG BOX
																</Text>
																<Text fontSize={15} fontWeight={600}>
																	<strong>
																		formFieldsAccountsCreateDataRow ={" "}
																	</strong>
																	{JSON.stringify(
																		formFieldsAccountsCreateDataRow
																	)}
																</Text>
															</Box>
														</DrawerBody>
													</DrawerContent>
												</Drawer>
											);
										}
									)}

									{/* Drawer Accounts Add */}
									<Drawer
										isOpen={isOpenAccountsAdd ?? false}
										onClose={() => drawerIsOpenAccountsAdd()}
										size="lg"
									>
										<DrawerOverlay />
										<DrawerContent>
											<DrawerCloseButton />
											<DrawerHeader>
												<HStack>
													<BsPlusCircleFill fontSize={25} />
													<Text fontSize={25} fontWeight={800}>
														{" "}
														ADD ACCOUNTS
													</Text>
												</HStack>
											</DrawerHeader>
											<DrawerBody>
												{/* Support setup */}
												<Box
													bg="#ffffff"
													borderRadius="xl"
													borderWidth="1px"
													borderColor="gray.400"
													shadow="lg"
													position="relative"
													mt="5"
													textAlign={["center"]}
													_hover={{
														bg: "gray.50",
														borderWidth: "2px",
														borderColor: "red.400",
													}}
												>
													<Grid
														w="100%"
														p="1"
														templateColumns="repeat(12, 1fr)"
													>
														{/* Support set */}
														<GridItem
															color="black"
															colSpan={{
																base: "12",
																sm: "12",
																md: "12",
																lg: "12",
																xl: "12",
																"2xl": "12",
															}}
														>
															<Box p={1} textAlign={["left"]}>
																<Grid
																	w="100%"
																	gap={2}
																	p="2"
																	templateColumns="repeat(12, 1fr)"
																>
																	<GridItem colSpan={12}>
																		<Text fontSize={16} fontWeight={700}>
																			SUPPORT
																		</Text>
																	</GridItem>
																	<GridItem colSpan={12}>
																		<RadioGroup defaultValue="">
																			<Tabs variant="soft-rounded" isFitted>
																				<TabList overflowX="auto">
																					<Tab
																						bg="gray.50"
																						_selected={{
																							color: "white",
																							bg: "red.400",
																						}}
																						_hover={{
																							bg: "gray.300",
																							color: "black",
																							borderColor: "gray.300",
																						}}
																						m={1}
																						fontSize={18}
																						fontWeight={700}
																					>
																						FR
																					</Tab>
																					<Tab
																						bg="gray.50"
																						_selected={{
																							color: "white",
																							bg: "red.400",
																						}}
																						_hover={{
																							bg: "gray.300",
																							color: "black",
																							borderColor: "gray.300",
																						}}
																						m={1}
																						fontSize={18}
																						fontWeight={700}
																					>
																						ES
																					</Tab>
																					<Tab
																						bg="gray.50"
																						_selected={{
																							color: "white",
																							bg: "red.400",
																						}}
																						_hover={{
																							bg: "gray.300",
																							color: "black",
																							borderColor: "gray.300",
																						}}
																						m={1}
																						fontSize={18}
																						fontWeight={700}
																					>
																						PT
																					</Tab>
																					<Tab
																						bg="gray.50"
																						_selected={{
																							color: "white",
																							bg: "red.400",
																						}}
																						_hover={{
																							bg: "gray.300",
																							color: "black",
																							borderColor: "gray.300",
																						}}
																						m={1}
																						fontSize={18}
																						fontWeight={700}
																					>
																						IT
																					</Tab>
																					<Tab
																						bg="gray.50"
																						_selected={{
																							color: "white",
																							bg: "red.400",
																						}}
																						_hover={{
																							bg: "gray.300",
																							color: "black",
																							borderColor: "gray.300",
																						}}
																						m={1}
																						fontSize={18}
																						fontWeight={700}
																					>
																						BE
																					</Tab>
																					<Tab
																						bg="gray.50"
																						_selected={{
																							color: "white",
																							bg: "red.400",
																						}}
																						_hover={{
																							bg: "gray.300",
																							color: "black",
																							borderColor: "gray.300",
																						}}
																						m={1}
																						fontSize={18}
																						fontWeight={700}
																					>
																						CH
																					</Tab>
																					<Tab
																						bg="gray.50"
																						_selected={{
																							color: "white",
																							bg: "red.400",
																						}}
																						_hover={{
																							bg: "gray.300",
																							color: "black",
																							borderColor: "gray.300",
																						}}
																						m={1}
																						fontSize={18}
																						fontWeight={700}
																					>
																						DE
																					</Tab>
																					<Tab
																						bg="gray.50"
																						_selected={{
																							color: "white",
																							bg: "red.400",
																						}}
																						_hover={{
																							bg: "gray.300",
																							color: "black",
																							borderColor: "gray.300",
																						}}
																						m={1}
																						fontSize={18}
																						fontWeight={700}
																					>
																						LU
																					</Tab>
																				</TabList>

																				<TabPanels minH="350px">
																					{/* FR */}
																					<TabPanel>
																						<VStack p="4" direction="row">
																							{/* Ticketmaster.fr */}
																							<Box
																								w="100%"
																								textAlign={["left"]}
																								borderRadius="xl"
																								borderWidth="1px"
																								borderColor={
																									accountsAddSupportId ===
																									"ticketmasterfr"
																										? "red.400"
																										: "gray.200"
																								}
																								fontSize={20}
																								fontWeight={400}
																								color={
																									accountsAddSupportId ===
																									"ticketmasterfr"
																										? "white"
																										: "black"
																								}
																								bg={
																									accountsAddSupportId ===
																									"ticketmasterfr"
																										? "red.400"
																										: "gray.100"
																								}
																								_hover={{
																									bg: "red.50",
																									color: "#000",
																									borderWidth: "1px",
																									borderColor: "red.400",
																								}}
																							>
																								<Radio
																									p="2"
																									size="lg"
																									bg="white"
																									value="ticketmasterfr"
																									w="100%"
																									id="accountSupportId"
																									name="accountSupportId"
																									onChange={(event) =>
																										accountAddSupportChange(
																											event
																										)
																									}
																								>
																									<HStack>
																										<Box ml="5">
																											<Text
																												fontSize={20}
																												fontWeight={700}
																											>
																												Ticketmaster.fr
																											</Text>
																										</Box>
																										<Spacer />
																									</HStack>
																								</Radio>
																							</Box>

																							{/* AccorArena.com */}
																							<Box
																								w="100%"
																								textAlign={["left"]}
																								borderRadius="xl"
																								borderWidth="1px"
																								borderColor={
																									accountsAddSupportId ===
																									"accorarenacom"
																										? "red.400"
																										: "gray.200"
																								}
																								fontSize={20}
																								fontWeight={400}
																								color={
																									accountsAddSupportId ===
																									"accorarenacom"
																										? "white"
																										: "black"
																								}
																								bg={
																									accountsAddSupportId ===
																									"accorarenacom"
																										? "red.400"
																										: "gray.100"
																								}
																								_hover={{
																									bg: "red.50",
																									color: "#000",
																									borderWidth: "1px",
																									borderColor: "red.400",
																								}}
																							>
																								<Radio
																									p="2"
																									size="lg"
																									bg="white"
																									value="accorarenacom"
																									w="100%"
																									id="accountSupportId"
																									name="accountSupportId"
																									onChange={(event) =>
																										accountAddSupportChange(
																											event
																										)
																									}
																								>
																									<HStack>
																										<Box ml="5">
																											<Text
																												fontSize={20}
																												fontWeight={700}
																											>
																												AccorArena.com
																											</Text>
																										</Box>
																										<Spacer />
																									</HStack>
																								</Radio>
																							</Box>

																							{/* Fnacspectacle.com */}
																							<Box
																								w="100%"
																								textAlign={["left"]}
																								borderRadius="xl"
																								borderWidth="1px"
																								borderColor={
																									accountsAddSupportId ===
																									"fnacspectaclecom"
																										? "red.400"
																										: "gray.200"
																								}
																								fontSize={20}
																								fontWeight={400}
																								color={
																									accountsAddSupportId ===
																									"fnacspectaclecom"
																										? "white"
																										: "black"
																								}
																								bg={
																									accountsAddSupportId ===
																									"fnacspectaclecom"
																										? "red.400"
																										: "gray.100"
																								}
																								_hover={{
																									bg: "red.50",
																									color: "#000",
																									borderWidth: "1px",
																									borderColor: "red.400",
																								}}
																							>
																								<Radio
																									p="2"
																									size="lg"
																									bg="white"
																									value="fnacspectaclecom"
																									w="100%"
																									id="accountSupportId"
																									name="accountSupportId"
																									onChange={(event) =>
																										accountAddSupportChange(
																											event
																										)
																									}
																								>
																									<HStack>
																										<Box ml="5">
																											<Text
																												fontSize={20}
																												fontWeight={700}
																											>
																												Fnacspectacle.com
																											</Text>
																										</Box>
																										<Spacer />
																									</HStack>
																								</Radio>
																							</Box>

																							{/* StadeFrance.com */}
																							<Box
																								w="100%"
																								textAlign={["left"]}
																								borderRadius="xl"
																								borderWidth="1px"
																								borderColor={
																									accountsAddSupportId ===
																									"stadefrancecom"
																										? "red.400"
																										: "gray.200"
																								}
																								fontSize={20}
																								fontWeight={400}
																								color={
																									accountsAddSupportId ===
																									"stadefrancecom"
																										? "white"
																										: "black"
																								}
																								bg={
																									accountsAddSupportId ===
																									"stadefrancecom"
																										? "red.400"
																										: "gray.100"
																								}
																								_hover={{
																									bg: "red.50",
																									color: "#000",
																									borderWidth: "1px",
																									borderColor: "red.400",
																								}}
																							>
																								<Radio
																									p="2"
																									size="lg"
																									bg="white"
																									value="stadefrancecom"
																									w="100%"
																									id="accountSupportId"
																									name="accountSupportId"
																									onChange={(event) =>
																										accountAddSupportChange(
																											event
																										)
																									}
																								>
																									<HStack>
																										<Box ml="5">
																											<Text
																												fontSize={20}
																												fontWeight={700}
																											>
																												StadeFrance.com
																											</Text>
																										</Box>
																										<Spacer />
																									</HStack>
																								</Radio>
																							</Box>

																							{/* OlympiaHall.com */}
																							<Box
																								w="100%"
																								textAlign={["left"]}
																								borderRadius="xl"
																								borderWidth="1px"
																								borderColor={
																									accountsAddSupportId ===
																									"olympiahallcom"
																										? "red.400"
																										: "gray.200"
																								}
																								fontSize={20}
																								fontWeight={400}
																								color={
																									accountsAddSupportId ===
																									"olympiahallcom"
																										? "white"
																										: "black"
																								}
																								bg={
																									accountsAddSupportId ===
																									"olympiahallcom"
																										? "red.400"
																										: "gray.100"
																								}
																								_hover={{
																									bg: "red.50",
																									color: "#000",
																									borderWidth: "1px",
																									borderColor: "red.400",
																								}}
																							>
																								<Radio
																									p="2"
																									size="lg"
																									bg="white"
																									value="olympiahallcom"
																									w="100%"
																									id="accountSupportId"
																									name="accountSupportId"
																									onChange={(event) =>
																										accountAddSupportChange(
																											event
																										)
																									}
																								>
																									<HStack>
																										<Box ml="5">
																											<Text
																												fontSize={20}
																												fontWeight={700}
																											>
																												OlympiaHall.com
																											</Text>
																										</Box>
																										<Spacer />
																									</HStack>
																								</Radio>
																							</Box>
																						</VStack>
																					</TabPanel>

																					{/* ES */}
																					<TabPanel>
																						<Text>No support !</Text>
																					</TabPanel>

																					{/* PT */}
																					<TabPanel>
																						<VStack p="4" direction="row">
																							{/* Ticketline.pt */}
																							<Box
																								w="100%"
																								textAlign={["left"]}
																								borderRadius="xl"
																								borderWidth="1px"
																								borderColor={
																									accountsAddSupportId ===
																									"ticketlinept"
																										? "red.400"
																										: "gray.200"
																								}
																								fontSize={20}
																								fontWeight={400}
																								color={
																									accountsAddSupportId ===
																									"ticketlinept"
																										? "white"
																										: "black"
																								}
																								bg={
																									accountsAddSupportId ===
																									"ticketlinept"
																										? "red.400"
																										: "gray.100"
																								}
																								_hover={{
																									bg: "red.50",
																									color: "#000",
																									borderWidth: "1px",
																									borderColor: "red.400",
																								}}
																							>
																								<Radio
																									p="2"
																									size="lg"
																									bg="white"
																									value="ticketlinept"
																									w="100%"
																									id="accountSupportId"
																									name="accountSupportId"
																									onChange={(event) =>
																										accountAddSupportChange(
																											event
																										)
																									}
																								>
																									<HStack>
																										<Box ml="5">
																											<Text
																												fontSize={20}
																												fontWeight={700}
																											>
																												Ticketline.pt
																											</Text>
																										</Box>
																										<Spacer />
																									</HStack>
																								</Radio>
																							</Box>

																							{/* Blueticket.meo.pt */}
																							<Box
																								w="100%"
																								textAlign={["left"]}
																								borderRadius="xl"
																								borderWidth="1px"
																								borderColor={
																									accountsAddSupportId ===
																									"blueticketmeopt"
																										? "red.400"
																										: "gray.200"
																								}
																								fontSize={20}
																								fontWeight={400}
																								color={
																									accountsAddSupportId ===
																									"blueticketmeopt"
																										? "white"
																										: "black"
																								}
																								bg={
																									accountsAddSupportId ===
																									"blueticketmeopt"
																										? "red.400"
																										: "gray.100"
																								}
																								_hover={{
																									bg: "red.50",
																									color: "#000",
																									borderWidth: "1px",
																									borderColor: "red.400",
																								}}
																							>
																								<Radio
																									p="2"
																									size="lg"
																									bg="white"
																									value="blueticketmeopt"
																									w="100%"
																									id="accountSupportId"
																									name="accountSupportId"
																									onChange={(event) =>
																										accountAddSupportChange(
																											event
																										)
																									}
																								>
																									<HStack>
																										<Box ml="5">
																											<Text
																												fontSize={20}
																												fontWeight={700}
																											>
																												Blueticket.meo.pt
																											</Text>
																										</Box>
																										<Spacer />
																									</HStack>
																								</Radio>
																							</Box>
																						</VStack>
																					</TabPanel>

																					{/* IT */}
																					<TabPanel>
																						<Text>No support !</Text>
																					</TabPanel>
																				</TabPanels>
																			</Tabs>
																		</RadioGroup>
																	</GridItem>
																</Grid>
															</Box>
														</GridItem>
													</Grid>
												</Box>

												{/* Group setup */}
												<Box
													bg="#ffffff"
													borderRadius="xl"
													borderWidth="1px"
													borderColor="gray.400"
													shadow="lg"
													position="relative"
													mt="5"
													textAlign={["center"]}
													_hover={{
														bg: "gray.50",
														borderWidth: "2px",
														borderColor: "red.400",
													}}
												>
													<Grid
														w="100%"
														p="1"
														templateColumns="repeat(12, 1fr)"
													>
														<GridItem
															color="black"
															colSpan={{
																base: "12",
																sm: "12",
																md: "12",
																lg: "12",
																xl: "12",
																"2xl": "12",
															}}
														>
															<Box p={1} textAlign={["left"]}>
																<RadioGroup defaultValue="">
																	<Grid
																		w="100%"
																		gap={2}
																		p="2"
																		templateColumns="repeat(12, 1fr)"
																	>
																		<GridItem colSpan={6}>
																			<Text fontSize={16} fontWeight={700}>
																				GROUP
																			</Text>
																		</GridItem>
																		<GridItem
																			colSpan={6}
																			textAlign={["right"]}
																		></GridItem>
																		<GridItem colSpan={4}>
																			<Box
																				w="100%"
																				textAlign={["left"]}
																				borderRadius="xl"
																				borderWidth="1px"
																				borderColor={
																					accountsAddGroupId === "group01"
																						? "red.400"
																						: "gray.200"
																				}
																				fontSize={20}
																				fontWeight={400}
																				color={
																					accountsAddGroupId === "group01"
																						? "white"
																						: "black"
																				}
																				bg={
																					accountsAddGroupId === "group01"
																						? "red.400"
																						: "gray.100"
																				}
																				_hover={{
																					bg: "red.50",
																					color: "#000",
																					borderWidth: "1px",
																					borderColor: "red.400",
																				}}
																			>
																				<Radio
																					p="2"
																					size="lg"
																					bg="white"
																					value="group01"
																					w="100%"
																					id="accountsAddGroupId"
																					name="accountsAddGroupId"
																					onChange={(event) =>
																						accountAddGroupChange(event)
																					}
																				>
																					<Box ml="5">
																						<Text
																							fontSize={16}
																							fontWeight={500}
																						>
																							Group_01
																						</Text>
																					</Box>
																				</Radio>
																			</Box>
																		</GridItem>
																		<GridItem colSpan={4}>
																			<Box
																				w="100%"
																				textAlign={["left"]}
																				borderRadius="xl"
																				borderWidth="1px"
																				borderColor={
																					accountsAddGroupId === "group02"
																						? "red.400"
																						: "gray.200"
																				}
																				fontSize={20}
																				fontWeight={400}
																				color={
																					accountsAddGroupId === "group02"
																						? "white"
																						: "black"
																				}
																				bg={
																					accountsAddGroupId === "group02"
																						? "red.400"
																						: "gray.100"
																				}
																				_hover={{
																					bg: "red.50",
																					color: "#000",
																					borderWidth: "1px",
																					borderColor: "red.400",
																				}}
																			>
																				<Radio
																					p="2"
																					size="lg"
																					bg="white"
																					value="group02"
																					w="100%"
																					id="accountsAddGroupId"
																					name="accountsAddGroupId"
																					onChange={(event) =>
																						accountAddGroupChange(event)
																					}
																				>
																					<Box ml="5">
																						<Text
																							fontSize={16}
																							fontWeight={500}
																						>
																							Group_02
																						</Text>
																					</Box>
																				</Radio>
																			</Box>
																		</GridItem>
																		<GridItem colSpan={4}>
																			<Box
																				w="100%"
																				textAlign={["left"]}
																				borderRadius="xl"
																				borderWidth="1px"
																				borderColor={
																					accountsAddGroupId === "group03"
																						? "red.400"
																						: "gray.200"
																				}
																				fontSize={20}
																				fontWeight={400}
																				color={
																					accountsAddGroupId === "group03"
																						? "white"
																						: "black"
																				}
																				bg={
																					accountsAddGroupId === "group03"
																						? "red.400"
																						: "gray.100"
																				}
																				_hover={{
																					bg: "red.50",
																					color: "#000",
																					borderWidth: "1px",
																					borderColor: "red.400",
																				}}
																			>
																				<Radio
																					p="2"
																					size="lg"
																					bg="white"
																					value="group03"
																					w="100%"
																					id="accountsAddGroupId"
																					name="accountsAddGroupId"
																					onChange={(event) =>
																						accountAddGroupChange(event)
																					}
																				>
																					<Box ml="5">
																						<Text
																							fontSize={16}
																							fontWeight={500}
																						>
																							Group_03
																						</Text>
																					</Box>
																				</Radio>
																			</Box>
																		</GridItem>
																	</Grid>
																</RadioGroup>
															</Box>
														</GridItem>
													</Grid>
												</Box>

												{/* Account setup */}
												{formFieldsAccountsAddData.map(
													(formFieldsAccountsAddDataRow, index) => {
														return (
															<>
																{/* Account set */}
																<Box
																	key={index}
																	bg="#ffffff"
																	borderRadius="xl"
																	borderWidth="1px"
																	borderColor="gray.400"
																	shadow="lg"
																	position="relative"
																	mt="5"
																	textAlign={["center"]}
																	_hover={{
																		bg: "gray.50",
																		borderWidth: "2px",
																		borderColor: "red.400",
																	}}
																>
																	<Grid
																		w="100%"
																		p="1"
																		templateColumns="repeat(12, 1fr)"
																	>
																		<GridItem
																			color="black"
																			colSpan={{
																				base: "12",
																				sm: "12",
																				md: "12",
																				lg: "12",
																				xl: "12",
																				"2xl": "12",
																			}}
																		>
																			<Box p={1} textAlign={["left"]}>
																				<Grid
																					w="100%"
																					gap={2}
																					p="2"
																					templateColumns="repeat(12, 1fr)"
																				>
																					<GridItem colSpan={6}>
																						<Text
																							fontSize={16}
																							fontWeight={700}
																						>
																							ACCOUNT #{index + 1}
																						</Text>
																					</GridItem>
																					<GridItem
																						colSpan={6}
																						textAlign={["right"]}
																					>
																						<IconButton
																							variant="solid"
																							color="red.400"
																							bg="gray.100"
																							aria-label="Done"
																							fontSize="20px"
																							w="15px"
																							h="15px"
																							icon={<FiMinus />}
																							_hover={{
																								bg: "red.400",
																								color: "white",
																							}}
																							onClick={() =>
																								delAccountAdd(index)
																							}
																						/>
																					</GridItem>
																					<GridItem colSpan={6}>
																						<Text
																							color={"gray.400"}
																							fontSize={15}
																							fontWeight={400}
																						>
																							Login
																						</Text>
																						<Input
																							isInvalid={
																								!formFieldsAccountsAddDataRow.login
																							}
																							id="login"
																							name="login"
																							value={
																								formFieldsAccountsAddDataRow.login
																							}
																							onChange={(event) =>
																								accountAddFormChange(
																									event,
																									index
																								)
																							}
																							focusBorderColor="blue.400"
																							errorBorderColor="red.300"
																							bg="yellow.100"
																						/>
																					</GridItem>
																					<GridItem colSpan={6}>
																						<Text
																							color={"gray.400"}
																							fontSize={15}
																							fontWeight={400}
																						>
																							Password
																						</Text>
																						<Input
																							isInvalid={
																								!formFieldsAccountsAddDataRow.password
																							}
																							id="password"
																							name="password"
																							value={
																								formFieldsAccountsAddDataRow.password
																							}
																							onChange={(event) =>
																								accountAddFormChange(
																									event,
																									index
																								)
																							}
																							focusBorderColor="blue.400"
																							errorBorderColor="red.300"
																							bg="yellow.100"
																						/>
																					</GridItem>
																				</Grid>
																			</Box>
																		</GridItem>
																	</Grid>
																</Box>
															</>
														);
													}
												)}

												<Center p="3">
													<IconButton
														isRound={true}
														variant="solid"
														color="red.400"
														bg="gray.100"
														aria-label="Done"
														fontSize="30px"
														icon={<FiPlus />}
														_hover={{ bg: "red.400", color: "white" }}
														onClick={() => addAccountAdd()}
													/>
												</Center>

												{/* Button */}
												<Button
													w="100%"
													h="75px"
													mt="5"
													bg={canAddAccounts ? "white" : "white"}
													color={canAddAccounts ? "red.400" : "gray.400"}
													borderColor={canAddAccounts ? "red.400" : "gray.400"}
													borderWidth="2px"
													_hover={
														canAddAccounts
															? {
																	bg: "red.400",
																	color: "white",
																	borderWidth: "2px",
																	borderColor: "red.400",
															  }
															: {
																	bg: "gray.400",
																	color: "white",
																	borderWidth: "2px",
																	borderColor: "gray.400",
															  }
													}
													onClick={onSaveAddAccountsClicked}
													disabled={!canAddAccounts}
												>
													<Text
														fontSize={{
															base: "16",
															sm: "16",
															md: "16",
															lg: "16",
															xl: "22",
														}}
														fontWeight={600}
													>
														ADD ACCOUNT
														{formFieldsAccountsAddData.length > 1 ? (
															<>S</>
														) : null}
													</Text>
												</Button>

												{/* Debug Box */}
												<Box
													mt="5"
													p="1"
													color="blue.600"
													bg="gray.100"
													borderRadius="xl"
													borderWidth="1px"
													borderColor="gray.400"
												>
													<Text mb="1" fontSize={18} fontWeight={700}>
														DEBUG BOX
													</Text>
													<Text fontSize={15} fontWeight={600}>
														<strong>accountsAddSupportId = </strong>
														{JSON.stringify(accountsAddSupportId)}
														<br />
														<strong>accountsAddGroupId = </strong>
														{JSON.stringify(accountsAddGroupId)}
														<br />
														<strong>accountsAddDataNb = </strong>
														{JSON.stringify(accountsAddDataNb)}
														<br />
														<strong>formFieldsAccountsAddData = </strong>
														{JSON.stringify(formFieldsAccountsAddData)}
														<br />
													</Text>
												</Box>
											</DrawerBody>
										</DrawerContent>
									</Drawer>

									{/* Drawer Accounts Edit */}
									{formFieldsAccountEditData.length > 0 ? (
										formFieldsAccountEditData.map(
											(formFieldsAccountEditDataRow, index) => (
												<Drawer
													key="index"
													isOpen={isOpenAccountsEdit ?? false}
													onClose={() => drawerIsOpenAccountsEdit()}
													size="lg"
												>
													<DrawerOverlay />
													<DrawerContent>
														<DrawerCloseButton />
														<DrawerHeader>
															<HStack>
																<BsPencilFill fontSize={25} />
																<Text fontSize={25} fontWeight={800}>
																	{" "}
																	EDIT ACCOUNTS
																</Text>
															</HStack>
														</DrawerHeader>
														<DrawerBody>
															{/* Support setup */}
															<Box
																bg="#ffffff"
																borderRadius="xl"
																borderWidth="1px"
																borderColor="gray.400"
																shadow="lg"
																position="relative"
																mt="5"
																textAlign={["center"]}
																_hover={{
																	bg: "gray.50",
																	borderWidth: "2px",
																	borderColor: "red.400",
																}}
															>
																<Grid
																	w="100%"
																	p="1"
																	templateColumns="repeat(12, 1fr)"
																>
																	{/* Support set */}
																	<GridItem
																		color="black"
																		colSpan={{
																			base: "12",
																			sm: "12",
																			md: "12",
																			lg: "12",
																			xl: "12",
																			"2xl": "12",
																		}}
																	>
																		<Box p={1} textAlign={["left"]}>
																			<Grid
																				w="100%"
																				gap={2}
																				p="2"
																				templateColumns="repeat(12, 1fr)"
																			>
																				<GridItem colSpan={12}>
																					<Text fontSize={16} fontWeight={700}>
																						SUPPORT
																					</Text>
																				</GridItem>
																				<GridItem colSpan={12}>
																					<RadioGroup
																						defaultValue={
																							formFieldsAccountEditDataRow.accountSupportId
																						}
																					>
																						<Tabs
																							variant="soft-rounded"
																							isFitted
																						>
																							<TabList overflowX="auto">
																								<Tab
																									bg="gray.50"
																									_selected={{
																										color: "white",
																										bg: "red.400",
																									}}
																									_hover={{
																										bg: "gray.300",
																										color: "black",
																										borderColor: "gray.300",
																									}}
																									m={1}
																									fontSize={18}
																									fontWeight={700}
																								>
																									FR
																								</Tab>
																								<Tab
																									bg="gray.50"
																									_selected={{
																										color: "white",
																										bg: "red.400",
																									}}
																									_hover={{
																										bg: "gray.300",
																										color: "black",
																										borderColor: "gray.300",
																									}}
																									m={1}
																									fontSize={18}
																									fontWeight={700}
																								>
																									ES
																								</Tab>
																								<Tab
																									bg="gray.50"
																									_selected={{
																										color: "white",
																										bg: "red.400",
																									}}
																									_hover={{
																										bg: "gray.300",
																										color: "black",
																										borderColor: "gray.300",
																									}}
																									m={1}
																									fontSize={18}
																									fontWeight={700}
																								>
																									PT
																								</Tab>
																								<Tab
																									bg="gray.50"
																									_selected={{
																										color: "white",
																										bg: "red.400",
																									}}
																									_hover={{
																										bg: "gray.300",
																										color: "black",
																										borderColor: "gray.300",
																									}}
																									m={1}
																									fontSize={18}
																									fontWeight={700}
																								>
																									IT
																								</Tab>
																								<Tab
																									bg="gray.50"
																									_selected={{
																										color: "white",
																										bg: "red.400",
																									}}
																									_hover={{
																										bg: "gray.300",
																										color: "black",
																										borderColor: "gray.300",
																									}}
																									m={1}
																									fontSize={18}
																									fontWeight={700}
																								>
																									BE
																								</Tab>
																								<Tab
																									bg="gray.50"
																									_selected={{
																										color: "white",
																										bg: "red.400",
																									}}
																									_hover={{
																										bg: "gray.300",
																										color: "black",
																										borderColor: "gray.300",
																									}}
																									m={1}
																									fontSize={18}
																									fontWeight={700}
																								>
																									CH
																								</Tab>
																								<Tab
																									bg="gray.50"
																									_selected={{
																										color: "white",
																										bg: "red.400",
																									}}
																									_hover={{
																										bg: "gray.300",
																										color: "black",
																										borderColor: "gray.300",
																									}}
																									m={1}
																									fontSize={18}
																									fontWeight={700}
																								>
																									DE
																								</Tab>
																								<Tab
																									bg="gray.50"
																									_selected={{
																										color: "white",
																										bg: "red.400",
																									}}
																									_hover={{
																										bg: "gray.300",
																										color: "black",
																										borderColor: "gray.300",
																									}}
																									m={1}
																									fontSize={18}
																									fontWeight={700}
																								>
																									LU
																								</Tab>
																							</TabList>

																							<TabPanels minH="350px">
																								{/* FR */}
																								<TabPanel>
																									<VStack p="4" direction="row">
																										{/* Ticketmaster.fr */}
																										<Box
																											w="100%"
																											textAlign={["left"]}
																											borderRadius="xl"
																											borderWidth="1px"
																											borderColor={
																												formFieldsAccountEditDataRow.accountSupportId ===
																												"ticketmasterfr"
																													? "red.400"
																													: "gray.200"
																											}
																											fontSize={20}
																											fontWeight={400}
																											color={
																												formFieldsAccountEditDataRow.accountSupportId ===
																												"ticketmasterfr"
																													? "white"
																													: "black"
																											}
																											bg={
																												formFieldsAccountEditDataRow.accountSupportId ===
																												"ticketmasterfr"
																													? "red.400"
																													: "gray.100"
																											}
																											_hover={{
																												bg: "red.50",
																												color: "#000",
																												borderWidth: "1px",
																												borderColor: "red.400",
																											}}
																										>
																											<Radio
																												p="2"
																												size="lg"
																												bg="white"
																												value="ticketmasterfr"
																												w="100%"
																												id="accountSupportId"
																												name="accountSupportId"
																												onChange={(event) =>
																													accountEditFormChange(
																														event,
																														index
																													)
																												}
																											>
																												<HStack>
																													<Box ml="5">
																														<Text
																															fontSize={20}
																															fontWeight={700}
																														>
																															Ticketmaster.fr
																														</Text>
																													</Box>
																													<Spacer />
																												</HStack>
																											</Radio>
																										</Box>

																										{/* AccorArena.com */}
																										<Box
																											w="100%"
																											textAlign={["left"]}
																											borderRadius="xl"
																											borderWidth="1px"
																											borderColor={
																												formFieldsAccountEditDataRow.accountSupportId ===
																												"accorarenacom"
																													? "red.400"
																													: "gray.200"
																											}
																											fontSize={20}
																											fontWeight={400}
																											color={
																												formFieldsAccountEditDataRow.accountSupportId ===
																												"accorarenacom"
																													? "white"
																													: "black"
																											}
																											bg={
																												formFieldsAccountEditDataRow.accountSupportId ===
																												"accorarenacom"
																													? "red.400"
																													: "gray.100"
																											}
																											_hover={{
																												bg: "red.50",
																												color: "#000",
																												borderWidth: "1px",
																												borderColor: "red.400",
																											}}
																										>
																											<Radio
																												p="2"
																												size="lg"
																												bg="white"
																												value="accorarenacom"
																												w="100%"
																												id="accountSupportId"
																												name="accountSupportId"
																												onChange={(event) =>
																													accountEditFormChange(
																														event,
																														index
																													)
																												}
																											>
																												<HStack>
																													<Box ml="5">
																														<Text
																															fontSize={20}
																															fontWeight={700}
																														>
																															AccorArena.com
																														</Text>
																													</Box>
																													<Spacer />
																												</HStack>
																											</Radio>
																										</Box>

																										{/* Fnacspectacle.com */}
																										<Box
																											w="100%"
																											textAlign={["left"]}
																											borderRadius="xl"
																											borderWidth="1px"
																											borderColor={
																												formFieldsAccountEditDataRow.accountSupportId ===
																												"fnacspectaclecom"
																													? "red.400"
																													: "gray.200"
																											}
																											fontSize={20}
																											fontWeight={400}
																											color={
																												formFieldsAccountEditDataRow.accountSupportId ===
																												"fnacspectaclecom"
																													? "white"
																													: "black"
																											}
																											bg={
																												formFieldsAccountEditDataRow.accountSupportId ===
																												"fnacspectaclecom"
																													? "red.400"
																													: "gray.100"
																											}
																											_hover={{
																												bg: "red.50",
																												color: "#000",
																												borderWidth: "1px",
																												borderColor: "red.400",
																											}}
																										>
																											<Radio
																												p="2"
																												size="lg"
																												bg="white"
																												value="fnacspectaclecom"
																												w="100%"
																												id="accountSupportId"
																												name="accountSupportId"
																												onChange={(event) =>
																													accountEditFormChange(
																														event,
																														index
																													)
																												}
																											>
																												<HStack>
																													<Box ml="5">
																														<Text
																															fontSize={20}
																															fontWeight={700}
																														>
																															Fnacspectacle.com
																														</Text>
																													</Box>
																													<Spacer />
																												</HStack>
																											</Radio>
																										</Box>

																										{/* StadeFrance.com */}
																										<Box
																											w="100%"
																											textAlign={["left"]}
																											borderRadius="xl"
																											borderWidth="1px"
																											borderColor={
																												formFieldsAccountEditDataRow.accountSupportId ===
																												"stadefrancecom"
																													? "red.400"
																													: "gray.200"
																											}
																											fontSize={20}
																											fontWeight={400}
																											color={
																												formFieldsAccountEditDataRow.accountSupportId ===
																												"stadefrancecom"
																													? "white"
																													: "black"
																											}
																											bg={
																												formFieldsAccountEditDataRow.accountSupportId ===
																												"stadefrancecom"
																													? "red.400"
																													: "gray.100"
																											}
																											_hover={{
																												bg: "red.50",
																												color: "#000",
																												borderWidth: "1px",
																												borderColor: "red.400",
																											}}
																										>
																											<Radio
																												p="2"
																												size="lg"
																												bg="white"
																												value="stadefrancecom"
																												w="100%"
																												id="accountSupportId"
																												name="accountSupportId"
																												onChange={(event) =>
																													accountEditFormChange(
																														event,
																														index
																													)
																												}
																											>
																												<HStack>
																													<Box ml="5">
																														<Text
																															fontSize={20}
																															fontWeight={700}
																														>
																															StadeFrance.com
																														</Text>
																													</Box>
																													<Spacer />
																												</HStack>
																											</Radio>
																										</Box>

																										{/* OlympiaHall.com */}
																										<Box
																											w="100%"
																											textAlign={["left"]}
																											borderRadius="xl"
																											borderWidth="1px"
																											borderColor={
																												formFieldsAccountEditDataRow.accountSupportId ===
																												"olympiahallcom"
																													? "red.400"
																													: "gray.200"
																											}
																											fontSize={20}
																											fontWeight={400}
																											color={
																												formFieldsAccountEditDataRow.accountSupportId ===
																												"olympiahallcom"
																													? "white"
																													: "black"
																											}
																											bg={
																												formFieldsAccountEditDataRow.accountSupportId ===
																												"olympiahallcom"
																													? "red.400"
																													: "gray.100"
																											}
																											_hover={{
																												bg: "red.50",
																												color: "#000",
																												borderWidth: "1px",
																												borderColor: "red.400",
																											}}
																										>
																											<Radio
																												p="2"
																												size="lg"
																												bg="white"
																												value="olympiahallcom"
																												w="100%"
																												id="accountSupportId"
																												name="accountSupportId"
																												onChange={(event) =>
																													accountEditFormChange(
																														event,
																														index
																													)
																												}
																											>
																												<HStack>
																													<Box ml="5">
																														<Text
																															fontSize={20}
																															fontWeight={700}
																														>
																															OlympiaHall.com
																														</Text>
																													</Box>
																													<Spacer />
																												</HStack>
																											</Radio>
																										</Box>
																									</VStack>
																								</TabPanel>

																								{/* ES */}
																								<TabPanel>
																									<Text>No support !</Text>
																								</TabPanel>

																								{/* PT */}
																								<TabPanel>
																									<VStack p="4" direction="row">
																										{/* Ticketline.pt */}
																										<Box
																											w="100%"
																											textAlign={["left"]}
																											borderRadius="xl"
																											borderWidth="1px"
																											borderColor={
																												formFieldsAccountEditDataRow.accountSupportId ===
																												"ticketlinept"
																													? "red.400"
																													: "gray.200"
																											}
																											fontSize={20}
																											fontWeight={400}
																											color={
																												formFieldsAccountEditDataRow.accountSupportId ===
																												"ticketlinept"
																													? "white"
																													: "black"
																											}
																											bg={
																												formFieldsAccountEditDataRow.accountSupportId ===
																												"ticketlinept"
																													? "red.400"
																													: "gray.100"
																											}
																											_hover={{
																												bg: "red.50",
																												color: "#000",
																												borderWidth: "1px",
																												borderColor: "red.400",
																											}}
																										>
																											<Radio
																												p="2"
																												size="lg"
																												bg="white"
																												value="ticketlinept"
																												w="100%"
																												id="accountSupportId"
																												name="accountSupportId"
																												onChange={(event) =>
																													accountEditFormChange(
																														event,
																														index
																													)
																												}
																											>
																												<HStack>
																													<Box ml="5">
																														<Text
																															fontSize={20}
																															fontWeight={700}
																														>
																															Ticketline.pt
																														</Text>
																													</Box>
																													<Spacer />
																												</HStack>
																											</Radio>
																										</Box>

																										{/* Blueticket.meo.pt */}
																										<Box
																											w="100%"
																											textAlign={["left"]}
																											borderRadius="xl"
																											borderWidth="1px"
																											borderColor={
																												formFieldsAccountEditDataRow.accountSupportId ===
																												"blueticketmeopt"
																													? "red.400"
																													: "gray.200"
																											}
																											fontSize={20}
																											fontWeight={400}
																											color={
																												formFieldsAccountEditDataRow.accountSupportId ===
																												"blueticketmeopt"
																													? "white"
																													: "black"
																											}
																											bg={
																												formFieldsAccountEditDataRow.accountSupportId ===
																												"blueticketmeopt"
																													? "red.400"
																													: "gray.100"
																											}
																											_hover={{
																												bg: "red.50",
																												color: "#000",
																												borderWidth: "1px",
																												borderColor: "red.400",
																											}}
																										>
																											<Radio
																												p="2"
																												size="lg"
																												bg="white"
																												value="blueticketmeopt"
																												w="100%"
																												id="accountSupportId"
																												name="accountSupportId"
																												onChange={(event) =>
																													accountEditFormChange(
																														event,
																														index
																													)
																												}
																											>
																												<HStack>
																													<Box ml="5">
																														<Text
																															fontSize={20}
																															fontWeight={700}
																														>
																															Blueticket.meo.pt
																														</Text>
																													</Box>
																													<Spacer />
																												</HStack>
																											</Radio>
																										</Box>
																									</VStack>
																								</TabPanel>

																								{/* IT */}
																								<TabPanel>
																									<Text>No support !</Text>
																								</TabPanel>
																							</TabPanels>
																						</Tabs>
																					</RadioGroup>
																				</GridItem>
																			</Grid>
																		</Box>
																	</GridItem>
																</Grid>
															</Box>

															{/* Group setup */}
															<Box
																bg="#ffffff"
																borderRadius="xl"
																borderWidth="1px"
																borderColor="gray.400"
																shadow="lg"
																position="relative"
																mt="5"
																textAlign={["center"]}
																_hover={{
																	bg: "gray.50",
																	borderWidth: "2px",
																	borderColor: "red.400",
																}}
															>
																<Grid
																	w="100%"
																	p="1"
																	templateColumns="repeat(12, 1fr)"
																>
																	<GridItem
																		color="black"
																		colSpan={{
																			base: "12",
																			sm: "12",
																			md: "12",
																			lg: "12",
																			xl: "12",
																			"2xl": "12",
																		}}
																	>
																		<Box p={1} textAlign={["left"]}>
																			<RadioGroup
																				defaultValue={
																					formFieldsAccountEditDataRow.accountGroupId
																				}
																			>
																				<Grid
																					w="100%"
																					gap={2}
																					p="2"
																					templateColumns="repeat(12, 1fr)"
																				>
																					<GridItem colSpan={6}>
																						<Text
																							fontSize={16}
																							fontWeight={700}
																						>
																							GROUP
																						</Text>
																					</GridItem>
																					<GridItem
																						colSpan={6}
																						textAlign={["right"]}
																					></GridItem>
																					<GridItem colSpan={4}>
																						<Box
																							w="100%"
																							textAlign={["left"]}
																							borderRadius="xl"
																							borderWidth="1px"
																							borderColor={
																								formFieldsAccountEditDataRow.accountGroupId ===
																								"group01"
																									? "red.400"
																									: "gray.200"
																							}
																							fontSize={20}
																							fontWeight={400}
																							color={
																								formFieldsAccountEditDataRow.accountGroupId ===
																								"group01"
																									? "white"
																									: "black"
																							}
																							bg={
																								formFieldsAccountEditDataRow.accountGroupId ===
																								"group01"
																									? "red.400"
																									: "gray.100"
																							}
																							_hover={{
																								bg: "red.50",
																								color: "#000",
																								borderWidth: "1px",
																								borderColor: "red.400",
																							}}
																						>
																							<Radio
																								p="2"
																								size="lg"
																								bg="white"
																								value="group01"
																								w="100%"
																								id="accountGroupId"
																								name="accountGroupId"
																								onChange={(event) =>
																									accountEditFormChange(
																										event,
																										index
																									)
																								}
																							>
																								<Box ml="5">
																									<Text
																										fontSize={16}
																										fontWeight={500}
																									>
																										Group_01
																									</Text>
																								</Box>
																							</Radio>
																						</Box>
																					</GridItem>
																					<GridItem colSpan={4}>
																						<Box
																							w="100%"
																							textAlign={["left"]}
																							borderRadius="xl"
																							borderWidth="1px"
																							borderColor={
																								formFieldsAccountEditDataRow.accountGroupId ===
																								"group02"
																									? "red.400"
																									: "gray.200"
																							}
																							fontSize={20}
																							fontWeight={400}
																							color={
																								formFieldsAccountEditDataRow.accountGroupId ===
																								"group02"
																									? "white"
																									: "black"
																							}
																							bg={
																								formFieldsAccountEditDataRow.accountGroupId ===
																								"group02"
																									? "red.400"
																									: "gray.100"
																							}
																							_hover={{
																								bg: "red.50",
																								color: "#000",
																								borderWidth: "1px",
																								borderColor: "red.400",
																							}}
																						>
																							<Radio
																								p="2"
																								size="lg"
																								bg="white"
																								value="group02"
																								w="100%"
																								id="accountGroupId"
																								name="accountGroupId"
																								onChange={(event) =>
																									accountEditFormChange(
																										event,
																										index
																									)
																								}
																							>
																								<Box ml="5">
																									<Text
																										fontSize={16}
																										fontWeight={500}
																									>
																										Group_02
																									</Text>
																								</Box>
																							</Radio>
																						</Box>
																					</GridItem>
																					<GridItem colSpan={4}>
																						<Box
																							w="100%"
																							textAlign={["left"]}
																							borderRadius="xl"
																							borderWidth="1px"
																							borderColor={
																								formFieldsAccountEditDataRow.accountGroupId ===
																								"group03"
																									? "red.400"
																									: "gray.200"
																							}
																							fontSize={20}
																							fontWeight={400}
																							color={
																								formFieldsAccountEditDataRow.accountGroupId ===
																								"group03"
																									? "white"
																									: "black"
																							}
																							bg={
																								formFieldsAccountEditDataRow.accountGroupId ===
																								"group03"
																									? "red.400"
																									: "gray.100"
																							}
																							_hover={{
																								bg: "red.50",
																								color: "#000",
																								borderWidth: "1px",
																								borderColor: "red.400",
																							}}
																						>
																							<Radio
																								p="2"
																								size="lg"
																								bg="white"
																								value="group03"
																								w="100%"
																								id="accountGroupId"
																								name="accountGroupId"
																								onChange={(event) =>
																									accountEditFormChange(
																										event,
																										index
																									)
																								}
																							>
																								<Box ml="5">
																									<Text
																										fontSize={16}
																										fontWeight={500}
																									>
																										Group_03
																									</Text>
																								</Box>
																							</Radio>
																						</Box>
																					</GridItem>
																				</Grid>
																			</RadioGroup>
																		</Box>
																	</GridItem>
																</Grid>
															</Box>

															{/* Account setup */}
															<Box
																key={index}
																bg="#ffffff"
																borderRadius="xl"
																borderWidth="1px"
																borderColor="gray.400"
																shadow="lg"
																position="relative"
																mt="5"
																textAlign={["center"]}
																_hover={{
																	bg: "gray.50",
																	borderWidth: "2px",
																	borderColor: "red.400",
																}}
															>
																<Grid
																	w="100%"
																	p="1"
																	templateColumns="repeat(12, 1fr)"
																>
																	<GridItem
																		color="black"
																		colSpan={{
																			base: "12",
																			sm: "12",
																			md: "12",
																			lg: "12",
																			xl: "12",
																			"2xl": "12",
																		}}
																	>
																		<Box p={1} textAlign={["left"]}>
																			<Grid
																				w="100%"
																				gap={2}
																				p="2"
																				templateColumns="repeat(12, 1fr)"
																			>
																				<GridItem colSpan={6}>
																					<Text fontSize={16} fontWeight={700}>
																						ACCOUNT
																					</Text>
																				</GridItem>
																				<GridItem
																					colSpan={6}
																					textAlign={["right"]}
																				></GridItem>
																				<GridItem colSpan={6}>
																					<Text
																						color={"gray.400"}
																						fontSize={15}
																						fontWeight={400}
																					>
																						Login
																					</Text>
																					<Input
																						isInvalid={
																							!formFieldsAccountEditDataRow.accountLogin
																						}
																						id="accountLogin"
																						name="accountLogin"
																						value={
																							formFieldsAccountEditDataRow.accountLogin
																						}
																						onChange={(event) =>
																							accountEditFormChange(
																								event,
																								index
																							)
																						}
																						focusBorderColor="blue.400"
																						errorBorderColor="red.300"
																						bg="yellow.100"
																					/>
																				</GridItem>
																				<GridItem colSpan={6}>
																					<Text
																						color={"gray.400"}
																						fontSize={15}
																						fontWeight={400}
																					>
																						Password
																					</Text>
																					<Input
																						isInvalid={
																							!formFieldsAccountEditDataRow.accountPassword
																						}
																						id="accountPassword"
																						name="accountPassword"
																						value={
																							formFieldsAccountEditDataRow.accountPassword
																						}
																						onChange={(event) =>
																							accountEditFormChange(
																								event,
																								index
																							)
																						}
																						focusBorderColor="blue.400"
																						errorBorderColor="red.300"
																						bg="yellow.100"
																					/>
																				</GridItem>
																			</Grid>
																		</Box>
																	</GridItem>
																</Grid>
															</Box>

															{/* Options setup */}
															<Box
																bg="#ffffff"
																borderRadius="xl"
																borderWidth="1px"
																borderColor="gray.400"
																shadow="lg"
																position="relative"
																mt="5"
																textAlign={["center"]}
																_hover={{
																	bg: "gray.50",
																	borderWidth: "2px",
																	borderColor: "red.400",
																}}
															>
																<Grid
																	w="100%"
																	p="1"
																	templateColumns="repeat(12, 1fr)"
																>
																	<GridItem
																		color="black"
																		colSpan={{
																			base: "12",
																			sm: "12",
																			md: "12",
																			lg: "12",
																			xl: "12",
																			"2xl": "12",
																		}}
																	>
																		<Box p={1} textAlign={["left"]}>
																			<RadioGroup defaultValue="">
																				<Grid
																					w="100%"
																					gap={2}
																					p="2"
																					templateColumns="repeat(12, 1fr)"
																				>
																					<GridItem colSpan={6}>
																						<Text
																							fontSize={16}
																							fontWeight={700}
																						>
																							OPTIONS
																						</Text>
																					</GridItem>
																					<GridItem
																						colSpan={6}
																						textAlign={["right"]}
																					></GridItem>
																					<GridItem colSpan={12}>
																						<Text
																							color={"gray.400"}
																							fontSize={15}
																							fontWeight={400}
																						>
																							State
																						</Text>
																						<Switch
																							size="lg"
																							id="accountState"
																							name="accountState"
																							colorScheme="green"
																							isChecked={
																								formFieldsAccountEditDataRow.accountState
																							}
																							onChange={(event) =>
																								accountEditFormChange(
																									event,
																									index
																								)
																							}
																							//onChange={(isChecked) => accountEditSwitchChange(isChecked, index)}
																							value={
																								formFieldsAccountEditDataRow.accountState
																							}
																						></Switch>
																					</GridItem>
																				</Grid>
																			</RadioGroup>
																		</Box>
																	</GridItem>
																</Grid>
															</Box>

															{/* Profile setup */}
															<Box
																bg="#ffffff"
																borderRadius="xl"
																borderWidth="1px"
																borderColor="gray.400"
																shadow="lg"
																position="relative"
																mt="5"
																textAlign={["center"]}
																_hover={{
																	bg: "gray.50",
																	borderWidth: "2px",
																	borderColor: "red.400",
																}}
															>
																<Grid
																	w="100%"
																	p="1"
																	templateColumns="repeat(12, 1fr)"
																>
																	<GridItem
																		color="black"
																		colSpan={{
																			base: "12",
																			sm: "12",
																			md: "12",
																			lg: "12",
																			xl: "12",
																			"2xl": "12",
																		}}
																	>
																		<Box p={1} textAlign={["left"]}>
																			<RadioGroup defaultValue="">
																				<Grid
																					w="100%"
																					gap={2}
																					p="2"
																					templateColumns="repeat(12, 1fr)"
																				>
																					<GridItem colSpan={6}>
																						<Text
																							fontSize={16}
																							fontWeight={700}
																						>
																							PROFILE
																						</Text>
																					</GridItem>
																					<GridItem
																						colSpan={6}
																						textAlign={["right"]}
																					></GridItem>
																					<GridItem colSpan={12}>
																						<Text
																							color={"gray.400"}
																							fontSize={15}
																							fontWeight={400}
																						>
																							Firstname, Lastname, ...
																						</Text>
																					</GridItem>
																				</Grid>
																			</RadioGroup>
																		</Box>
																	</GridItem>
																</Grid>
															</Box>

															{/* Stats setup */}
															<Box
																key={index}
																bg="#ffffff"
																borderRadius="xl"
																borderWidth="1px"
																borderColor="gray.400"
																shadow="lg"
																position="relative"
																mt="5"
																textAlign={["center"]}
																_hover={{
																	bg: "gray.50",
																	borderWidth: "2px",
																	borderColor: "red.400",
																}}
															>
																<Grid
																	w="100%"
																	p="1"
																	templateColumns="repeat(12, 1fr)"
																>
																	<GridItem
																		color="black"
																		colSpan={{
																			base: "12",
																			sm: "12",
																			md: "12",
																			lg: "12",
																			xl: "12",
																			"2xl": "12",
																		}}
																	>
																		<Box p={1} textAlign={["left"]}>
																			<Grid
																				w="100%"
																				gap={2}
																				p="2"
																				templateColumns="repeat(12, 1fr)"
																			>
																				<GridItem colSpan={6}>
																					<Text fontSize={16} fontWeight={700}>
																						INFO
																					</Text>
																				</GridItem>
																				<GridItem
																					colSpan={6}
																					textAlign={["right"]}
																				></GridItem>
																				<GridItem colSpan={4}>
																					<Text
																						color={"gray.400"}
																						fontSize={15}
																						fontWeight={400}
																					>
																						Order
																					</Text>
																					<Input
																						id="accountOrderNb"
																						name="accountOrderNb"
																						value={
																							formFieldsAccountEditDataRow.accountOrderNb
																						}
																						isDisabled
																					/>
																				</GridItem>
																				<GridItem colSpan={4}>
																					<Text
																						color={"gray.400"}
																						fontSize={15}
																						fontWeight={400}
																					>
																						Last use
																					</Text>
																					<Input
																						id="accountLastUse"
																						name="accountLastUse"
																						value={
																							formFieldsAccountEditDataRow.accountLastUse
																						}
																						isDisabled
																					/>
																				</GridItem>
																				<GridItem colSpan={4}>
																					<Text
																						color={"gray.400"}
																						fontSize={15}
																						fontWeight={400}
																					>
																						Last crawl
																					</Text>
																					<Input
																						id="accountLastCrawl"
																						name="accountLastCrawl"
																						value={
																							formFieldsAccountEditDataRow.accountLastCrawl
																						}
																						isDisabled
																					/>
																				</GridItem>
																			</Grid>
																		</Box>
																	</GridItem>
																</Grid>
															</Box>

															{/* Button */}
															<Button
																w="100%"
																h="75px"
																mt="5"
																bg={canEditAccounts ? "white" : "white"}
																color={canEditAccounts ? "red.400" : "gray.400"}
																borderColor={
																	canEditAccounts ? "red.400" : "gray.400"
																}
																borderWidth="2px"
																_hover={
																	canEditAccounts
																		? {
																				bg: "red.400",
																				color: "white",
																				borderWidth: "2px",
																				borderColor: "red.400",
																		  }
																		: {
																				bg: "gray.400",
																				color: "white",
																				borderWidth: "2px",
																				borderColor: "gray.400",
																		  }
																}
																onClick={onSaveEditAccountsClicked}
																disabled={!canEditAccounts}
															>
																<Text
																	fontSize={{
																		base: "16",
																		sm: "16",
																		md: "16",
																		lg: "16",
																		xl: "22",
																	}}
																	fontWeight={600}
																>
																	EDIT ACCOUNT
																	{formFieldsAccountEditData.length > 1 ? (
																		<>S</>
																	) : null}
																</Text>
															</Button>

															{/* Debug Box */}
															<Box
																mt="5"
																p="1"
																color="blue.600"
																bg="gray.100"
																borderRadius="xl"
																borderWidth="1px"
																borderColor="gray.400"
															>
																<Text mb="1" fontSize={18} fontWeight={700}>
																	DEBUG BOX
																</Text>
																<Text fontSize={15} fontWeight={600}>
																	<strong>formFieldsAccountEditData = </strong>
																	{JSON.stringify(formFieldsAccountEditData)}
																	<br />
																</Text>
															</Box>
														</DrawerBody>
													</DrawerContent>
												</Drawer>
											)
										)
									) : (
										// Si formFieldsAccountEditData est vide, affiche un squelette ou un indicateur de chargement
										<Drawer
											key="index"
											isOpen={isOpenAccountsEdit ?? false}
											onClose={() => drawerIsOpenAccountsEdit()}
											size="lg"
										>
											<DrawerOverlay />
											<DrawerContent>
												<DrawerCloseButton />
												<DrawerHeader>
													<HStack>
														<BsPencilFill fontSize={25} />
														<Text fontSize={25} fontWeight={800}>
															{" "}
															EDIT ACCOUNTS
														</Text>
													</HStack>
												</DrawerHeader>
												<DrawerBody>
													<Skeleton height="20px" width="100%" />
												</DrawerBody>
											</DrawerContent>
										</Drawer>
									)}
								</GridItem>

								{/* - */}
								<GridItem
									color="black"
									p="2"
									colSpan={{
										base: "12",
										sm: "12",
										md: "12",
										lg: "12",
										xl: "12",
										"2xl": "4",
									}}
								>
									<Box p="2" textAlign={["left"]} w="100%" color="black">
										<Text fontSize={25} fontWeight={800}>
											-
										</Text>
									</Box>
									<Box
										p="2"
										textAlign={["left"]}
										w="100%"
										minH="100px"
										color="black"
										bg="#F5F5F5"
										borderRadius="xl"
										border="1px"
										borderColor="gray.300"
										borderStyle={"solid"}
									></Box>
								</GridItem>
							</Grid>
						</Box>
					</GridItem>
				</Grid>
			</Center>
		</>
	);

	return content;
};
export default ProfilyList;
