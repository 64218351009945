import React from "react";
import { Oval as Spinner } from "react-loader-spinner";

import { Grid, GridItem, AbsoluteCenter, Center, Text, Tag } from '@chakra-ui/react'

const LoadingScreen = (props) => {
  return (
    <>
      <Grid h='100vh' bg='#45413C'>
        <AbsoluteCenter p='0' color='white' axis='both'>
          <GridItem>
            <Center>
              <Spinner
                height={80}
                width={80}
                color="red"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#fff"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </Center>
            <Center>
              <Text color='#FFFFFF' fontSize={25} fontWeight={600} >BROKY.</Text>
            </Center>
            <Center>
              <Text color='#FFFFFF' fontSize={14} fontWeight={400} ><Tag size='md' variant='solid' colorScheme='red'>{props.section || 'is loading...'}</Tag></Text>
            </Center>
          </GridItem>
        </AbsoluteCenter>
      </Grid>
    </>
  );
};

export default LoadingScreen;
