import { useLocation, Navigate, Outlet } from "react-router-dom"
import useLang from "../../hooks/useLang"

const RequireLang = ({ allowedLangs }) => {
    const location = useLocation()
    const { langs } = useLang()

    const content = (
        langs.some(lang => allowedLangs.includes(lang))
            ? <Outlet />
            : <Navigate to="/login" state={{ from: location }} replace />
    )

    return content
}
export default RequireLang