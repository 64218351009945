import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSliceDby } from "../../app/api/apiSliceDby";

const eventsAdapter = createEntityAdapter({
	sortComparer: (a, b) =>
		a.completed === b.completed ? 0 : a.completed ? 1 : -1,
});

const initialState = eventsAdapter.getInitialState();

export const eventsApiSlice = apiSliceDby.injectEndpoints({
	endpoints: (builder) => ({
		getEvents: builder.query({
			query: () => ({
				url: "/events",
				validateStatus: (response, result) => {
					return response.status === 200 && !result.isError;
				},
			}),
			transformResponse: (responseData) => {
				const loadedEvents = responseData.map((Event) => {
					Event.id = Event._id;
					return Event;
				});
				return eventsAdapter.setAll(initialState, loadedEvents);
			},
			providesTags: (result, error, arg) => {
				if (result?.ids) {
					return [
						{ type: "Event", id: "LIST" },
						...result.ids.map((id) => ({ type: "Event", id })),
					];
				} else return [{ type: "Event", id: "LIST" }];
			},
		}),

		searchEvents: builder.query({
			query: ({ searchString }) => ({
				url: `/events/search`,
				method: "POST",
				body: { searchString },
				validateStatus: (response, result) => {
					return response.status === 200 && !result.isError;
				},
			}),
		}),
	}),
});

export const { useGetEventsQuery, useSearchEventsQuery } = eventsApiSlice;

// returns the query result object
export const selectEventsResult = eventsApiSlice.endpoints.getEvents.select();

// creates memoized selector
const selectEventsData = createSelector(
	selectEventsResult,
	(eventsResult) => eventsResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
	selectAll: selectAllEvents,
	selectById: selectEventById,
	selectIds: selectEventIds,
	// Pass in a selector that returns the events slice of state
} = eventsAdapter.getSelectors(
	(state) => selectEventsData(state) ?? initialState
);
