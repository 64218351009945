import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// import `ChakraProvider` component
import { ChakraProvider } from "@chakra-ui/react";

// Load screen
import { LoadingScreen } from "./screens";

// NAV
import Layout from "./components/Layout";
import Public from "./components/Public";
import Login from "./features/auth/Login";
import DashLayout from "./components/DashLayout";
import Welcome from "./features/auth/Welcome";

// NOTES
import NotesList from "./features/notes/NotesList";
import EditNote from "./features/notes/EditNote";
import NewNote from "./features/notes/NewNote";

// USERS
import UsersList from "./features/users/UsersList";
import EditUser from "./features/users/EditUser";
import NewUserForm from "./features/users/NewUserForm";

// TIXY
import TixyBoard from "./features/tixy/board";
import TixyDispatcher from "./features/tixy/dispatcher";
import TixyInventory from "./features/tixy/inventory";
import TixyPosMarket from "./features/tixy/posMarket";
import TixyFirstMarket from "./features/tixy/firstMarket";
import TixySecondMarket from "./features/tixy/secondMarket";
import TixyStockEntry from "./features/tixy/stockEntry";
import TixyStockExit from "./features/tixy/stockExit";

// CRAWLY
import CrawlyFirstMarket from "./features/crawly/firstMarket";

// PROFILY
import ProfilyManager from "./features/profily/manager";

// CARTS
import CartsManager from "./features/carts/CartsManager";
import CartsList from "./features/carts/CartsList";
import EditCart from "./features/carts/EditCart";
import NewCart from "./features/carts/NewCart";

/*
import CustomersList from './features/customers/CitiesList'
import EditCustomer from './features/customers/EditCustomer'
import NewCustomer from './features/customers/NewCustomer'
*/
import Prefetch from "./features/auth/Prefetch";

import PersistLogin from "./features/auth/PersistLogin";
import RequireAuth from "./features/auth/RequireAuth";
import { ROLES } from "./config/roles";

import RequireLang from "./features/lang/RequireLang";
import { LANGS } from "./config/langs";

import useTitle from "./hooks/useTitle";

//const lang = "en"

function App() {
	useTitle("BROKY");

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 5000);
	}, []);

	return (
		<>
			<ChakraProvider>
				{!loading ? (
					<Routes>
						<Route path="/" element={<Layout />}>
							{/* public routes */}
							<Route index element={<Public />} />
							<Route path="login" element={<Login />} />

							{/* Protected Routes */}
							<Route element={<PersistLogin />}>
								<Route
									element={
										<RequireAuth allowedRoles={[...Object.values(ROLES)]} />
									}
								>
									<Route
										element={
											<RequireLang allowedLangs={[...Object.values(LANGS)]} />
										}
									>
										<Route element={<Prefetch />}>
											<Route path="dash" element={<DashLayout />}>
												<Route index element={<Welcome />} />

												<Route
													element={
														<RequireAuth
															allowedRoles={[ROLES.Manager, ROLES.Admin]}
														/>
													}
												>
													<Route path="users">
														<Route index element={<UsersList />} />
														<Route path=":id" element={<EditUser />} />
														<Route path="new" element={<NewUserForm />} />
													</Route>
												</Route>

												<Route path="notes">
													<Route index element={<NotesList />} />
													<Route path=":id" element={<EditNote />} />
													<Route path="new" element={<NewNote />} />
												</Route>

												<Route path="carts">
													<Route index element={<CartsList />} />
													<Route path=":id" element={<EditCart />} />
													<Route path="new" element={<NewCart />} />
												</Route>

												<Route path="tixy">
													<Route index element={<TixyBoard />} />
													<Route path="board" element={<TixyBoard />} />
													<Route
														path="dispatcher"
														element={<TixyDispatcher />}
													/>
													<Route path="inventory" element={<TixyInventory />} />
													<Route path="posMarket" element={<TixyPosMarket />} />
													<Route
														path="firstMarket"
														element={<TixyFirstMarket />}
													/>
													<Route
														path="secondMarket"
														element={<TixySecondMarket />}
													/>

													<Route
														path="stockEntry"
														element={<TixyStockEntry />}
													/>
													<Route path="stockExit" element={<TixyStockExit />} />
												</Route>

												<Route path="crawly">
													<Route
														path="firstMarket"
														element={<CrawlyFirstMarket />}
													/>
												</Route>

												<Route path="profily">
													<Route index element={<ProfilyManager />} />
												</Route>
											</Route>
											{/* End Dash */}
										</Route>
									</Route>
								</Route>
							</Route>
							{/* End Protected Routes */}
						</Route>
					</Routes>
				) : (
					<LoadingScreen />
				)}
			</ChakraProvider>
		</>
	);
}

export default App;
