import NewCartForm from './NewCartForm'
import useTitle from '../../hooks/useTitle'

const NewCart = () => {
    useTitle('BROKY: New Cart')

    const content = <NewCartForm />

    return content
}
export default NewCart