import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSliceBroky } from "../../app/api/apiSliceBroky";

const accountsAdapter = createEntityAdapter({
  sortComparer: (a, b) =>
    a.completed === b.completed ? 0 : a.completed ? 1 : -1,
});

const initialState = accountsAdapter.getInitialState();

export const accountsApiSlice = apiSliceBroky.injectEndpoints({
  endpoints: (builder) => ({
    getAccount: builder.query({
      query: (params) => {
        // Vérification si accountId est null
        if (!params.accountId) {
          throw new Error("AccountId is null");
        }

        return {
          url: `/account/${params.accountId}`, // Utilisation de la variable accountId ici
          validateStatus: (response, result) => {
            return response.status === 200 && !result.isError;
          },
        };
      },
    }),

    getAccounts: builder.query({
      query: () => ({
        url: "/accounts",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedAccounts = responseData.map((Account) => {
          Account.id = Account._id;
          return Account;
        });
        return accountsAdapter.setAll(initialState, loadedAccounts);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Account", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Account", id })),
          ];
        } else return [{ type: "Account", id: "LIST" }];
      },
    }),
    addNewAccounts: builder.mutation({
      query: (initialAccount) => ({
        url: "/accounts",
        method: "POST",
        body: {
          ...initialAccount,
        },
      }),
      invalidatesTags: [{ type: "Account", id: "LIST" }],
    }),
    updateAccount: builder.mutation({
      query: (initialAccount) => ({
        url: "/accounts",
        method: "PATCH",
        body: {
          ...initialAccount,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Account", id: arg.id },
      ],
    }),
    deleteAccount: builder.mutation({
      query: ({ id }) => ({
        url: `/accounts`,
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Account", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetAccountQuery,
  useGetAccountsQuery,
  useAddNewAccountsMutation,
  useUpdateAccountMutation,
  useDeleteAccountMutation,
} = accountsApiSlice;

// returns the query result object
export const selectAccountsResult =
  accountsApiSlice.endpoints.getAccounts.select();

// creates memoized selector
const selectAccountsData = createSelector(
  selectAccountsResult,
  (accountsResult) => accountsResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllAccounts,
  selectById: selectAccountById,
  selectIds: selectAccountIds,
  // Pass in a selector that returns the accounts slice of state
} = accountsAdapter.getSelectors(
  (state) => selectAccountsData(state) ?? initialState
);
