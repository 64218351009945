import { useState, useEffect } from "react"
import { useGetCartsWaitQuery, useUpdateCartWaitMutation } from "./cartsApiSlice"
import Cart from "./Cart"
import { Link } from 'react-router-dom'
import useAuth from "../../hooks/useAuth"
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'
import useTitle from "../../hooks/useTitle"
import PulseLoader from 'react-spinners/PulseLoader'

// import `ChakraProvider` component
import { Box, Grid, GridItem, Center, HStack, VStack, Container, Flex, SimpleGrid, FormControl, FormLabel, Textarea, Checkbox, Input, Switch, Badge, Tag, TagLabel, TagCloseButton, Avatar, Toast, Icon, IconButton, Image, Stack, Alert, AlertIcon, Select, Button, useRadioGroup, useRadio, RadioGroup, Radio, Text, useDisclosure, useToast, Spacer } from '@chakra-ui/react'

// import `ChakraProvider` icon
import { AddIcon } from '@chakra-ui/icons'

import { Bs0CircleFill, Bs1CircleFill, Bs2CircleFill, BsBoxFill, BsFilePdf, BsGridFill, BsBarChartLineFill } from "react-icons/bs";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationCrosshairs, faSnowman, faList } from "@fortawesome/free-solid-svg-icons"

const CartsList = () => {
    useTitle('BROKY: Carts Manager')

    const { username, isManager, isAdmin } = useAuth()

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    let menuContent
    if (isManager || isAdmin) {
        menuContent = (
            <>
                <Link to="/dash/carts/new">
                    <Button
                        size='sm'
                        leftIcon={<AddIcon />}
                        colorScheme='red'
                        variant='solid'>
                        <Text
                            color='white'
                            display={{ base: 'none', sm: 'flex' }}
                            fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }}
                            fontWeight={600}
                        >
                            {T.common.Add}
                        </Text>
                    </Button>
                </Link>
            </>
        )
    }

    let content
    content = (
        <>
            <Center bg='#45413C' axis='both'>
                <Grid w='95vw' minH='100vh' maxW='95vw' templateColumns='repeat(12, 1fr)'>
                    <GridItem colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>

                        <Grid p='5' gap='5' templateColumns='repeat(12, 1fr)'>
                            <GridItem colSpan={{ base: "6", sm: "6", md: "6", lg: "6", xl: "6", "2xl": "6" }} textAlign={['left']}>
                                <Text color={'white'} fontSize={{ base: "24", sm: "24", md: "24", lg: "24", xl: "28" }} fontWeight={600}><FontAwesomeIcon icon={faLocationCrosshairs} /> {T.tixy.Tixy} : {T.tixy.Dispatcher}</Text>
                            </GridItem>
                            <GridItem colSpan={{ base: "6", sm: "6", md: "6", lg: "6", xl: "6", "2xl": "6" }} textAlign={['right']}>
                                {menuContent}
                            </GridItem>
                        </Grid>

                        <Box borderRadius='xl' p={2} textAlign={['center']} bg='white'>

                            <Grid p='2' gap='4' w='100%' maxW='100vw' templateColumns='repeat(12, 1fr)'>

                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "8", "2xl": "9" }}>

                                    {/* Head */}
                                    <Box p='2' textAlign={['left']} w='100%' minH='100px' color='black'>
                                        <Text fontSize={25} fontWeight={800}>Coldplay</Text>
                                        <Text fontSize={22} fontWeight={600}>2024.06.12, 19:00</Text>
                                        <Text fontSize={20} fontWeight={500}>France, Saint-Denis, Stade de France</Text>
                                    </Box>
                                </GridItem>

                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "4", "2xl": "3" }}>

                                    {/* Button NAV */}
                                    <SimpleGrid columns={4} spacing={2}>

                                        {/* Go INVENTORY */}
                                        <Link to="/dash/tixy/inventory">
                                            <Box p='2' textAlign={['center']} color='black' bg='gray.50' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                <Text color='gray.600' fontSize={14} fontWeight={600}>INVENTORY</Text>
                                                <IconButton
                                                    isRound={true}
                                                    variant='solid'
                                                    bg='gray.300'
                                                    color='gray.700'
                                                    aria-label='Add'
                                                    fontSize='15px'
                                                    icon={<BsBoxFill />}
                                                />
                                            </Box>
                                        </Link>

                                        {/* Go POS MARKET */}
                                        <Link to="/dash/tixy/posMarket">
                                            <Box p='2' textAlign={['center']} color='black' bg='gray.50' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                <Text color='gray.600' fontSize={14} fontWeight={600}>POS MARKET</Text>
                                                <IconButton
                                                    isRound={true}
                                                    variant='solid'
                                                    bg='gray.300'
                                                    color='gray.700'
                                                    aria-label='Add'
                                                    fontSize='15px'
                                                    icon={<Bs0CircleFill />}
                                                />
                                            </Box>
                                        </Link>

                                        {/* Go 1ST MARKET */}
                                        <Link to="/dash/tixy/firstMarket">
                                            <Box p='2' textAlign={['center']} color='black' bg='gray.50' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                <Text color='gray.600' fontSize={14} fontWeight={600}>1ST MARKET</Text>
                                                <IconButton
                                                    isRound={true}
                                                    variant='solid'
                                                    bg='gray.300'
                                                    color='gray.700'
                                                    aria-label='Add'
                                                    fontSize='15px'
                                                    icon={<Bs1CircleFill />}
                                                />
                                            </Box>
                                        </Link>

                                        {/* Go 2ND MARKET */}
                                        <Link to="/dash/tixy/secondMarket">
                                            <Box p='2' textAlign={['center']} color='black' bg='gray.50' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                <Text color='gray.600' fontSize={14} fontWeight={600}>2ND MARKET</Text>
                                                <IconButton
                                                    isRound={true}
                                                    variant='solid'
                                                    bg='gray.300'
                                                    color='gray.700'
                                                    aria-label='Add'
                                                    fontSize='15px'
                                                    icon={<Bs2CircleFill />}
                                                />
                                            </Box>
                                        </Link>

                                    </SimpleGrid>

                                </GridItem>


                                {/* ROW 0 */}
                                <GridItem color='black' p='2' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }} _hover={{ bg: 'gray.200', border: '1px', borderColor: 'gray.400', borderStyle: 'solid' }}>

                                    <HStack>

                                        {/* Add Tickets */}
                                        <Box p='2' textAlign={['center']} w='33%' color='black' bg='#F5F5F5' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'dashed'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'dashed' }}>
                                            <Text color='gray.400' fontSize={20} fontWeight={600}>NEW TICKET</Text>
                                            <IconButton
                                                isRound={true}
                                                variant='solid'
                                                bg='gray.300'
                                                color='white'
                                                aria-label='Add'
                                                fontSize='15px'
                                                icon={<AddIcon />}
                                            />
                                        </Box>


                                        {/* Add Listing */}
                                        <Box p='2' textAlign={['center']} w='33%' color='black' bg='#F5F5F5' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'dashed'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'dashed' }} cursor={'pointer'}>
                                            <Text color='gray.400' fontSize={20} fontWeight={600}>NEW LISTING</Text>
                                            <IconButton
                                                isRound={true}
                                                variant='solid'
                                                bg='gray.300'
                                                color='white'
                                                aria-label='Add'
                                                fontSize='15px'
                                                icon={<AddIcon />}
                                            />
                                        </Box>


                                        {/* Add Sale */}
                                        <Box p='2' textAlign={['center']} w='33%' color='black' bg='#F5F5F5' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'dashed'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'dashed' }}>
                                            <Text color='gray.400' fontSize={20} fontWeight={600}>NEW SALE</Text>
                                            <IconButton
                                                isRound={true}
                                                variant='solid'
                                                bg='gray.300'
                                                color='white'
                                                aria-label='Add'
                                                fontSize='15px'
                                                icon={<AddIcon />}
                                            />
                                        </Box>

                                    </HStack>

                                </GridItem>


                                {/* ROW 1 */}
                                <GridItem color='black' p='2' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }} _hover={{ bg: 'gray.200', border: '1px', borderColor: 'gray.400', borderStyle: 'solid' }}>

                                    <HStack>

                                        {/* Tickets */}
                                        <Box p='2' textAlign={['left']} w='33%' minH='100px' color='black' bg='#F5F5F5' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }}>

                                            <Grid gap='2' w='100%' maxW='100vw' templateColumns='repeat(12, 1fr)'>

                                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                    <HStack spacing='30px'>
                                                        <Box p='1' mr='5' bg='gray.50' borderRadius='lg'>
                                                            <HStack>
                                                                <Text fontSize={18} fontWeight={700}>43245322</Text>
                                                                <Text color='gray.400' fontSize={13} fontWeight={500}>TIXBOX ID</Text>
                                                            </HStack>
                                                        </Box>
                                                    </HStack>
                                                </GridItem>

                                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                    <VStack>
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='#FFF' borderRadius='lg' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.50', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <Tag fontSize={20} fontWeight={400} mr='2' bg='green.50' border='1px' borderColor='green.300' borderStyle={'solid'}>#1</Tag>
                                                                <HStack spacing='30px'>
                                                                    <Box p='1' mr='5' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>ORDER ID</Text>
                                                                        <Text fontSize={15} fontWeight={700}>5439231</Text>
                                                                    </Box>
                                                                    <Box p='1' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>CATEGORY</Text>
                                                                        <Text fontSize={15} fontWeight={700}>Golden seat</Text>
                                                                    </Box>
                                                                    <Box p='1' mr='5' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>BLOC</Text>
                                                                        <Text fontSize={15} fontWeight={700}>Lower Tier A</Text>
                                                                    </Box>
                                                                    <Box p='1' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>ROW</Text>
                                                                        <Text fontSize={15} fontWeight={700}>14</Text>
                                                                    </Box>
                                                                    <Box p='1' mr='2' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>SEAT</Text>
                                                                        <Text fontSize={15} fontWeight={700}>10</Text>
                                                                    </Box>
                                                                    <Box p='1' mr='2' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>FACIAL</Text>
                                                                        <Text fontSize={15} fontWeight={700}>100.50</Text>
                                                                    </Box>
                                                                    <Box p='1' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>BARCODE</Text>
                                                                        <Text fontSize={15} fontWeight={700}>43xxx3345</Text>
                                                                    </Box>
                                                                </HStack>
                                                            </HStack>
                                                        </Box>
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='#FFF' borderRadius='lg' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.50', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <Tag fontSize={20} fontWeight={400} mr='2' bg='green.50' border='1px' borderColor='green.300' borderStyle={'solid'}>#2</Tag>
                                                                <HStack spacing='30px'>
                                                                    <Box p='1' mr='5' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>ORDER ID</Text>
                                                                        <Text fontSize={15} fontWeight={700}>5439231</Text>
                                                                    </Box>
                                                                    <Box p='1' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>CATEGORY</Text>
                                                                        <Text fontSize={15} fontWeight={700}>Golden seat</Text>
                                                                    </Box>
                                                                    <Box p='1' mr='5' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>BLOC</Text>
                                                                        <Text fontSize={15} fontWeight={700}>Lower Tier A</Text>
                                                                    </Box>
                                                                    <Box p='1' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>ROW</Text>
                                                                        <Text fontSize={15} fontWeight={700}>14</Text>
                                                                    </Box>
                                                                    <Box p='1' mr='2' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>SEAT</Text>
                                                                        <Text fontSize={15} fontWeight={700}>10</Text>
                                                                    </Box>
                                                                    <Box p='1' mr='2' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>FACIAL</Text>
                                                                        <Text fontSize={15} fontWeight={700}>100.50</Text>
                                                                    </Box>
                                                                    <Box p='1' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>BARCODE</Text>
                                                                        <Text fontSize={15} fontWeight={700}>43xxx3345</Text>
                                                                    </Box>
                                                                </HStack>
                                                            </HStack>
                                                        </Box>
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='#FFF' borderRadius='lg' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.50', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <Tag fontSize={20} fontWeight={400} mr='2' bg='red.50' border='1px' borderColor='red.300' borderStyle={'solid'}>#3</Tag>
                                                                <HStack spacing='30px'>
                                                                    <Box p='1' mr='5' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>ORDER ID</Text>
                                                                        <Text fontSize={15} fontWeight={700}>5439231</Text>
                                                                    </Box>
                                                                    <Box p='1' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>CATEGORY</Text>
                                                                        <Text fontSize={15} fontWeight={700}>Golden seat</Text>
                                                                    </Box>
                                                                    <Box p='1' mr='5' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>BLOC</Text>
                                                                        <Text fontSize={15} fontWeight={700}>Lower Tier A</Text>
                                                                    </Box>
                                                                    <Box p='1' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>ROW</Text>
                                                                        <Text fontSize={15} fontWeight={700}>14</Text>
                                                                    </Box>
                                                                    <Box p='1' mr='2' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>SEAT</Text>
                                                                        <Text fontSize={15} fontWeight={700}>10</Text>
                                                                    </Box>
                                                                    <Box p='1' mr='2' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>FACIAL</Text>
                                                                        <Text fontSize={15} fontWeight={700}>100.50</Text>
                                                                    </Box>
                                                                    <Box p='1' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>BARCODE</Text>
                                                                        <Text fontSize={15} fontWeight={700}>43xxx3345</Text>
                                                                    </Box>
                                                                </HStack>
                                                            </HStack>
                                                        </Box>
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='#FFF' borderRadius='lg' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.50', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <Tag fontSize={20} fontWeight={400} mr='2' bg='red.50' border='1px' borderColor='red.300' borderStyle={'solid'}>#4</Tag>
                                                                <HStack spacing='30px'>
                                                                    <Box p='1' mr='5' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>ORDER ID</Text>
                                                                        <Text fontSize={15} fontWeight={700}>5439231</Text>
                                                                    </Box>
                                                                    <Box p='1' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>CATEGORY</Text>
                                                                        <Text fontSize={15} fontWeight={700}>Golden seat</Text>
                                                                    </Box>
                                                                    <Box p='1' mr='5' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>BLOC</Text>
                                                                        <Text fontSize={15} fontWeight={700}>Lower Tier A</Text>
                                                                    </Box>
                                                                    <Box p='1' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>ROW</Text>
                                                                        <Text fontSize={15} fontWeight={700}>14</Text>
                                                                    </Box>
                                                                    <Box p='1' mr='2' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>SEAT</Text>
                                                                        <Text fontSize={15} fontWeight={700}>10</Text>
                                                                    </Box>
                                                                    <Box p='1' mr='2' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>FACIAL</Text>
                                                                        <Text fontSize={15} fontWeight={700}>100.50</Text>
                                                                    </Box>
                                                                    <Box p='1' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>BARCODE</Text>
                                                                        <Text fontSize={15} fontWeight={700}>43xxx3345</Text>
                                                                    </Box>
                                                                </HStack>
                                                            </HStack>
                                                        </Box>
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='#FFF' borderRadius='lg' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.50', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <Tag fontSize={20} fontWeight={400} mr='2' bg='red.50' border='1px' borderColor='red.300' borderStyle={'solid'}>#5</Tag>
                                                                <HStack spacing='30px'>
                                                                    <Box p='1' mr='5' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>ORDER ID</Text>
                                                                        <Text fontSize={15} fontWeight={700}>5439231</Text>
                                                                    </Box>
                                                                    <Box p='1' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>CATEGORY</Text>
                                                                        <Text fontSize={15} fontWeight={700}>Golden seat</Text>
                                                                    </Box>
                                                                    <Box p='1' mr='5' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>BLOC</Text>
                                                                        <Text fontSize={15} fontWeight={700}>Lower Tier A</Text>
                                                                    </Box>
                                                                    <Box p='1' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>ROW</Text>
                                                                        <Text fontSize={15} fontWeight={700}>14</Text>
                                                                    </Box>
                                                                    <Box p='1' mr='2' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>SEAT</Text>
                                                                        <Text fontSize={15} fontWeight={700}>10</Text>
                                                                    </Box>
                                                                    <Box p='1' mr='2' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>FACIAL</Text>
                                                                        <Text fontSize={15} fontWeight={700}>100.50</Text>
                                                                    </Box>
                                                                    <Box p='1' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>BARCODE</Text>
                                                                        <Text fontSize={15} fontWeight={700}>43xxx3346</Text>
                                                                    </Box>
                                                                </HStack>
                                                            </HStack>
                                                        </Box>
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='#FFF' borderRadius='lg' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.50', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <Tag fontSize={20} fontWeight={400} mr='2' bg='red.50' border='1px' borderColor='red.300' borderStyle={'solid'}>#5</Tag>
                                                                <HStack spacing='30px'>
                                                                    <Box p='1' mr='5' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>ORDER ID</Text>
                                                                        <Text fontSize={15} fontWeight={700}>5439231</Text>
                                                                    </Box>
                                                                    <Box p='1' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>CATEGORY</Text>
                                                                        <Text fontSize={15} fontWeight={700}>Golden seat</Text>
                                                                    </Box>
                                                                    <Box p='1' mr='5' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>BLOC</Text>
                                                                        <Text fontSize={15} fontWeight={700}>Lower Tier A</Text>
                                                                    </Box>
                                                                    <Box p='1' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>ROW</Text>
                                                                        <Text fontSize={15} fontWeight={700}>14</Text>
                                                                    </Box>
                                                                    <Box p='1' mr='2' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>SEAT</Text>
                                                                        <Text fontSize={15} fontWeight={700}>10</Text>
                                                                    </Box>
                                                                    <Box p='1' mr='2' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>FACIAL</Text>
                                                                        <Text fontSize={15} fontWeight={700}>100.50</Text>
                                                                    </Box>
                                                                    <Box p='1' bg='gray.50' borderRadius='lg'>
                                                                        <Text color='gray.400' fontSize={10} fontWeight={500}>BARCODE</Text>
                                                                        <Text fontSize={15} fontWeight={700}>43xxx3347</Text>
                                                                    </Box>
                                                                </HStack>
                                                            </HStack>
                                                        </Box>
                                                    </VStack>
                                                </GridItem>

                                            </Grid>

                                        </Box>


                                        {/* Listing */}
                                        <Box p='2' textAlign={['center']} w='33%' minH='100px' color='black' bg='#F5F5F5' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>

                                            <Grid gap='2' w='100%' maxW='100vw' templateColumns='repeat(12, 1fr)'>

                                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                    <HStack>
                                                        <Box p='1' mr='5' bg='gray.50' borderRadius='lg'>
                                                            <HStack>
                                                                <Text fontSize={18} fontWeight={700}>54213256</Text>
                                                                <Text color='gray.400' fontSize={13} fontWeight={500}>LISTING ID</Text>
                                                            </HStack>
                                                        </Box>
                                                        <Spacer />
                                                        <Text color='gray.400' fontSize={13} fontWeight={500}>CLONE</Text>
                                                        <Text color='gray.400' fontSize={13} fontWeight={500}>DELETE</Text>
                                                    </HStack>
                                                </GridItem>

                                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                    <VStack>
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='#FFF' borderRadius='lg' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.50', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <Flex>
                                                                <Box p='1'>
                                                                    <Text color='gray.400' fontSize={10} fontWeight={500}>BLOC / CATEGORY</Text>
                                                                    <Select
                                                                        w='200px'
                                                                        placeholder=' '
                                                                        bg={'white'}
                                                                        fontSize={17}
                                                                        fontWeight={700}
                                                                    >
                                                                        <option value='option1'>Lower tier P</option>
                                                                        <option value='option2'>Category 1</option>
                                                                    </Select>
                                                                </Box>
                                                                <Box p='1' w='100px'>
                                                                    <Text color='gray.400' fontSize={10} fontWeight={500}>ROW</Text>
                                                                    <Input fontSize={17} fontWeight={700} bg={'white'} />
                                                                </Box>
                                                                <Spacer />
                                                                <Box p='1' w='80px'>
                                                                    <Text color='gray.400' fontSize={10} fontWeight={500}>QUANTITY</Text>
                                                                    <Input fontSize={17} fontWeight={700} bg={'white'} />
                                                                </Box>
                                                                <Box p='1' w='80px'>
                                                                    <Text color='gray.400' fontSize={10} fontWeight={500}>SPLIT MODE</Text>
                                                                    <Select
                                                                        w='150px'
                                                                        placeholder=' '
                                                                        bg={'white'}
                                                                        fontSize={17}
                                                                        fontWeight={700}
                                                                    >
                                                                        <option value='option1'>skip 1or 3</option>
                                                                        <option value='option2'>none</option>
                                                                    </Select>
                                                                </Box>
                                                                <Spacer />
                                                                <Box p='1' w='120px'>
                                                                    <Text color='gray.400' fontSize={10} fontWeight={500}>FACIAL PRICE</Text>
                                                                    <Input fontSize={17} fontWeight={700} bg={'white'} />
                                                                </Box>
                                                            </Flex>
                                                            <Flex>
                                                                <Box p='1'>
                                                                    <HStack>
                                                                        <Text color='gray.400' mr='2' fontSize={10} fontWeight={500}>FEATURE</Text>
                                                                        <Badge>Visibility limited</Badge>
                                                                    </HStack>
                                                                </Box>
                                                                <Spacer />
                                                                <Box p='1'>
                                                                    <HStack>
                                                                        <Text color='gray.400' mr='2' fontSize={10} fontWeight={500}>FORMAT</Text>
                                                                        <Badge>eticket</Badge>
                                                                    </HStack>
                                                                </Box>
                                                            </Flex>
                                                        </Box>
                                                    </VStack>
                                                </GridItem>

                                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                    <VStack>

                                                        {/* Ticket bullet */}
                                                        <HStack spacing={2} textAlign={['center']}>
                                                            <Box p='0' w='50px' h='25px' color='black' bg='green.50' borderRadius='lg' border='1px' borderColor='green.300' borderStyle={'solid'} _hover={{ border: '2px', borderColor: 'green.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                                <Text fontSize={15} fontWeight={400}>#1</Text>
                                                            </Box>
                                                            <Box p='0' w='50px' h='25px' color='black' bg='green.50' borderRadius='lg' border='1px' borderColor='green.300' borderStyle={'solid'} _hover={{ border: '2px', borderColor: 'green.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                                <Text fontSize={15} fontWeight={400}>#2</Text>
                                                            </Box>
                                                            <Box p='0' w='50px' h='25px' color='black' bg='red.50' borderRadius='lg' border='1px' borderColor='red.300' borderStyle={'solid'} _hover={{ border: '2px', borderColor: 'red.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                                <Text fontSize={15} fontWeight={400}>#3</Text>
                                                            </Box>
                                                            <Box p='0' w='50px' h='25px' color='black' bg='red.50' borderRadius='lg' border='1px' borderColor='red.300' borderStyle={'solid'} _hover={{ border: '2px', borderColor: 'red.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                                <Text fontSize={15} fontWeight={400}>#4</Text>
                                                            </Box>
                                                            <Box p='0' w='50px' h='25px' color='black' bg='red.50' borderRadius='lg' border='1px' borderColor='red.300' borderStyle={'solid'} _hover={{ border: '2px', borderColor: 'red.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                                <Text fontSize={15} fontWeight={400}>#5</Text>
                                                            </Box>
                                                            <Box p='0' w='50px' h='25px' color='black' bg='red.50' borderRadius='lg' border='1px' borderColor='red.300' borderStyle={'solid'} _hover={{ border: '2px', borderColor: 'red.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                                <Text fontSize={15} fontWeight={400}>#6</Text>
                                                            </Box>
                                                        </HStack>

                                                        {/* Arenaccess */}
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='#FFF' borderRadius='lg' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.50', border: '1px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <VStack mr='5' w='150px'>
                                                                    <Text fontSize={18} fontWeight={600}>ARENACCESS</Text>
                                                                    <Text mt='-3' color='gray.400' fontSize={12} fontWeight={400}>SHOP</Text>
                                                                </VStack>
                                                                <HStack columns={3} spacing={3}>
                                                                    <Box w='50px'>
                                                                        <Text fontSize={17} fontWeight={700}>
                                                                            <Switch id='email-alerts' />
                                                                        </Text>
                                                                    </Box>
                                                                    <Box w='250px'>
                                                                        <Text color='gray.400' fontSize={11} fontWeight={500}>PLACEMENT</Text>
                                                                        <Select
                                                                            placeholder=' '
                                                                            bg={'white'}
                                                                            fontSize={17}
                                                                            fontWeight={700}
                                                                        >
                                                                            <option value='option1'>Lower tier P</option>
                                                                            <option value='option2'>Category 1</option>
                                                                        </Select>
                                                                    </Box>
                                                                    <Box w='100px'>
                                                                        <VStack>
                                                                            <HStack>
                                                                                <Checkbox defaultChecked></Checkbox>
                                                                                <Text color='gray.400' fontSize={11} fontWeight={500}>ROW</Text>
                                                                            </HStack>
                                                                            <HStack>
                                                                                <Checkbox></Checkbox>
                                                                                <Text color='gray.400' fontSize={11} fontWeight={500}>SEAT</Text>
                                                                            </HStack>
                                                                        </VStack>
                                                                    </Box>
                                                                    <Box w='100px'>
                                                                        <Text color='gray.400' fontSize={11} fontWeight={500}>RESALE PRICE</Text>
                                                                        <Text fontSize={17} fontWeight={700}>
                                                                            <Input bg={'white'} />
                                                                        </Text>
                                                                    </Box>
                                                                </HStack>
                                                            </HStack>
                                                        </Box>

                                                        {/* Gigsoon */}
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='#FFF' borderRadius='lg' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.50', border: '1px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <VStack mr='5' w='150px'>
                                                                    <Text fontSize={18} fontWeight={600}>GIGSOON</Text>
                                                                    <Text mt='-3' color='gray.400' fontSize={12} fontWeight={400}>MARKETPLACE</Text>
                                                                </VStack>
                                                                <HStack columns={3} spacing={3}>
                                                                    <Box w='50px'>
                                                                        <Text fontSize={17} fontWeight={700}>
                                                                            <Switch id='email-alerts' />
                                                                        </Text>
                                                                    </Box>
                                                                    <Box w='250px'>
                                                                        <Text color='gray.400' fontSize={11} fontWeight={500}>PLACEMENT</Text>
                                                                        <Select
                                                                            placeholder=' '
                                                                            bg={'white'}
                                                                            fontSize={17}
                                                                            fontWeight={700}
                                                                        >
                                                                            <option value='option1'>Lower tier P</option>
                                                                            <option value='option2'>Category 1</option>
                                                                        </Select>
                                                                    </Box>
                                                                    <Box w='100px'>
                                                                        <VStack>
                                                                            <HStack>
                                                                                <Checkbox defaultChecked></Checkbox>
                                                                                <Text color='gray.400' fontSize={11} fontWeight={500}>ROW</Text>
                                                                            </HStack>
                                                                            <HStack>
                                                                                <Checkbox></Checkbox>
                                                                                <Text color='gray.400' fontSize={11} fontWeight={500}>SEAT</Text>
                                                                            </HStack>
                                                                        </VStack>
                                                                    </Box>
                                                                    <Box w='100px'>
                                                                        <Text color='gray.400' fontSize={11} fontWeight={500}>RESALE PRICE</Text>
                                                                        <Text fontSize={17} fontWeight={700}>
                                                                            <Input bg={'white'} />
                                                                        </Text>
                                                                    </Box>
                                                                </HStack>
                                                            </HStack>
                                                        </Box>

                                                        {/* Viagogo */}
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='#FFF' borderRadius='lg' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.50', border: '1px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <VStack mr='5' w='150px'>
                                                                    <Text fontSize={18} fontWeight={600}>VIAGOGO</Text>
                                                                    <Text mt='-3' color='gray.400' fontSize={12} fontWeight={400}>MARKETPLACE</Text>
                                                                </VStack>
                                                                <HStack columns={3} spacing={3}>
                                                                    <Box w='50px'>
                                                                        <Text fontSize={17} fontWeight={700}>
                                                                            <Switch id='email-alerts' />
                                                                        </Text>
                                                                    </Box>
                                                                    <Box w='250px'>
                                                                        <Text color='gray.400' fontSize={11} fontWeight={500}>PLACEMENT</Text>
                                                                        <Select
                                                                            placeholder=' '
                                                                            bg={'white'}
                                                                            fontSize={17}
                                                                            fontWeight={700}
                                                                        >
                                                                            <option value='option1'>Lower tier P</option>
                                                                            <option value='option2'>Category 1</option>
                                                                        </Select>
                                                                    </Box>
                                                                    <Box w='100px'>
                                                                        <VStack>
                                                                            <HStack>
                                                                                <Checkbox defaultChecked></Checkbox>
                                                                                <Text color='gray.400' fontSize={11} fontWeight={500}>ROW</Text>
                                                                            </HStack>
                                                                            <HStack>
                                                                                <Checkbox></Checkbox>
                                                                                <Text color='gray.400' fontSize={11} fontWeight={500}>SEAT</Text>
                                                                            </HStack>
                                                                        </VStack>
                                                                    </Box>
                                                                    <Box w='100px'>
                                                                        <Text color='gray.400' fontSize={11} fontWeight={500}>RESALE PRICE</Text>
                                                                        <Text fontSize={17} fontWeight={700}>
                                                                            <Input bg={'white'} />
                                                                        </Text>
                                                                    </Box>
                                                                </HStack>
                                                            </HStack>
                                                        </Box>

                                                        <IconButton
                                                            isRound={true}
                                                            variant='solid'
                                                            bg='gray.300'
                                                            color='white'
                                                            aria-label='Add'
                                                            fontSize='15px'
                                                            icon={<AddIcon />}
                                                        />
                                                    </VStack>
                                                </GridItem>

                                            </Grid>

                                        </Box>


                                        {/* Sales */}
                                        <Box w='33%'>

                                            <VStack>

                                                {/* Sale #1*/}
                                                <Box p='2' textAlign={['right']} w='100%' minH='100px' color='black' bg='#F5F5F5' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                    <Grid gap='2' w='100%' maxW='100vw' templateColumns='repeat(12, 1fr)'>

                                                        <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                            <HStack spacing='15px'>
                                                                <Box p='1' mr='2' bg='gray.50' borderRadius='lg'>
                                                                    <Text color='gray.400' fontSize={13} fontWeight={500}>SUPPORT</Text>
                                                                    <Text fontSize={18} fontWeight={700}>ARENACCESS</Text>
                                                                </Box>
                                                                <Spacer />
                                                                <Box p='1' mr='2' bg='gray.50' borderRadius='lg'>
                                                                    <Text color='gray.400' fontSize={13} fontWeight={500}>DATE</Text>
                                                                    <Text fontSize={18} fontWeight={500}>2023.01.18</Text>
                                                                </Box>
                                                                <Box p='1' mr='2' bg='gray.50' borderRadius='lg'>
                                                                    <Text color='gray.400' fontSize={13} fontWeight={500}>QUANTITY</Text>
                                                                    <Text fontSize={18} fontWeight={500}>2</Text>
                                                                </Box>
                                                                <Box p='1' mr='2' bg='gray.50' borderRadius='lg'>
                                                                    <Text color='gray.400' fontSize={13} fontWeight={500}>TOTAL AMOUNT</Text>
                                                                    <Text fontSize={18} fontWeight={500}>240.66</Text>
                                                                </Box>
                                                                <Spacer />
                                                                <Box p='1' bg='gray.50' borderRadius='lg'>
                                                                    <Text color='gray.400' fontSize={13} fontWeight={500}>SALE ID</Text>
                                                                    <Text fontSize={18} fontWeight={700}>43245322</Text>
                                                                </Box>
                                                            </HStack>
                                                        </GridItem>
                                                        <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                            <SimpleGrid columns={2} spacing={3}>
                                                                <Box p='1' textAlign={['left']} w='100%' color='black' bg='#FFF' borderRadius='lg' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.50', border: '1px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                                    <HStack spacing={30}>
                                                                        <Box>
                                                                            <Text color='gray.400' fontSize={11} fontWeight={500}>PLACEMENT</Text>
                                                                            <Text fontSize={17} fontWeight={700}>Carré OR</Text>
                                                                        </Box>
                                                                        <Box>
                                                                            <Text color='gray.400' fontSize={11} fontWeight={500}>ROW</Text>
                                                                            <Text fontSize={17} fontWeight={700}>14</Text>
                                                                        </Box>
                                                                        <Box>
                                                                            <Text color='gray.400' fontSize={11} fontWeight={500}>SEAT</Text>
                                                                            <Text fontSize={17} fontWeight={700}>10</Text>
                                                                        </Box>
                                                                        <Spacer />
                                                                        <Box>
                                                                            <Text color='gray.400' fontSize={11} fontWeight={500}>UNIT AMOUNT</Text>
                                                                            <Text fontSize={17} fontWeight={700}>120.33</Text>
                                                                        </Box>
                                                                        <Spacer />
                                                                        <Box>
                                                                            <Text textAlign={['right']} color='gray.400' fontSize={11} fontWeight={500}>TICKET</Text>
                                                                            <HStack spacing={2} textAlign={['center']}>
                                                                                <Box p='0' w='50px' h='25px' color='black' bg='red.50' borderRadius='lg' border='1px' borderColor='red.300' borderStyle={'solid'} _hover={{ border: '2px', borderColor: 'red.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                                                    <Text fontSize={15} fontWeight={400}>#3</Text>
                                                                                </Box>
                                                                            </HStack>
                                                                        </Box>
                                                                    </HStack>
                                                                </Box>
                                                                <Box p='1' textAlign={['left']} w='100%' color='black' bg='#FFF' borderRadius='lg' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.50', border: '1px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                                    <HStack spacing={30}>
                                                                        <Box>
                                                                            <Text color='gray.400' fontSize={11} fontWeight={500}>PLACEMENT</Text>
                                                                            <Text fontSize={17} fontWeight={700}>Carré OR</Text>
                                                                        </Box>
                                                                        <Box>
                                                                            <Text color='gray.400' fontSize={11} fontWeight={500}>ROW</Text>
                                                                            <Text fontSize={17} fontWeight={700}>14</Text>
                                                                        </Box>
                                                                        <Box>
                                                                            <Text color='gray.400' fontSize={11} fontWeight={500}>SEAT</Text>
                                                                            <Text fontSize={17} fontWeight={700}>11</Text>
                                                                        </Box>
                                                                        <Spacer />
                                                                        <Box>
                                                                            <Text color='gray.400' fontSize={11} fontWeight={500}>UNIT AMOUNT</Text>
                                                                            <Text fontSize={17} fontWeight={700}>120.33</Text>
                                                                        </Box>
                                                                        <Spacer />
                                                                        <Box>
                                                                            <Text textAlign={['right']} color='gray.400' fontSize={11} fontWeight={500}>TICKET</Text>
                                                                            <HStack spacing={2} textAlign={['center']}>
                                                                                <Box p='0' w='50px' h='25px' color='black' bg='red.50' borderRadius='lg' border='1px' borderColor='red.300' borderStyle={'solid'} _hover={{ border: '2px', borderColor: 'red.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                                                    <Text fontSize={15} fontWeight={400}>#4</Text>
                                                                                </Box>
                                                                            </HStack>
                                                                        </Box>
                                                                    </HStack>
                                                                </Box>
                                                            </SimpleGrid>
                                                        </GridItem>

                                                    </Grid>
                                                </Box>

                                                {/* Sale #2*/}
                                                <Box p='2' textAlign={['right']} w='100%' minH='100px' color='black' bg='#F5F5F5' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                    <Grid gap='2' w='100%' maxW='100vw' templateColumns='repeat(12, 1fr)'>

                                                        <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                            <HStack spacing='15px'>
                                                                <Box p='1' mr='2' bg='gray.50' borderRadius='lg'>
                                                                    <Text color='gray.400' fontSize={13} fontWeight={500}>SUPPORT</Text>
                                                                    <Text fontSize={18} fontWeight={700}>ARENACCESS</Text>
                                                                </Box>
                                                                <Spacer />
                                                                <Box p='1' mr='2' bg='gray.50' borderRadius='lg'>
                                                                    <Text color='gray.400' fontSize={13} fontWeight={500}>DATE</Text>
                                                                    <Text fontSize={18} fontWeight={500}>2023.01.18</Text>
                                                                </Box>
                                                                <Box p='1' mr='2' bg='gray.50' borderRadius='lg'>
                                                                    <Text color='gray.400' fontSize={13} fontWeight={500}>QUANTITY</Text>
                                                                    <Text fontSize={18} fontWeight={500}>2</Text>
                                                                </Box>
                                                                <Box p='1' mr='2' bg='gray.50' borderRadius='lg'>
                                                                    <Text color='gray.400' fontSize={13} fontWeight={500}>TOTAL AMOUNT</Text>
                                                                    <Text fontSize={18} fontWeight={500}>240.66</Text>
                                                                </Box>
                                                                <Spacer />
                                                                <Box p='1' bg='gray.50' borderRadius='lg'>
                                                                    <Text color='gray.400' fontSize={13} fontWeight={500}>SALE ID</Text>
                                                                    <Text fontSize={18} fontWeight={700}>43245322</Text>
                                                                </Box>
                                                            </HStack>
                                                        </GridItem>
                                                        <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                            <SimpleGrid columns={2} spacing={3}>
                                                                <Box p='1' textAlign={['left']} w='100%' color='black' bg='#FFF' borderRadius='lg' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.50', border: '1px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                                    <HStack spacing={30}>
                                                                        <Box>
                                                                            <Text color='gray.400' fontSize={11} fontWeight={500}>PLACEMENT</Text>
                                                                            <Text fontSize={17} fontWeight={700}>Carré OR</Text>
                                                                        </Box>
                                                                        <Box>
                                                                            <Text color='gray.400' fontSize={11} fontWeight={500}>ROW</Text>
                                                                            <Text fontSize={17} fontWeight={700}>14</Text>
                                                                        </Box>
                                                                        <Box>
                                                                            <Text color='gray.400' fontSize={11} fontWeight={500}>SEAT</Text>
                                                                            <Text fontSize={17} fontWeight={700}>12</Text>
                                                                        </Box>
                                                                        <Spacer />
                                                                        <Box>
                                                                            <Text color='gray.400' fontSize={11} fontWeight={500}>UNIT AMOUNT</Text>
                                                                            <Text fontSize={17} fontWeight={700}>120.33</Text>
                                                                        </Box>
                                                                        <Spacer />
                                                                        <Box>
                                                                            <Text textAlign={['right']} color='gray.400' fontSize={11} fontWeight={500}>TICKET</Text>
                                                                            <HStack spacing={2} textAlign={['center']}>
                                                                                <Box p='0' w='50px' h='25px' color='black' bg='red.50' borderRadius='lg' border='1px' borderColor='red.300' borderStyle={'solid'} _hover={{ border: '2px', borderColor: 'red.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                                                    <Text fontSize={15} fontWeight={400}>#5</Text>
                                                                                </Box>
                                                                            </HStack>
                                                                        </Box>
                                                                    </HStack>
                                                                </Box>
                                                                <Box p='1' textAlign={['left']} w='100%' color='black' bg='#FFF' borderRadius='lg' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.50', border: '1px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                                    <HStack spacing={30}>
                                                                        <Box>
                                                                            <Text color='gray.400' fontSize={11} fontWeight={500}>PLACEMENT</Text>
                                                                            <Text fontSize={17} fontWeight={700}>Carré OR</Text>
                                                                        </Box>
                                                                        <Box>
                                                                            <Text color='gray.400' fontSize={11} fontWeight={500}>ROW</Text>
                                                                            <Text fontSize={17} fontWeight={700}>14</Text>
                                                                        </Box>
                                                                        <Box>
                                                                            <Text color='gray.400' fontSize={11} fontWeight={500}>SEAT</Text>
                                                                            <Text fontSize={17} fontWeight={700}>13</Text>
                                                                        </Box>
                                                                        <Spacer />
                                                                        <Box>
                                                                            <Text color='gray.400' fontSize={11} fontWeight={500}>UNIT AMOUNT</Text>
                                                                            <Text fontSize={17} fontWeight={700}>120.33</Text>
                                                                        </Box>
                                                                        <Spacer />
                                                                        <Box>
                                                                            <Text textAlign={['right']} color='gray.400' fontSize={11} fontWeight={500}>TICKET</Text>
                                                                            <HStack spacing={2} textAlign={['center']}>
                                                                                <Box p='0' w='50px' h='25px' color='black' bg='red.50' borderRadius='lg' border='1px' borderColor='red.300' borderStyle={'solid'} _hover={{ border: '2px', borderColor: 'red.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                                                    <Text fontSize={15} fontWeight={400}>#6</Text>
                                                                                </Box>
                                                                            </HStack>
                                                                        </Box>
                                                                    </HStack>
                                                                </Box>
                                                            </SimpleGrid>
                                                        </GridItem>

                                                    </Grid>
                                                </Box>

                                                <Box p='2' textAlign={['center']} w='100%' color='black' bg='#F5F5F5' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                    <IconButton
                                                        isRound={true}
                                                        variant='solid'
                                                        bg='gray.300'
                                                        color='white'
                                                        aria-label='Add'
                                                        fontSize='15px'
                                                        icon={<AddIcon />}
                                                    />
                                                </Box>

                                            </VStack>

                                        </Box>

                                    </HStack>

                                </GridItem>



                                {/* ROW 2 */}
                                <GridItem color='black' p='2' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }} _hover={{ bg: 'gray.200', border: '1px', borderColor: 'gray.400', borderStyle: 'solid' }}>

                                    <HStack>

                                        {/* Tickets */}

                                        {/* Add Tickets */}
                                        <Box p='2' textAlign={['center']} w='33%' color='black' bg='#F5F5F5' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }}>
                                            <IconButton
                                                isRound={true}
                                                variant='solid'
                                                bg='gray.300'
                                                color='white'
                                                aria-label='Add'
                                                fontSize='15px'
                                                icon={<AddIcon />}
                                            />
                                        </Box>


                                        {/* Listing */}
                                        <Box p='2' textAlign={['center']} w='33%' minH='100px' color='black' bg='#F5F5F5' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>

                                            <Grid gap='2' w='100%' maxW='100vw' templateColumns='repeat(12, 1fr)'>

                                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                    <HStack>
                                                        <Box p='1' mr='5' bg='gray.50' borderRadius='lg'>
                                                            <HStack>
                                                                <Text fontSize={18} fontWeight={700}>54213256</Text>
                                                                <Text color='gray.400' fontSize={13} fontWeight={500}>LISTING ID</Text>
                                                            </HStack>
                                                        </Box>
                                                        <Spacer />
                                                        <Text color='gray.400' fontSize={13} fontWeight={500}>CLONE</Text>
                                                        <Text color='gray.400' fontSize={13} fontWeight={500}>DELETE</Text>
                                                    </HStack>
                                                </GridItem>

                                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                    <VStack>
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='#FFF' borderRadius='lg' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.50', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <Flex>
                                                                <Box p='1'>
                                                                    <Text color='gray.400' fontSize={10} fontWeight={500}>BLOC / CATEGORY</Text>
                                                                    <Select
                                                                        w='200px'
                                                                        placeholder=' '
                                                                        bg={'white'}
                                                                        fontSize={17}
                                                                        fontWeight={700}
                                                                    >
                                                                        <option value='option1'>Lower tier P</option>
                                                                        <option value='option2'>Category 1</option>
                                                                    </Select>
                                                                </Box>
                                                                <Box p='1' w='100px'>
                                                                    <Text color='gray.400' fontSize={10} fontWeight={500}>ROW</Text>
                                                                    <Input fontSize={17} fontWeight={700} bg={'white'} />
                                                                </Box>
                                                                <Spacer />
                                                                <Box p='1' w='80px'>
                                                                    <Text color='gray.400' fontSize={10} fontWeight={500}>QUANTITY</Text>
                                                                    <Input fontSize={17} fontWeight={700} bg={'white'} />
                                                                </Box>
                                                                <Box p='1' w='80px'>
                                                                    <Text color='gray.400' fontSize={10} fontWeight={500}>SPLIT MODE</Text>
                                                                    <Select
                                                                        w='150px'
                                                                        placeholder=' '
                                                                        bg={'white'}
                                                                        fontSize={17}
                                                                        fontWeight={700}
                                                                    >
                                                                        <option value='option1'>skip 1or 3</option>
                                                                        <option value='option2'>none</option>
                                                                    </Select>
                                                                </Box>
                                                                <Spacer />
                                                                <Box p='1' w='120px'>
                                                                    <Text color='gray.400' fontSize={10} fontWeight={500}>FACIAL PRICE</Text>
                                                                    <Input fontSize={17} fontWeight={700} bg={'white'} />
                                                                </Box>
                                                            </Flex>
                                                            <Flex>
                                                                <Box p='1'>
                                                                    <HStack>
                                                                        <Text color='gray.400' mr='2' fontSize={10} fontWeight={500}>FEATURE</Text>
                                                                        <Badge>Visibility limited</Badge>
                                                                    </HStack>
                                                                </Box>
                                                                <Spacer />
                                                                <Box p='1'>
                                                                    <HStack>
                                                                        <Text color='gray.400' mr='2' fontSize={10} fontWeight={500}>FORMAT</Text>
                                                                        <Badge>eticket</Badge>
                                                                    </HStack>
                                                                </Box>
                                                            </Flex>
                                                        </Box>
                                                    </VStack>
                                                </GridItem>

                                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                    <VStack>

                                                        {/* Ticket bullet */}
                                                        <HStack spacing={2} textAlign={['center']}>
                                                            <Box p='0' w='50px' h='25px' color='black' bg='green.50' borderRadius='lg' border='1px' borderColor='green.300' borderStyle={'solid'} _hover={{ border: '2px', borderColor: 'green.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                                <Text fontSize={15} fontWeight={400}>#1</Text>
                                                            </Box>
                                                            <Box p='0' w='50px' h='25px' color='black' bg='green.50' borderRadius='lg' border='1px' borderColor='green.300' borderStyle={'solid'} _hover={{ border: '2px', borderColor: 'green.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                                <Text fontSize={15} fontWeight={400}>#2</Text>
                                                            </Box>
                                                            <Box p='0' w='50px' h='25px' color='black' bg='green.50' borderRadius='lg' border='1px' borderColor='green.300' borderStyle={'solid'} _hover={{ border: '2px', borderColor: 'green.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                                <Text fontSize={15} fontWeight={400}>#3</Text>
                                                            </Box>
                                                            <Box p='0' w='50px' h='25px' color='black' bg='green.50' borderRadius='lg' border='1px' borderColor='green.300' borderStyle={'solid'} _hover={{ border: '2px', borderColor: 'green.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                                <Text fontSize={15} fontWeight={400}>#4</Text>
                                                            </Box>
                                                        </HStack>

                                                        {/* Arenaccess */}
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='#FFF' borderRadius='lg' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.50', border: '1px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <VStack mr='5' w='150px'>
                                                                    <Text fontSize={18} fontWeight={600}>ARENACCESS</Text>
                                                                    <Text mt='-3' color='gray.400' fontSize={12} fontWeight={400}>SHOP</Text>
                                                                </VStack>
                                                                <HStack columns={3} spacing={3}>
                                                                    <Box w='50px'>
                                                                        <Text fontSize={17} fontWeight={700}>
                                                                            <Switch id='email-alerts' />
                                                                        </Text>
                                                                    </Box>
                                                                    <Box w='250px'>
                                                                        <Text color='gray.400' fontSize={11} fontWeight={500}>PLACEMENT</Text>
                                                                        <Select
                                                                            placeholder=' '
                                                                            bg={'white'}
                                                                            fontSize={17}
                                                                            fontWeight={700}
                                                                        >
                                                                            <option value='option1'>Lower tier P</option>
                                                                            <option value='option2'>Category 1</option>
                                                                        </Select>
                                                                    </Box>
                                                                    <Box w='100px'>
                                                                        <VStack>
                                                                            <HStack>
                                                                                <Checkbox defaultChecked></Checkbox>
                                                                                <Text color='gray.400' fontSize={11} fontWeight={500}>ROW</Text>
                                                                            </HStack>
                                                                            <HStack>
                                                                                <Checkbox></Checkbox>
                                                                                <Text color='gray.400' fontSize={11} fontWeight={500}>SEAT</Text>
                                                                            </HStack>
                                                                        </VStack>
                                                                    </Box>
                                                                    <Box w='100px'>
                                                                        <Text color='gray.400' fontSize={11} fontWeight={500}>RESALE PRICE</Text>
                                                                        <Text fontSize={17} fontWeight={700}>
                                                                            <Input bg={'white'} />
                                                                        </Text>
                                                                    </Box>
                                                                </HStack>
                                                            </HStack>
                                                        </Box>

                                                        <IconButton
                                                            isRound={true}
                                                            variant='solid'
                                                            bg='gray.300'
                                                            color='white'
                                                            aria-label='Add'
                                                            fontSize='15px'
                                                            icon={<AddIcon />}
                                                        />
                                                    </VStack>
                                                </GridItem>

                                            </Grid>

                                        </Box>


                                        {/* Add Sale */}
                                        <Box p='2' textAlign={['center']} w='33%' color='black' bg='#F5F5F5' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }}>
                                            <IconButton
                                                isRound={true}
                                                variant='solid'
                                                bg='gray.300'
                                                color='white'
                                                aria-label='Add'
                                                fontSize='15px'
                                                icon={<AddIcon />}
                                            />
                                        </Box>

                                    </HStack>

                                </GridItem>


                                <GridItem color='black' p='2' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }} _hover={{ bg: 'gray.200', border: '1px', borderColor: 'gray.400', borderStyle: 'solid' }}>

                                    <HStack>

                                        {/* Tickets */}
                                        <Box p='2' textAlign={['left']} w='33%' minH='100px' color='black' bg='#F5F5F5' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }}>

                                            <Grid gap='2' w='100%' maxW='100vw' templateColumns='repeat(12, 1fr)'>

                                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                    <Text fontSize={18} fontWeight={700}>BuyID : 43245322</Text>
                                                </GridItem>

                                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                    <VStack>
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='#FFF' borderRadius='lg' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.50', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <Text fontSize={20} fontWeight={400} mr='3'>#1</Text>
                                                                <SimpleGrid columns={5} spacing={3}>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>CATEGORY</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Carré OR</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BLOC</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Lower A</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>ROW</Text>
                                                                        <Text fontSize={17} fontWeight={700}>14</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>SEAT</Text>
                                                                        <Text fontSize={17} fontWeight={700}>10</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BARCODE</Text>
                                                                        <Text fontSize={17} fontWeight={700}>43577643345</Text>
                                                                    </Box>
                                                                </SimpleGrid>
                                                            </HStack>
                                                        </Box>
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='#FFF' borderRadius='lg' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.50', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <Text fontSize={20} fontWeight={400} mr='3'>#2</Text>
                                                                <SimpleGrid columns={5} spacing={3}>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>CATEGORY</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Carré OR</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BLOC</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Lower A</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>ROW</Text>
                                                                        <Text fontSize={17} fontWeight={700}>14</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>SEAT</Text>
                                                                        <Text fontSize={17} fontWeight={700}>11</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BARCODE</Text>
                                                                        <Text fontSize={17} fontWeight={700}>43577643346</Text>
                                                                    </Box>
                                                                </SimpleGrid>
                                                            </HStack>
                                                        </Box>
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='#FFF' borderRadius='lg' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.50', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <Text fontSize={20} fontWeight={400} mr='3'>#3</Text>
                                                                <SimpleGrid columns={5} spacing={3}>
                                                                    <Box>
                                                                        <Text color='gray.400' fontSize={11} fontWeight={500}>CATEGORY</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Carré OR</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text color='gray.400' fontSize={11} fontWeight={500}>BLOC</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Lower A</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text color='gray.400' fontSize={11} fontWeight={500}>ROW</Text>
                                                                        <Text fontSize={17} fontWeight={700}>14</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text color='gray.400' fontSize={11} fontWeight={500}>SEAT</Text>
                                                                        <Text fontSize={17} fontWeight={700}>12</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text color='gray.400' fontSize={11} fontWeight={500}>BARCODE</Text>
                                                                        <Text fontSize={17} fontWeight={700}>43577643347</Text>
                                                                    </Box>
                                                                </SimpleGrid>
                                                            </HStack>
                                                        </Box>
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='#FFF' borderRadius='lg' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.50', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <Text fontSize={20} fontWeight={400} mr='3'>#4</Text>
                                                                <SimpleGrid columns={5} spacing={3}>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>CATEGORY</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Carré OR</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BLOC</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Lower A</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>ROW</Text>
                                                                        <Text fontSize={17} fontWeight={700}>14</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>SEAT</Text>
                                                                        <Text fontSize={17} fontWeight={700}>13</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BARCODE</Text>
                                                                        <Text fontSize={17} fontWeight={700}>43577643348</Text>
                                                                    </Box>
                                                                </SimpleGrid>
                                                            </HStack>
                                                        </Box>
                                                    </VStack>
                                                </GridItem>

                                            </Grid>

                                        </Box>

                                        <Box w='50px' minH='100px' border='1px' color='black'>
                                            >>>
                                        </Box>

                                        {/* Listing */}
                                        <Box p='2' textAlign={['right']} w='33%' minH='100px' color='black' bg='#F5F5F5' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>

                                            <Grid gap='2' w='100%' maxW='100vw' templateColumns='repeat(12, 1fr)'>

                                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                    <Text fontSize={18} fontWeight={700}>ListingID : 54213256</Text>
                                                </GridItem>

                                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                    <VStack>
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='green.50' borderRadius='lg' border='1px' borderColor='green.300' borderStyle={'solid'} _hover={{ border: '2px', borderColor: 'green.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <Text fontSize={20} fontWeight={400} mr='3'>#1</Text>
                                                                <SimpleGrid columns={5} spacing={3}>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>CATEGORY</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Carré OR</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BLOC</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Lower A</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>ROW</Text>
                                                                        <Text fontSize={17} fontWeight={700}>14</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>SEAT</Text>
                                                                        <Text fontSize={17} fontWeight={700}>10</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BARCODE</Text>
                                                                        <Text fontSize={17} fontWeight={700}>43577643345</Text>
                                                                    </Box>
                                                                </SimpleGrid>
                                                            </HStack>
                                                        </Box>
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='green.50' borderRadius='lg' border='1px' borderColor='green.300' borderStyle={'solid'} _hover={{ border: '2px', borderColor: 'green.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <Text fontSize={20} fontWeight={400} mr='3'>#2</Text>
                                                                <SimpleGrid columns={5} spacing={3}>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>CATEGORY</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Carré OR</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BLOC</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Lower A</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>ROW</Text>
                                                                        <Text fontSize={17} fontWeight={700}>14</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>SEAT</Text>
                                                                        <Text fontSize={17} fontWeight={700}>11</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BARCODE</Text>
                                                                        <Text fontSize={17} fontWeight={700}>43577643346</Text>
                                                                    </Box>
                                                                </SimpleGrid>
                                                            </HStack>
                                                        </Box>
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='red.50' borderRadius='lg' border='1px' borderColor='red.300' borderStyle={'solid'} _hover={{ border: '2px', borderColor: 'red.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <Text fontSize={20} fontWeight={400} mr='3'>#3</Text>
                                                                <SimpleGrid columns={5} spacing={3}>
                                                                    <Box>
                                                                        <Text color='gray.400' fontSize={11} fontWeight={500}>CATEGORY</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Carré OR</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text color='gray.400' fontSize={11} fontWeight={500}>BLOC</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Lower A</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text color='gray.400' fontSize={11} fontWeight={500}>ROW</Text>
                                                                        <Text fontSize={17} fontWeight={700}>14</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text color='gray.400' fontSize={11} fontWeight={500}>SEAT</Text>
                                                                        <Text fontSize={17} fontWeight={700}>12</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text color='gray.400' fontSize={11} fontWeight={500}>BARCODE</Text>
                                                                        <Text fontSize={17} fontWeight={700}>43577643347</Text>
                                                                    </Box>
                                                                </SimpleGrid>
                                                            </HStack>
                                                        </Box>
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='red.50' borderRadius='lg' border='1px' borderColor='red.300' borderStyle={'solid'} _hover={{ border: '2px', borderColor: 'red.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <Text fontSize={20} fontWeight={400} mr='3'>#4</Text>
                                                                <SimpleGrid columns={5} spacing={3}>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>CATEGORY</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Carré OR</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BLOC</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Lower A</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>ROW</Text>
                                                                        <Text fontSize={17} fontWeight={700}>14</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>SEAT</Text>
                                                                        <Text fontSize={17} fontWeight={700}>13</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BARCODE</Text>
                                                                        <Text fontSize={17} fontWeight={700}>43577643348</Text>
                                                                    </Box>
                                                                </SimpleGrid>
                                                            </HStack>
                                                        </Box>
                                                    </VStack>
                                                </GridItem>

                                            </Grid>

                                        </Box>

                                        <Box w='50px' minH='100px' border='1px' color='black'>
                                            >>>
                                        </Box>

                                        {/* Sales */}
                                        <Box p='2' textAlign={['right']} w='33%' minH='100px' color='black' bg='#F5F5F5' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>

                                            <Grid gap='2' w='100%' maxW='100vw' templateColumns='repeat(12, 1fr)'>

                                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                    <Text fontSize={18} fontWeight={700}>SaleID : 54213256</Text>
                                                </GridItem>
                                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                    <VStack>
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='#FFF' borderRadius='lg' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.200', border: '1px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <Text fontSize={20} fontWeight={400} mr='3'>#3</Text>
                                                                <SimpleGrid columns={5} spacing={3}>
                                                                    <Box>
                                                                        <Text color='gray.400' fontSize={11} fontWeight={500}>CATEGORY</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Carré OR</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text color='gray.400' fontSize={11} fontWeight={500}>BLOC</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Lower A</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text color='gray.400' fontSize={11} fontWeight={500}>ROW</Text>
                                                                        <Text fontSize={17} fontWeight={700}>14</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text color='gray.400' fontSize={11} fontWeight={500}>SEAT</Text>
                                                                        <Text fontSize={17} fontWeight={700}>12</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text color='gray.400' fontSize={11} fontWeight={500}>BARCODE</Text>
                                                                        <Text fontSize={17} fontWeight={700}>43577643347</Text>
                                                                    </Box>
                                                                </SimpleGrid>
                                                            </HStack>
                                                        </Box>
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='#FFF' borderRadius='lg' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.300', border: '1px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <Text fontSize={20} fontWeight={400} mr='3'>#4</Text>
                                                                <SimpleGrid columns={5} spacing={3}>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>CATEGORY</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Carré OR</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BLOC</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Lower A</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>ROW</Text>
                                                                        <Text fontSize={17} fontWeight={700}>14</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>SEAT</Text>
                                                                        <Text fontSize={17} fontWeight={700}>13</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BARCODE</Text>
                                                                        <Text fontSize={17} fontWeight={700}>43577643348</Text>
                                                                    </Box>
                                                                </SimpleGrid>
                                                            </HStack>
                                                        </Box>
                                                    </VStack>
                                                </GridItem>


                                            </Grid>

                                        </Box>

                                    </HStack>
                                </GridItem>

                                <GridItem color='black' p='2' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }} _hover={{ bg: 'gray.200', border: '1px', borderColor: 'gray.400', borderStyle: 'solid' }}>
                                    <HStack>

                                        {/* Tickets */}
                                        <Box p='2' textAlign={['left']} w='33%' minH='100px' color='black' bg='#F5F5F5' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>

                                            <Grid gap='2' w='100%' maxW='100vw' templateColumns='repeat(12, 1fr)'>

                                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "4" }}>
                                                    <Text fontSize={18} fontWeight={700}>Coldplay</Text>
                                                    <Text fontSize={17} fontWeight={500}>2024.06.12, 19:00</Text>
                                                    <Text fontSize={17} fontWeight={500}>Stade de France</Text>
                                                </GridItem>

                                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "8" }}>
                                                    <VStack>
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='#FFF' borderRadius='lg' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.300', border: '1px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <Text fontSize={20} fontWeight={400} mr='3'>#1</Text>
                                                                <SimpleGrid columns={5} spacing={3}>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>CATEGORY</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Carré OR</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BLOC</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Lower A</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>ROW</Text>
                                                                        <Text fontSize={17} fontWeight={700}>14</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>SEAT</Text>
                                                                        <Text fontSize={17} fontWeight={700}>10</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BARCODE</Text>
                                                                        <Text fontSize={17} fontWeight={700}>43577643345</Text>
                                                                    </Box>
                                                                </SimpleGrid>
                                                            </HStack>
                                                        </Box>
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='#FFF' borderRadius='lg' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.300', border: '1px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <Text fontSize={20} fontWeight={400} mr='3'>#2</Text>
                                                                <SimpleGrid columns={5} spacing={3}>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>CATEGORY</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Carré OR</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BLOC</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Lower A</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>ROW</Text>
                                                                        <Text fontSize={17} fontWeight={700}>14</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>SEAT</Text>
                                                                        <Text fontSize={17} fontWeight={700}>11</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BARCODE</Text>
                                                                        <Text fontSize={17} fontWeight={700}>43577643346</Text>
                                                                    </Box>
                                                                </SimpleGrid>
                                                            </HStack>
                                                        </Box>
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='#FFF' borderRadius='lg' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.200', border: '1px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <Text fontSize={20} fontWeight={400} mr='3'>#3</Text>
                                                                <SimpleGrid columns={5} spacing={3}>
                                                                    <Box>
                                                                        <Text color='gray.400' fontSize={11} fontWeight={500}>CATEGORY</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Carré OR</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text color='gray.400' fontSize={11} fontWeight={500}>BLOC</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Lower A</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text color='gray.400' fontSize={11} fontWeight={500}>ROW</Text>
                                                                        <Text fontSize={17} fontWeight={700}>14</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text color='gray.400' fontSize={11} fontWeight={500}>SEAT</Text>
                                                                        <Text fontSize={17} fontWeight={700}>12</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text color='gray.400' fontSize={11} fontWeight={500}>BARCODE</Text>
                                                                        <Text fontSize={17} fontWeight={700}>43577643347</Text>
                                                                    </Box>
                                                                </SimpleGrid>
                                                            </HStack>
                                                        </Box>
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='#FFF' borderRadius='lg' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.300', border: '1px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <Text fontSize={20} fontWeight={400} mr='3'>#4</Text>
                                                                <SimpleGrid columns={5} spacing={3}>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>CATEGORY</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Carré OR</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BLOC</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Lower A</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>ROW</Text>
                                                                        <Text fontSize={17} fontWeight={700}>14</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>SEAT</Text>
                                                                        <Text fontSize={17} fontWeight={700}>13</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BARCODE</Text>
                                                                        <Text fontSize={17} fontWeight={700}>43577643348</Text>
                                                                    </Box>
                                                                </SimpleGrid>
                                                            </HStack>
                                                        </Box>
                                                    </VStack>
                                                </GridItem>

                                            </Grid>

                                        </Box>

                                        <Box w='50px' minH='100px' border='1px' color='black'>
                                            >>>
                                        </Box>

                                        {/* Listing */}
                                        <Box p='2' textAlign={['right']} w='33%' minH='100px' color='black' bg='#F5F5F5' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>

                                            <Grid gap='2' w='100%' maxW='100vw' templateColumns='repeat(12, 1fr)'>

                                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                    <Text fontSize={18} fontWeight={700}>ListingID : 54213256</Text>
                                                </GridItem>

                                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                    <VStack>
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='green.50' borderRadius='lg' border='1px' borderColor='green.300' borderStyle={'solid'} _hover={{ border: '2px', borderColor: 'green.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <Text fontSize={20} fontWeight={400} mr='3'>#1</Text>
                                                                <SimpleGrid columns={5} spacing={3}>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>CATEGORY</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Carré OR</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BLOC</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Lower A</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>ROW</Text>
                                                                        <Text fontSize={17} fontWeight={700}>14</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>SEAT</Text>
                                                                        <Text fontSize={17} fontWeight={700}>10</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BARCODE</Text>
                                                                        <Text fontSize={17} fontWeight={700}>43577643345</Text>
                                                                    </Box>
                                                                </SimpleGrid>
                                                            </HStack>
                                                        </Box>
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='green.50' borderRadius='lg' border='1px' borderColor='green.300' borderStyle={'solid'} _hover={{ border: '2px', borderColor: 'green.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <Text fontSize={20} fontWeight={400} mr='3'>#2</Text>
                                                                <SimpleGrid columns={5} spacing={3}>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>CATEGORY</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Carré OR</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BLOC</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Lower A</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>ROW</Text>
                                                                        <Text fontSize={17} fontWeight={700}>14</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>SEAT</Text>
                                                                        <Text fontSize={17} fontWeight={700}>11</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BARCODE</Text>
                                                                        <Text fontSize={17} fontWeight={700}>43577643346</Text>
                                                                    </Box>
                                                                </SimpleGrid>
                                                            </HStack>
                                                        </Box>
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='green.50' borderRadius='lg' border='1px' borderColor='green.300' borderStyle={'solid'} _hover={{ border: '2px', borderColor: 'green.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <Text fontSize={20} fontWeight={400} mr='3'>#3</Text>
                                                                <SimpleGrid columns={5} spacing={3}>
                                                                    <Box>
                                                                        <Text color='gray.400' fontSize={11} fontWeight={500}>CATEGORY</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Carré OR</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text color='gray.400' fontSize={11} fontWeight={500}>BLOC</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Lower A</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text color='gray.400' fontSize={11} fontWeight={500}>ROW</Text>
                                                                        <Text fontSize={17} fontWeight={700}>14</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text color='gray.400' fontSize={11} fontWeight={500}>SEAT</Text>
                                                                        <Text fontSize={17} fontWeight={700}>12</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text color='gray.400' fontSize={11} fontWeight={500}>BARCODE</Text>
                                                                        <Text fontSize={17} fontWeight={700}>43577643347</Text>
                                                                    </Box>
                                                                </SimpleGrid>
                                                            </HStack>
                                                        </Box>
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='green.50' borderRadius='lg' border='1px' borderColor='green.300' borderStyle={'solid'} _hover={{ border: '2px', borderColor: 'green.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <Text fontSize={20} fontWeight={400} mr='3'>#4</Text>
                                                                <SimpleGrid columns={5} spacing={3}>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>CATEGORY</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Carré OR</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BLOC</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Lower A</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>ROW</Text>
                                                                        <Text fontSize={17} fontWeight={700}>14</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>SEAT</Text>
                                                                        <Text fontSize={17} fontWeight={700}>13</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BARCODE</Text>
                                                                        <Text fontSize={17} fontWeight={700}>43577643348</Text>
                                                                    </Box>
                                                                </SimpleGrid>
                                                            </HStack>
                                                        </Box>
                                                    </VStack>
                                                </GridItem>

                                            </Grid>

                                        </Box>

                                        <Box w='50px' minH='100px' border='1px' color='black'>
                                            >>>
                                        </Box>

                                        {/* Sales - no sales */}
                                        <Box p='2' textAlign={['right']} w='33%' minH='100px' color='black' bg='#F5F5F5' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                            Create sales
                                        </Box>

                                    </HStack>
                                </GridItem>

                                <GridItem color='black' p='2' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }} _hover={{ bg: 'gray.200', border: '1px', borderColor: 'gray.400', borderStyle: 'solid' }}>
                                    <HStack>

                                        {/* Tickets */}
                                        <Box p='2' textAlign={['left']} w='33%' minH='100px' color='black' bg='#F5F5F5' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>

                                            <Grid gap='2' w='100%' maxW='100vw' templateColumns='repeat(12, 1fr)'>

                                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "4" }}>
                                                    <Text fontSize={18} fontWeight={700}>Coldplay</Text>
                                                    <Text fontSize={17} fontWeight={500}>2024.06.12, 19:00</Text>
                                                    <Text fontSize={17} fontWeight={500}>Stade de France</Text>
                                                </GridItem>

                                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "8" }}>

                                                </GridItem>

                                            </Grid>

                                        </Box>

                                        <Box w='50px' minH='100px' border='1px' color='black'>
                                            >>>
                                        </Box>

                                        {/* Listing */}
                                        <Box p='2' textAlign={['right']} w='33%' minH='100px' color='black' bg='#F5F5F5' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>

                                            <Grid gap='2' w='100%' maxW='100vw' templateColumns='repeat(12, 1fr)'>

                                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                    <Text fontSize={18} fontWeight={700}>ListingID : 54213256</Text>
                                                </GridItem>

                                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                    <VStack>
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='red.50' borderRadius='lg' border='1px' borderColor='red.300' borderStyle={'solid'} _hover={{ border: '2px', borderColor: 'red.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <Text fontSize={20} fontWeight={400} mr='3'>#1</Text>
                                                                <SimpleGrid columns={5} spacing={3}>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>CATEGORY</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Carré OR</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BLOC</Text>
                                                                        <Text fontSize={17} fontWeight={700}></Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>ROW</Text>
                                                                        <Text fontSize={17} fontWeight={700}></Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>SEAT</Text>
                                                                        <Text fontSize={17} fontWeight={700}></Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BARCODE</Text>
                                                                        <Text fontSize={17} fontWeight={700}></Text>
                                                                    </Box>
                                                                </SimpleGrid>
                                                            </HStack>
                                                        </Box>
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='red.50' borderRadius='lg' border='1px' borderColor='red.300' borderStyle={'solid'} _hover={{ border: '2px', borderColor: 'red.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <Text fontSize={20} fontWeight={400} mr='3'>#2</Text>
                                                                <SimpleGrid columns={5} spacing={3}>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>CATEGORY</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Carré OR</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BLOC</Text>
                                                                        <Text fontSize={17} fontWeight={700}></Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>ROW</Text>
                                                                        <Text fontSize={17} fontWeight={700}></Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>SEAT</Text>
                                                                        <Text fontSize={17} fontWeight={700}></Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BARCODE</Text>
                                                                        <Text fontSize={17} fontWeight={700}></Text>
                                                                    </Box>
                                                                </SimpleGrid>
                                                            </HStack>
                                                        </Box>
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='green.50' borderRadius='lg' border='1px' borderColor='green.300' borderStyle={'solid'} _hover={{ border: '2px', borderColor: 'green.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <Text fontSize={20} fontWeight={400} mr='3'>#3</Text>
                                                                <SimpleGrid columns={5} spacing={3}>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>CATEGORY</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Carré OR</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BLOC</Text>
                                                                        <Text fontSize={17} fontWeight={700}></Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>ROW</Text>
                                                                        <Text fontSize={17} fontWeight={700}></Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>SEAT</Text>
                                                                        <Text fontSize={17} fontWeight={700}></Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BARCODE</Text>
                                                                        <Text fontSize={17} fontWeight={700}></Text>
                                                                    </Box>
                                                                </SimpleGrid>
                                                            </HStack>
                                                        </Box>
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='green.50' borderRadius='lg' border='1px' borderColor='green.300' borderStyle={'solid'} _hover={{ border: '2px', borderColor: 'green.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <Text fontSize={20} fontWeight={400} mr='3'>#4</Text>
                                                                <SimpleGrid columns={5} spacing={3}>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>CATEGORY</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Carré OR</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BLOC</Text>
                                                                        <Text fontSize={17} fontWeight={700}></Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>ROW</Text>
                                                                        <Text fontSize={17} fontWeight={700}></Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>SEAT</Text>
                                                                        <Text fontSize={17} fontWeight={700}></Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BARCODE</Text>
                                                                        <Text fontSize={17} fontWeight={700}></Text>
                                                                    </Box>
                                                                </SimpleGrid>
                                                            </HStack>
                                                        </Box>
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='green.50' borderRadius='lg' border='1px' borderColor='green.300' borderStyle={'solid'} _hover={{ border: '2px', borderColor: 'green.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <Text fontSize={20} fontWeight={400} mr='3'>#5</Text>
                                                                <SimpleGrid columns={5} spacing={3}>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>CATEGORY</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Carré OR</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BLOC</Text>
                                                                        <Text fontSize={17} fontWeight={700}></Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>ROW</Text>
                                                                        <Text fontSize={17} fontWeight={700}></Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>SEAT</Text>
                                                                        <Text fontSize={17} fontWeight={700}></Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BARCODE</Text>
                                                                        <Text fontSize={17} fontWeight={700}></Text>
                                                                    </Box>
                                                                </SimpleGrid>
                                                            </HStack>
                                                        </Box>
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='green.50' borderRadius='lg' border='1px' borderColor='green.300' borderStyle={'solid'} _hover={{ border: '2px', borderColor: 'green.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <Text fontSize={20} fontWeight={400} mr='3'>#6</Text>
                                                                <SimpleGrid columns={5} spacing={3}>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>CATEGORY</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Carré OR</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BLOC</Text>
                                                                        <Text fontSize={17} fontWeight={700}></Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>ROW</Text>
                                                                        <Text fontSize={17} fontWeight={700}></Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>SEAT</Text>
                                                                        <Text fontSize={17} fontWeight={700}></Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BARCODE</Text>
                                                                        <Text fontSize={17} fontWeight={700}></Text>
                                                                    </Box>
                                                                </SimpleGrid>
                                                            </HStack>
                                                        </Box>
                                                    </VStack>
                                                </GridItem>

                                            </Grid>

                                        </Box>

                                        <Box w='50px' minH='100px' border='1px' color='black'>
                                            >>>
                                        </Box>

                                        {/* Sales */}
                                        <Box p='2' textAlign={['right']} w='33%' minH='100px' color='black' bg='#F5F5F5' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>

                                            <Grid gap='2' w='100%' maxW='100vw' templateColumns='repeat(12, 1fr)'>

                                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                    <Text fontSize={18} fontWeight={700}>SaleID : 54213256</Text>
                                                </GridItem>

                                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                    <VStack>
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='#FFF' borderRadius='lg' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.300', border: '1px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <Text fontSize={20} fontWeight={400} mr='3'>#1</Text>
                                                                <SimpleGrid columns={5} spacing={3}>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>CATEGORY</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Carré OR</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BLOC</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Lower A</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>ROW</Text>
                                                                        <Text fontSize={17} fontWeight={700}>14</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>SEAT</Text>
                                                                        <Text fontSize={17} fontWeight={700}>10</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BARCODE</Text>
                                                                        <Text fontSize={17} fontWeight={700}>43577643345</Text>
                                                                    </Box>
                                                                </SimpleGrid>
                                                            </HStack>
                                                        </Box>
                                                        <Box p='1' textAlign={['left']} w='100%' color='black' bg='#FFF' borderRadius='lg' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.300', border: '1px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                            <HStack>
                                                                <Text fontSize={20} fontWeight={400} mr='3'>#2</Text>
                                                                <SimpleGrid columns={5} spacing={3}>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>CATEGORY</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Carré OR</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BLOC</Text>
                                                                        <Text fontSize={17} fontWeight={700}>Lower A</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>ROW</Text>
                                                                        <Text fontSize={17} fontWeight={700}>14</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>SEAT</Text>
                                                                        <Text fontSize={17} fontWeight={700}>11</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontSize={11} fontWeight={500}>BARCODE</Text>
                                                                        <Text fontSize={17} fontWeight={700}>43577643346</Text>
                                                                    </Box>
                                                                </SimpleGrid>
                                                            </HStack>
                                                        </Box>
                                                    </VStack>
                                                </GridItem>

                                            </Grid>

                                        </Box>

                                    </HStack>
                                </GridItem>

                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                    <HStack>
                                        <Box w='50%' h='100px' color='black' bg='#F5F5F5' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                            A
                                        </Box>
                                        <Box w='50px' h='100px' color='black'>
                                            B
                                        </Box>
                                        <Box w='50%' h='100px' color='black' bg='#F5F5F5' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                            C
                                        </Box>
                                    </HStack>
                                </GridItem>

                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                    <HStack>
                                        <Box w='50%' h='100px' color='black' bg='#F5F5F5' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                            A
                                        </Box>
                                        <Box w='50px' h='100px' color='black'>
                                            B
                                        </Box>
                                        <Box w='50%' h='100px' color='black' bg='#F5F5F5' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                            C
                                        </Box>
                                    </HStack>
                                </GridItem>

                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                    <HStack>
                                        <Box w='50%' h='100px' color='black' bg='#F5F5F5' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                            A
                                        </Box>
                                        <Box w='50px' h='100px' color='black'>
                                            B
                                        </Box>
                                        <Box w='50%' h='100px' color='black' bg='#F5F5F5' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                            C
                                        </Box>
                                    </HStack>
                                </GridItem>

                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                    <HStack>
                                        <Box w='50%' h='100px' color='black' bg='#F5F5F5' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                            A
                                        </Box>
                                        <Box w='50px' h='100px' color='black'>
                                            B
                                        </Box>
                                        <Box w='50%' h='100px' color='black' bg='#F5F5F5' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                            C
                                        </Box>
                                    </HStack>
                                </GridItem>
                            </Grid>

                        </Box>


                    </GridItem>
                </Grid>
            </Center >
        </>
    )

    return content
}
export default CartsList