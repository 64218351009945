import http from './http-common';

const upload = (formData, targetFolder, onUploadProgress) => {
    //alert("targetFolder = " + targetFolder)
    const destRouteUpload = '/upload/' + targetFolder
    return http.post(destRouteUpload, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
    });
};

const getFiles = () => {
    return http.get('/files');
};

export { upload as uploadFile, getFiles };