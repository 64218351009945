import { useState, useEffect } from "react";
import {
  useGetInventoryWaitQuery,
  useUpdateCartWaitMutation,
} from "./cartsApiSlice";
import Cart from "./Cart";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useLang from "../../hooks/useLang";
import useTranslate from "../../hooks/useTranslate";
import useTitle from "../../hooks/useTitle";
import PulseLoader from "react-spinners/PulseLoader";

// import `ChakraProvider` component
import {
  Box,
  Grid,
  GridItem,
  Center,
  HStack,
  VStack,
  Container,
  Flex,
  SimpleGrid,
  FormControl,
  FormLabel,
  Textarea,
  Checkbox,
  Input,
  Switch,
  Badge,
  Tag,
  TagLabel,
  TagCloseButton,
  Avatar,
  Toast,
  Icon,
  IconButton,
  Image,
  Stack,
  Alert,
  AlertIcon,
  Select,
  Button,
  useRadioGroup,
  useRadio,
  RadioGroup,
  Radio,
  Text,
  useDisclosure,
  useToast,
  Spacer,
} from "@chakra-ui/react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";

// import `ChakraProvider` icon
import { AddIcon } from "@chakra-ui/icons";

import {
  Bs0CircleFill,
  Bs1CircleFill,
  Bs2CircleFill,
  BsBoxFill,
  BsFilePdf,
  BsGridFill,
  BsBarChartLineFill,
} from "react-icons/bs";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationCrosshairs,
  faSnowman,
  faList,
} from "@fortawesome/free-solid-svg-icons";

const FirstMarket = () => {
  useTitle("BROKY: Inventory Inventory");

  const { username, isManager, isAdmin } = useAuth();

  const { lang } = useLang();
  const { T } = useTranslate(lang);

  let menuContent;
  if (isManager || isAdmin) {
    menuContent = (
      <>
        <Link to="/dash/carts/new">
          <Button
            size="sm"
            leftIcon={<AddIcon />}
            colorScheme="red"
            variant="solid"
          >
            <Text
              color="white"
              display={{ base: "none", sm: "flex" }}
              fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }}
              fontWeight={600}
            >
              {T.common.Add}
            </Text>
          </Button>
        </Link>
      </>
    );
  }

  const [hoveredRow, setHoveredRow] = useState(null);

  const handleRowMouseEnter = (productId) => {
    setHoveredRow(productId);
  };

  const handleRowMouseLeave = () => {
    setHoveredRow(null);
  };

  const initialTableState = {
    table1: false,
    table2: false,
    // Ajoutez d'autres tables avec leur état initial ici
  };

  const [tableStates, setTableStates] = useState(initialTableState);

  const handleSwitchChange = (tableName) => {
    setTableStates((prevTableStates) => ({
      ...prevTableStates,
      [tableName]: !prevTableStates[tableName],
    }));
  };

  let content;
  content = (
    <>
      <Center bg="#45413C" axis="both">
        <Grid
          w="95vw"
          minH="100vh"
          maxW="95vw"
          templateColumns="repeat(12, 1fr)"
        >
          <GridItem
            colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}
          >
            <Grid p="5" gap="5" templateColumns="repeat(12, 1fr)">
              <GridItem
                colSpan={{
                  base: "6",
                  sm: "6",
                  md: "6",
                  lg: "6",
                  xl: "6",
                  "2xl": "6",
                }}
                textAlign={["left"]}
              >
                <Text
                  color={"white"}
                  fontSize={{
                    base: "24",
                    sm: "24",
                    md: "24",
                    lg: "24",
                    xl: "28",
                  }}
                  fontWeight={600}
                >
                  <FontAwesomeIcon icon={faLocationCrosshairs} /> {T.tixy.Tixy}{" "}
                  : {T.tixy.firstMarket}
                </Text>
              </GridItem>
              <GridItem
                colSpan={{
                  base: "6",
                  sm: "6",
                  md: "6",
                  lg: "6",
                  xl: "6",
                  "2xl": "6",
                }}
                textAlign={["right"]}
              >
                {menuContent}
              </GridItem>
            </Grid>

            <Box
              borderRadius="xl"
              mb="2"
              p={2}
              textAlign={["center"]}
              bg="white"
              _hover={{
                bg: "gray.200",
                border: "1px",
                borderRadius: "xl",
                borderColor: "gray.400",
                borderStyle: "solid",
              }}
            >
              <Grid
                gap="2"
                w="100%"
                maxW="100vw"
                templateColumns="repeat(12, 1fr)"
              >
                {/* Head */}
                <GridItem
                  color="black"
                  colSpan={{
                    base: "12",
                    sm: "12",
                    md: "12",
                    lg: "12",
                    xl: "12",
                    "2xl": "2",
                  }}
                >
                  <Flex>
                    <Box p="2" textAlign={["left"]}>
                      <Switch
                        size="md"
                        checked={tableStates["table1"]}
                        onChange={() => handleSwitchChange("table1")}
                      />
                    </Box>
                    <Box
                      p="1"
                      textAlign={["left"]}
                      w="100%"
                      color="black"
                      lineHeight="1.2"
                    >
                      <Text fontSize={20} fontWeight={800}>
                        Coldplay
                      </Text>
                      <Text color="gray.600" fontSize={14} fontWeight={600}>
                        2024.06.12, 19:00
                      </Text>
                      <Text color="gray.600" fontSize={13} fontWeight={500}>
                        France, Saint-Denis, Stade de France
                      </Text>
                    </Box>
                  </Flex>

                  {/* Button NAV */}
                  {tableStates["table1"] && (
                    <>
                      <SimpleGrid
                        columns={{
                          base: "4",
                          sm: "4",
                          md: "4",
                          lg: "4",
                          xl: "4",
                          "2xl": "4",
                        }}
                        spacing={2}
                        mt="2"
                        mb="2"
                      >
                        {/* Go DISPATCHER */}
                        <Link to="/dash/tixy/dispatcher">
                          <Box
                            p="2"
                            textAlign={["center"]}
                            color="black"
                            bg="gray.50"
                            borderRadius="xl"
                            border="1px"
                            borderColor="gray.300"
                            borderStyle={"solid"}
                            _hover={{
                              bg: "gray.100",
                              border: "2px",
                              borderColor: "gray.400",
                              borderStyle: "solid",
                            }}
                            cursor={"pointer"}
                          >
                            <Text
                              color="gray.600"
                              fontSize={14}
                              fontWeight={600}
                            >
                              DISPATCHER
                            </Text>
                            <IconButton
                              isRound={true}
                              variant="solid"
                              bg="gray.300"
                              color="gray.700"
                              aria-label="Add"
                              fontSize="15px"
                              icon={<BsGridFill />}
                            />
                          </Box>
                        </Link>

                        {/* Go INVENTORY */}
                        <Link to="/dash/tixy/inventory">
                          <Box
                            p="2"
                            textAlign={["center"]}
                            color="black"
                            bg="gray.50"
                            borderRadius="xl"
                            border="1px"
                            borderColor="gray.300"
                            borderStyle={"solid"}
                            _hover={{
                              bg: "gray.100",
                              border: "2px",
                              borderColor: "gray.400",
                              borderStyle: "solid",
                            }}
                            cursor={"pointer"}
                          >
                            <Text
                              color="gray.600"
                              fontSize={14}
                              fontWeight={600}
                            >
                              INVENTORY
                            </Text>
                            <IconButton
                              isRound={true}
                              variant="solid"
                              bg="gray.300"
                              color="gray.700"
                              aria-label="Add"
                              fontSize="15px"
                              icon={<BsBoxFill />}
                            />
                          </Box>
                        </Link>

                        {/* Go POS MARKET */}
                        <Link to="/dash/tixy/posMarket">
                          <Box
                            p="2"
                            textAlign={["center"]}
                            color="black"
                            bg="gray.50"
                            borderRadius="xl"
                            border="1px"
                            borderColor="gray.300"
                            borderStyle={"solid"}
                            _hover={{
                              bg: "gray.100",
                              border: "2px",
                              borderColor: "gray.400",
                              borderStyle: "solid",
                            }}
                            cursor={"pointer"}
                          >
                            <Text
                              color="gray.600"
                              fontSize={14}
                              fontWeight={600}
                            >
                              POS MARKET
                            </Text>
                            <IconButton
                              isRound={true}
                              variant="solid"
                              bg="gray.300"
                              color="gray.700"
                              aria-label="Add"
                              fontSize="15px"
                              icon={<Bs0CircleFill />}
                            />
                          </Box>
                        </Link>

                        {/* Go 2ND MARKET */}
                        <Link to="/dash/tixy/secondMarket">
                          <Box
                            p="2"
                            textAlign={["center"]}
                            color="black"
                            bg="gray.50"
                            borderRadius="xl"
                            border="1px"
                            borderColor="gray.300"
                            borderStyle={"solid"}
                            _hover={{
                              bg: "gray.100",
                              border: "2px",
                              borderColor: "gray.400",
                              borderStyle: "solid",
                            }}
                            cursor={"pointer"}
                          >
                            <Text
                              color="gray.600"
                              fontSize={14}
                              fontWeight={600}
                            >
                              2ND MARKET
                            </Text>
                            <IconButton
                              isRound={true}
                              variant="solid"
                              bg="gray.300"
                              color="gray.700"
                              aria-label="Add"
                              fontSize="15px"
                              icon={<Bs2CircleFill />}
                            />
                          </Box>
                        </Link>
                      </SimpleGrid>
                    </>
                  )}
                </GridItem>

                {/* Inventory */}
                <GridItem
                  color="black"
                  colSpan={{
                    base: "12",
                    sm: "12",
                    md: "12",
                    lg: "12",
                    xl: "12",
                    "2xl": "10",
                  }}
                >
                  <Box
                    p="2"
                    textAlign={["center"]}
                    w="100%"
                    color="black"
                    bg="gray.50"
                    borderRadius="xl"
                    border="1px"
                    borderColor="gray.300"
                    borderStyle={"solid"}
                    _hover={{
                      bg: "gray.100",
                      border: "2px",
                      borderColor: "gray.400",
                      borderStyle: "solid",
                    }}
                  >
                    <Flex spacing="10px" color="gray.600">
                      {/* SOURCE */}
                      <Box
                        minW="280px"
                        p="2"
                        mr="2"
                        bg="gray.200"
                        borderRadius="xl"
                        lineHeight="1"
                      >
                        <HStack>
                          <Box bg="gray.50" p="1" minW="50px" borderRadius="md">
                            <Text
                              color="gray.700"
                              fontSize={10}
                              fontWeight={400}
                            >
                              SAVE
                            </Text>
                            <Text
                              color="green.400"
                              fontSize={16}
                              fontWeight={700}
                            >
                              24h
                            </Text>
                          </Box>
                          <Box bg="gray.50" p="1" minW="50px" borderRadius="md">
                            <Text
                              color="gray.700"
                              fontSize={10}
                              fontWeight={400}
                            >
                              SCAN
                            </Text>
                            <Text
                              color="green.400"
                              fontSize={16}
                              fontWeight={700}
                            >
                              30s
                            </Text>
                          </Box>
                          <Box
                            bg="gray.500"
                            p="1"
                            minW="200px"
                            borderRadius="md"
                          >
                            <Text color="white" fontSize={20} fontWeight={500}>
                              Ticketmaster.fr
                            </Text>
                          </Box>
                        </HStack>
                        {tableStates["table1"] && (
                          <>
                            <Box
                              minWidth="max-content"
                              mt="1"
                              bg={"gray.50"}
                              borderRadius="md"
                              p="1"
                            >
                              <Box pr="1">
                                <HStack textAlign={"right"}>
                                  <Switch
                                    size="sm"
                                    checked={tableStates["table1"]}
                                  />
                                  <Text
                                    fontSize={11}
                                    fontWeight={500}
                                    maxW="50px"
                                    textAlign={"left"}
                                  >
                                    SAVE
                                  </Text>
                                  <Spacer />
                                  <Select
                                    //placeholder="extra small size"
                                    size="xs"
                                    bg={"white"}
                                  >
                                    <option value="option1">OFF</option>
                                    <option value="option1">
                                      every 30 seconds
                                    </option>
                                    <option value="option3">
                                      every 1 minutes
                                    </option>
                                    <option value="option3">
                                      every 5 minutes
                                    </option>
                                    <option value="option4">
                                      every 10 minutes
                                    </option>
                                    <option value="option4">
                                      every 20 minutes
                                    </option>
                                    <option value="option4">
                                      every 30 minutes
                                    </option>
                                    <option value="option4">
                                      every 1 hours
                                    </option>
                                    <option value="option4">
                                      every 2 hours
                                    </option>
                                    <option value="option4">
                                      every 3 hours
                                    </option>
                                    <option value="option4">
                                      every 6 hours
                                    </option>
                                    <option value="option4">
                                      every 12 hours
                                    </option>
                                    <option value="option4">
                                      every 24 hours
                                    </option>
                                  </Select>
                                </HStack>
                              </Box>
                              <Box pr="1">
                                <HStack textAlign={"right"}>
                                  <Switch
                                    size="sm"
                                    checked={tableStates["table1"]}
                                  />
                                  <Text
                                    fontSize={11}
                                    fontWeight={500}
                                    maxW="50px"
                                    textAlign={"left"}
                                  >
                                    SCAN
                                  </Text>
                                  <Spacer />
                                  <Select
                                    //placeholder="extra small size"
                                    size="xs"
                                    bg={"white"}
                                  >
                                    <option value="option1">OFF</option>
                                    <option value="option1">
                                      every 5 seconds
                                    </option>
                                    <option value="option1">
                                      every 10 seconds
                                    </option>
                                    <option value="option1">
                                      every 30 seconds
                                    </option>
                                    <option value="option3">
                                      every 60 seconds
                                    </option>
                                  </Select>
                                </HStack>
                              </Box>
                            </Box>
                          </>
                        )}
                      </Box>

                      {/* CAT */}
                      <Box
                        minW="180px"
                        p="2"
                        mr="2"
                        bg="green.100"
                        borderRadius="xl"
                        lineHeight="1"
                      >
                        <HStack>
                          <Text
                            maxW="150px"
                            fontSize={16}
                            fontWeight={700}
                            whiteSpace="normal"
                          >
                            CARRE OR
                          </Text>
                          <Spacer />
                          <Text
                            color="green.400"
                            fontSize={25}
                            fontWeight={700}
                          >
                            438
                          </Text>
                          <Box bg="gray.50" p="1" minW="50px" borderRadius="md">
                            <Text
                              color="gray.700"
                              fontSize={10}
                              fontWeight={400}
                            >
                              24h
                            </Text>
                            <Text
                              color="red.400"
                              fontSize={18}
                              fontWeight={500}
                            >
                              -12
                            </Text>
                          </Box>
                        </HStack>
                        {!tableStates["table1"] && (
                          <>
                            <Flex minWidth="max-content" mt="1" spacing="1">
                              <Text
                                p="1"
                                mr="1"
                                whiteSpace="normal"
                                fontSize={10}
                                fontWeight={400}
                                color={"gray.400"}
                                bg={"gray.50"}
                                borderRadius="md"
                              >
                                ALERT
                              </Text>
                              <Text
                                p="1"
                                mr="1"
                                whiteSpace="normal"
                                fontSize={10}
                                fontWeight={700}
                                color={"gray.700"}
                                bg={"gray.100"}
                                borderRadius="md"
                              >
                                CARTBY
                              </Text>
                              <Text
                                p="1"
                                mr="1"
                                whiteSpace="normal"
                                fontSize={10}
                                fontWeight={400}
                                color={"gray.400"}
                                bg={"gray.50"}
                                borderRadius="md"
                              >
                                BLOC
                              </Text>
                              <Text
                                p="1"
                                mr="1"
                                whiteSpace="normal"
                                fontSize={10}
                                fontWeight={400}
                                color={"gray.400"}
                                bg={"gray.50"}
                                borderRadius="md"
                              >
                                VAR
                              </Text>
                              <Text
                                p="1"
                                mr="1"
                                whiteSpace="normal"
                                fontSize={10}
                                fontWeight={400}
                                color={"gray.400"}
                                bg={"gray.50"}
                                borderRadius="md"
                              >
                                PAY
                              </Text>
                            </Flex>
                          </>
                        )}
                        {tableStates["table1"] && (
                          <>
                            <Flex
                              minWidth="max-content"
                              mt="1"
                              bg={"gray.50"}
                              borderRadius="md"
                              p="1"
                            >
                              <Box width={"55%"} pr="1">
                                <HStack>
                                  <Text
                                    maxW="150px"
                                    fontSize={11}
                                    fontWeight={500}
                                    whiteSpace="normal"
                                  >
                                    ALERT
                                  </Text>
                                  <Spacer />
                                  <Text
                                    color="gray.400"
                                    fontSize={10}
                                    fontWeight={700}
                                  >
                                    100-1000
                                  </Text>
                                </HStack>
                                <HStack>
                                  <Text
                                    maxW="150px"
                                    fontSize={11}
                                    fontWeight={500}
                                    whiteSpace="normal"
                                  >
                                    CARTBY
                                  </Text>
                                  <Spacer />
                                  <Text
                                    color="gray.400"
                                    fontSize={10}
                                    fontWeight={700}
                                  >
                                    2-4
                                  </Text>
                                </HStack>
                                <HStack>
                                  <Text
                                    maxW="150px"
                                    fontSize={11}
                                    fontWeight={500}
                                    whiteSpace="normal"
                                  >
                                    BLOC
                                  </Text>
                                  <Spacer />
                                  <Text
                                    color="gray.400"
                                    fontSize={10}
                                    fontWeight={700}
                                  >
                                    20-50
                                  </Text>
                                </HStack>
                                <HStack>
                                  <Text
                                    maxW="150px"
                                    fontSize={11}
                                    fontWeight={500}
                                    whiteSpace="normal"
                                  >
                                    VAR
                                  </Text>
                                  <Spacer />
                                  <Text
                                    color="gray.400"
                                    fontSize={10}
                                    fontWeight={700}
                                  >
                                    1-20
                                  </Text>
                                </HStack>
                                <HStack>
                                  <Text
                                    maxW="150px"
                                    fontSize={11}
                                    fontWeight={500}
                                    whiteSpace="normal"
                                  >
                                    PAY
                                  </Text>
                                  <Spacer />
                                  <Text
                                    color="gray.400"
                                    fontSize={10}
                                    fontWeight={700}
                                  >
                                    0-0
                                  </Text>
                                </HStack>{" "}
                              </Box>
                              <Box width={"45%"} pl="2">
                                <HStack>
                                  <Spacer />
                                  <Text
                                    maxW="150px"
                                    fontSize={11}
                                    fontWeight={500}
                                    whiteSpace="normal"
                                  >
                                    VAR.
                                  </Text>
                                </HStack>
                                <HStack>
                                  <Text
                                    maxW="150px"
                                    fontSize={11}
                                    fontWeight={500}
                                    whiteSpace="normal"
                                  >
                                    24H
                                  </Text>
                                  <Spacer />
                                  <Text
                                    color="gray.400"
                                    fontSize={10}
                                    fontWeight={700}
                                  >
                                    -20
                                  </Text>
                                </HStack>
                                <HStack>
                                  <Text
                                    maxW="150px"
                                    fontSize={11}
                                    fontWeight={500}
                                    whiteSpace="normal"
                                  >
                                    07D
                                  </Text>
                                  <Spacer />
                                  <Text
                                    color="gray.400"
                                    fontSize={10}
                                    fontWeight={700}
                                  >
                                    -80
                                  </Text>
                                </HStack>
                                <HStack>
                                  <Text
                                    maxW="150px"
                                    fontSize={11}
                                    fontWeight={500}
                                    whiteSpace="normal"
                                  >
                                    30D
                                  </Text>
                                  <Spacer />
                                  <Text
                                    color="gray.400"
                                    fontSize={10}
                                    fontWeight={700}
                                  >
                                    -160
                                  </Text>
                                </HStack>
                              </Box>
                            </Flex>
                          </>
                        )}
                      </Box>

                      {/* CAT */}
                      <Box
                        minW="180px"
                        p="2"
                        mr="2"
                        bg="green.100"
                        borderRadius="xl"
                        lineHeight="1"
                      >
                        <HStack>
                          <Text
                            maxW="150px"
                            fontSize={16}
                            fontWeight={700}
                            whiteSpace="normal"
                          >
                            CATEGORY 1
                          </Text>
                          <Spacer />
                          <Text
                            color="green.400"
                            fontSize={25}
                            fontWeight={700}
                          >
                            247
                          </Text>
                          <Box bg="gray.50" p="1" minW="50px" borderRadius="md">
                            <Text
                              color="gray.700"
                              fontSize={10}
                              fontWeight={400}
                            >
                              24h
                            </Text>
                            <Text
                              color="red.400"
                              fontSize={18}
                              fontWeight={500}
                            >
                              -31
                            </Text>
                          </Box>
                        </HStack>
                        {tableStates["table1"] && (
                          <>
                            <Flex
                              minWidth="max-content"
                              mt="1"
                              bg={"gray.50"}
                              borderRadius="md"
                              p="1"
                            >
                              <Box width={"55%"} pr="1">
                                <HStack>
                                  <Text
                                    maxW="150px"
                                    fontSize={11}
                                    fontWeight={500}
                                    whiteSpace="normal"
                                  >
                                    ALERT
                                  </Text>
                                  <Spacer />
                                  <Text
                                    color="gray.400"
                                    fontSize={10}
                                    fontWeight={700}
                                  >
                                    100-1000
                                  </Text>
                                </HStack>
                                <HStack>
                                  <Text
                                    maxW="150px"
                                    fontSize={11}
                                    fontWeight={500}
                                    whiteSpace="normal"
                                  >
                                    CARTBY
                                  </Text>
                                  <Spacer />
                                  <Text
                                    color="gray.400"
                                    fontSize={10}
                                    fontWeight={700}
                                  >
                                    2-4
                                  </Text>
                                </HStack>
                                <HStack>
                                  <Text
                                    maxW="150px"
                                    fontSize={11}
                                    fontWeight={500}
                                    whiteSpace="normal"
                                  >
                                    BLOC
                                  </Text>
                                  <Spacer />
                                  <Text
                                    color="gray.400"
                                    fontSize={10}
                                    fontWeight={700}
                                  >
                                    20-50
                                  </Text>
                                </HStack>
                                <HStack>
                                  <Text
                                    maxW="150px"
                                    fontSize={11}
                                    fontWeight={500}
                                    whiteSpace="normal"
                                  >
                                    VAR
                                  </Text>
                                  <Spacer />
                                  <Text
                                    color="gray.400"
                                    fontSize={10}
                                    fontWeight={700}
                                  >
                                    1-20
                                  </Text>
                                </HStack>
                                <HStack>
                                  <Text
                                    maxW="150px"
                                    fontSize={11}
                                    fontWeight={500}
                                    whiteSpace="normal"
                                  >
                                    PAY
                                  </Text>
                                  <Spacer />
                                  <Text
                                    color="gray.400"
                                    fontSize={10}
                                    fontWeight={700}
                                  >
                                    0-0
                                  </Text>
                                </HStack>{" "}
                              </Box>
                              <Box width={"45%"} pl="2">
                                <HStack>
                                  <Spacer />
                                  <Text
                                    maxW="150px"
                                    fontSize={11}
                                    fontWeight={500}
                                    whiteSpace="normal"
                                  >
                                    VAR.
                                  </Text>
                                </HStack>
                                <HStack>
                                  <Text
                                    maxW="150px"
                                    fontSize={11}
                                    fontWeight={500}
                                    whiteSpace="normal"
                                  >
                                    24H
                                  </Text>
                                  <Spacer />
                                  <Text
                                    color="gray.400"
                                    fontSize={10}
                                    fontWeight={700}
                                  >
                                    -20
                                  </Text>
                                </HStack>
                                <HStack>
                                  <Text
                                    maxW="150px"
                                    fontSize={11}
                                    fontWeight={500}
                                    whiteSpace="normal"
                                  >
                                    7J
                                  </Text>
                                  <Spacer />
                                  <Text
                                    color="gray.400"
                                    fontSize={10}
                                    fontWeight={700}
                                  >
                                    -80
                                  </Text>
                                </HStack>
                                <HStack>
                                  <Text
                                    maxW="150px"
                                    fontSize={11}
                                    fontWeight={500}
                                    whiteSpace="normal"
                                  >
                                    30J
                                  </Text>
                                  <Spacer />
                                  <Text
                                    color="gray.400"
                                    fontSize={10}
                                    fontWeight={700}
                                  >
                                    -160
                                  </Text>
                                </HStack>
                              </Box>
                            </Flex>
                          </>
                        )}
                      </Box>

                      {/* CAT */}
                      <Box
                        minW="180px"
                        p="2"
                        mr="2"
                        bg="red.100"
                        borderRadius="xl"
                        lineHeight="1"
                      >
                        <HStack>
                          <Text
                            maxW="150px"
                            fontSize={16}
                            fontWeight={700}
                            whiteSpace="normal"
                          >
                            CATEGORY 2 LATERAL VR
                          </Text>
                          <Spacer />
                          <Text color="red.400" fontSize={25} fontWeight={700}>
                            0
                          </Text>
                          <Box bg="gray.50" p="1" minW="50px" borderRadius="md">
                            <Text
                              color="gray.700"
                              fontSize={10}
                              fontWeight={400}
                            >
                              24h
                            </Text>
                            <Text
                              color="red.400"
                              fontSize={18}
                              fontWeight={500}
                            >
                              -8
                            </Text>
                          </Box>
                        </HStack>
                        {tableStates["table1"] && (
                          <>
                            <Flex
                              minWidth="max-content"
                              mt="1"
                              bg={"gray.50"}
                              borderRadius="md"
                              p="1"
                            >
                              <Box width={"55%"} pr="1">
                                <HStack>
                                  <Text
                                    maxW="150px"
                                    fontSize={11}
                                    fontWeight={500}
                                    whiteSpace="normal"
                                  >
                                    ALERT
                                  </Text>
                                  <Spacer />
                                  <Text
                                    color="gray.400"
                                    fontSize={10}
                                    fontWeight={700}
                                  >
                                    100-1000
                                  </Text>
                                </HStack>
                                <HStack>
                                  <Text
                                    maxW="150px"
                                    fontSize={11}
                                    fontWeight={500}
                                    whiteSpace="normal"
                                  >
                                    CARTBY
                                  </Text>
                                  <Spacer />
                                  <Text
                                    color="gray.400"
                                    fontSize={10}
                                    fontWeight={700}
                                  >
                                    2-4
                                  </Text>
                                </HStack>
                                <HStack>
                                  <Text
                                    maxW="150px"
                                    fontSize={11}
                                    fontWeight={500}
                                    whiteSpace="normal"
                                  >
                                    BLOC
                                  </Text>
                                  <Spacer />
                                  <Text
                                    color="gray.400"
                                    fontSize={10}
                                    fontWeight={700}
                                  >
                                    20-50
                                  </Text>
                                </HStack>
                                <HStack>
                                  <Text
                                    maxW="150px"
                                    fontSize={11}
                                    fontWeight={500}
                                    whiteSpace="normal"
                                  >
                                    VAR
                                  </Text>
                                  <Spacer />
                                  <Text
                                    color="gray.400"
                                    fontSize={10}
                                    fontWeight={700}
                                  >
                                    1-20
                                  </Text>
                                </HStack>
                                <HStack>
                                  <Text
                                    maxW="150px"
                                    fontSize={11}
                                    fontWeight={500}
                                    whiteSpace="normal"
                                  >
                                    PAY
                                  </Text>
                                  <Spacer />
                                  <Text
                                    color="gray.400"
                                    fontSize={10}
                                    fontWeight={700}
                                  >
                                    0-0
                                  </Text>
                                </HStack>{" "}
                              </Box>
                              <Box width={"45%"} pl="2">
                                <HStack>
                                  <Spacer />
                                  <Text
                                    maxW="150px"
                                    fontSize={11}
                                    fontWeight={500}
                                    whiteSpace="normal"
                                  >
                                    VAR.
                                  </Text>
                                </HStack>
                                <HStack>
                                  <Text
                                    maxW="150px"
                                    fontSize={11}
                                    fontWeight={500}
                                    whiteSpace="normal"
                                  >
                                    24H
                                  </Text>
                                  <Spacer />
                                  <Text
                                    color="gray.400"
                                    fontSize={10}
                                    fontWeight={700}
                                  >
                                    -20
                                  </Text>
                                </HStack>
                                <HStack>
                                  <Text
                                    maxW="150px"
                                    fontSize={11}
                                    fontWeight={500}
                                    whiteSpace="normal"
                                  >
                                    7J
                                  </Text>
                                  <Spacer />
                                  <Text
                                    color="gray.400"
                                    fontSize={10}
                                    fontWeight={700}
                                  >
                                    -80
                                  </Text>
                                </HStack>
                                <HStack>
                                  <Text
                                    maxW="150px"
                                    fontSize={11}
                                    fontWeight={500}
                                    whiteSpace="normal"
                                  >
                                    30J
                                  </Text>
                                  <Spacer />
                                  <Text
                                    color="gray.400"
                                    fontSize={10}
                                    fontWeight={700}
                                  >
                                    -160
                                  </Text>
                                </HStack>
                              </Box>
                            </Flex>
                          </>
                        )}
                      </Box>
                    </Flex>

                    {/* LAST CRAWL */}
                    {tableStates["table1"] && (
                      <>
                        <Box
                          mt="2"
                          height={"400px"}
                          borderRadius="xl"
                          mb="2"
                          p={2}
                          textAlign={["center"]}
                          bg="white"
                        >
                          GRAPH
                        </Box>
                      </>
                    )}
                  </Box>
                </GridItem>
              </Grid>
            </Box>

            <Box
              borderRadius="xl"
              mb="2"
              p={2}
              textAlign={["center"]}
              bg="white"
              _hover={{
                bg: "gray.200",
                border: "1px",
                borderRadius: "xl",
                borderColor: "gray.400",
                borderStyle: "solid",
              }}
            >
              <Grid
                gap="2"
                w="100%"
                maxW="100vw"
                templateColumns="repeat(12, 1fr)"
              >
                {/* Head */}
                <GridItem
                  color="black"
                  colSpan={{
                    base: "12",
                    sm: "12",
                    md: "12",
                    lg: "12",
                    xl: "12",
                    "2xl": "2",
                  }}
                >
                  <Box
                    p="1"
                    textAlign={["left"]}
                    w="100%"
                    color="black"
                    lineHeight="1.2"
                  >
                    <Text fontSize={20} fontWeight={800}>
                      Coldplay
                    </Text>
                    <Text color="gray.600" fontSize={14} fontWeight={600}>
                      2024.06.12, 19:00
                    </Text>
                    <Text color="gray.600" fontSize={13} fontWeight={500}>
                      France, Saint-Denis, Stade de France
                    </Text>
                  </Box>
                </GridItem>

                {/* Inventory */}
                <GridItem
                  color="black"
                  colSpan={{
                    base: "12",
                    sm: "12",
                    md: "12",
                    lg: "12",
                    xl: "12",
                    "2xl": "10",
                  }}
                >
                  <Box
                    p="2"
                    textAlign={["center"]}
                    w="100%"
                    color="black"
                    bg="gray.50"
                    borderRadius="xl"
                    border="1px"
                    borderColor="gray.300"
                    borderStyle={"solid"}
                    _hover={{
                      bg: "gray.100",
                      border: "2px",
                      borderColor: "gray.400",
                      borderStyle: "solid",
                    }}
                  >
                    <Flex spacing="10px" color="gray.600">
                      {/* CAT */}
                      <Box
                        minW="180px"
                        p="2"
                        mr="2"
                        bg="green.100"
                        borderRadius="xl"
                        lineHeight="1"
                      >
                        <HStack>
                          <Text
                            maxW="150px"
                            fontSize={16}
                            fontWeight={700}
                            whiteSpace="normal"
                          >
                            CARRE OR
                          </Text>
                          <Spacer />
                          <Text
                            color="green.400"
                            fontSize={25}
                            fontWeight={700}
                          >
                            438
                          </Text>
                          <Box bg="gray.50" p="1" minW="50px" borderRadius="md">
                            <Text
                              color="gray.700"
                              fontSize={10}
                              fontWeight={400}
                            >
                              24h
                            </Text>
                            <Text
                              color="red.400"
                              fontSize={18}
                              fontWeight={500}
                            >
                              -12
                            </Text>
                          </Box>
                        </HStack>
                      </Box>

                      {/* CAT */}
                      <Box
                        minW="180px"
                        p="2"
                        mr="2"
                        bg="green.100"
                        borderRadius="xl"
                        lineHeight="1"
                      >
                        <HStack>
                          <Text
                            maxW="150px"
                            fontSize={16}
                            fontWeight={700}
                            whiteSpace="normal"
                          >
                            CATEGORY 1
                          </Text>
                          <Spacer />
                          <Text
                            color="green.400"
                            fontSize={25}
                            fontWeight={700}
                          >
                            247
                          </Text>
                          <Box bg="gray.50" p="1" minW="50px" borderRadius="md">
                            <Text
                              color="gray.700"
                              fontSize={10}
                              fontWeight={400}
                            >
                              24h
                            </Text>
                            <Text
                              color="red.400"
                              fontSize={18}
                              fontWeight={500}
                            >
                              -31
                            </Text>
                          </Box>
                        </HStack>
                      </Box>

                      {/* CAT */}
                      <Box
                        minW="180px"
                        p="2"
                        mr="2"
                        bg="red.100"
                        borderRadius="xl"
                        lineHeight="1"
                      >
                        <HStack>
                          <Text
                            maxW="150px"
                            fontSize={16}
                            fontWeight={700}
                            whiteSpace="normal"
                          >
                            CATEGORY 2 LATERAL VR
                          </Text>
                          <Spacer />
                          <Text color="red.400" fontSize={25} fontWeight={700}>
                            0
                          </Text>
                          <Box bg="gray.50" p="1" minW="50px" borderRadius="md">
                            <Text
                              color="gray.700"
                              fontSize={10}
                              fontWeight={400}
                            >
                              24h
                            </Text>
                            <Text
                              color="red.400"
                              fontSize={18}
                              fontWeight={500}
                            >
                              -8
                            </Text>
                          </Box>
                        </HStack>
                      </Box>
                    </Flex>
                  </Box>
                </GridItem>
              </Grid>
            </Box>

            <Box
              borderRadius="xl"
              mb="2"
              p={2}
              textAlign={["center"]}
              bg="white"
              _hover={{
                bg: "gray.200",
                border: "1px",
                borderRadius: "xl",
                borderColor: "gray.400",
                borderStyle: "solid",
              }}
            >
              <Grid
                gap="2"
                w="100%"
                maxW="100vw"
                templateColumns="repeat(12, 1fr)"
              >
                {/* Head */}
                <GridItem
                  color="black"
                  colSpan={{
                    base: "12",
                    sm: "12",
                    md: "12",
                    lg: "12",
                    xl: "12",
                    "2xl": "2",
                  }}
                >
                  <Box
                    p="1"
                    textAlign={["left"]}
                    w="100%"
                    color="black"
                    lineHeight="1.1"
                  >
                    <Text fontSize={20} fontWeight={800}>
                      Coldplay
                    </Text>
                    <Text color="gray.600" fontSize={16} fontWeight={600}>
                      2024.06.12, 19:00
                    </Text>
                    <Text color="gray.600" fontSize={14} fontWeight={500}>
                      France, Saint-Denis, Stade de France
                    </Text>
                  </Box>
                </GridItem>

                {/* Inventory */}
                <GridItem
                  color="black"
                  colSpan={{
                    base: "12",
                    sm: "12",
                    md: "12",
                    lg: "12",
                    xl: "12",
                    "2xl": "10",
                  }}
                >
                  <Box
                    p="2"
                    textAlign={["center"]}
                    w="100%"
                    color="black"
                    bg="gray.50"
                    borderRadius="xl"
                    border="1px"
                    borderColor="gray.300"
                    borderStyle={"solid"}
                    _hover={{
                      bg: "gray.100",
                      border: "2px",
                      borderColor: "gray.400",
                      borderStyle: "solid",
                    }}
                  >
                    <Flex spacing="10px" color="gray.600">
                      {/* CAT */}
                      <Box
                        minW="180px"
                        p="2"
                        mr="2"
                        bg="green.100"
                        borderRadius="xl"
                        lineHeight="1"
                      >
                        <HStack>
                          <Text
                            maxW="150px"
                            fontSize={16}
                            fontWeight={700}
                            whiteSpace="normal"
                          >
                            CARRE OR
                          </Text>
                          <Spacer />
                          <Text
                            color="green.400"
                            fontSize={25}
                            fontWeight={700}
                          >
                            438
                          </Text>
                          <Box bg="gray.50" p="1" minW="50px" borderRadius="md">
                            <Text
                              color="gray.700"
                              fontSize={10}
                              fontWeight={400}
                            >
                              24h
                            </Text>
                            <Text
                              color="red.400"
                              fontSize={18}
                              fontWeight={500}
                            >
                              -12
                            </Text>
                          </Box>
                        </HStack>
                      </Box>

                      {/* CAT */}
                      <Box
                        minW="180px"
                        p="2"
                        mr="2"
                        bg="green.100"
                        borderRadius="xl"
                        lineHeight="1"
                      >
                        <HStack>
                          <Text
                            maxW="150px"
                            fontSize={16}
                            fontWeight={700}
                            whiteSpace="normal"
                          >
                            CATEGORY 1
                          </Text>
                          <Spacer />
                          <Text
                            color="green.400"
                            fontSize={25}
                            fontWeight={700}
                          >
                            247
                          </Text>
                          <Box bg="gray.50" p="1" minW="50px" borderRadius="md">
                            <Text
                              color="gray.700"
                              fontSize={10}
                              fontWeight={400}
                            >
                              24h
                            </Text>
                            <Text
                              color="red.400"
                              fontSize={18}
                              fontWeight={500}
                            >
                              -31
                            </Text>
                          </Box>
                        </HStack>
                      </Box>

                      {/* CAT */}
                      <Box
                        minW="180px"
                        p="2"
                        mr="2"
                        bg="red.100"
                        borderRadius="xl"
                        lineHeight="1"
                      >
                        <HStack>
                          <Text
                            maxW="150px"
                            fontSize={16}
                            fontWeight={700}
                            whiteSpace="normal"
                          >
                            CATEGORY 2 LATERAL VR
                          </Text>
                          <Spacer />
                          <Text color="red.400" fontSize={25} fontWeight={700}>
                            0
                          </Text>
                          <Box bg="gray.50" p="1" minW="50px" borderRadius="md">
                            <Text
                              color="gray.700"
                              fontSize={10}
                              fontWeight={400}
                            >
                              24h
                            </Text>
                            <Text
                              color="red.400"
                              fontSize={18}
                              fontWeight={500}
                            >
                              -8
                            </Text>
                          </Box>
                        </HStack>
                      </Box>
                    </Flex>
                  </Box>
                </GridItem>
              </Grid>
            </Box>

            <Box
              borderRadius="xl"
              mb="2"
              p={2}
              textAlign={["center"]}
              bg="white"
              _hover={{
                bg: "gray.200",
                border: "1px",
                borderRadius: "xl",
                borderColor: "gray.400",
                borderStyle: "solid",
              }}
            >
              <Grid
                gap="2"
                w="100%"
                maxW="100vw"
                templateColumns="repeat(12, 1fr)"
              >
                {/* Head */}
                <GridItem
                  color="black"
                  colSpan={{
                    base: "12",
                    sm: "12",
                    md: "12",
                    lg: "12",
                    xl: "12",
                    "2xl": "2",
                  }}
                >
                  <Box
                    p="1"
                    textAlign={["left"]}
                    w="100%"
                    color="black"
                    lineHeight="1.1"
                  >
                    <Text fontSize={20} fontWeight={800}>
                      Coldplay
                    </Text>
                    <Text color="gray.600" fontSize={16} fontWeight={600}>
                      2024.06.12, 19:00
                    </Text>
                    <Text color="gray.600" fontSize={14} fontWeight={500}>
                      France, Saint-Denis, Stade de France
                    </Text>
                  </Box>
                </GridItem>

                {/* Inventory */}
                <GridItem
                  color="black"
                  colSpan={{
                    base: "12",
                    sm: "12",
                    md: "12",
                    lg: "12",
                    xl: "12",
                    "2xl": "10",
                  }}
                >
                  <Box
                    p="2"
                    textAlign={["center"]}
                    w="100%"
                    color="black"
                    bg="gray.50"
                    borderRadius="xl"
                    border="1px"
                    borderColor="gray.300"
                    borderStyle={"solid"}
                    _hover={{
                      bg: "gray.100",
                      border: "2px",
                      borderColor: "gray.400",
                      borderStyle: "solid",
                    }}
                  >
                    <Flex spacing="10px" color="gray.600">
                      {/* CAT */}
                      <Box
                        minW="180px"
                        p="2"
                        mr="2"
                        bg="green.100"
                        borderRadius="xl"
                        lineHeight="1"
                      >
                        <HStack>
                          <Text
                            maxW="150px"
                            fontSize={16}
                            fontWeight={700}
                            whiteSpace="normal"
                          >
                            CARRE OR
                          </Text>
                          <Spacer />
                          <Text
                            color="green.400"
                            fontSize={25}
                            fontWeight={700}
                          >
                            438
                          </Text>
                          <Box bg="gray.50" p="1" minW="50px" borderRadius="md">
                            <Text
                              color="gray.700"
                              fontSize={10}
                              fontWeight={400}
                            >
                              24h
                            </Text>
                            <Text
                              color="red.400"
                              fontSize={18}
                              fontWeight={500}
                            >
                              -12
                            </Text>
                          </Box>
                        </HStack>
                      </Box>

                      {/* CAT */}
                      <Box
                        minW="180px"
                        p="2"
                        mr="2"
                        bg="green.100"
                        borderRadius="xl"
                        lineHeight="1"
                      >
                        <HStack>
                          <Text
                            maxW="150px"
                            fontSize={16}
                            fontWeight={700}
                            whiteSpace="normal"
                          >
                            CATEGORY 1
                          </Text>
                          <Spacer />
                          <Text
                            color="green.400"
                            fontSize={25}
                            fontWeight={700}
                          >
                            247
                          </Text>
                          <Box bg="gray.50" p="1" minW="50px" borderRadius="md">
                            <Text
                              color="gray.700"
                              fontSize={10}
                              fontWeight={400}
                            >
                              24h
                            </Text>
                            <Text
                              color="red.400"
                              fontSize={18}
                              fontWeight={500}
                            >
                              -31
                            </Text>
                          </Box>
                        </HStack>
                      </Box>

                      {/* CAT */}
                      <Box
                        minW="180px"
                        p="2"
                        mr="2"
                        bg="red.100"
                        borderRadius="xl"
                        lineHeight="1"
                      >
                        <HStack>
                          <Text
                            maxW="150px"
                            fontSize={16}
                            fontWeight={700}
                            whiteSpace="normal"
                          >
                            CATEGORY 2 LATERAL VR
                          </Text>
                          <Spacer />
                          <Text color="red.400" fontSize={25} fontWeight={700}>
                            0
                          </Text>
                          <Box bg="gray.50" p="1" minW="50px" borderRadius="md">
                            <Text
                              color="gray.700"
                              fontSize={10}
                              fontWeight={400}
                            >
                              24h
                            </Text>
                            <Text
                              color="red.400"
                              fontSize={18}
                              fontWeight={500}
                            >
                              -8
                            </Text>
                          </Box>
                        </HStack>
                      </Box>
                    </Flex>
                  </Box>
                </GridItem>
              </Grid>
            </Box>

            <Box
              borderRadius="xl"
              mb="2"
              p={2}
              textAlign={["center"]}
              bg="white"
              _hover={{
                bg: "gray.200",
                border: "1px",
                borderRadius: "xl",
                borderColor: "gray.400",
                borderStyle: "solid",
              }}
            >
              <Grid
                gap="2"
                w="100%"
                maxW="100vw"
                templateColumns="repeat(12, 1fr)"
              >
                {/* Head */}
                <GridItem
                  color="black"
                  colSpan={{
                    base: "12",
                    sm: "12",
                    md: "12",
                    lg: "12",
                    xl: "12",
                    "2xl": "2",
                  }}
                >
                  <Box
                    p="1"
                    textAlign={["left"]}
                    w="100%"
                    color="black"
                    lineHeight="1.1"
                  >
                    <Text fontSize={20} fontWeight={800}>
                      Coldplay
                    </Text>
                    <Text color="gray.600" fontSize={16} fontWeight={600}>
                      2024.06.12, 19:00
                    </Text>
                    <Text color="gray.600" fontSize={14} fontWeight={500}>
                      France, Saint-Denis, Stade de France
                    </Text>
                  </Box>
                </GridItem>

                {/* Inventory */}
                <GridItem
                  color="black"
                  colSpan={{
                    base: "12",
                    sm: "12",
                    md: "12",
                    lg: "12",
                    xl: "12",
                    "2xl": "10",
                  }}
                >
                  <Box
                    p="2"
                    textAlign={["center"]}
                    w="100%"
                    color="black"
                    bg="gray.50"
                    borderRadius="xl"
                    border="1px"
                    borderColor="gray.300"
                    borderStyle={"solid"}
                    _hover={{
                      bg: "gray.100",
                      border: "2px",
                      borderColor: "gray.400",
                      borderStyle: "solid",
                    }}
                  >
                    <Flex spacing="10px" color="gray.600">
                      {/* CAT */}
                      <Box
                        minW="180px"
                        p="2"
                        mr="2"
                        bg="green.100"
                        borderRadius="xl"
                        lineHeight="1"
                      >
                        <HStack>
                          <Text
                            maxW="150px"
                            fontSize={16}
                            fontWeight={700}
                            whiteSpace="normal"
                          >
                            CARRE OR
                          </Text>
                          <Spacer />
                          <Text
                            color="green.400"
                            fontSize={25}
                            fontWeight={700}
                          >
                            438
                          </Text>
                          <Box bg="gray.50" p="1" minW="50px" borderRadius="md">
                            <Text
                              color="gray.700"
                              fontSize={10}
                              fontWeight={400}
                            >
                              24h
                            </Text>
                            <Text
                              color="red.400"
                              fontSize={18}
                              fontWeight={500}
                            >
                              -12
                            </Text>
                          </Box>
                        </HStack>
                      </Box>

                      {/* CAT */}
                      <Box
                        minW="180px"
                        p="2"
                        mr="2"
                        bg="green.100"
                        borderRadius="xl"
                        lineHeight="1"
                      >
                        <HStack>
                          <Text
                            maxW="150px"
                            fontSize={16}
                            fontWeight={700}
                            whiteSpace="normal"
                          >
                            CATEGORY 1
                          </Text>
                          <Spacer />
                          <Text
                            color="green.400"
                            fontSize={25}
                            fontWeight={700}
                          >
                            247
                          </Text>
                          <Box bg="gray.50" p="1" minW="50px" borderRadius="md">
                            <Text
                              color="gray.700"
                              fontSize={10}
                              fontWeight={400}
                            >
                              24h
                            </Text>
                            <Text
                              color="red.400"
                              fontSize={18}
                              fontWeight={500}
                            >
                              -31
                            </Text>
                          </Box>
                        </HStack>
                      </Box>

                      {/* CAT */}
                      <Box
                        minW="180px"
                        p="2"
                        mr="2"
                        bg="red.100"
                        borderRadius="xl"
                        lineHeight="1"
                      >
                        <HStack>
                          <Text
                            maxW="150px"
                            fontSize={16}
                            fontWeight={700}
                            whiteSpace="normal"
                          >
                            CATEGORY 2 LATERAL VR
                          </Text>
                          <Spacer />
                          <Text color="red.400" fontSize={25} fontWeight={700}>
                            0
                          </Text>
                          <Box bg="gray.50" p="1" minW="50px" borderRadius="md">
                            <Text
                              color="gray.700"
                              fontSize={10}
                              fontWeight={400}
                            >
                              24h
                            </Text>
                            <Text
                              color="red.400"
                              fontSize={18}
                              fontWeight={500}
                            >
                              -8
                            </Text>
                          </Box>
                        </HStack>
                      </Box>
                    </Flex>
                  </Box>
                </GridItem>
              </Grid>
            </Box>

            <Box
              borderRadius="xl"
              mb="2"
              p={2}
              textAlign={["center"]}
              bg="white"
              _hover={{
                bg: "gray.200",
                border: "1px",
                borderRadius: "xl",
                borderColor: "gray.400",
                borderStyle: "solid",
              }}
            >
              <Grid
                gap="2"
                w="100%"
                maxW="100vw"
                templateColumns="repeat(12, 1fr)"
              >
                {/* Head */}
                <GridItem
                  color="black"
                  colSpan={{
                    base: "12",
                    sm: "12",
                    md: "12",
                    lg: "12",
                    xl: "12",
                    "2xl": "2",
                  }}
                >
                  <Box
                    p="1"
                    textAlign={["left"]}
                    w="100%"
                    color="black"
                    lineHeight="1.1"
                  >
                    <Text fontSize={20} fontWeight={800}>
                      Coldplay
                    </Text>
                    <Text color="gray.600" fontSize={16} fontWeight={600}>
                      2024.06.12, 19:00
                    </Text>
                    <Text color="gray.600" fontSize={14} fontWeight={500}>
                      France, Saint-Denis, Stade de France
                    </Text>
                  </Box>
                </GridItem>

                {/* Inventory */}
                <GridItem
                  color="black"
                  colSpan={{
                    base: "12",
                    sm: "12",
                    md: "12",
                    lg: "12",
                    xl: "12",
                    "2xl": "10",
                  }}
                >
                  <Box
                    p="2"
                    textAlign={["center"]}
                    w="100%"
                    color="black"
                    bg="gray.50"
                    borderRadius="xl"
                    border="1px"
                    borderColor="gray.300"
                    borderStyle={"solid"}
                    _hover={{
                      bg: "gray.100",
                      border: "2px",
                      borderColor: "gray.400",
                      borderStyle: "solid",
                    }}
                  >
                    <Flex spacing="10px" color="gray.600">
                      {/* CAT */}
                      <Box
                        minW="180px"
                        p="2"
                        mr="2"
                        bg="green.100"
                        borderRadius="xl"
                        lineHeight="1"
                      >
                        <HStack>
                          <Text
                            maxW="150px"
                            fontSize={16}
                            fontWeight={700}
                            whiteSpace="normal"
                          >
                            CARRE OR
                          </Text>
                          <Spacer />
                          <Text
                            color="green.400"
                            fontSize={25}
                            fontWeight={700}
                          >
                            438
                          </Text>
                          <Box bg="gray.50" p="1" minW="50px" borderRadius="md">
                            <Text
                              color="gray.700"
                              fontSize={10}
                              fontWeight={400}
                            >
                              24h
                            </Text>
                            <Text
                              color="red.400"
                              fontSize={18}
                              fontWeight={500}
                            >
                              -12
                            </Text>
                          </Box>
                        </HStack>
                      </Box>

                      {/* CAT */}
                      <Box
                        minW="180px"
                        p="2"
                        mr="2"
                        bg="green.100"
                        borderRadius="xl"
                        lineHeight="1"
                      >
                        <HStack>
                          <Text
                            maxW="150px"
                            fontSize={16}
                            fontWeight={700}
                            whiteSpace="normal"
                          >
                            CATEGORY 1
                          </Text>
                          <Spacer />
                          <Text
                            color="green.400"
                            fontSize={25}
                            fontWeight={700}
                          >
                            247
                          </Text>
                          <Box bg="gray.50" p="1" minW="50px" borderRadius="md">
                            <Text
                              color="gray.700"
                              fontSize={10}
                              fontWeight={400}
                            >
                              24h
                            </Text>
                            <Text
                              color="red.400"
                              fontSize={18}
                              fontWeight={500}
                            >
                              -31
                            </Text>
                          </Box>
                        </HStack>
                      </Box>

                      {/* CAT */}
                      <Box
                        minW="180px"
                        p="2"
                        mr="2"
                        bg="red.100"
                        borderRadius="xl"
                        lineHeight="1"
                      >
                        <HStack>
                          <Text
                            maxW="150px"
                            fontSize={16}
                            fontWeight={700}
                            whiteSpace="normal"
                          >
                            CATEGORY 2 LATERAL VR
                          </Text>
                          <Spacer />
                          <Text color="red.400" fontSize={25} fontWeight={700}>
                            0
                          </Text>
                          <Box bg="gray.50" p="1" minW="50px" borderRadius="md">
                            <Text
                              color="gray.700"
                              fontSize={10}
                              fontWeight={400}
                            >
                              24h
                            </Text>
                            <Text
                              color="red.400"
                              fontSize={18}
                              fontWeight={500}
                            >
                              -8
                            </Text>
                          </Box>
                        </HStack>
                      </Box>
                    </Flex>
                  </Box>
                </GridItem>
              </Grid>
            </Box>
          </GridItem>
        </Grid>
      </Center>
    </>
  );

  return content;
};
export default FirstMarket;
