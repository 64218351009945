import { useGetNotesQuery } from "./notesApiSlice"
import Note from "./Note"
import { Link } from 'react-router-dom'
import useAuth from "../../hooks/useAuth"
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'
import useTitle from "../../hooks/useTitle"
import PulseLoader from 'react-spinners/PulseLoader'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from "@fortawesome/free-solid-svg-icons"

const NotesList = () => {
    useTitle('BROKY: Notes List')

    const { username, isManager, isAdmin } = useAuth()

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const {
        data: notes,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetNotesQuery('notesList', {
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    let content

    if (isLoading) content = <PulseLoader color={"#FFF"} />

    if (isError) {
        if (isManager || isAdmin) {
            content = (
                <>
                    <div><p className="errmsg">{error?.data?.message}</p></div>
                    <Link to="/dash/notes/new">
                        <button
                            className="icon-button"
                            title={T.common.Add}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    </Link>
                </>
            )
        } else {
            content = <p className="errmsg">{error?.data?.message}</p>
        }
    }

    if (isSuccess) {
        const { ids, entities } = notes

        let filteredIds
        if (isManager || isAdmin) {
            filteredIds = [...ids]
        } else {
            filteredIds = ids.filter(noteId => entities[noteId].username === username)
        }

        const tableContent = ids?.length && filteredIds.map(noteId => <Note key={noteId} noteId={noteId} />)

        let menuContent
        if (isManager || isAdmin) {
            menuContent = (
                <>
                    <Link to="/dash/notes/new">
                        <button
                            className="icon-button"
                            title={T.common.Add}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    </Link>
                </>
            )
        }

        content = (
            <>
                <div className="form__title-row">
                    <h2>{T.notes.NotesList}</h2>
                    <div className="form__action-buttons">
                        {menuContent}
                    </div>
                </div>
                <table className="table table--notes">
                    <thead className="table__thead">
                        <tr>
                            <th scope="col" className="table__th note__status">{T.notes.Status}</th>
                            <th scope="col" className="table__th note__created">{T.common.Created}</th>
                            <th scope="col" className="table__th note__updated">{T.common.Updated}</th>
                            <th scope="col" className="table__th note__title">{T.notes.Title}</th>
                            <th scope="col" className="table__th note__username">{T.notes.Owner}</th>
                            <th scope="col" className="table__th note__edit">{T.notes.Edit}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableContent}
                    </tbody>
                </table>
            </>
        )
    }

    return content
}
export default NotesList