import { useState, useEffect, useRef } from 'react'
import { useNavigate, Link, useLocation } from 'react-router-dom'

import useAuth from '../../hooks/useAuth'
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'


// import `ChakraProvider` component
import { css, Box, Grid, GridItem, Spacer, Collapse, Tag, Center, HStack, VStack, Container, Badge, Icon, IconButton, Image, ButtonGroup, Button, Text, Input, Select, Switch, useDisclosure, AbsoluteCenter } from '@chakra-ui/react'
import { TableContainer, Table, Thead, Tbody, Tfoot, Tr, Th, Td } from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, } from '@chakra-ui/react'
import { Editable, EditableInput, EditableTextarea, EditablePreview, } from '@chakra-ui/react'
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, } from '@chakra-ui/react'


// import `ChakraProvider` icon
import { AddIcon } from '@chakra-ui/icons'
import { FiDownload, FiChevronRight, FiCalendar, FiMapPin, FiMinusSquare, FiPlusSquare, FiEdit, FiCopy, FiSave, FiStar, FiPlus, FiMinus, FiPause, FiPlay, FiGlobe, FiAward, FiUpload, FiCheck, FiMoreHorizontal, FiClock, FiFrown } from "react-icons/fi";
import { ImTicket, ImBarcode } from "react-icons/im";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe, faEarthAmericas, faCity, faRing, faDungeon, faUsers, faLink, faTicket } from "@fortawesome/free-solid-svg-icons"


import PulseLoader from 'react-spinners/PulseLoader'


function ModalExport({ isMarketSelect, isSalesListing }) {

    const [isOpenExport, setisOpenExport] = useState({});
    const modalExportIsOpen = (isOpen) => {
        setisOpenExport(isOpen);
    };
    const modalExportAction = () => {
        alert('Confirmed !')
        setisOpenExport(false)
    }

    return (
        <>
            <Button
                w='100%'
                size='sm'
                leftIcon={<FiDownload />}
                color='white'
                bg='red.600'
                variant='solid'
                _hover={{ bg: 'gray.100', color: 'red.600', borderWidth: '1px', borderColor: 'red.600' }}
                onClick={() => modalExportIsOpen(true)}
            >
                <Text display={{ base: 'none', sm: 'flex' }} fontSize={16} fontWeight={600}>EXPORTS</Text>
            </Button>

            <Modal
                isOpen={isOpenExport ?? false}
                onClose={() => modalExportIsOpen(false)}
                size='md'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Export</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>market = {isMarketSelect}<br />listing = {isSalesListing}</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={() => modalExportAction()}>
                            Confirm
                        </Button>
                        <Button variant='ghost' onClick={() => modalExportIsOpen(false)}>Back</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}


function ModalConfirm({ row, listing, action }) {

    const [isOpenConfirm, setisOpenConfirm] = useState({});
    const modalConfirmIsOpen = (index, isOpen) => {
        if (isOpen === false) {
            setTimeout(() => {
                setCell(index)

                setTimeout(() => {
                    setCell('')
                }, 5000);

            }, 1000);

        }
        setisOpenConfirm((prevState) => {
            return {
                ...prevState,
                [index]: isOpen,
            };

        });
    };
    const modalConfirmAction = () => {
        alert('Confirmed !')
        setisOpenConfirm(false)
    }

    const [cell, setCell] = useState('');

    return (
        <>
            <Button
                w='100%'
                _hover={{ color: 'white', bg: 'red.600' }}
                size='sm'
                leftIcon={<FiCheck />}
                onClick={() => modalConfirmIsOpen(listing.id, !isOpenConfirm[listing.id])}
            >
                <Text display={{ base: 'none', sm: 'flex' }} fontSize={16} fontWeight={600}>Pay</Text>
            </Button>

            <Modal
                key={listing.id}
                id={listing.id}
                isOpen={isOpenConfirm[listing.id] ?? false}
                onClose={() => modalConfirmIsOpen(listing.id, false)}
                size='md'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Are you sure to confirm this sales ?</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={() => modalConfirmAction()}>
                            Confirm
                        </Button>
                        <Button variant='ghost' onClick={() => modalConfirmIsOpen(listing.id, false)}>Back</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

function DawnerViewSale({ row, listing }) {

    const [isOpenSaleEdits, setisOpenSaleEdits] = useState({});
    const drawerSaleIsOpen = (index, isOpen) => {
        if (isOpen === false) {
            setTimeout(() => {
                setCell(index)

                setTimeout(() => {
                    setCell('')
                }, 5000);

            }, 1000);

        }
        setisOpenSaleEdits((prevState) => {
            return {
                ...prevState,
                [index]: isOpen,
            };

        });
    };

    const [cell, setCell] = useState('');

    return (
        <>
            <IconButton
                _hover={{ color: 'white', bg: 'red.600' }}
                aria-label='More'
                icon={<FiMoreHorizontal />}
                onClick={() => drawerSaleIsOpen(listing.id, !isOpenSaleEdits[listing.id])}
            />
            <Drawer
                key={listing.id}
                id={listing.id}
                isOpen={isOpenSaleEdits[listing.id] ?? false}
                onClose={() => drawerSaleIsOpen(listing.id, false)}
                size='md'
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>VIEW CART</DrawerHeader>
                    <DrawerBody>
                        <Box
                            bg='#efefef'
                            borderRadius='xl'
                            borderWidth="1px"
                            borderColor='#dfdfdf'
                            shadow="lg"
                            position="relative"

                            textAlign={['center']}
                        >
                            <Grid w='100%' gap={2} p='2' templateColumns='repeat(12, 1fr)'>

                                {/* Event detail */}
                                <GridItem color='black' colSpan={12}>
                                    <Box p={2} textAlign={['left']}>
                                        <HStack>
                                            <Box w='100px' h='100px' mr='3' borderRadius='xl' border='1px' borderColor='red.600'>
                                                <Box p={1} borderRadius='xl' border='2px' bg='red.600' borderColor='#ffffff' textAlign={['center']}>
                                                    <Text color='white' fontSize={15} fontWeight={600}>
                                                        May. 2024
                                                    </Text>
                                                </Box>
                                                <Box borderRadius='xl' m={0} p={0} color='white' bg='#ffffff' textAlign={['center']}>
                                                    <Text color='black' fontSize={27} fontWeight={700}>
                                                        25
                                                    </Text>
                                                    <Text color='black' fontSize={15} fontWeight={500}>
                                                        {row.eventHour}
                                                    </Text>
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Text fontSize={25} fontWeight={700}>
                                                    {row.eventName}
                                                </Text>
                                                <Text fontSize={20} fontWeight={400} color='red.500'><Icon as={FiMapPin} h={4} w={4} mr={1} alignSelf={'center'} />{row.venueName}, Paris, France</Text>
                                            </Box>
                                        </HStack>
                                    </Box>
                                </GridItem>

                                {/* Price detail */}
                                <GridItem color='black' textAlign={'left'} colSpan={12}>
                                    <Text fontSize={15} fontWeight={500}>Sale details :</Text>
                                    <Box p={2} mt='2' w='100%' textAlign={['left']} borderRadius='xl' fontSize={20} fontWeight={400} color='black' bg='white'>
                                        <HStack>
                                            <Text color='red.600' fontSize={20} fontWeight={400}>Ticket price</Text>
                                            <Spacer />
                                            <Text color='red.600' fontSize={20} fontWeight={400}>2 x €78.5</Text>
                                        </HStack>
                                        <HStack>
                                            <Text color='red.600' fontSize={20} fontWeight={400}>Ships & fee</Text>
                                            <Spacer />
                                            <Text color='red.600' fontSize={20} fontWeight={400}>€1.454</Text>
                                        </HStack>
                                        <HStack>
                                            <Text color='red.600' fontSize={22} fontWeight={700}>YOU PAY</Text>
                                            <Spacer />
                                            <Text color='red.600' fontSize={22} fontWeight={700}>€133.20</Text>
                                        </HStack>
                                    </Box>
                                </GridItem>
                            </Grid>

                        </Box>

                        <Box
                            bg='#ffffff'
                            borderRadius='xl'
                            borderWidth="1px"
                            borderColor='#dfdfdf'
                            shadow="lg"
                            position="relative"
                            mt='5'
                            textAlign={['center']}
                            _hover={{ bg: 'gray.100', color: 'black', borderWidth: '2px', borderColor: 'red.500' }}
                        >
                            <Grid w='100%' p='1' templateColumns='repeat(12, 1fr)'>
                                {/* Ticket detail */}
                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>

                                    <Box p={1} textAlign={['left']}>

                                        <Grid w='100%' gap={2} p='2' templateColumns='repeat(12, 1fr)'>
                                            <GridItem colSpan={11}>
                                                <Text color='#AAAAAA' fontSize={15} fontWeight={400}>sale ID : {listing.id}</Text>
                                            </GridItem>
                                            <GridItem colSpan={1}>
                                                <IconButton
                                                    color='#999999'
                                                    aria-label='Clone'
                                                    icon={<FiFrown />}
                                                    size='sm'
                                                    onClick={() => alert('Signal a problem...')}
                                                />
                                            </GridItem>

                                            <GridItem colSpan={12}>
                                                <Box p={2} mt='2' w='100%' textAlign={['center']} borderRadius='xl' fontSize={20} fontWeight={400} color='white' bg='white'>
                                                    <Center>
                                                        <HStack>
                                                            <Box>
                                                                <HStack textAlign={['center']}>
                                                                    <IconButton
                                                                        isRound={true}
                                                                        variant='solid'
                                                                        color='#ffffff'
                                                                        bg='red.600'
                                                                        aria-label='Done'
                                                                        fontSize='35px'
                                                                        icon={<ImTicket />}
                                                                        w='50px'
                                                                        h='50px'
                                                                    />
                                                                    <Text color='red.600' mr='2' fontSize={25} fontWeight={700}>x6</Text>
                                                                </HStack>
                                                            </Box>
                                                            <Box>
                                                                <Text color='red.600' fontSize={12} fontWeight={600}>SECTION</Text>
                                                                <Tag color='white' bg='red.600' fontSize={18} fontWeight={500}>Bloc 401</Tag>
                                                            </Box>
                                                            <Box>
                                                                <Text color='red.600' fontSize={12} fontWeight={600}>ROW</Text>
                                                                <Tag color='white' bg='red.600' fontSize={18} fontWeight={500}>32</Tag>
                                                            </Box>
                                                        </HStack>
                                                    </Center>
                                                </Box>
                                            </GridItem>

                                            <GridItem colSpan={12}>
                                                {listing.state == 'needUpload' &&
                                                    <DawnerUploadTicket key={listing.id} row={row} listing={listing} />
                                                }
                                                {listing.state == 'needConfirm' &&
                                                    <ModalConfirm key={listing.id} row={row} listing={listing} modalAction='confirmSale' />
                                                }
                                            </GridItem>
                                        </Grid>

                                    </Box>
                                </GridItem>

                            </Grid>
                        </Box>

                    </DrawerBody>
                </DrawerContent>
            </Drawer >
        </>
    )
}

function DawnerUploadTicket({ row, listing }) {

    const [isOpenUploadTickets, setisOpenUploadTickets] = useState({});
    const drawerUploadTicketsIsOpen = (index, isOpen) => {
        if (isOpen === false) {
            setTimeout(() => {
                setCell(index)

                setTimeout(() => {
                    setCell('')
                }, 5000);

            }, 1000);
        }
        setisOpenUploadTickets((prevState) => {
            return {
                ...prevState,
                [index]: isOpen,
            };

        });
    };

    const [cell, setCell] = useState('');

    return (
        <>
            {/* Upload ticket */}
            <Button
                w='100%'
                _hover={{ color: 'white', bg: 'red.600' }}
                size='sm'
                leftIcon={<FiUpload />}
                onClick={() => drawerUploadTicketsIsOpen(listing.id, !isOpenUploadTickets[listing.id])}
            >
                <Text display={{ base: 'none', sm: 'flex' }} fontSize={16} fontWeight={600}>Upload</Text>
            </Button>

            <Drawer
                key={listing.id}
                id={listing.id}
                isOpen={isOpenUploadTickets[listing.id] ?? false}
                onClose={() => drawerUploadTicketsIsOpen(listing.id, false)}
                size='xl'
                placement='bottom'
            >
                <DrawerOverlay />
                <DrawerContent bg='#CCCCCC'>
                    <DrawerCloseButton />
                    <DrawerHeader>UPLOAD TICKET</DrawerHeader>
                    <DrawerBody>
                        <Box
                            bg='#efefef'
                            borderRadius='xl'
                            borderWidth="1px"
                            borderColor='#dfdfdf'
                            shadow="lg"
                            position="relative"
                            textAlign={['center']}
                            mb='5'
                        >
                            <Grid w='100%' gap={2} p='2' templateColumns='repeat(12, 1fr)'>

                                {/* Event title */}
                                <GridItem color='black' colSpan={{ base: "8", sm: "8", md: "8", lg: "8", xl: "8", "2xl": "8" }}>
                                    <Box p={2} textAlign={['left']}>
                                        <HStack>
                                            <Box w='100px' h='100px' mr='3' borderRadius='xl' border='1px' borderColor='red.600'>
                                                <Box p={1} borderRadius='xl' border='2px' bg='red.600' borderColor='#ffffff' textAlign={['center']}>
                                                    <Text color='white' fontSize={15} fontWeight={600}>
                                                        Sept. 2023
                                                    </Text>
                                                </Box>
                                                <Box borderRadius='xl' m={0} p={0} color='white' bg='#ffffff' textAlign={['center']}>
                                                    <Text color='black' fontSize={27} fontWeight={700}>
                                                        22
                                                    </Text>
                                                    <Text color='black' fontSize={15} fontWeight={500}>
                                                        {row.eventHour}
                                                    </Text>
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Text fontSize={25} fontWeight={700}>
                                                    {row.eventName}
                                                </Text>
                                                <Text fontSize={20} fontWeight={400} color='red.500'><Icon as={FiMapPin} h={4} w={4} mr={1} alignSelf={'center'} />{row.venueName}, Paris, France</Text>
                                            </Box>
                                        </HStack>
                                    </Box>
                                </GridItem>

                                <GridItem color='black' textAlign={'left'} colSpan={{ base: "4", sm: "4", md: "4", lg: "4", xl: "4", "2xl": "4" }}>
                                    {/* Ticket detail */}
                                    <Text fontSize={15} fontWeight={500}>You need upload :</Text>

                                    <Box p={2} mt='2' w='100%' textAlign={['center']} borderRadius='xl' fontSize={20} fontWeight={400} color='white' bg='white'>
                                        <HStack>
                                            <Box>
                                                <HStack textAlign={['center']}>
                                                    <IconButton
                                                        isRound={true}
                                                        variant='solid'
                                                        color='#ffffff'
                                                        bg='red.600'
                                                        aria-label='Done'
                                                        fontSize='35px'
                                                        icon={<ImTicket />}
                                                        w='50px'
                                                        h='50px'
                                                    />
                                                    <Text color='red.600' mr='2' fontSize={25} fontWeight={700}>x6</Text>
                                                </HStack>
                                            </Box>
                                            <Box>
                                                <Text color='red.600' fontSize={12} fontWeight={600}>SECTION</Text>
                                                <Tag color='white' bg='red.600' fontSize={18} fontWeight={500}>Bloc 401</Tag>
                                            </Box>
                                            <Box>
                                                <Text color='red.600' fontSize={12} fontWeight={600}>ROW</Text>
                                                <Tag color='white' bg='red.600' fontSize={18} fontWeight={500}>32</Tag>
                                            </Box>
                                        </HStack>
                                    </Box>
                                </GridItem>

                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                    <Box
                                        w='100%'
                                        h='350px'
                                        bg="white"
                                        borderRadius='xl'
                                        borderWidth="1px"
                                        borderColor='#dfdfdf'
                                        textAlign={'center'}
                                        overflowX='scroll'
                                    >
                                        <Text fontSize={22} fontWeight={600}>Tickets slots</Text>
                                        <Center>
                                            <HStack>
                                                <Box
                                                    w='200px'
                                                    h='270px'
                                                    mt='5'
                                                    bg='#f5f5f5'
                                                    borderRadius='xl'
                                                    borderWidth="2px"
                                                    borderStyle='dashed'
                                                    borderColor='#CCCCCC'
                                                >
                                                    <Text
                                                        fontSize={14}
                                                        fontWeight={700}
                                                        color='#666666'
                                                    >
                                                        TICKET #1
                                                    </Text>
                                                </Box>
                                                <Box
                                                    w='200px'
                                                    h='270px'
                                                    mt='5'
                                                    bg='#f5f5f5'
                                                    borderRadius='xl'
                                                    borderWidth="1px"
                                                    borderColor='#dfdfdf'
                                                >
                                                    <Text
                                                        fontSize={14}
                                                        fontWeight={700}
                                                        color='#666666'
                                                    >
                                                        TICKET #2
                                                    </Text>
                                                </Box>
                                                <Box
                                                    w='200px'
                                                    h='270px'
                                                    mt='5'
                                                    bg='#f5f5f5'
                                                    borderRadius='xl'
                                                    borderWidth="1px"
                                                    borderColor='#dfdfdf'
                                                >
                                                    <Text
                                                        fontSize={14}
                                                        fontWeight={700}
                                                        color='#666666'
                                                    >
                                                        TICKET #3
                                                    </Text>
                                                </Box>
                                                <Box
                                                    w='200px'
                                                    h='270px'
                                                    mt='5'
                                                    bg='#f5f5f5'
                                                    borderRadius='xl'
                                                    borderWidth="1px"
                                                    borderColor='#dfdfdf'
                                                >
                                                    <Text
                                                        fontSize={14}
                                                        fontWeight={700}
                                                        color='#666666'
                                                    >
                                                        TICKET #4
                                                    </Text>
                                                </Box>
                                                <Box
                                                    w='200px'
                                                    h='270px'
                                                    mt='5'
                                                    bg='#f5f5f5'
                                                    borderRadius='xl'
                                                    borderWidth="1px"
                                                    borderColor='#dfdfdf'
                                                >
                                                    <Text
                                                        fontSize={14}
                                                        fontWeight={700}
                                                        color='#666666'
                                                    >
                                                        TICKET #5
                                                    </Text>
                                                </Box>
                                                <Box
                                                    w='200px'
                                                    h='270px'
                                                    mt='5'
                                                    bg='#f5f5f5'
                                                    borderRadius='xl'
                                                    borderWidth="1px"
                                                    borderColor='#dfdfdf'
                                                >
                                                    <Text
                                                        fontSize={14}
                                                        fontWeight={700}
                                                        color='#666666'
                                                    >
                                                        TICKET #6
                                                    </Text>
                                                </Box>
                                                <Box
                                                    w='200px'
                                                    h='270px'
                                                    mt='5'
                                                    bg='#f5f5f5'
                                                    borderRadius='xl'
                                                    borderWidth="1px"
                                                    borderColor='#dfdfdf'
                                                >
                                                    <Text
                                                        fontSize={14}
                                                        fontWeight={700}
                                                        color='#666666'
                                                    >
                                                        TICKET #7
                                                    </Text>
                                                </Box>
                                                <Box
                                                    w='200px'
                                                    h='270px'
                                                    mt='5'
                                                    bg='#f5f5f5'
                                                    borderRadius='xl'
                                                    borderWidth="1px"
                                                    borderColor='#dfdfdf'
                                                >
                                                    <Text
                                                        fontSize={14}
                                                        fontWeight={700}
                                                        color='#666666'
                                                    >
                                                        TICKET #8
                                                    </Text>
                                                </Box>
                                            </HStack>
                                        </Center>

                                    </Box>
                                </GridItem>


                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                    <Box
                                        w='100%'
                                        h='350px'
                                        bg="white"
                                        borderRadius='xl'
                                        borderWidth="1px"
                                        borderColor='#dfdfdf'
                                        textAlign={'center'}
                                        overflowX='scroll'
                                    >
                                        <Center>
                                            <HStack>
                                                <Box
                                                    w='200px'
                                                    h='270px'
                                                    mt='5'
                                                    bg='#f5f5f5'
                                                    borderRadius='xl'
                                                    borderWidth="2px"
                                                    borderStyle='dashed'
                                                    borderColor='#CCCCCC'
                                                >
                                                    <Text
                                                        fontSize={14}
                                                        fontWeight={700}
                                                        color='#666666'
                                                    >
                                                        TICKET #1
                                                    </Text>
                                                </Box>
                                                <Box
                                                    w='200px'
                                                    h='270px'
                                                    mt='5'
                                                    bg='#f5f5f5'
                                                    borderRadius='xl'
                                                    borderWidth="1px"
                                                    borderColor='#dfdfdf'
                                                >
                                                    <Text
                                                        fontSize={14}
                                                        fontWeight={700}
                                                        color='#666666'
                                                    >
                                                        TICKET #2
                                                    </Text>
                                                </Box>
                                                <Box
                                                    w='200px'
                                                    h='270px'
                                                    mt='5'
                                                    bg='#f5f5f5'
                                                    borderRadius='xl'
                                                    borderWidth="1px"
                                                    borderColor='#dfdfdf'
                                                >
                                                    <Text
                                                        fontSize={14}
                                                        fontWeight={700}
                                                        color='#666666'
                                                    >
                                                        TICKET #3
                                                    </Text>
                                                </Box>
                                                <Box
                                                    w='200px'
                                                    h='270px'
                                                    mt='5'
                                                    bg='#f5f5f5'
                                                    borderRadius='xl'
                                                    borderWidth="1px"
                                                    borderColor='#dfdfdf'
                                                >
                                                    <Text
                                                        fontSize={14}
                                                        fontWeight={700}
                                                        color='#666666'
                                                    >
                                                        TICKET #4
                                                    </Text>
                                                </Box>
                                                <Box
                                                    w='200px'
                                                    h='270px'
                                                    mt='5'
                                                    bg='#f5f5f5'
                                                    borderRadius='xl'
                                                    borderWidth="1px"
                                                    borderColor='#dfdfdf'
                                                >
                                                    <Text
                                                        fontSize={14}
                                                        fontWeight={700}
                                                        color='#666666'
                                                    >
                                                        TICKET #5
                                                    </Text>
                                                </Box>
                                                <Box
                                                    w='200px'
                                                    h='270px'
                                                    mt='5'
                                                    bg='#f5f5f5'
                                                    borderRadius='xl'
                                                    borderWidth="1px"
                                                    borderColor='#dfdfdf'
                                                >
                                                    <Text
                                                        fontSize={14}
                                                        fontWeight={700}
                                                        color='#666666'
                                                    >
                                                        TICKET #6
                                                    </Text>
                                                </Box>
                                                <Box
                                                    w='200px'
                                                    h='270px'
                                                    mt='5'
                                                    bg='#f5f5f5'
                                                    borderRadius='xl'
                                                    borderWidth="1px"
                                                    borderColor='#dfdfdf'
                                                >
                                                    <Text
                                                        fontSize={14}
                                                        fontWeight={700}
                                                        color='#666666'
                                                    >
                                                        TICKET #7
                                                    </Text>
                                                </Box>
                                                <Box
                                                    w='200px'
                                                    h='270px'
                                                    mt='5'
                                                    bg='#f5f5f5'
                                                    borderRadius='xl'
                                                    borderWidth="1px"
                                                    borderColor='#dfdfdf'
                                                >
                                                    <Text
                                                        fontSize={14}
                                                        fontWeight={700}
                                                        color='#666666'
                                                    >
                                                        TICKET #8
                                                    </Text>
                                                </Box>
                                            </HStack>
                                        </Center>
                                        <Text fontSize={22} fontWeight={600}>Tickets loaded</Text>

                                    </Box>
                                </GridItem>


                            </Grid>

                        </Box>

                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )

}


const Listings = () => {

    const { username, isManager, isAdmin } = useAuth()

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const navigate = useNavigate()
    const { pathname } = useLocation()


    const data_confirm = [
        {
            eventId: '87363',
            eventName: 'Bruce Springteen',
            eventDate: '2024-05-25',
            eventHour: '20:00',
            venueName: 'Orange Velodrome',
            ticketSale: '8',
            ticketListing: '12',
            listings: [
                { id: '2132743253', state: 'needConfirm', section: 'Section 401', row: '22', seat: '26 - 27', nb: '2', facial: '88.20', price: '120.10', },
                { id: '2136242647', state: 'needConfirm', section: 'Section 402', row: '28', seat: '', nb: '4', facial: '88.20', price: '125.50', },
                { id: '2132459741', state: 'needConfirm', section: 'Section 503', row: '14', seat: '', nb: '2', facial: '74.10', price: '140.00', },
                { id: '2132437490', state: 'needConfirm', section: 'Floor', row: '', seat: '', nb: '2', facial: '66.510', price: '94.10', },
            ]
        },
        {
            eventId: '34421',
            eventName: 'Colplay',
            eventDate: '2024-09-22',
            eventHour: '20:00',
            venueName: 'Accor Arena',
            ticketSale: '4',
            ticketListing: '6',
            listings: [
                { id: '2132648714', state: 'needConfirm', section: 'Upper Tier', row: '13', seat: '', nb: '4', facial: '92.10', price: '175.30', },
                { id: '2135244379', state: 'needConfirm', section: 'Floor', row: '', seat: '', nb: '4', facial: '100.10', price: '175.30', },
                { id: '2132416388', state: 'needConfirm', section: 'Bloc 1', row: '5', seat: '11 - 12', nb: '2', facial: '120.30', price: '210.00', },
            ]
        },
        {
            eventId: '36373',
            eventName: 'Bruce Springteen',
            eventDate: '2024-05-25',
            eventHour: '20:00',
            venueName: 'Orange Velodrome',
            ticketSale: '8',
            ticketListing: '12',
            listings: [
                { id: '2132453253', state: 'needConfirm', section: 'Section 401', row: '22', seat: '26 - 27', nb: '2', facial: '88.20', price: '120.10', },
                { id: '2138242647', state: 'needConfirm', section: 'Section 402', row: '28', seat: '', nb: '4', facial: '88.20', price: '125.50', },
                { id: '2138249741', state: 'needConfirm', section: 'Section 503', row: '14', seat: '', nb: '2', facial: '74.10', price: '140.00', },
                { id: '2183243790', state: 'needConfirm', section: 'Floor', row: '', seat: '', nb: '2', facial: '66.510', price: '94.10', },
            ]
        },
        {
            eventId: '32491',
            eventName: 'Colplay',
            eventDate: '2024-09-22',
            eventHour: '20:00',
            venueName: 'Accor Arena',
            ticketSale: '4',
            ticketListing: '6',
            listings: [
                { id: '2135248714', state: 'needConfirm', section: 'Upper Tier', row: '13', seat: '', nb: '4', facial: '92.10', price: '175.30', },
                { id: '2133244379', state: 'needConfirm', section: 'Floor', row: '', seat: '', nb: '4', facial: '100.10', price: '175.30', },
                { id: '2313241388', state: 'needConfirm', section: 'Bloc 1', row: '5', seat: '11 - 12', nb: '2', facial: '120.30', price: '210.00', },
            ]
        },
    ]

    const data_upload = [
        {
            eventId: '87363',
            eventName: 'Bruce Springteen',
            eventDate: '2024-05-25',
            eventHour: '20:00',
            venueName: 'Orange Velodrome',
            ticketSale: '8',
            ticketListing: '12',
            listings: [
                { id: '2132743253', state: 'needUpload', section: 'Section 401', row: '22', seat: '26 - 27', nb: '2', facial: '88.20', price: '120.10', },
                { id: '2136242647', state: 'needUpload', section: 'Section 402', row: '28', seat: '', nb: '4', facial: '88.20', price: '125.50', },
                { id: '2132459741', state: 'needUpload', section: 'Section 503', row: '14', seat: '', nb: '2', facial: '74.10', price: '140.00', },
                { id: '2132437490', state: 'needUpload', section: 'Floor', row: '', seat: '', nb: '2', facial: '66.510', price: '94.10', },
            ]
        },
        {
            eventId: '34421',
            eventName: 'Colplay',
            eventDate: '2024-09-22',
            eventHour: '20:00',
            venueName: 'Accor Arena',
            ticketSale: '4',
            ticketListing: '6',
            listings: [
                { id: '2132648714', state: 'needUpload', section: 'Upper Tier', row: '13', seat: '', nb: '4', facial: '92.10', price: '175.30', },
                { id: '2135244379', state: 'needUpload', section: 'Floor', row: '', seat: '', nb: '4', facial: '100.10', price: '175.30', },
                { id: '2132416388', state: 'needUpload', section: 'Bloc 1', row: '5', seat: '11 - 12', nb: '2', facial: '120.30', price: '210.00', },
            ]
        },
    ]

    const isLoading = false

    const [isMarketSelect, setisMarketSelect] = useState('FR');
    const [isSalesListing, setisSalesListing] = useState('needConfirm');


    const [isOpenSalesEdits, setisOpenSalesEdits] = useState({});
    const drawerSalesIsOpen = (index, isOpen) => {
        //alert('index = ' + index)
        //alert('isOpen = ' + isOpen)
        setisOpenSalesEdits((prevState) => {
            return {
                ...prevState,
                [index]: isOpen,
            };
        });
        //alert('isOpenSalesEdits = ' + JSON.stringify(isOpenSalesEdits))
        //alert('isOpenSalesEdits[' + index + '] = ' + JSON.stringify(isOpenSalesEdits[index]))
    };

    const [cell, setCell] = useState('');


    const [isOpenSaleEdits, setisOpenSaleEdits] = useState({});
    const drawerSaleIsOpen = (index, isOpen) => {
        if (isOpen === false) {
            setTimeout(() => {
                setCell(index)

                setTimeout(() => {
                    setCell('')
                }, 5000);

            }, 1000);

        }
        setisOpenSaleEdits((prevState) => {
            return {
                ...prevState,
                [index]: isOpen,
            };

        });
    };









    const content = (
        <>
            <Center bg='#45413C' p='0' color='white' axis='both'>
                <Grid colSpan={12} w='95vw' maxW='2000' minH='100vh' gap={5} p='5' templateColumns='repeat(12, 1fr)'>

                    <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>
                        <Grid templateColumns='repeat(12, 1fr)' gap={2}>
                            <GridItem colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>
                                <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='1px' textAlign={['center']} borderColor='black' bg='white' w="100%">
                                    <HStack>
                                        <Box p='2' color='#262626' textAlign={['left']} >
                                            <Text color='black' fontSize={{ base: "20", sm: "20", md: "20", lg: "24", xl: "28" }} fontWeight={700}>CARTS</Text>
                                        </Box>
                                        <Spacer />
                                        <Box p='2' color='#262626' textAlign={['right']}>
                                            <ModalExport isMarketSelect={isMarketSelect} isSalesListing={isSalesListing} />
                                        </Box>
                                    </HStack>

                                    <Tabs variant='soft-rounded' isFitted>
                                        <TabList
                                            overflowX="auto"
                                            css={css({
                                                scrollbarWidth: 'none',
                                                '::-webkit-scrollbar': { display: 'none' },
                                                '-webkit-overflow-scrolling': 'touch',
                                                boxShadow: 'inset 0 -2px 0 rgba(0, 0, 0, 0.1)',
                                                border: '0 none',
                                            })}
                                        >
                                            <Tab onClick={() => setisMarketSelect('FR')} _selected={{ color: 'white', bg: 'red.600' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>FR</Tab>
                                            <Tab onClick={() => setisMarketSelect('ES')} _selected={{ color: 'white', bg: 'red.600' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>ES</Tab>
                                            <Tab onClick={() => setisMarketSelect('PT')} _selected={{ color: 'white', bg: 'red.600' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>PT</Tab>
                                            <Tab onClick={() => setisMarketSelect('IT')} _selected={{ color: 'white', bg: 'red.600' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>IT</Tab>
                                            <Tab onClick={() => setisMarketSelect('DE')} _selected={{ color: 'white', bg: 'red.600' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>DE</Tab>
                                        </TabList>

                                        <TabPanels minH='80vh'>

                                            <TabPanel>
                                                <Tabs variant='soft-rounded'>
                                                    <TabList
                                                        overflowX="auto"
                                                        css={css({
                                                            scrollbarWidth: 'none',
                                                            '::-webkit-scrollbar': { display: 'none' },
                                                            '-webkit-overflow-scrolling': 'touch',
                                                            boxShadow: 'inset 0 -2px 0 rgba(0, 0, 0, 0.1)',
                                                            border: '0 none',
                                                        })}
                                                    >
                                                        <Tab onClick={() => setisSalesListing('needConfirm')} _selected={{ color: 'white', bg: 'red.600' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={16} fontWeight={700}>NEW</Tab>
                                                        <Tab onClick={() => setisSalesListing('needUpload')} _selected={{ color: 'white', bg: 'red.600' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={16} fontWeight={700}>PAYED</Tab>
                                                        <Tab onClick={() => setisSalesListing('canceledError')} _selected={{ color: 'white', bg: 'red.600' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={16} fontWeight={700}>CANCELED / ERROR</Tab>
                                                    </TabList>

                                                    <TabPanels>

                                                        {/* NEED CONFIRM */}
                                                        <TabPanel>
                                                            {/* Head */}
                                                            <Grid w='100%' gap={0} p='1' bg='red.600' textAlign={'left'} fontSize='14px' fontWeight={700} color='white' templateColumns='repeat(12, 1fr)'>
                                                                <GridItem colSpan={4}>
                                                                    NAME
                                                                </GridItem>
                                                                <GridItem colSpan={2}>
                                                                    DATE / HOUR
                                                                </GridItem>
                                                                <GridItem colSpan={3}>
                                                                    VENUE
                                                                </GridItem>
                                                                <GridItem colSpan={1}>
                                                                    CARTS
                                                                </GridItem>
                                                                <GridItem colSpan={1}>
                                                                    TICKETS
                                                                </GridItem>
                                                                <GridItem colSpan={1} textAlign={'right'}>
                                                                    CONFIRM
                                                                </GridItem>
                                                            </Grid>

                                                            {!isLoading &&
                                                                data_confirm?.map((row, index) => (
                                                                    <Grid w='100%' gap={0} p='1' bg={index % 2 !== 0 ? "#DDDDDD" : "#F5F5F5"}
                                                                        textAlign={'left'} fontSize='22px' fontWeight={400} color='#222222' templateColumns='repeat(12, 1fr)'
                                                                        _hover={{ fontSize: '23px', bg: 'red.50', color: '#333333', border: '2px', borderColor: 'red.600' }}
                                                                        //onClick={listingRow.onToggle}
                                                                        key={index}
                                                                    >
                                                                        <GridItem colSpan={4}>
                                                                            <HStack>
                                                                                {isOpenSalesEdits[row.eventId]
                                                                                    && <FiMinusSquare cursor={'pointer'} onClick={() => drawerSalesIsOpen(row.eventId, !isOpenSalesEdits[row.eventId])} fontSize='22px' boxSize={6} />
                                                                                    || <FiPlusSquare cursor={'pointer'} onClick={() => drawerSalesIsOpen(row.eventId, !isOpenSalesEdits[row.eventId])} fontSize='22px' boxSize={6} />
                                                                                }
                                                                                <Text color='#222222' fontWeight={700}>{row.eventName}</Text>
                                                                            </HStack>
                                                                        </GridItem>
                                                                        <GridItem fontSize='18px' colSpan={2}>
                                                                            <Icon as={FiCalendar} h={4} w={4} mr={1} alignSelf={'center'} /> {row.eventDate} | {row.eventHour}
                                                                        </GridItem>
                                                                        <GridItem fontSize='18px' colSpan={3}>
                                                                            <Icon as={FiMapPin} h={4} w={4} mr={1} alignSelf={'center'} /> {row.venueName}
                                                                        </GridItem>
                                                                        <GridItem fontSize='20px' fontWeight={500} colSpan={1}>
                                                                            14
                                                                        </GridItem>
                                                                        <GridItem fontSize='20px' fontWeight={500} colSpan={1}>
                                                                            61
                                                                        </GridItem>
                                                                        <GridItem colSpan={1} textAlign={'right'}>
                                                                            <Button
                                                                                _hover={{ color: 'white', bg: 'red.600' }}
                                                                                size='sm'
                                                                                leftIcon={<FiCheck />}
                                                                                onClick={() => alert('Confirm ALL sales for this event...')}
                                                                            >
                                                                                <Text display={{ base: 'none', sm: 'flex' }} fontSize={16} fontWeight={600}>Confirm all</Text>
                                                                            </Button>

                                                                        </GridItem>



                                                                        <GridItem colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                                            <Collapse
                                                                                key={index}
                                                                                isOpen={isOpenSalesEdits[row.eventId] ?? true}
                                                                                onClose={() => drawerSalesIsOpen(row.eventId, false)}
                                                                                id={index}
                                                                                in={isOpenSalesEdits[row.eventId]}
                                                                                animateOpacity
                                                                            >
                                                                                <Center>
                                                                                    <VStack w='100%' mb='8'>
                                                                                        <Grid bg='#CCCCCC' borderColor={'#CCCCCC'} borderWidth={'1px'} fontSize='14px' fontWeight={700} w='100%' gap={1} mt='3' mb='-2' p='0' textAlign={'center'} color='black' templateColumns='repeat(12, 1fr)'>
                                                                                            <GridItem colSpan={3} textAlign={'left'}>
                                                                                                SECTION
                                                                                            </GridItem>
                                                                                            <GridItem colSpan={2}>
                                                                                                ROW
                                                                                            </GridItem>
                                                                                            <GridItem colSpan={1}>
                                                                                                SEAT
                                                                                            </GridItem>
                                                                                            <GridItem colSpan={1}>
                                                                                                NB
                                                                                            </GridItem>
                                                                                            <GridItem colSpan={1}>
                                                                                                $ TOTAL
                                                                                            </GridItem>
                                                                                            <GridItem colSpan={2}>
                                                                                                TIME
                                                                                            </GridItem>
                                                                                            <GridItem colSpan={2} textAlign={'right'}>
                                                                                                CONFIRM
                                                                                            </GridItem>
                                                                                        </Grid>

                                                                                        {!isLoading &&
                                                                                            row.listings?.map((listing, index) => (

                                                                                                <Grid bg='#FFFFFF' borderColor={'#CCCCCC'} borderWidth={'1px'} w='100%' gap={1} mb='-3' p='1' textAlign={'center'} color='black' templateColumns='repeat(12, 1fr)'
                                                                                                    _hover={{ bg: '#EEEEEE', border: '1px', borderColor: '#CCCCCC' }}
                                                                                                    fontSize='18px'
                                                                                                    fontWeight={400}
                                                                                                >
                                                                                                    <GridItem className={cell == listing.id ? 'focusBackground' : ''} colSpan={3}>
                                                                                                        <Text textAlign={'left'} fontSize='20px' fontWeight={600}>{listing.section}</Text>
                                                                                                    </GridItem>
                                                                                                    <GridItem className={cell == listing.id ? 'focusBackground' : ''} colSpan={2}
                                                                                                    >
                                                                                                        {listing.row}
                                                                                                    </GridItem>
                                                                                                    <GridItem className={cell == listing.id ? 'focusBackground' : ''} colSpan={1}>
                                                                                                        {listing.seat}
                                                                                                    </GridItem>
                                                                                                    <GridItem className={cell == listing.id ? 'focusBackground' : ''} colSpan={1}>
                                                                                                        {listing.nb}
                                                                                                    </GridItem>
                                                                                                    <GridItem className={cell == listing.id ? 'focusBackground' : ''} fontWeight={600} colSpan={1}>
                                                                                                        {listing.price}
                                                                                                    </GridItem>
                                                                                                    <GridItem className={cell == listing.id ? 'focusBackground' : ''} colSpan={2}>
                                                                                                        <Icon as={FiClock} h={4} w={4} mr={1} alignSelf={'center'} /> 12:40:21
                                                                                                    </GridItem>
                                                                                                    <GridItem className={cell == listing.id ? 'focusBackground' : ''} colSpan={2} textAlign={'right'}>
                                                                                                        <ButtonGroup size='sm' isAttached
                                                                                                            bg='gray.100'
                                                                                                            color='red.600'
                                                                                                            variant='outline'
                                                                                                            rounded={'lg'}
                                                                                                        >
                                                                                                            {/* Modal Confirm Sale */}
                                                                                                            <ModalConfirm key={listing.id} row={row} listing={listing} modalAction='confirmSale' />
                                                                                                            {/* Dawner View Sale */}
                                                                                                            <DawnerViewSale key={listing.id} row={row} listing={listing} />
                                                                                                        </ButtonGroup>
                                                                                                    </GridItem>

                                                                                                </Grid>

                                                                                            ))
                                                                                        }

                                                                                    </VStack>
                                                                                </Center>

                                                                            </Collapse>

                                                                        </GridItem>

                                                                    </Grid>
                                                                ))
                                                            }
                                                        </TabPanel>

                                                        {/* NEED UPLOAD */}
                                                        <TabPanel>
                                                            {/* Head */}
                                                            <Grid w='100%' gap={0} p='1' bg='red.600' textAlign={'left'} fontSize='14px' fontWeight={700} color='white' templateColumns='repeat(12, 1fr)'>
                                                                <GridItem colSpan={4}>
                                                                    NAME
                                                                </GridItem>
                                                                <GridItem colSpan={3}>
                                                                    DATE / HOUR
                                                                </GridItem>
                                                                <GridItem colSpan={3}>
                                                                    VENUE
                                                                </GridItem>
                                                                <GridItem colSpan={2} textAlign={'right'}>
                                                                    UPLOAD
                                                                </GridItem>
                                                            </Grid>

                                                            {!isLoading &&
                                                                data_upload?.map((row, index) => (
                                                                    <Grid w='100%' gap={0} p='1' bg={index % 2 !== 0 ? "#DDDDDD" : "#F5F5F5"}
                                                                        textAlign={'left'} fontSize='22px' fontWeight={400} color='#222222' templateColumns='repeat(12, 1fr)'
                                                                        _hover={{ fontSize: '23px', bg: 'red.50', color: '#333333', border: '2px', borderColor: 'red.600' }}
                                                                        //onClick={listingRow.onToggle}
                                                                        key={index}
                                                                    >
                                                                        <GridItem colSpan={4}>
                                                                            <HStack>
                                                                                {isOpenSalesEdits[row.eventId]
                                                                                    && <FiMinusSquare cursor={'pointer'} onClick={() => drawerSalesIsOpen(row.eventId, !isOpenSalesEdits[row.eventId])} fontSize='22px' boxSize={6} />
                                                                                    || <FiPlusSquare cursor={'pointer'} onClick={() => drawerSalesIsOpen(row.eventId, !isOpenSalesEdits[row.eventId])} fontSize='22px' boxSize={6} />
                                                                                }
                                                                                <Text color='#222222' fontWeight={700}>{row.eventName}</Text>
                                                                            </HStack>
                                                                        </GridItem>
                                                                        <GridItem fontSize='18px' colSpan={3}>
                                                                            <Icon as={FiCalendar} h={4} w={4} mr={1} alignSelf={'center'} /> {row.eventDate} | {row.eventHour}
                                                                        </GridItem>
                                                                        <GridItem fontSize='18px' colSpan={3}>
                                                                            <Icon as={FiMapPin} h={4} w={4} mr={1} alignSelf={'center'} /> {row.venueName}
                                                                        </GridItem>
                                                                        <GridItem fontSize='20px' fontWeight={500} colSpan={2} textAlign={'right'}>
                                                                            14
                                                                        </GridItem>

                                                                        <GridItem colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                                            <Collapse
                                                                                key={index}
                                                                                isOpen={isOpenSalesEdits[row.eventId] ?? true}
                                                                                onClose={() => drawerSalesIsOpen(row.eventId, false)}
                                                                                id={index}
                                                                                in={isOpenSalesEdits[row.eventId]}
                                                                                animateOpacity
                                                                            >
                                                                                <Center>
                                                                                    <VStack w='100%' mb='8'>
                                                                                        <Grid bg='#CCCCCC' borderColor={'#CCCCCC'} borderWidth={'1px'} fontSize='14px' fontWeight={700} w='100%' gap={1} mt='3' mb='-2' p='0' textAlign={'center'} color='black' templateColumns='repeat(12, 1fr)'>
                                                                                            <GridItem colSpan={3} textAlign={'left'}>
                                                                                                SECTION
                                                                                            </GridItem>
                                                                                            <GridItem colSpan={2}>
                                                                                                ROW
                                                                                            </GridItem>
                                                                                            <GridItem colSpan={1}>
                                                                                                SEAT
                                                                                            </GridItem>
                                                                                            <GridItem colSpan={1}>
                                                                                                NB
                                                                                            </GridItem>
                                                                                            <GridItem colSpan={1}>
                                                                                                $ TOTAL
                                                                                            </GridItem>
                                                                                            <GridItem colSpan={2}>
                                                                                                TIME
                                                                                            </GridItem>
                                                                                            <GridItem colSpan={2} textAlign={'right'}>
                                                                                                UPLOAD
                                                                                            </GridItem>
                                                                                        </Grid>

                                                                                        {!isLoading &&
                                                                                            row.listings?.map((listing, index) => (

                                                                                                <Grid bg='#FFFFFF' borderColor={'#CCCCCC'} borderWidth={'1px'} w='100%' gap={1} mb='-3' p='1' textAlign={'center'} color='black' templateColumns='repeat(12, 1fr)'
                                                                                                    _hover={{ bg: '#EEEEEE', border: '1px', borderColor: '#CCCCCC' }}
                                                                                                    fontSize='18px'
                                                                                                    fontWeight={400}
                                                                                                >
                                                                                                    <GridItem className={cell == listing.id ? 'focusBackground' : ''} colSpan={3}>
                                                                                                        <Text textAlign={'left'} fontSize='20px' fontWeight={600}>{listing.section}</Text>
                                                                                                    </GridItem>
                                                                                                    <GridItem className={cell == listing.id ? 'focusBackground' : ''} colSpan={2}
                                                                                                    >
                                                                                                        {listing.row}
                                                                                                    </GridItem>
                                                                                                    <GridItem className={cell == listing.id ? 'focusBackground' : ''} colSpan={1}>
                                                                                                        {listing.seat}
                                                                                                    </GridItem>
                                                                                                    <GridItem className={cell == listing.id ? 'focusBackground' : ''} colSpan={1}>
                                                                                                        {listing.nb}
                                                                                                    </GridItem>
                                                                                                    <GridItem className={cell == listing.id ? 'focusBackground' : ''} fontWeight={600} colSpan={1}>
                                                                                                        {listing.price}
                                                                                                    </GridItem>
                                                                                                    <GridItem className={cell == listing.id ? 'focusBackground' : ''} colSpan={2}>
                                                                                                        <Icon as={FiClock} h={4} w={4} mr={1} alignSelf={'center'} /> 12:40:21
                                                                                                    </GridItem>
                                                                                                    <GridItem className={cell == listing.id ? 'focusBackground' : ''} colSpan={2} textAlign={'right'}>
                                                                                                        <ButtonGroup size='sm' isAttached
                                                                                                            bg='gray.100'
                                                                                                            color='red.600'
                                                                                                            variant='outline'
                                                                                                            rounded={'lg'}
                                                                                                        >
                                                                                                            {/* Dawner Upload Ticket */}
                                                                                                            <DawnerUploadTicket key={listing.id} row={row} listing={listing} />
                                                                                                            {/* Dawner View Sale */}
                                                                                                            <DawnerViewSale key={listing.id} row={row} listing={listing} />
                                                                                                        </ButtonGroup>
                                                                                                    </GridItem>




                                                                                                </Grid>

                                                                                            ))
                                                                                        }

                                                                                    </VStack>
                                                                                </Center>

                                                                            </Collapse>

                                                                        </GridItem>

                                                                    </Grid>
                                                                ))
                                                            }
                                                        </TabPanel>

                                                        {/* WAIT PAYMENT */}
                                                        <TabPanel>
                                                            <p>No found !</p>
                                                        </TabPanel>

                                                        {/* FULL COMPLETED */}
                                                        <TabPanel>
                                                            <p>No found !</p>
                                                        </TabPanel>

                                                        {/* CANCELED / ERROR */}
                                                        <TabPanel>
                                                            <p>No found !</p>
                                                        </TabPanel>

                                                    </TabPanels>
                                                </Tabs>
                                            </TabPanel>

                                            <TabPanel>
                                            </TabPanel>

                                        </TabPanels>
                                    </Tabs>


                                </Box>
                            </GridItem>
                        </Grid>
                        <br />
                    </GridItem>

                </Grid>

            </Center>


        </>

    )

    return content
}
export default Listings