import { useState, useEffect } from "react";
import {
  useGetInventoryWaitQuery,
  useUpdateCartWaitMutation,
} from "./cartsApiSlice";
import Cart from "./Cart";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useLang from "../../hooks/useLang";
import useTranslate from "../../hooks/useTranslate";
import useTitle from "../../hooks/useTitle";
import PulseLoader from "react-spinners/PulseLoader";

// import `ChakraProvider` component
import {
  Box,
  Grid,
  GridItem,
  Center,
  HStack,
  VStack,
  Container,
  Flex,
  SimpleGrid,
  FormControl,
  FormLabel,
  Textarea,
  Checkbox,
  Input,
  Switch,
  Badge,
  Tag,
  TagLabel,
  TagCloseButton,
  Avatar,
  Toast,
  Icon,
  IconButton,
  Image,
  Stack,
  Alert,
  AlertIcon,
  Select,
  Button,
  useRadioGroup,
  useRadio,
  RadioGroup,
  Radio,
  Text,
  useDisclosure,
  useToast,
  Spacer,
} from "@chakra-ui/react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";

// import `ChakraProvider` icon
import { AddIcon } from "@chakra-ui/icons";

import {
  Bs0CircleFill,
  Bs1CircleFill,
  Bs2CircleFill,
  BsBoxFill,
  BsFilePdf,
  BsGridFill,
  BsBarChartLineFill,
} from "react-icons/bs";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationCrosshairs,
  faSnowman,
  faList,
} from "@fortawesome/free-solid-svg-icons";

const FirstMarket = () => {
  useTitle("BROKY: Inventory Inventory");

  const { username, isManager, isAdmin } = useAuth();

  const { lang } = useLang();
  const { T } = useTranslate(lang);

  let menuContent;
  if (isManager || isAdmin) {
    menuContent = (
      <>
        <Link to="/dash/carts/new">
          <Button
            size="sm"
            leftIcon={<AddIcon />}
            colorScheme="red"
            variant="solid"
          >
            <Text
              color="white"
              display={{ base: "none", sm: "flex" }}
              fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }}
              fontWeight={600}
            >
              {T.common.Add}
            </Text>
          </Button>
        </Link>
      </>
    );
  }

  const [hoveredRow, setHoveredRow] = useState(null);

  const handleRowMouseEnter = (productId) => {
    setHoveredRow(productId);
  };

  const handleRowMouseLeave = () => {
    setHoveredRow(null);
  };

  const initialTableState = {
    table1: false,
    table2: false,
    // Ajoutez d'autres tables avec leur état initial ici
  };

  const [tableStates, setTableStates] = useState(initialTableState);

  const handleSwitchChange = (tableName) => {
    setTableStates((prevTableStates) => ({
      ...prevTableStates,
      [tableName]: !prevTableStates[tableName],
    }));
  };

  let content;
  content = (
    <>
      <Center bg="#45413C" axis="both">
        <Grid
          w="95vw"
          minH="100vh"
          maxW="95vw"
          templateColumns="repeat(12, 1fr)"
        >
          <GridItem
            colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}
          >
            <Grid p="5" gap="5" templateColumns="repeat(12, 1fr)">
              <GridItem
                colSpan={{
                  base: "6",
                  sm: "6",
                  md: "6",
                  lg: "6",
                  xl: "6",
                  "2xl": "6",
                }}
                textAlign={["left"]}
              >
                <Text
                  color={"white"}
                  fontSize={{
                    base: "24",
                    sm: "24",
                    md: "24",
                    lg: "24",
                    xl: "28",
                  }}
                  fontWeight={600}
                >
                  <FontAwesomeIcon icon={faLocationCrosshairs} />{" "}
                  {T.crawly.Crawly} : {T.crawly.firstMarket}
                </Text>
              </GridItem>
              <GridItem
                colSpan={{
                  base: "6",
                  sm: "6",
                  md: "6",
                  lg: "6",
                  xl: "6",
                  "2xl": "6",
                }}
                textAlign={["right"]}
              >
                {menuContent}
              </GridItem>
            </Grid>

            <Box
              borderRadius="xl"
              mb="2"
              p={2}
              textAlign={["center"]}
              bg="white"
              _hover={{
                bg: "gray.200",
                border: "1px",
                borderRadius: "xl",
                borderColor: "gray.400",
                borderStyle: "solid",
              }}
            >
              <Grid
                gap="2"
                w="100%"
                maxW="100vw"
                templateColumns="repeat(12, 1fr)"
              >
                {/* Head */}
                <GridItem
                  color="black"
                  colSpan={{
                    base: "12",
                    sm: "12",
                    md: "12",
                    lg: "12",
                    xl: "12",
                    "2xl": "2",
                  }}
                >
                  <Flex>
                    <Box p="2" textAlign={["left"]}>
                      <Switch
                        size="md"
                        checked={tableStates["table1"]}
                        onChange={() => handleSwitchChange("table1")}
                      />
                    </Box>
                    <Box
                      p="1"
                      textAlign={["left"]}
                      w="100%"
                      color="black"
                      lineHeight="1.2"
                    >
                      <Text fontSize={20} fontWeight={800}>
                        Coldplay
                      </Text>
                      <Text color="gray.600" fontSize={14} fontWeight={600}>
                        2024.06.12, 19:00
                      </Text>
                      <Text color="gray.600" fontSize={13} fontWeight={500}>
                        France, Saint-Denis, Stade de France
                      </Text>
                    </Box>
                  </Flex>
                </GridItem>

                {/* Inventory */}
                <GridItem
                  color="black"
                  colSpan={{
                    base: "12",
                    sm: "12",
                    md: "12",
                    lg: "12",
                    xl: "12",
                    "2xl": "10",
                  }}
                >
                  <Box
                    p="2"
                    textAlign={["center"]}
                    w="100%"
                    color="black"
                    bg="gray.50"
                    borderRadius="xl"
                    border="1px"
                    borderColor="gray.300"
                    borderStyle={"solid"}
                    _hover={{
                      bg: "gray.100",
                      border: "2px",
                      borderColor: "gray.400",
                      borderStyle: "solid",
                    }}
                  >
                    <Flex spacing="10px" color="gray.600">
                      {/* SOURCE */}
                      <Box
                        minW="280px"
                        p="2"
                        mr="2"
                        bg="gray.200"
                        borderRadius="xl"
                        lineHeight="1"
                      >
                        <HStack>
                          <Box bg="gray.50" p="1" minW="50px" borderRadius="md">
                            <Text
                              color="gray.700"
                              fontSize={10}
                              fontWeight={400}
                            >
                              SAVE
                            </Text>
                            <Text
                              color="green.400"
                              fontSize={16}
                              fontWeight={700}
                            >
                              24h
                            </Text>
                          </Box>
                          <Box bg="gray.50" p="1" minW="50px" borderRadius="md">
                            <Text
                              color="gray.700"
                              fontSize={10}
                              fontWeight={400}
                            >
                              SCAN
                            </Text>
                            <Text
                              color="green.400"
                              fontSize={16}
                              fontWeight={700}
                            >
                              30s
                            </Text>
                          </Box>
                          <Box
                            bg="gray.500"
                            p="1"
                            minW="200px"
                            borderRadius="md"
                          >
                            <Text color="white" fontSize={20} fontWeight={500}>
                              Ticketmaster.fr
                            </Text>
                          </Box>
                        </HStack>
                      </Box>

                      {/* CAT */}
                      <Box
                        minW="180px"
                        p="2"
                        mr="2"
                        bg="green.100"
                        borderRadius="xl"
                        lineHeight="1"
                      >
                        <HStack>
                          <Text
                            maxW="150px"
                            fontSize={16}
                            fontWeight={700}
                            whiteSpace="normal"
                          >
                            CARRE OR
                          </Text>
                          <Spacer />
                          <Text
                            color="green.400"
                            fontSize={25}
                            fontWeight={700}
                          >
                            438
                          </Text>
                          <Box bg="gray.50" p="1" minW="50px" borderRadius="md">
                            <Text
                              color="gray.700"
                              fontSize={10}
                              fontWeight={400}
                            >
                              24h
                            </Text>
                            <Text
                              color="red.400"
                              fontSize={18}
                              fontWeight={500}
                            >
                              -12
                            </Text>
                          </Box>
                        </HStack>
                      </Box>

                      {/* CAT */}
                      <Box
                        minW="180px"
                        p="2"
                        mr="2"
                        bg="green.100"
                        borderRadius="xl"
                        lineHeight="1"
                      >
                        <HStack>
                          <Text
                            maxW="150px"
                            fontSize={16}
                            fontWeight={700}
                            whiteSpace="normal"
                          >
                            CATEGORY 1
                          </Text>
                          <Spacer />
                          <Text
                            color="green.400"
                            fontSize={25}
                            fontWeight={700}
                          >
                            247
                          </Text>
                          <Box bg="gray.50" p="1" minW="50px" borderRadius="md">
                            <Text
                              color="gray.700"
                              fontSize={10}
                              fontWeight={400}
                            >
                              24h
                            </Text>
                            <Text
                              color="red.400"
                              fontSize={18}
                              fontWeight={500}
                            >
                              -31
                            </Text>
                          </Box>
                        </HStack>
                      </Box>

                      {/* CAT */}
                      <Box
                        minW="180px"
                        p="2"
                        mr="2"
                        bg="red.100"
                        borderRadius="xl"
                        lineHeight="1"
                      >
                        <HStack>
                          <Text
                            maxW="150px"
                            fontSize={16}
                            fontWeight={700}
                            whiteSpace="normal"
                          >
                            CATEGORY 2 LATERAL VR
                          </Text>
                          <Spacer />
                          <Text color="red.400" fontSize={25} fontWeight={700}>
                            0
                          </Text>
                          <Box bg="gray.50" p="1" minW="50px" borderRadius="md">
                            <Text
                              color="gray.700"
                              fontSize={10}
                              fontWeight={400}
                            >
                              24h
                            </Text>
                            <Text
                              color="red.400"
                              fontSize={18}
                              fontWeight={500}
                            >
                              -8
                            </Text>
                          </Box>
                        </HStack>
                      </Box>
                    </Flex>

                    {tableStates["table1"] && (
                      <>
                        <VStack mt="2">
                          <TableContainer w="100%">
                            <Table
                              size="sm"
                              variant="simple"
                              bg="#FFF"
                              borderRadius="lg"
                              border="1px"
                              borderColor="gray.300"
                              borderStyle={"solid"}
                            >
                              <Tbody>
                                <Tr bg="gray.200" color="gray.600">
                                  <Td w="30px">
                                    <Text fontSize={13} fontWeight={700}>
                                      DATE
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={13} fontWeight={700}>
                                      STOCK
                                    </Text>
                                  </Td>
                                </Tr>
                                <Tr
                                  key="1"
                                  onMouseEnter={() => handleRowMouseEnter(1)}
                                  onMouseLeave={handleRowMouseLeave}
                                  style={{
                                    background:
                                      hoveredRow === 1
                                        ? "#F7FAFC"
                                        : "transparent",
                                    border:
                                      hoveredRow === 1
                                        ? "2px solid #A0AEC0"
                                        : "0px",
                                  }}
                                >
                                  <Td textAlign={["center"]}>
                                    <Text
                                      color="gray.700"
                                      fontSize={12}
                                      fontWeight={700}
                                    >
                                      2024.02.10, 9:05
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Tag
                                      pl="5"
                                      pr="5"
                                      ml="2"
                                      fontSize={15}
                                      fontWeight={600}
                                      bg="gray.50"
                                      border="1px"
                                      borderColor="gray.300"
                                      borderStyle={"solid"}
                                    >
                                      <Text
                                        color="green.400"
                                        minWidth="20px"
                                        textAlign={"right"}
                                      >
                                        135
                                      </Text>
                                      <Text ml="2" mr="2">
                                        Catégory 1
                                      </Text>
                                      <Text
                                        color="red.400"
                                        minWidth="40px"
                                        textAlign={"right"}
                                      >
                                        -3
                                      </Text>
                                    </Tag>
                                  </Td>
                                </Tr>
                                <Tr
                                  key="2"
                                  onMouseEnter={() => handleRowMouseEnter(2)}
                                  onMouseLeave={handleRowMouseLeave}
                                  style={{
                                    background:
                                      hoveredRow === 2
                                        ? "#F7FAFC"
                                        : "transparent",
                                    border:
                                      hoveredRow === 2
                                        ? "2px solid #A0AEC0"
                                        : "0px",
                                  }}
                                >
                                  <Td textAlign={["center"]}>
                                    <Text
                                      color="gray.700"
                                      fontSize={12}
                                      fontWeight={700}
                                    >
                                      2024.02.10, 9:05
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Tag
                                      pl="5"
                                      pr="5"
                                      ml="2"
                                      fontSize={15}
                                      fontWeight={600}
                                      bg="gray.50"
                                      border="1px"
                                      borderColor="gray.300"
                                      borderStyle={"solid"}
                                    >
                                      <Text
                                        color="green.400"
                                        minWidth="21px"
                                        textAlign={"right"}
                                      >
                                        135
                                      </Text>
                                      <Text
                                        minWidth="120px"
                                        maxWidth="120px"
                                        whiteSpace="normal"
                                        ml="2"
                                        mr="2"
                                      >
                                        Catégory 1 allée
                                      </Text>
                                      <Text
                                        color="red.400"
                                        minWidth="40px"
                                        textAlign={"right"}
                                      >
                                        -3
                                      </Text>
                                    </Tag>
                                    <Tag
                                      pl="5"
                                      pr="5"
                                      ml="2"
                                      fontSize={15}
                                      fontWeight={600}
                                      bg="gray.50"
                                      border="1px"
                                      borderColor="gray.300"
                                      borderStyle={"solid"}
                                    >
                                      <Text
                                        color="green.400"
                                        minWidth="20px"
                                        textAlign={"right"}
                                      >
                                        135
                                      </Text>
                                      <Text
                                        minWidth="120px"
                                        maxWidth="120px"
                                        whiteSpace="normal"
                                        ml="2"
                                        mr="2"
                                      >
                                        Catégory 1 allée
                                      </Text>
                                      <Text
                                        color="red.400"
                                        minWidth="40px"
                                        textAlign={"right"}
                                      >
                                        -3
                                      </Text>
                                    </Tag>
                                  </Td>
                                </Tr>
                              </Tbody>
                              <Tfoot>
                                <Tr>
                                  <Th></Th>
                                  <Th></Th>
                                </Tr>
                              </Tfoot>
                            </Table>
                          </TableContainer>
                        </VStack>
                      </>
                    )}
                  </Box>
                </GridItem>
              </Grid>
            </Box>
          </GridItem>
        </Grid>
      </Center>
    </>
  );

  return content;
};
export default FirstMarket;
