export const TRANSLATIONS_EN = {
  common: {
    Open: "Open",
    Close: "Close",
    Add: "Add",
    Create: "Create",
    Edit: "Edit",
    Save: "Save",
    Delete: "Delete",
    Search: "Search",
    Actived: "Activé",
    Disabled: "Désactivé",
    Created: "Created",
    Updated: "Updated",
    Back: "Back",
    UnselectAll: "Unselect all",
    Import: "Import",
    Filters: "Filters",
  },
  cals: {
    CalsManager: "Cal Manager",
    CalsList: "Cals list",
  },
  events: {
    EventsManager: "Event Manager",
    EventsFinder: "Events finder",
    EventsList: "Events list",
    Event: "Events",
    NewEvent: "New event",
    EventName: "Event Name",
    DateStart: "Date Start",
    HourStart: "Hour Start",
    HourOpen: "Hour Open",
    Category: "Category",
    TourName: "Tour Name",
    HasMultiDates: "Has Multi Dates",
    UrlPicture: "Url Picture",
    Picture: "Picture",
    Venue: "Venue",

    TicketCategory: "Category",
    TicketName: "Name",
    TicketPriceFacial: "Price Facial",
    TicketPriceFee1: "Price Fee1",
    TicketPriceFee2: "Price Fee2",
    TicketPriceMin: "Price Min",
    TicketPriceMax: "Price Max",
    TicketDevice: "Device",
    TicketType: "Type",
    TicketOption: "Option",
    TicketFeature: "Feature",

    SourceType: "Type",
    SourceUrl: "Url",

    CalType: "Type",
    CalDateOpen: "Date Open",
    CalDateClose: "Date Close",

    VenueName: "Venue Name",
    VenueType: "Venue Type",
    CityName: "City Name",
    CountryName: "Country Name",
    MarketName: "Market Name",
    CustomerName: "Customer Name",
    Ticket: "Ticket",
    Placement: "Placement",
    Attention: "Attention",
    Map: "Map",
    Source: "Source",
    AliasName: "Alias Name",
    Status: "Status",
    Action: "Action",
    Edit: "Edit",
  },
  customers: {
    CustomersList: "Customers list",
    Customer: "Customer",
    Status: "Statut",
    NewCustomer: "New customer",
    EditCustomer: "Customer edit",
    CustomerName: "Customer name",
    CustomerGenre: "Customer genre",
    CustomerType: "Customer type",
    TranslatedName: "Translate name",
    Action: "Action",
    Edit: "Edit",
    DeleteThisCustomer: "Delete this customer",
  },
  venues: {
    VenuesList: "Venues list",
    Venue: "Venue",
    Status: "Statut",
    NewVenue: "New venue",
    EditVenue: "Venue edit",
    VenueName: "Venue name",
    CityName: "City name",
    CountryName: "Country name",
    MarketName: "Market name",
    VenueAliasName: "Venue alias name",
    VenueDescription: "Description",
    VenueCapacity: "Capacity",
    VenueLevel: "Level",
    VenueType: "Venue type",
    TagAssign: "Tag Assign",
    BlockList: "Block List",
    BlockListImport: "Block List Import",
    SourceName: "Source Name",
    SourceType: "Source Type",
    BlockCode: "Block Code",
    BlockLabel: "Block Label",
    BlockAlias: "Block Alias",
    BlockAliasImport: "Block Alias Import",

    PastCode: "Past Code",
    PreviewImport: "Preview Import",

    CityArea: "City area",
    IsoCode: "ISO code",
    TranslatedName: "Translate name",
    Action: "Action",
    Edit: "Edit",
    DeleteThisVenue: "Delete this venue",
  },
  sources: {
    SourcesManager: "Source Manager",
    SourcesList: "Sources list",
    Source: "Source",
    Status: "Status",
    NewSource: "New source",
    EditSource: "Edit source",
    SourceName: "Source name",
    SourceType: "Source type",
    SourceLang: "Source lang",
    SourceWebsite: "Source website",
    SourceUrlRegex: "Source url regex",
    IsoCode: "ISO code",
    Action: "Action",
    Edit: "Edit",
    DeleteThisSource: "Delete this source",
  },
  maps: {
    MapsManager: "Map Manager",
    MapsList: "Maps list",
    Map: "Map",
    Status: "Status",
    NewMap: "New map",
    EditMap: "Edit map",
    MapName: "Map name",
    MapType: "Map type",
    VenueId: "Venue id",
    VenueName: "Venue name",
    VenueType: "Venue type",
    VenueCityName: "City name",
    VenueCountryName: "Country name",
    VenueMarketName: "Market name",
    VenueBlocs: "Venue blocs",

    StaticPicture: "Static picture",
    BlocsPictures: "Blocs pictures",
    ImportFileSvg: "Import SVG file",
    ViewFileSvg: "View SVG file",
    EditFileSvg: "Edit SVG file",
    MapBlock: "Map block",
    ListBlock: "List block",

    TranslatedName: "Translated name",
    Action: "Action",
    Edit: "Edit",
    DeleteThisMap: "Delete this map",
  },
  categories: {
    CategoriesList: "Categories list",
    Category: "Category",
    Status: "Status",
    NewCategory: "New category",
    EditCategory: "Edit category",
    CategoryName: "Category name",
    CategoryParent: "Parent category",
    TranslatedName: "Translated name",
    Action: "Action",
    Edit: "Edit",
    DeleteThisCategory: "Delete this category",
  },
  tickets: {
    TicketsList: "Tickets list",
    Ticket: "Ticket",
    Status: "Status",
    NewTicket: "New ticket",
    EditTicket: "Edit ticket",
    TicketName: "Ticket name",
    TranslatedName: "Translated name",
    Action: "Action",
    Edit: "Edit",
    DeleteThisTicket: "Delete this ticket",
  },
  cities: {
    CitiesList: "Cities list",
    City: "City",
    Status: "Status",
    NewCity: "New city",
    EditCity: "Edit city",
    CityName: "City name",
    CountryName: "Country name",
    MarketName: "Market name",
    CityISO: "City ISO code",
    CountryArea: "Country area",
    IsoCode: "ISO code",
    TimezoneUTC: "City Timezone UTC",
    TranslatedName: "Translated name",
    Action: "Action",
    Edit: "Edit",
    DeleteThisCity: "Delete this city",
  },
  countries: {
    CountriesList: "Countries list",
    Country: "Country",
    Status: "Status",
    NewCountry: "New country",
    EditCountry: "Edit country",
    CountryName: "Country name",
    MarketName: "Market name",
    CountryISO: "Country ISO code",
    MarketArea: "Market area",
    IsoCode: "ISO code",
    TranslatedName: "Translated name",
    Action: "Action",
    Edit: "Edit",
    DeleteThisCountry: "Delete this country",
  },
  markets: {
    MarketsList: "Markets list",
    Market: "Market",
    Status: "Status",
    NewMarket: "New market",
    EditMarket: "Edit market",
    MarketName: "Market name",
    IsoCode: "ISO code",
    TranslatedName: "Translated name",
    Action: "Action",
    Edit: "Edit",
    DeleteThisMarket: "Delete this market",
  },

  tixy: {
    Tixy: "Tixy",
    Manager: "Manager",
    Board: "Board",
    Dispatcher: "Dispatcher",
    Inventory: "Inventory",
    posMarket: "Pos Market",
    firstMarket: "First Market",
    secondMarket: "Second Market",
    StockEntry: "Stock Entry",
    StockExit: "Stock Exit",
    LastStockEntry: "Last Stock Entry",
    LastStockExit: "Last Stock Exit",
    LastAccountCrawled: "Last Account Crawled",
    NextAccountCrawled: "Next Account Crawled",
  },

  crawly: {
    Crawly: "Crawly",
    firstMarket: "First Market",
  },

  profily: {
    Profily: "Profily",
    Manager: "Manager",
  },

  notes: {
    NotesList: "Notes list",
    Status: "Status",
    Title: "Title",
    Note: "Note",
    Owner: "Owner",
    Edit: "Edit",
    Completed: "Completed",
    NewNote: "New note",
    EditNote: "Edit note",
    AssignedTo: "Assigned to",
  },
  users: {
    UsersList: "Users list",
    Username: "Username",
    Roles: "Roles",
    Edit: "Edit",
    NewUser: "New user",
    EditUser: "Edit user",
    Password: "Password",
    AssignedRoles: "Assigned roles",
    Active: "Active",
  },
  dash: {
    Logout: "Logout",
    Welcome: "Welcome",
    Users: "Users",
    Notes: "Notes",
    Markets: "Markets",
    Countries: "Countries",
    Cities: "Cities",
    Venues: "Venues",
    Maps: "Maps",
    Tickets: "Tickets",
    Customers: "Customers",
    Sources: "Sources",
    Tools: "Tools",
  },
};
