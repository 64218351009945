import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAddNewCartMutation } from "./cartsApiSlice"
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'

// import `ChakraProvider` component
import { Box, Grid, GridItem, Center, HStack, Container, Input, Select, Button, IconButton, Text, useToast } from '@chakra-ui/react'
import { FormControl, FormLabel } from "@chakra-ui/react"

// import `ChakraProvider` icon
import { ArrowBackIcon, CheckIcon, AddIcon, MinusIcon } from '@chakra-ui/icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTicket } from "@fortawesome/free-solid-svg-icons"

const NewCartForm = ({ countries }) => {

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const [addNewCart, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewCartMutation()

    const navigate = useNavigate()

    let [canSave, setCanSave] = useState(false)

    const [formFieldsCarts, setFormFieldsCarts] = useState([{}])

    const toastInfo = useToast({
        position: 'bottom-left',
        variant: 'left-accent',
    })

    const checkCanSave = () => {
        {
            setCanSave(true)
            formFieldsCarts.map((formFieldsCart) => {
                if (!isLoading && (formFieldsCart.cartType === '' || formFieldsCart.cartLang === '' || formFieldsCart.cartWebsite === '')) {
                    setCanSave(false)
                }
            })
        }
    }

    const onPageBack = () => navigate('/dash/carts')

    const onSaveCartClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            let cartType = ''
            let cartLang = ''
            let cartWebsite = ''
            let cartUrlRegex = ''
            formFieldsCarts.forEach((formFieldsCart, index) => {
                cartType = formFieldsCart.cartType
                cartLang = formFieldsCart.cartLang
                cartWebsite = formFieldsCart.cartWebsite
                cartUrlRegex = formFieldsCart.cartUrlRegex
            })

            await addNewCart({ cartType: cartType, cartLang: cartLang, cartWebsite: cartWebsite, cartUrlRegex: cartUrlRegex })
        }

    }

    const handleFormChange = (event, index) => {
        let data = [...formFieldsCarts];
        data[index][event.target.name] = event.target.value;
        setFormFieldsCarts(data);
        checkCanSave()
    }

    useEffect(() => {
        checkCanSave()
        if (isSuccess) {
            toastInfo({ title: 'SOURCE : Add successfully', status: 'success' })
            navigate('/dash/carts')
        }
        if (isError) {
            toastInfo({ title: 'SOURCE : Add error (' + error?.data?.message + ')', status: 'error' })
        }
    }, [checkCanSave, isError, error, isSuccess, navigate])

    const content = (
        <>
            <Center bg='#45413C' p='0' color='white' axis='both'>
                <Grid w='95vw' h='100vh' maxW='1500' gap={5} p='5' templateColumns='repeat(12, 1fr)'>
                    <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>
                        <HStack color='white'>
                            <Container textAlign={['left']} >
                                <Text fontSize={{ base: "24", sm: "24", md: "24", lg: "24", xl: "28" }} fontWeight={600}><FontAwesomeIcon icon={faTicket} /> {T.carts.NewCart}</Text><br />
                            </Container>
                            <Container textAlign={['right']}>
                                <Button size='sm' onClick={onPageBack} leftIcon={<ArrowBackIcon />} colorScheme='gray' variant='solid' mr='2'>
                                    <Text display={{ base: 'none', sm: 'flex' }} fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }} fontWeight={600}>{T.common.Back}</Text>
                                </Button>
                                <Button
                                    size='sm'
                                    onClick={onSaveCartClicked}
                                    disabled={!canSave}
                                    leftIcon={<CheckIcon />}
                                    colorScheme={canSave ? "red" : "gray"}
                                >
                                    <Text display={{ base: 'none', sm: 'flex' }} fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }} fontWeight={600}>{T.common.Save}</Text>
                                </Button>
                            </Container>
                        </HStack>
                        <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' borderColor='black' bg='white' w="100%">

                            <form onSubmit={onSaveCartClicked}>

                                <HStack>
                                    <FormControl>
                                        <FormLabel htmlFor='CartType' mt='5' mb='0'>
                                            {T.carts.CartType}
                                        </FormLabel>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel htmlFor='CartLang' mt='5' mb='0'>
                                            {T.carts.CartLang}
                                        </FormLabel>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel htmlFor='CartWebsite' mt='5' mb='0'>
                                            {T.carts.CartWebsite}
                                        </FormLabel>
                                    </FormControl>
                                </HStack>
                                {formFieldsCarts.map((formFieldsCart, index) => {
                                    return (
                                        <Box key={index}>

                                            <HStack>
                                                <FormControl>
                                                    <Select
                                                        isInvalid={!formFieldsCart.cartType ?? checkCanSave()}
                                                        name='cartType'
                                                        placeholder='Select option'
                                                        onChange={event => handleFormChange(event, index)}
                                                        value={formFieldsCart.cartType}
                                                        focusBorderColor='blue.400'
                                                        errorBorderColor='red.300'
                                                        size='md'
                                                        bg='yellow.100'
                                                        bg='yellow.100'
                                                    >
                                                        <option value='isFirstmarket'>isFirstmarket</option>
                                                        <option value='isSecondmarket'>isSecondmarket</option>
                                                        <option value='isAgregator'>isAgregator</option>
                                                        <option value='isOfficial'>isOfficial</option>
                                                    </Select>
                                                </FormControl>
                                                <FormControl>
                                                    <Input
                                                        isInvalid={!formFieldsCart.cartLang}
                                                        name='cartLang'
                                                        placeholder={T.carts.IsoCode}
                                                        onChange={event => handleFormChange(event, index)}
                                                        value={formFieldsCart.cartLang}
                                                        focusBorderColor='blue.400'
                                                        errorBorderColor='red.300'
                                                        minLength="2"
                                                        maxLength="2"
                                                        bg='yellow.100'
                                                        bg='yellow.100'
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <Input
                                                        isInvalid={!formFieldsCart.cartWebsite}
                                                        name='cartWebsite'
                                                        placeholder={T.carts.CartWebsite}
                                                        onChange={event => handleFormChange(event, index)}
                                                        value={formFieldsCart.cartWebsite}
                                                        focusBorderColor='blue.400'
                                                        errorBorderColor='red.300'
                                                        bg='yellow.100'
                                                        bg='yellow.100'
                                                    />
                                                </FormControl>
                                            </HStack>

                                            <FormControl>
                                                <FormLabel htmlFor='CartUrlRegex' mt='5' mb='0'>
                                                    {T.carts.CartUrlRegex}
                                                </FormLabel>
                                                <Input
                                                    //isInvalid={!formFieldsCart.cartUrlRegex}
                                                    name='cartUrlRegex'
                                                    placeholder={T.carts.CartUrlRegex}
                                                    onChange={event => handleFormChange(event, index)}
                                                    value={formFieldsCart.cartUrlRegex}
                                                    focusBorderColor='blue.400'
                                                    errorBorderColor='red.300'
                                                    bg='yellow.100'
                                                    bg='yellow.100'
                                                />
                                            </FormControl>

                                        </Box>

                                    )
                                })}

                            </form>

                        </Box>
                    </GridItem>
                </Grid>
            </Center>
        </>
    )

    return content
}

export default NewCartForm