import { useState, useEffect } from "react"
import { useGetCartsWaitQuery, useUpdateCartWaitMutation } from "./cartsApiSlice"
import Cart from "./Cart"
import { Link } from 'react-router-dom'
import useAuth from "../../hooks/useAuth"
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'
import useTitle from "../../hooks/useTitle"
import PulseLoader from 'react-spinners/PulseLoader'

// import `ChakraProvider` component
import { Box, Grid, GridItem, Center, HStack, VStack, Container, Flex, SimpleGrid, FormControl, FormLabel, Textarea, Checkbox, Input, Switch, Badge, Tag, TagLabel, TagCloseButton, Avatar, Toast, Icon, IconButton, Image, Stack, Alert, AlertIcon, Select, Button, useRadioGroup, useRadio, RadioGroup, Radio, Text, useDisclosure, useToast, Spacer } from '@chakra-ui/react'
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react'

// import `ChakraProvider` icon
import { AddIcon } from '@chakra-ui/icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationCrosshairs, faSnowman, faList } from "@fortawesome/free-solid-svg-icons"

const CartsList = () => {
    useTitle('BROKY: Carts Manager')

    const { username, isManager, isAdmin } = useAuth()

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    let menuContent
    if (isManager || isAdmin) {
        menuContent = (
            <>
                <Link to="/dash/carts/new">
                    <Button
                        size='sm'
                        leftIcon={<AddIcon />}
                        colorScheme='red'
                        variant='solid'>
                        <Text
                            color='white'
                            display={{ base: 'none', sm: 'flex' }}
                            fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }}
                            fontWeight={600}
                        >
                            {T.common.Add}
                        </Text>
                    </Button>
                </Link>
            </>
        )
    }

    let content
    content = (
        <>
            <Center bg='#45413C' axis='both'>
                <Grid w='95vw' minH='100vh' maxW='95vw' templateColumns='repeat(12, 1fr)'>
                    <GridItem colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>

                        <Grid p='5' gap='5' templateColumns='repeat(12, 1fr)'>
                            <GridItem colSpan={{ base: "6", sm: "6", md: "6", lg: "6", xl: "6", "2xl": "6" }} textAlign={['left']}>
                                <Text color={'white'} fontSize={{ base: "24", sm: "24", md: "24", lg: "24", xl: "28" }} fontWeight={600}><FontAwesomeIcon icon={faLocationCrosshairs} /> {T.tixy.Tixy} : {T.tixy.StockExit}</Text>
                            </GridItem>
                            <GridItem colSpan={{ base: "6", sm: "6", md: "6", lg: "6", xl: "6", "2xl": "6" }} textAlign={['right']}>
                                {menuContent}
                            </GridItem>
                        </Grid>

                        <Box borderRadius='xl' p={2} textAlign={['center']} bg='white'>

                            <Grid p='2' gap='4' w='100%' maxW='100vw' templateColumns='repeat(12, 1fr)'>

                                {/* PROFILES */}
                                <GridItem color='black' p='2' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "4" }}>
                                    <HStack>
                                        <Box p='2' textAlign={['left']} w='100%' color='black'>
                                            <Text fontSize={25} fontWeight={800}>{T.tixy.LastStockExit}</Text>
                                        </Box>
                                        <Link to="/dash/carts/new">
                                            <Button
                                                size='sm'
                                                leftIcon={<AddIcon />}
                                                colorScheme='red'
                                                variant='solid'>
                                                <Text
                                                    color='white'
                                                    display={{ base: 'none', sm: 'flex' }}
                                                    fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "18", "2xl": "18" }}
                                                    fontWeight={500}
                                                >
                                                    {T.common.Add}
                                                </Text>
                                            </Button>
                                        </Link>
                                    </HStack>
                                    <Box p='2' textAlign={['left']} w='100%' minH='100px' color='black' bg='#F5F5F5' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'}>
                                        <TableContainer>
                                            <Table size='sm'>
                                                <Thead>
                                                    <Tr>
                                                        <Th>#ID</Th>
                                                        <Th>LASTNAME</Th>
                                                        <Th>FIRSTNAME</Th>
                                                        <Th>EMAIL</Th>
                                                        <Th>USE</Th>
                                                        <Th isNumeric>CREATE</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    <Tr _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }}>
                                                        <Td>6</Td>
                                                        <Td>DOE</Td>
                                                        <Td>John</Td>
                                                        <Td>john.doe@gmail.com</Td>
                                                        <Td>0</Td>
                                                        <Td isNumeric>2023.01.15, 19:20</Td>
                                                    </Tr>
                                                    <Tr>
                                                        <Td>5</Td>
                                                        <Td>DOE</Td>
                                                        <Td>John</Td>
                                                        <Td>john.doe@gmail.com</Td>
                                                        <Td>0</Td>
                                                        <Td isNumeric>2023.01.15, 19:20</Td>
                                                    </Tr>
                                                    <Tr>
                                                        <Td>4</Td>
                                                        <Td>DOE</Td>
                                                        <Td>John</Td>
                                                        <Td>john.doe@gmail.com</Td>
                                                        <Td>0</Td>
                                                        <Td isNumeric>2023.01.15, 19:20</Td>
                                                    </Tr>
                                                    <Tr>
                                                        <Td>3</Td>
                                                        <Td>DOE</Td>
                                                        <Td>John</Td>
                                                        <Td>john.doe@gmail.com</Td>
                                                        <Td>0</Td>
                                                        <Td isNumeric>2023.01.15, 19:20</Td>
                                                    </Tr>
                                                    <Tr>
                                                        <Td>2</Td>
                                                        <Td>DOE</Td>
                                                        <Td>John</Td>
                                                        <Td>john.doe@gmail.com</Td>
                                                        <Td>0</Td>
                                                        <Td isNumeric>2023.01.15, 19:20</Td>
                                                    </Tr>
                                                    <Tr>
                                                        <Td>1</Td>
                                                        <Td>DOE</Td>
                                                        <Td>John</Td>
                                                        <Td>john.doe@gmail.com</Td>
                                                        <Td>0</Td>
                                                        <Td isNumeric>2023.01.15, 19:20</Td>
                                                    </Tr>
                                                </Tbody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                </GridItem>

                                {/* ACCOUNTS */}
                                <GridItem color='black' p='2' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "4" }}>

                                    <Box p='2' textAlign={['left']} w='100%' color='black'>
                                        <Text fontSize={25} fontWeight={800}>Accounts</Text>
                                    </Box>
                                    <Box p='2' textAlign={['left']} w='100%' minH='100px' color='black' bg='#F5F5F5' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'}>
                                        <TableContainer>
                                            <Table size='sm'>
                                                <Thead>
                                                    <Tr>
                                                        <Th>#ID</Th>
                                                        <Th>SUPPORT</Th>
                                                        <Th>EMAIL</Th>
                                                        <Th>ORDER</Th>
                                                        <Th>LAST USE</Th>
                                                        <Th isNumeric>LAST CRAWL</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    <Tr _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }}>
                                                        <Td>1</Td>
                                                        <Td>Ticketmaster.fr</Td>
                                                        <Td>john.doe@gmail.com</Td>
                                                        <Td>0</Td>
                                                        <Td>2023.01.15, 17:10</Td>
                                                        <Td isNumeric>2023.01.15, 19:20</Td>
                                                    </Tr>
                                                </Tbody>
                                            </Table>
                                        </TableContainer>
                                    </Box>


                                </GridItem>

                                {/* PROFILES */}
                                <GridItem color='black' p='2' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "4" }}>

                                    <Box p='2' textAlign={['left']} w='100%' color='black'>
                                        <Text fontSize={25} fontWeight={800}>-</Text>
                                    </Box>
                                    <Box p='2' textAlign={['left']} w='100%' minH='100px' color='black' bg='#F5F5F5' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'}>
                                    </Box>

                                </GridItem>

                            </Grid>

                        </Box>


                    </GridItem>
                </Grid>
            </Center >
        </>
    )

    return content
}
export default CartsList