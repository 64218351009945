import { store } from "../../app/store";
import { usersApiSlice } from "../users/usersApiSlice";
import { notesApiSlice } from "../notes/notesApiSlice";
import { cartsApiSlice } from "../carts/cartsApiSlice";

import { useEffect } from "react";
import { Outlet } from "react-router-dom";

const Prefetch = () => {
	useEffect(() => {
		store.dispatch(
			usersApiSlice.util.prefetch("getUsers", "usersList", { force: true })
		);
		store.dispatch(
			notesApiSlice.util.prefetch("getNotes", "notesList", { force: true })
		);
		store.dispatch(
			cartsApiSlice.util.prefetch("getCarts", "cartsList", { force: true })
		);
	}, []);

	return <Outlet />;
};
export default Prefetch;
