import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from 'react-router-dom'
import { useGetCartsQuery } from './cartsApiSlice'
import { memo } from 'react'
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'

// import `ChakraProvider` component
import { Icon, IconButton, Tr, Td, Tooltip, Text } from '@chakra-ui/react'

// import `ChakraProvider` icon
import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons'

const Cart = ({ cartId }) => {

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const { Cart } = useGetCartsQuery("cartsList", {
        selectFromResult: ({ data }) => ({
            Cart: data?.entities[cartId]
        }),
    })

    const navigate = useNavigate()

    if (Cart) {

        const handleEdit = () => navigate(`/dash/carts/${cartId}`)

        return (
            <Tr _hover={{ color: '#333333', border: '2px', borderColor: 'red.600' }}>
                <Td>
                    {Cart.cartStatus
                        ? <Icon as={CheckIcon} w={4} h={4} color='green.500' title={T.common.Actived} />
                        : <Icon as={CloseIcon} w={4} h={4} color='red' title={T.common.Disabled} />
                    }
                </Td>

                <Td>
                    <strong>{Cart.cartName}</strong>
                </Td>

                <Td>
                    {Cart.cartUrlRegex ? <Text color='green'>{Cart.cartUrlRegex}</Text> : <Text color='red'>No</Text>}
                </Td>

                <Td isNumeric>
                    <Tooltip hasArrow label={T.common.Edit} bg='gray.300' closeOnClick={true} color='black' placement='left'>
                        <IconButton
                            colorScheme='gray'
                            aria-label='New'
                            icon={<EditIcon />}
                            onClick={handleEdit}
                            _hover={{ bg: 'red.600', color: 'white', borderColor: 'red.600' }}
                        />
                    </Tooltip>
                </Td>
            </Tr>
        )

    } else return null
}

const memoizedCart = memo(Cart)

export default memoizedCart