import { useState, useEffect } from "react"
import { useUpdateCartMutation, useDeleteCartMutation } from "./cartsApiSlice"
import { useNavigate } from "react-router-dom"
import useAuth from "../../hooks/useAuth"
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'

// import `ChakraProvider` component
import { Box, Grid, GridItem, Center, HStack, Container, Input, Select, Switch, Button, IconButton, Text, useToast } from '@chakra-ui/react'
import { FormControl, FormLabel } from "@chakra-ui/react"

// import `ChakraProvider` icon
import { ArrowBackIcon, CheckIcon, CloseIcon, AddIcon, MinusIcon } from '@chakra-ui/icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTicket } from "@fortawesome/free-solid-svg-icons"

const EditCartForm = ({ Cart, countries }) => {

    const { isManager, isAdmin } = useAuth()

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const [updateCart, {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateCartMutation()

    const [deleteCart, {
        data: delData,
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delError
    }] = useDeleteCartMutation()

    const navigate = useNavigate()

    let [canSave, setCanSave] = useState(false)

    const [cartStatus, setCartStatus] = useState(Cart.cartStatus)

    const toastInfo = useToast({
        position: 'bottom-left',
        variant: 'left-accent',
    })

    const checkCanSave = () => {
        {
            setCanSave(true)
            formFieldsCarts.map((formFieldsCart) => {
                if (!isLoading && (formFieldsCart.cartType === '' || formFieldsCart.cartLang === '' || formFieldsCart.cartWebsite === '')) {
                    setCanSave(false)
                }
            })
        }
    }

    const onPageBack = () => navigate('/dash/carts')

    const onCartStatusChanged = e => setCartStatus(prev => !prev)

    const onSaveCartClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            let cartType = ''
            let cartLang = ''
            let cartWebsite = ''
            let cartUrlRegex = ''
            formFieldsCarts.forEach((formFieldsCart, index) => {
                cartType = formFieldsCart.cartType
                cartLang = formFieldsCart.cartLang
                cartWebsite = formFieldsCart.cartWebsite
                cartUrlRegex = formFieldsCart.cartUrlRegex
            })

            await updateCart({ id: Cart.id, cartType: cartType, cartLang: cartLang, cartWebsite: cartWebsite, cartUrlRegex: cartUrlRegex, cartStatus })
        }
    }

    const onDeleteCartClicked = async () => {
        await deleteCart({ id: Cart.id })
    }

    const created = new Date(Cart.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })
    const updated = new Date(Cart.updatedAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })

    let deleteButton = null
    if (isManager || isAdmin) {
        deleteButton = (
            <Button size='xs' onClick={onDeleteCartClicked} name="Delete" title={T.common.Delete} leftIcon={<CloseIcon />} colorScheme='red' variant='outline'>{T.carts.DeleteThisCart}</Button>
        )
    }

    const initCartNamesForm = () => {
        let cart = []
        cart.push({ cartName: Cart.cartName, cartType: Cart.cartType, cartLang: Cart.cartLang, cartWebsite: Cart.cartWebsite, cartUrlRegex: Cart.cartUrlRegex })
        return cart
    }

    const [formFieldsCarts, setFormFields] = useState(initCartNamesForm)

    const handleFormChange = (event, index) => {
        let data = [...formFieldsCarts];
        data[index][event.target.name] = event.target.value;
        setFormFields(data);
        checkCanSave()
    }

    const addFields = () => {
        let object = {
            lang: '',
            cartName: ''
        }
        setFormFields([...formFieldsCarts, object])
        setCanSave(false)
    }

    const removeFields = (index) => {
        let data = [...formFieldsCarts];
        data.splice(index, 1)
        setFormFields(data)
        checkCanSave()
    }

    useEffect(() => {
        checkCanSave()
        if (isSuccess) {
            toastInfo({ title: 'SOURCE : ' + data?.message + ' successfully', status: 'success' })
            initCartNamesForm()
            navigate('/dash/carts')
        }
        if (isError) {
            toastInfo({ title: 'SOURCE : Update error (' + error?.data?.message + ')', status: 'error' })
        }
        if (isDelSuccess) {
            toastInfo({ title: 'SOURCE : ' + delData?.message + ' successfully', status: 'success' })
            initCartNamesForm()
            navigate('/dash/carts')
        }
        if (isDelError) {
            toastInfo({ title: 'SOURCE : Update error (' + delError?.data?.message + ')', status: 'error' })
        }
    }, [checkCanSave, initCartNamesForm, toastInfo, isError, error, isDelError, delError, isSuccess, isDelSuccess, navigate])

    const content = (
        <>
            <Center bg='#45413C' p='0' color='white' axis='both'>
                <Grid w='95vw' h='100vh' maxW='1500' gap={5} p='5' templateColumns='repeat(12, 1fr)'>
                    <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>
                        <HStack color='white'>
                            <Container textAlign={['left']} >
                                <Text fontSize={{ base: "24", sm: "24", md: "24", lg: "24", xl: "28" }} fontWeight={600}><FontAwesomeIcon icon={faTicket} /> {T.carts.EditCart}</Text><br />
                            </Container>
                            <Container textAlign={['right']}>
                                <Button size='sm' onClick={onPageBack} leftIcon={<ArrowBackIcon />} colorScheme='gray' variant='solid' mr='2'>
                                    <Text display={{ base: 'none', sm: 'flex' }} fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }} fontWeight={600}>{T.common.Back}</Text>
                                </Button>
                                <Button
                                    size='sm'
                                    onClick={onSaveCartClicked}
                                    disabled={!canSave}
                                    leftIcon={<CheckIcon />}
                                    colorScheme={canSave ? "red" : "gray"}
                                >
                                    <Text display={{ base: 'none', sm: 'flex' }} fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }} fontWeight={600}>{T.common.Save}</Text>
                                </Button>
                            </Container>
                        </HStack>
                        <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' borderColor='black' bg='white' w="100%">

                            <form onSubmit={e => e.preventDefault()}>

                                <HStack>
                                    <FormControl>
                                        <FormLabel htmlFor='CartType' mt='5' mb='0'>
                                            {T.carts.CartType}
                                        </FormLabel>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel htmlFor='CartLang' mt='5' mb='0'>
                                            {T.carts.CartLang}
                                        </FormLabel>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel htmlFor='CartWebsite' mt='5' mb='0'>
                                            {T.carts.CartWebsite}
                                        </FormLabel>
                                    </FormControl>
                                </HStack>
                                {formFieldsCarts.map((formFieldsCart, index) => {
                                    return (
                                        <Box key={index}>
                                            <HStack>
                                                <FormControl>
                                                    <Select
                                                        isInvalid={!formFieldsCart.cartType ?? checkCanSave()}
                                                        name='cartType'
                                                        placeholder='Select option'
                                                        onChange={event => handleFormChange(event, index)}
                                                        value={formFieldsCart.cartType}
                                                        focusBorderColor='blue.400'
                                                        errorBorderColor='red.300'
                                                        size='md'
                                                        bg='yellow.100'
                                                        bg='yellow.100'
                                                    >
                                                        <option value='isFirstmarket'>isFirstmarket</option>
                                                        <option value='isSecondmarket'>isSecondmarket</option>
                                                        <option value='isAgregator'>isAgregator</option>
                                                        <option value='isOfficial'>isOfficial</option>
                                                    </Select>
                                                </FormControl>
                                                <FormControl>
                                                    <Input
                                                        isInvalid={!formFieldsCart.cartLang}
                                                        name='cartLang'
                                                        placeholder={T.carts.IsoCode}
                                                        onChange={event => handleFormChange(event, index)}
                                                        value={formFieldsCart.cartLang}
                                                        focusBorderColor='blue.400'
                                                        errorBorderColor='red.300'
                                                        minLength="2"
                                                        maxLength="2"
                                                        bg='yellow.100'
                                                        bg='yellow.100'
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <Input
                                                        isInvalid={!formFieldsCart.cartWebsite}
                                                        name='cartWebsite'
                                                        placeholder={T.carts.CartWebsite}
                                                        onChange={event => handleFormChange(event, index)}
                                                        value={formFieldsCart.cartWebsite}
                                                        focusBorderColor='blue.400'
                                                        errorBorderColor='red.300'
                                                        bg='yellow.100'
                                                        bg='yellow.100'
                                                    />
                                                </FormControl>
                                            </HStack>

                                            <FormLabel htmlFor='CartUrlRegex' mt='5' mb='0'>
                                                {T.carts.CartUrlRegex}
                                            </FormLabel>
                                            <FormControl>
                                                <Input
                                                    //isInvalid={!formFieldsCart.cartUrlRegex}
                                                    name='cartUrlRegex'
                                                    placeholder={T.carts.CartUrlRegex}
                                                    onChange={event => handleFormChange(event, index)}
                                                    value={formFieldsCart.cartUrlRegex}
                                                    focusBorderColor='blue.400'
                                                    errorBorderColor='red.300'
                                                    bg='yellow.100'
                                                    bg='yellow.100'
                                                />
                                            </FormControl>
                                        </Box>
                                    )
                                })
                                }

                                <FormLabel htmlFor='cartStatus' mt='5' mb='0'>
                                    {T.carts.Status}
                                </FormLabel>
                                <Switch
                                    id='cartStatus'
                                    name="status"
                                    colorScheme='green'
                                    isChecked={cartStatus}
                                    onChange={onCartStatusChanged}
                                />

                                <br />

                                <HStack>
                                    <Text fontSize={{ base: "10", sm: "10", md: "10", lg: "15", xl: "15" }} fontWeight={600}>{T.common.Created}:</Text>
                                    <Text fontSize={{ base: "10", sm: "10", md: "10", lg: "15", xl: "15" }}>{created}</Text>
                                </HStack>
                                <HStack>
                                    <Text fontSize={{ base: "10", sm: "10", md: "10", lg: "15", xl: "15" }} fontWeight={600}>{T.common.Updated}:</Text>
                                    <Text fontSize={{ base: "10", sm: "10", md: "10", lg: "15", xl: "15" }}>{updated}</Text>
                                </HStack>
                            </form>
                            <br />
                            <br />
                            {deleteButton}

                        </Box>
                    </GridItem>

                </Grid>
            </Center >

        </>
    )

    return content
}

export default EditCartForm