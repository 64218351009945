import axios from "axios";
import { useState, useEffect } from "react";
import {
	useGetCartsWaitQuery,
	useUpdateCartWaitMutation,
} from "./cartsApiSlice";
import { useSearchEventsQuery } from "../events/eventsApiSlice";
import Cart from "./Cart";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useLang from "../../hooks/useLang";
import useTranslate from "../../hooks/useTranslate";
import useTitle from "../../hooks/useTitle";
import PulseLoader from "react-spinners/PulseLoader";
import TicketUploadForm from "../upload/TicketUploadForm";

// import `ChakraProvider` component
import {
	Box,
	Grid,
	GridItem,
	Center,
	HStack,
	VStack,
	Container,
	Flex,
	SimpleGrid,
	FormControl,
	FormLabel,
	Textarea,
	Checkbox,
	Input,
	Switch,
	Badge,
	Tag,
	TagLabel,
	TagCloseButton,
	Avatar,
	Toast,
	Icon,
	IconButton,
	Image,
	Stack,
	Alert,
	AlertIcon,
	Select,
	Button,
	useRadioGroup,
	useRadio,
	RadioGroup,
	Radio,
	Text,
	useDisclosure,
	useToast,
	Spacer,
	AlertDescription,
} from "@chakra-ui/react";
import {
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	TableCaption,
	TableContainer,
} from "@chakra-ui/react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
} from "@chakra-ui/react";
import {
	Drawer,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
} from "@chakra-ui/react";

// import `ChakraProvider` icon
import {
	AddIcon,
	ArrowForwardIcon,
	TriangleDownIcon,
	TriangleUpIcon,
} from "@chakra-ui/icons";

import { BsFiletypePdf, BsFilePdf } from "react-icons/bs";

import {
	FiMenu,
	FiChevronRight,
	FiCalendar,
	FiMapPin,
	FiMinusSquare,
	FiPlusSquare,
	FiEdit,
	FiCopy,
	FiSave,
	FiStar,
	FiPlus,
	FiMinus,
	FiPause,
	FiPlay,
	FiGlobe,
	FiAward,
	FiUpload,
} from "react-icons/fi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faLocationCrosshairs,
	faSnowman,
	faList,
	faFilePdf,
} from "@fortawesome/free-solid-svg-icons";

const StockEntry = () => {
	useTitle("BROKY: Stock Entry");

	const { username, isManager, isAdmin } = useAuth();

	const { lang } = useLang();
	const { T } = useTranslate(lang);

	const [eventSearch, setEventSearch] = useState("");
	const [searchEventsResults, setSearchEventsResults] = useState({});
	const [searchCounterKey, setSearchCounterKey] = useState(0);
	const eventSearchChange = (event) => {
		setSearchCounterKey(searchCounterKey + 1);
		//		if (event.target.value.length > 3) {
		//setSearchEventsResults([]);
		setEventSearch(event.target.value);
		//setSearchCustomersHighlight(event.target.value.split(' '))
		//searchBoxCustomers.onOpen()
		//		} else {
		//searchBoxCustomers.onClose()
		//		}
	};
	const {
		data: srcEventsData,
		isLoading: isSrcEventsLoading,
		isSuccess: isSrcEventsSuccess,
		isError: isSrcEventsError,
		error: SrcEventsError,
	} = useSearchEventsQuery({
		searchString: eventSearch,
		searchCounterKey,
	});

	const [isOpenEventSearch, setIsOpenEventSearch] = useState();
	const modalIsOpenEventSearch = (state) => {
		setIsOpenEventSearch(state);
	};

	/*
	const selectEvent = (eventId, eventName) => {
		let data = [...formFieldsEvent];
		data[0].eventId = eventId;
		data[0].eventName = eventName;
		setFormFieldsEvent(data);
		checkCanSave();
		searchBoxVenues.onClose();
	};

	const [formFieldsEventAddStockEntry, setFormFieldsEventAddStockEntry] =
		useState({});
		*/
	const data = [
		{
			order: {
				orderNum: "789456",
				orderDate: "2024-02-14T00:00:00.000Z",
				orderAmount: "450.22",
				orderCardUse: "RV9874",
			},
			account: {
				accountSupport: "ticketmasterfr",
				accountLogin: "john.doe@gmail.com",
				accountPassword: "azerty",
			},
			event: {
				eventName: "Gims",
				eventDateStart: "2024-11-22T00:00:00.000Z",
				eventHourOpen: "18:00",
				eventHourStart: "20:00",
				eventCategoryId: "654ca971dfd01ae12e737ccc",
				eventTourName: "Tour 2024",
			},
			venue: {
				venueId: "656a568744555a41eade58f1",
				venueName: {
					en: "Zenith Orleans",
					fr: "Zénith d'Orléans",
				},
				venueType: "area",
				cityName: {
					en: "Orléans",
					fr: "Orléans",
				},
				countryName: {
					en: "France",
					fr: "France",
				},
				marketName: {
					en: "Europe",
					fr: "Europe",
				},
				cityId: "654cb451c39bb85509b0ab14",
				countryId: "654ca318c39bb85509b091e7",
				marketId: "654c999da2d081feeaae9d5a",
			},
			venueCfg: {},
			ticket: [],
			ticketCfg: [
				{
					ticketCategory: "654ca864dfd01ae12e737cb1",
					ticketPricefacial: "72",
					ticketPricefee1: "0",
					ticketPricefee2: "0",
					ticketDevice: "EUR",
					ticketType: "isSeatingNumbered",
					ticketFeaturesPremium: [],
					ticketFeaturesAvantage: [],
					ticketFeaturesRestriction: [],
				},
				{
					ticketCategory: "65537d8a85fa38e8ac509c86",
					ticketPricefacial: "62",
					ticketPricefee1: "0",
					ticketPricefee2: "0",
					ticketDevice: "EUR",
					ticketType: "isSeatingNumbered",
					ticketFeaturesPremium: [],
					ticketFeaturesAvantage: [],
					ticketFeaturesRestriction: [],
				},
				{
					ticketCategory: "65537d9d85fa38e8ac509c8a",
					ticketPricefacial: "49",
					ticketPricefee1: "0",
					ticketPricefee2: "0",
					ticketDevice: "EUR",
					ticketType: "isSeatingNumbered",
					ticketFeaturesPremium: [],
					ticketFeaturesAvantage: [],
					ticketFeaturesRestriction: [],
				},
			],
			map: {
				staticMap: "noMap",
				dynamicMap: "",
				heatMap: "",
			},
			pic: ["65895ef044555a41ea29438a"],
			eventStatus: true,
		},
	];
	const [dataTmp, setDataTmp] = useState(data);

	//alert("dataTmp[0].event.eventName = " + dataTmp[0].event.eventName);
	const [isOpenAddStockEntry, setIsOpenAddStockEntry] = useState();
	const drawerIsOpenAddStockEntry = (state) => {
		modalIsOpenEventSearch(false);
		setIsOpenAddStockEntry(state);
	};

	const handleAddTicket = () => {
		const newTicket = {
			ticketCategory: "",
			ticketPrice: "",
			ticketDevise: "EUR",
			ticketBloc: "",
			ticketRow: "",
			ticketSeat: "",
			ticketBarcode: "",
			ticketFile: "",
		};
		const updatedTickets = [...dataTmp[0].ticket, newTicket];
		const updatedData = [
			{
				...dataTmp[0],
				ticket: updatedTickets,
			},
		];
		setDataTmp(updatedData);
	};
	const handleDeleteTicket = (index) => {
		const updatedTickets = dataTmp[0].ticket.filter((_, i) => i !== index);
		const updatedData = [
			{
				...dataTmp[0],
				ticket: updatedTickets,
			},
		];
		setDataTmp(updatedData);
	};

	const [ticketPics, setTicketPics] = useState([]);

	const updateTicketPics = (newValue) => {
		setTicketPics([...ticketPics, ...newValue]);
	};

	const deleteTicketPics = (e) => {
		const t = ticketPics.filter((item, index) => index !== e);
		setTicketPics(t);
	};

	const handleFileUpload = (event) => {
		const file = event.target.files[0];
		// Faites quelque chose avec le fichier ici, par exemple l'envoyer vers un serveur
		console.log("Fichier uploadé :", file);
	};

	const [file, setFile] = useState(null);
	const [loading, setLoading] = useState(false);
	const [thumbnailPath, setThumbnailPath] = useState("");
	const [pdfPath, setPdfPath] = useState("");

	useEffect(() => {
		if (file) {
			handleUpload();
		}
	}, [file]);

	const handleFileChange = (event) => {
		setFile(event.target.files[0]);
	};

	const handleUpload = async () => {
		try {
			alert("Do upload");

			setLoading(true);

			const formData = new FormData();
			formData.append("file", file);

			const response = await axios.post(
				"http://localhost:3800/upload",
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			);

			const { thumbnailPath, pdfPath } = response.data;

			setThumbnailPath(thumbnailPath);
			setPdfPath(pdfPath);
		} catch (error) {
			console.error("Error uploading file: ", error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (isSrcEventsLoading) {
			setSearchEventsResults({});
		}
		if (isSrcEventsSuccess) {
			console.log("Données des événements:", srcEventsData);
			setSearchEventsResults(srcEventsData);
		}
	}, [isSrcEventsSuccess, srcEventsData]);

	let menuContent;
	if (isManager || isAdmin) {
		menuContent = (
			<>
				<Link to="/dash/carts/new">
					<Button
						size="sm"
						leftIcon={<AddIcon />}
						colorScheme="red"
						variant="solid"
					>
						<Text
							color="white"
							display={{ base: "none", sm: "flex" }}
							fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }}
							fontWeight={600}
						>
							{T.common.Add}
						</Text>
					</Button>
				</Link>
			</>
		);
	}

	let content;
	content = (
		<>
			<Modal
				isOpen={isOpenEventSearch ?? false}
				onClose={() => modalIsOpenEventSearch(false)}
				scrollBehavior={"inside"}
				size={"xl"}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Search Event</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text color={"gray.400"} fontSize={15} fontWeight={400}>
							Event name
						</Text>
						<Input
							isInvalid={!eventSearch}
							name="eventSearch"
							value={eventSearch}
							onChange={(event) => eventSearchChange(event)}
							focusBorderColor="blue.400"
							errorBorderColor="red.300"
							bg="yellow.100"
							fontSize={22}
							fontWeight={700}
							borderRadius="xl"
							size={"xl"}
							p="2"
						/>
						<Text mt="5" color={"gray.400"} fontSize={15} fontWeight={400}>
							Result(s)
						</Text>
						<Box height={"50vh"}>
							<>
								{Object.keys(searchEventsResults).length > 0 ? (
									Object.values(searchEventsResults).map(
										(searchEventsResult, index) => (
											<>
												{/* Extraire le jour et le mois de la date */}
												{(() => {
													const dateStr =
														searchEventsResult.event.eventDateStart; // Supposez que 'date' est la clé où se trouve la date
													const date = new Date(dateStr);
													const day = date.getDate();
													const monthIndex = date.getMonth();
													const months = [
														"JAN",
														"FEV",
														"MAR",
														"APR",
														"MAY",
														"JUN",
														"JUL",
														"AUG",
														"SEP",
														"OCT",
														"NOV",
														"DEC",
													];
													const month = months[monthIndex];
													const year = date.getFullYear();

													{
														/* Afficher le jour et le mois */
													}
													return (
														<Box
															key={index}
															p={2}
															textAlign={["left"]}
															border="1px"
															borderColor="white"
															color="black"
															bg="#FFF"
															_hover={{
																bg: "gray.50",
																border: "1px",
																borderColor: "gray.400",
																borderStyle: "solid",
																cursor: "pointer",
															}}
															onClick={() => drawerIsOpenAddStockEntry(true)}
															/*
															onClick={() =>
																selectEvent(
																	searchEventsResult._id,
																	searchEventsResult.event.eventName
																)
															}
															*/
														>
															<HStack>
																<Box
																	w="70px"
																	h="70px"
																	mr="2"
																	borderRadius="xl"
																	border="1px"
																	borderColor="red.600"
																>
																	<Box
																		p={1}
																		borderRadius="xl"
																		border="2px"
																		bg="red.600"
																		borderColor="#ffffff"
																		textAlign={["center"]}
																	>
																		<Text
																			color="white"
																			fontSize={11}
																			fontWeight={600}
																		>
																			{month}. {year}
																		</Text>
																	</Box>
																	<Box
																		borderRadius="xl"
																		m={0}
																		p={0}
																		color="white"
																		bg="#ffffff"
																		textAlign={["center"]}
																		lineHeight={"1.2"}
																	>
																		<Text
																			color="black"
																			fontSize={23}
																			fontWeight={700}
																		>
																			{day}
																		</Text>
																		<Text
																			color="black"
																			fontSize={11}
																			fontWeight={500}
																		>
																			{searchEventsResult.event.eventHourStart}
																		</Text>
																	</Box>
																</Box>
																<Box>
																	<Text fontSize={23} fontWeight={700}>
																		{searchEventsResult.event.eventName}
																	</Text>
																	<Text
																		fontSize={16}
																		fontWeight={400}
																		color="red.600"
																	>
																		<Icon
																			as={FiMapPin}
																			h={4}
																			w={4}
																			mr={1}
																			alignSelf={"center"}
																		/>
																		{searchEventsResult.venue.venueName.en},{" "}
																		{searchEventsResult.venue.cityName.en},{" "}
																		{searchEventsResult.venue.countryName.en}
																	</Text>
																</Box>
															</HStack>
														</Box>
													);
												})()}
											</>
										)
									)
								) : (
									<Flex>
										<Box w="50vw" maxWidth="350px">
											<Text
												fontSize={{
													base: "18",
													sm: "18",
													md: "18",
													lg: "18",
													xl: "22",
												}}
												fontWeight={300}
											>
												No result
											</Text>
										</Box>
									</Flex>
								)}
							</>
						</Box>
						<Text mt="5" color={"gray.400"} fontSize={15} fontWeight={400}>
							Last research
						</Text>
						<Box height={"10vh"}></Box>
					</ModalBody>
					<ModalFooter>
						<Button onClick={() => modalIsOpenEventSearch(false)}>Close</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			<Center bg="#45413C" axis="both">
				<Grid
					w="95vw"
					minH="100vh"
					maxW="95vw"
					templateColumns="repeat(12, 1fr)"
				>
					<GridItem
						colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}
					>
						<Grid p="5" gap="5" templateColumns="repeat(12, 1fr)">
							<GridItem
								colSpan={{
									base: "6",
									sm: "6",
									md: "6",
									lg: "6",
									xl: "6",
									"2xl": "6",
								}}
								textAlign={["left"]}
							>
								<Text
									color={"white"}
									fontSize={{
										base: "24",
										sm: "24",
										md: "24",
										lg: "24",
										xl: "28",
									}}
									fontWeight={600}
								>
									<FontAwesomeIcon icon={faLocationCrosshairs} /> {T.tixy.Tixy}{" "}
									: {T.tixy.StockEntry}
								</Text>
							</GridItem>
							<GridItem
								colSpan={{
									base: "6",
									sm: "6",
									md: "6",
									lg: "6",
									xl: "6",
									"2xl": "6",
								}}
								textAlign={["right"]}
							></GridItem>
						</Grid>

						<Box borderRadius="xl" p={2} textAlign={["center"]} bg="white">
							<Grid
								p="2"
								gap="4"
								w="100%"
								maxW="100vw"
								templateColumns="repeat(12, 1fr)"
							>
								{/* LAST STOCK ENTRY */}
								<GridItem
									color="black"
									p="2"
									colSpan={{
										base: "12",
										sm: "12",
										md: "12",
										lg: "12",
										xl: "12",
										"2xl": "7",
									}}
								>
									<HStack>
										<Box p="2" textAlign={["left"]} w="100%" color="black">
											<Text fontSize={25} fontWeight={800}>
												{T.tixy.LastStockEntry}
											</Text>
										</Box>
										<Button
											size="sm"
											leftIcon={<AddIcon />}
											colorScheme="red"
											variant="solid"
											onClick={() => modalIsOpenEventSearch(true)}
										>
											<Text
												color="white"
												display={{ base: "none", sm: "flex" }}
												fontSize={{
													base: "18",
													sm: "18",
													md: "18",
													lg: "18",
													xl: "18",
													"2xl": "18",
												}}
												fontWeight={500}
											>
												{T.common.Add}
											</Text>
										</Button>
									</HStack>

									{/* ADD STOCK ENTRY */}
									{Object.keys(dataTmp).length > 0 ? (
										Object.values(dataTmp).map((searchEventsResult, index) => (
											<Drawer
												isOpen={isOpenAddStockEntry ?? false}
												onClose={() => drawerIsOpenAddStockEntry(false)}
												size="xl"
												placement="bottom"
											>
												<DrawerOverlay />
												<DrawerContent bg="#CCCCCC">
													<DrawerCloseButton />
													<DrawerHeader>
														<Text
															color={"black"}
															fontSize={"25"}
															fontWeight={700}
														>
															{T.common.Add} {T.tixy.StockEntry}
														</Text>
													</DrawerHeader>
													<DrawerBody>
														<Box
															bg="#efefef"
															borderRadius="xl"
															borderWidth="1px"
															borderColor="#dfdfdf"
															shadow="lg"
															position="relative"
															textAlign={["center"]}
															mb="5"
														>
															<Grid
																w="100%"
																gap={2}
																p="2"
																templateColumns="repeat(12, 1fr)"
															>
																{/* Event title */}
																<GridItem
																	color="black"
																	colSpan={{
																		base: "12",
																		sm: "12",
																		md: "12",
																		lg: "12",
																		xl: "12",
																		"2xl": "4",
																	}}
																>
																	<Box p={2} textAlign={["left"]}>
																		<HStack>
																			<Box
																				w="100px"
																				h="100px"
																				mr="3"
																				borderRadius="xl"
																				border="1px"
																				borderColor="red.600"
																			>
																				<Box
																					p={1}
																					borderRadius="xl"
																					border="2px"
																					bg="red.600"
																					borderColor="#ffffff"
																					textAlign={["center"]}
																				>
																					<Text
																						color="white"
																						fontSize={15}
																						fontWeight={600}
																					>
																						Sept. 2023
																					</Text>
																				</Box>
																				<Box
																					borderRadius="xl"
																					m={0}
																					p={0}
																					color="white"
																					bg="#ffffff"
																					textAlign={["center"]}
																				>
																					<Text
																						color="black"
																						fontSize={27}
																						fontWeight={700}
																					>
																						22
																					</Text>
																					<Text
																						color="black"
																						fontSize={15}
																						fontWeight={500}
																					>
																						eventHour
																					</Text>
																				</Box>
																			</Box>
																			<Box>
																				<Text fontSize={25} fontWeight={700}>
																					{searchEventsResult.event.eventName}
																				</Text>
																				<Text
																					fontSize={20}
																					fontWeight={400}
																					color="red.600"
																				>
																					<Icon
																						as={FiMapPin}
																						h={4}
																						w={4}
																						mr={1}
																						alignSelf={"center"}
																					/>
																					{
																						searchEventsResult.venue.venueName
																							.en
																					}
																					,{" "}
																					{searchEventsResult.venue.cityName.en}
																					,{" "}
																					{
																						searchEventsResult.venue.countryName
																							.en
																					}
																				</Text>
																			</Box>
																		</HStack>
																	</Box>
																</GridItem>

																{/* Order */}
																<GridItem
																	color="white"
																	textAlign={"center"}
																	colSpan={{
																		base: "12",
																		sm: "12",
																		md: "6",
																		lg: "6",
																		xl: "6",
																		"2xl": "4",
																	}}
																>
																	<Text
																		color="gray.600"
																		fontSize={16}
																		fontWeight={700}
																	>
																		Order
																	</Text>
																	<Box
																		p="2"
																		textAlign={["left"]}
																		minH="80px"
																		color="black"
																		bg="#F5F5F5"
																		borderRadius="xl"
																		border="1px"
																		borderColor="gray.300"
																		borderStyle={"solid"}
																	>
																		<HStack>
																			<Box mr="1">
																				<Text
																					color="gray.400"
																					fontSize={12}
																					fontWeight={500}
																				>
																					NUM
																				</Text>
																				<Input
																					id="profilePostalCode"
																					name="profilePostalCode"
																					onChange=""
																					value={
																						searchEventsResult.order.orderNum
																					}
																					fontSize={20}
																					fontWeight={400}
																					bg="yellow.100"
																				/>
																			</Box>
																			<Box mr="1">
																				<Text
																					color="gray.400"
																					fontSize={12}
																					fontWeight={500}
																				>
																					AMOUNT
																				</Text>
																				<Input
																					id="profilePostalCode"
																					name="profilePostalCode"
																					onChange=""
																					value={
																						searchEventsResult.order.orderAmount
																					}
																					fontSize={20}
																					fontWeight={400}
																					bg="yellow.100"
																				/>
																			</Box>
																			<Box mr="1">
																				<Text
																					color="gray.400"
																					fontSize={12}
																					fontWeight={500}
																				>
																					CB
																				</Text>
																				<Input
																					id="profilePostalCode"
																					name="profilePostalCode"
																					onChange=""
																					value={
																						searchEventsResult.order
																							.orderCardUse
																					}
																					fontSize={20}
																					fontWeight={400}
																					bg="yellow.100"
																				/>
																			</Box>
																			<Box mr="1">
																				<Text
																					color="gray.400"
																					fontSize={12}
																					fontWeight={500}
																				>
																					INVOICE FILE
																				</Text>
																				<Input
																					id="profilePostalCode"
																					name="profilePostalCode"
																					onChange=""
																					value=""
																					fontSize={20}
																					fontWeight={400}
																					bg="yellow.100"
																				/>
																			</Box>
																		</HStack>
																	</Box>
																</GridItem>

																{/* Account */}
																<GridItem
																	color="white"
																	textAlign={"center"}
																	colSpan={{
																		base: "12",
																		sm: "12",
																		md: "6",
																		lg: "6",
																		xl: "6",
																		"2xl": "4",
																	}}
																>
																	<Text
																		color="gray.600"
																		fontSize={16}
																		fontWeight={700}
																	>
																		Account
																	</Text>
																	<Box
																		p="2"
																		textAlign={["left"]}
																		minH="80px"
																		color="black"
																		bg="#F5F5F5"
																		borderRadius="xl"
																		border="1px"
																		borderColor="gray.300"
																		borderStyle={"solid"}
																	>
																		<HStack>
																			<Box mr="1">
																				<Text
																					color="gray.400"
																					fontSize={12}
																					fontWeight={500}
																				>
																					SUPPORT
																				</Text>
																				<Select
																					id="profileSexe"
																					name="profileSexe"
																					value=""
																					onChange=""
																					fontSize={18}
																					fontWeight={600}
																					bg="yellow.100"
																				>
																					<option value="category1">
																						Ticketmaster.fr
																					</option>
																					<option value="category2">
																						Fnacspectacle.com
																					</option>
																				</Select>
																			</Box>
																			<Box mr="1">
																				<Text
																					color="gray.400"
																					fontSize={12}
																					fontWeight={500}
																				>
																					LOGIN
																				</Text>
																				<Input
																					id="profilePostalCode"
																					name="profilePostalCode"
																					onChange=""
																					value={
																						searchEventsResult.account
																							.accountPassword
																					}
																					fontSize={20}
																					fontWeight={400}
																					bg="yellow.100"
																				/>
																			</Box>
																			<Box mr="1">
																				<Text
																					color="gray.400"
																					fontSize={12}
																					fontWeight={500}
																				>
																					PASSWORD
																				</Text>
																				<Input
																					id="profilePostalCode"
																					name="profilePostalCode"
																					onChange=""
																					value={
																						searchEventsResult.account
																							.accountPassword
																					}
																					fontSize={20}
																					fontWeight={400}
																					bg="yellow.100"
																				/>
																			</Box>
																		</HStack>
																	</Box>
																</GridItem>
																{/* Ticket */}
																<GridItem
																	color="white"
																	textAlign={"center"}
																	colSpan={{
																		base: "12",
																		sm: "12",
																		md: "12",
																		lg: "12",
																		xl: "12",
																		"2xl": "12",
																	}}
																>
																	<Text
																		color="gray.600"
																		fontSize={16}
																		fontWeight={700}
																	>
																		Tickets
																	</Text>
																	<Box
																		p="2"
																		textAlign={["left"]}
																		w="100%"
																		h="750px"
																		minH="100px"
																		color="black"
																		bg="#F5F5F5"
																		borderRadius="xl"
																		border="1px"
																		borderColor="gray.300"
																		borderStyle={"solid"}
																		overflowX={"scroll"}
																	>
																		<VStack>
																			{/*
																			<TicketUploadForm
																				updateTicketPics={updateTicketPics}
																				deleteTicketPics={deleteTicketPics}
																				ticketPics={ticketPics}
																			/>
																			*/}

																			<input
																				type="file"
																				id="file-upload"
																				style={{ display: "none" }}
																				accept=".pdf, .png, .jpeg, .jpg"
																				onChange={handleFileChange}
																			/>
																			<label htmlFor="file-upload">
																				<IconButton
																					as="span"
																					aria-label="File Upload (.pdf, .png, .jpeg, .jpg)"
																					isRound={true}
																					variant="solid"
																					color="red.400"
																					bg="gray.200"
																					fontSize="30px"
																					icon={<FiUpload />}
																					_hover={{
																						bg: "red.400",
																						color: "white",
																					}}
																				/>
																			</label>
																			<Center>
																				{searchEventsResult.ticket.map(
																					(
																						searchEventsResult2,
																						ticketIndex
																					) => (
																						<>
																							{/* TICKET n */}
																							<Box
																								key={ticketIndex}
																								p="2"
																								m="1"
																								textAlign={["center"]}
																								color="black"
																								bg="#FFF"
																								borderRadius="lg"
																								border="2px"
																								borderColor="gray.300"
																								borderStyle={"dashed"}
																								_hover={{
																									bg: "gray.50",
																									border: "2px",
																									borderColor: "gray.400",
																									borderStyle: "solid",
																								}}
																							>
																								<VStack>
																									<Box
																										p="1"
																										w="240px"
																										h="320px"
																										textAlign="center"
																										color="black"
																										bg="gray.50"
																										borderRadius="sm"
																										border="1px"
																										borderColor="gray.300"
																										borderStyle="solid"
																										display="flex"
																										alignItems="center"
																										justifyContent="center"
																										_hover={{
																											bg: "white",
																											border: "1px",
																											borderColor: "gray.400",
																											borderStyle: "solid",
																										}}
																									>
																										<IconButton
																											aria-label="Upload"
																											icon={<FiUpload />}
																										/>
																									</Box>
																									<Box>
																										<Text
																											color="gray.400"
																											fontSize={12}
																											fontWeight={500}
																										>
																											CATEGORY
																										</Text>
																										<Select
																											id="profileSexe"
																											name="profileSexe"
																											placeholder=" "
																											onChange=""
																											fontSize={18}
																											fontWeight={600}
																											bg="yellow.100"
																										>
																											<option value="category1">
																												Category 1 - €120.10
																											</option>
																											<option value="category2">
																												Category 2 - €105.30
																											</option>
																											<option value="category3">
																												Category 3 - €85.20
																											</option>
																										</Select>
																									</Box>
																									<HStack>
																										<Box mr="1">
																											<Text
																												color="gray.400"
																												fontSize={12}
																												fontWeight={500}
																											>
																												BLOC
																											</Text>
																											<Select
																												id="profileSexe"
																												name="profileSexe"
																												placeholder=" "
																												onChange=""
																												fontSize={18}
																												fontWeight={400}
																												bg="yellow.100"
																											>
																												<option value="category1">
																													A
																												</option>
																												<option value="category2">
																													B
																												</option>
																												<option value="category3">
																													C
																												</option>
																											</Select>
																										</Box>
																										<Box mr="1">
																											<Text
																												color="gray.400"
																												fontSize={12}
																												fontWeight={500}
																											>
																												RANG
																											</Text>
																											<Input
																												id="profilePostalCode"
																												name="profilePostalCode"
																												onChange=""
																												width={"60px"}
																												fontSize={20}
																												fontWeight={400}
																												bg="yellow.100"
																											/>
																										</Box>
																										<Box>
																											<Text
																												color="gray.400"
																												fontSize={12}
																												fontWeight={500}
																											>
																												SEAT
																											</Text>
																											<Input
																												id="profilePostalCode"
																												name="profilePostalCode"
																												onChange=""
																												width={"70px"}
																												fontSize={20}
																												fontWeight={700}
																												borderColor={"gray.700"}
																												bg="yellow.100"
																											/>
																										</Box>
																									</HStack>
																									<Box>
																										<Text
																											color="gray.400"
																											fontSize={12}
																											fontWeight={500}
																										>
																											GENCODE
																										</Text>
																										<Input
																											id="profilePostalCode"
																											name="profilePostalCode"
																											textAlign={"Center"}
																											onChange=""
																											width={"100%"}
																											fontSize={20}
																											fontWeight={400}
																											bg="yellow.100"
																										/>

																										<Text
																											mt="1"
																											color="gray.400"
																											fontSize={12}
																											fontWeight={500}
																										>
																											FILE
																										</Text>
																										<Text
																											color="gray.600"
																											fontSize={15}
																											fontWeight={400}
																										>
																											<Icon
																												as={BsFilePdf}
																												w={4}
																												h={4}
																											/>{" "}
																											ticket_4897513654.pdf
																										</Text>
																									</Box>
																									<IconButton
																										isRound={true}
																										variant="solid"
																										color="red.400"
																										bg="gray.200"
																										aria-label="Done"
																										fontSize="20px"
																										w="20px"
																										h="20px"
																										icon={<FiMinus />}
																										_hover={{
																											bg: "red.400",
																											color: "white",
																										}}
																										onClick={() =>
																											handleDeleteTicket(index)
																										}
																									/>
																								</VStack>
																							</Box>
																						</>
																					)
																				)}
																			</Center>
																			<IconButton
																				isRound={true}
																				variant="solid"
																				color="red.400"
																				bg="gray.200"
																				aria-label="Done"
																				fontSize="30px"
																				icon={<FiPlus />}
																				_hover={{
																					bg: "red.400",
																					color: "white",
																				}}
																				onClick={handleAddTicket}
																			/>
																		</VStack>
																	</Box>
																</GridItem>
															</Grid>
														</Box>
														{/* Debug Box */}
														<Box
															mt="5"
															p="1"
															color="blue.600"
															bg="gray.100"
															borderRadius="xl"
															borderWidth="1px"
															borderColor="gray.400"
														>
															<Text mb="1" fontSize={18} fontWeight={700}>
																DEBUG BOX
															</Text>
															<Text fontSize={15} fontWeight={600}>
																<strong>dataTmp = </strong>
																{JSON.stringify(dataTmp)}
																<br />
															</Text>
														</Box>
													</DrawerBody>
												</DrawerContent>
											</Drawer>
										))
									) : (
										<>B</>
									)}

									<Box
										p="2"
										textAlign={["left"]}
										w="100%"
										minH="100px"
										color="black"
										bg="#F5F5F5"
										borderRadius="xl"
										border="1px"
										borderColor="gray.300"
										borderStyle={"solid"}
									>
										<VStack>
											{/* ENTRY 0 */}
											<Box
												p="1"
												textAlign={["left"]}
												w="100%"
												color="black"
												bg="#FFF"
												borderRadius="lg"
												border="1px"
												borderColor="gray.300"
												borderStyle={"solid"}
												_hover={{
													bg: "gray.50",
													border: "2px",
													borderColor: "gray.400",
													borderStyle: "solid",
												}}
											>
												<Flex spacing="30px">
													<Box p="1" mr="5" w="100px">
														<Text
															color="gray.400"
															fontSize={10}
															fontWeight={500}
														>
															ENTRY
														</Text>
														<Text fontSize={17} fontWeight={700}>
															#0012
														</Text>
														<Text fontSize={12} fontWeight={500}>
															2023.01.15, 19:00
														</Text>
														<Tag fontSize={10} fontWeight={500}>
															PRESALE
														</Tag>
													</Box>
													<Box p="1" mr="5" w="250px">
														<Text
															color="gray.400"
															fontSize={10}
															fontWeight={500}
														>
															EVENT
														</Text>
														<Text fontSize={18} fontWeight={700}></Text>
														<Text fontSize={12} fontWeight={500}></Text>
														<Text
															color="gray.600"
															fontSize={12}
															fontWeight={500}
														></Text>
													</Box>
													<Spacer />
													<Box p="1" mr="3">
														<Text
															color="gray.400"
															fontSize={10}
															fontWeight={500}
														>
															TICKET
														</Text>
														<Text fontSize={17} fontWeight={700}>
															<Icon as={TriangleUpIcon} color="green.400" /> 4x
														</Text>
														<Text fontSize={15} fontWeight={500}>
															CATEGORY 1
														</Text>
														<Text
															color="gray.600"
															fontSize={12}
															fontWeight={500}
														>
															100.20 € /ea.
														</Text>
													</Box>
													<Box p="1" mr="5" w="200px">
														<Text
															color="gray.400"
															fontSize={10}
															fontWeight={500}
														>
															PLACEMENT
														</Text>
														<Text
															color="gray.600"
															fontSize={12}
															fontWeight={500}
														>
															<Icon as={BsFilePdf} w={4} h={4} /> Lower Tier A,
															Row 14, Seat 10
														</Text>
														<Text
															color="gray.600"
															fontSize={12}
															fontWeight={500}
														>
															<Icon as={BsFilePdf} w={4} h={4} /> Lower Tier A,
															Row 14, Seat 11
														</Text>
														<Text
															color="gray.600"
															fontSize={12}
															fontWeight={500}
														>
															<Icon as={BsFilePdf} w={4} h={4} /> Lower Tier A,
															Row 14, Seat 12
														</Text>
														<Text
															color="gray.600"
															fontSize={12}
															fontWeight={500}
														>
															<Icon as={BsFilePdf} w={4} h={4} /> Lower Tier A,
															Row 14, Seat 13
														</Text>
													</Box>
													<Spacer />
													<Box p="1" mr="5">
														<Text
															color="gray.400"
															fontSize={10}
															fontWeight={500}
														>
															ORDER
														</Text>
														<Text fontSize={15} fontWeight={700}>
															#1892154
														</Text>
														<Text
															color="gray.600"
															fontSize={12}
															fontWeight={500}
														>
															400.80 €
														</Text>
														<Text
															color="gray.600"
															fontSize={10}
															fontWeight={500}
														>
															CB #654
														</Text>
													</Box>
													<Box p="1" mr="3">
														<Text
															color="gray.400"
															fontSize={10}
															fontWeight={500}
														>
															ACCOUNT
														</Text>
														<Text fontSize={15} fontWeight={700}>
															Ticketmaster.fr
														</Text>
														<Text
															color="gray.600"
															fontSize={12}
															fontWeight={500}
														>
															john.doe@gmail.com
														</Text>
													</Box>
													<Spacer />
													<Box p="1" mr="5">
														<Text
															color="gray.400"
															fontSize={10}
															fontWeight={500}
														>
															GET TICKET
														</Text>
														<Icon
															as={BsFiletypePdf}
															mt="1"
															w={10}
															h={10}
															color="green.400"
														/>
													</Box>
													<Box p="1" mr="5">
														<Text
															color="gray.400"
															fontSize={10}
															fontWeight={500}
														>
															GET INVOICE
														</Text>
														<Icon
															as={BsFiletypePdf}
															mt="1"
															w={10}
															h={10}
															color="red.400"
														/>
													</Box>
												</Flex>
											</Box>

											{/* ENTRY 1 */}
											<Box
												p="1"
												textAlign={["left"]}
												w="100%"
												color="black"
												bg="#FFF"
												borderRadius="lg"
												border="1px"
												borderColor="gray.300"
												borderStyle={"solid"}
												_hover={{
													bg: "gray.50",
													border: "2px",
													borderColor: "gray.400",
													borderStyle: "solid",
												}}
											>
												<Flex spacing="30px">
													<Box p="1" mr="5" w="100px">
														<Text
															color="gray.400"
															fontSize={10}
															fontWeight={500}
														>
															ENTRY
														</Text>
														<Text fontSize={17} fontWeight={700}>
															#0012
														</Text>
														<Text fontSize={12} fontWeight={500}>
															2023.01.15, 19:00
														</Text>
														<Tag fontSize={10} fontWeight={500}>
															PRESALE
														</Tag>
													</Box>
													<Box p="1" mr="5" w="250px">
														<Text
															color="gray.400"
															fontSize={10}
															fontWeight={500}
														>
															EVENT
														</Text>
														<Text fontSize={18} fontWeight={700}>
															COLDPLAY
														</Text>
														<Text fontSize={12} fontWeight={500}>
															2023.01.15, 19:00
														</Text>
														<Text
															color="gray.600"
															fontSize={12}
															fontWeight={500}
														>
															France, Saint-denis, Stade de France
														</Text>
													</Box>
													<Spacer />
													<Box p="1" mr="3">
														<Text
															color="gray.400"
															fontSize={10}
															fontWeight={500}
														>
															TICKET
														</Text>
														<Text fontSize={17} fontWeight={700}>
															<Icon as={TriangleUpIcon} color="green.400" /> 4x
														</Text>
														<Text fontSize={15} fontWeight={500}>
															CATEGORY 1
														</Text>
														<Text
															color="gray.600"
															fontSize={12}
															fontWeight={500}
														>
															100.20 € /ea.
														</Text>
													</Box>
													<Box p="1" mr="5" w="200px">
														<Text
															color="gray.400"
															fontSize={10}
															fontWeight={500}
														>
															PLACEMENT
														</Text>
														<Text
															color="gray.600"
															fontSize={12}
															fontWeight={500}
														>
															<Icon as={BsFilePdf} w={4} h={4} /> Lower Tier A,
															Row 14, Seat 10
														</Text>
														<Text
															color="gray.600"
															fontSize={12}
															fontWeight={500}
														>
															<Icon as={BsFilePdf} w={4} h={4} /> Lower Tier A,
															Row 14, Seat 11
														</Text>
														<Text
															color="gray.600"
															fontSize={12}
															fontWeight={500}
														>
															<Icon as={BsFilePdf} w={4} h={4} /> Lower Tier A,
															Row 14, Seat 12
														</Text>
														<Text
															color="gray.600"
															fontSize={12}
															fontWeight={500}
														>
															<Icon as={BsFilePdf} w={4} h={4} /> Lower Tier A,
															Row 14, Seat 13
														</Text>
													</Box>
													<Spacer />
													<Box p="1" mr="5">
														<Text
															color="gray.400"
															fontSize={10}
															fontWeight={500}
														>
															ORDER
														</Text>
														<Text fontSize={15} fontWeight={700}>
															#1892154
														</Text>
														<Text
															color="gray.600"
															fontSize={12}
															fontWeight={500}
														>
															400.80 €
														</Text>
														<Text
															color="gray.600"
															fontSize={10}
															fontWeight={500}
														>
															CB #654
														</Text>
													</Box>
													<Box p="1" mr="3">
														<Text
															color="gray.400"
															fontSize={10}
															fontWeight={500}
														>
															ACCOUNT
														</Text>
														<Text fontSize={15} fontWeight={700}>
															Ticketmaster.fr
														</Text>
														<Text
															color="gray.600"
															fontSize={12}
															fontWeight={500}
														>
															john.doe@gmail.com
														</Text>
													</Box>
													<Spacer />
													<Box p="1" mr="5">
														<Text
															color="gray.400"
															fontSize={10}
															fontWeight={500}
														>
															GET TICKET
														</Text>
														<Icon
															as={BsFiletypePdf}
															mt="1"
															w={10}
															h={10}
															color="green.400"
														/>
													</Box>
													<Box p="1" mr="5">
														<Text
															color="gray.400"
															fontSize={10}
															fontWeight={500}
														>
															GET INVOICE
														</Text>
														<Icon
															as={BsFiletypePdf}
															mt="1"
															w={10}
															h={10}
															color="red.400"
														/>
													</Box>
												</Flex>
											</Box>

											{/* ENTRY 2 */}
											<Box
												p="1"
												textAlign={["left"]}
												w="100%"
												color="black"
												bg="#FFF"
												borderRadius="lg"
												border="1px"
												borderColor="gray.300"
												borderStyle={"solid"}
												_hover={{
													bg: "gray.50",
													border: "2px",
													borderColor: "gray.400",
													borderStyle: "solid",
												}}
											>
												<Flex spacing="30px">
													<Box p="1" mr="5" w="100px">
														<Text
															color="gray.400"
															fontSize={10}
															fontWeight={500}
														>
															ENTRY
														</Text>
														<Text fontSize={17} fontWeight={700}>
															#0011
														</Text>
														<Text fontSize={12} fontWeight={500}>
															2023.01.15, 19:00
														</Text>
														<Tag fontSize={10} fontWeight={500}>
															PRESALE
														</Tag>
													</Box>
													<Box p="1" mr="5" w="250px">
														<Text
															color="gray.400"
															fontSize={10}
															fontWeight={500}
														>
															EVENT
														</Text>
														<Text fontSize={18} fontWeight={700}>
															COLDPLAY
														</Text>
														<Text fontSize={12} fontWeight={500}>
															2023.01.15, 19:00
														</Text>
														<Text
															color="gray.600"
															fontSize={12}
															fontWeight={500}
														>
															France, Saint-denis, Stade de France
														</Text>
													</Box>
													<Spacer />
													<Box p="1" mr="3">
														<Text
															color="gray.400"
															fontSize={10}
															fontWeight={500}
														>
															TICKET
														</Text>
														<Text fontSize={17} fontWeight={700}>
															<Icon as={TriangleUpIcon} color="green.400" /> 2x
														</Text>
														<Text fontSize={15} fontWeight={500}>
															CATEGORY 1
														</Text>
														<Text
															color="gray.600"
															fontSize={12}
															fontWeight={500}
														>
															100.20 € /ea.
														</Text>
													</Box>
													<Box p="1" mr="5" w="200px">
														<Text
															color="gray.400"
															fontSize={10}
															fontWeight={500}
														>
															PLACEMENT
														</Text>
														<Text
															color="gray.600"
															fontSize={12}
															fontWeight={500}
														>
															<Icon as={BsFilePdf} w={4} h={4} /> Lower Tier A,
															Row 14, Seat 10
														</Text>
														<Text
															color="gray.600"
															fontSize={12}
															fontWeight={500}
														>
															<Icon as={BsFilePdf} w={4} h={4} /> Lower Tier A,
															Row 14, Seat 11
														</Text>
													</Box>
													<Spacer />
													<Box p="1" mr="5">
														<Text
															color="gray.400"
															fontSize={10}
															fontWeight={500}
														>
															ORDER
														</Text>
														<Text fontSize={15} fontWeight={700}>
															#1892154
														</Text>
														<Text
															color="gray.600"
															fontSize={12}
															fontWeight={500}
														>
															400.80 €
														</Text>
														<Text
															color="gray.600"
															fontSize={10}
															fontWeight={500}
														>
															CB #654
														</Text>
													</Box>
													<Box p="1" mr="3">
														<Text
															color="gray.400"
															fontSize={10}
															fontWeight={500}
														>
															ACCOUNT
														</Text>
														<Text fontSize={15} fontWeight={700}>
															Ticketmaster.fr
														</Text>
														<Text
															color="gray.600"
															fontSize={12}
															fontWeight={500}
														>
															john.doe@gmail.com
														</Text>
													</Box>
													<Spacer />
													<Box p="1" mr="5">
														<Text
															color="gray.400"
															fontSize={10}
															fontWeight={500}
														>
															GET TICKET
														</Text>
														<Icon
															as={BsFiletypePdf}
															mt="1"
															w={10}
															h={10}
															color="green.400"
														/>
													</Box>
													<Box p="1" mr="5">
														<Text
															color="gray.400"
															fontSize={10}
															fontWeight={500}
														>
															GET INVOICE
														</Text>
														<Icon
															as={BsFiletypePdf}
															mt="1"
															w={10}
															h={10}
															color="green.400"
														/>
													</Box>
												</Flex>
											</Box>
										</VStack>
									</Box>
								</GridItem>

								{/* LAST ACCOUNT CRAWLED */}
								<GridItem
									color="black"
									p="2"
									colSpan={{
										base: "12",
										sm: "12",
										md: "12",
										lg: "12",
										xl: "7",
										"2xl": "3",
									}}
								>
									<Box p="2" textAlign={["left"]} w="100%" color="black">
										<Text fontSize={25} fontWeight={800}>
											{T.tixy.LastAccountCrawled}
										</Text>
									</Box>
									<Box
										p="2"
										textAlign={["left"]}
										w="100%"
										minH="100px"
										color="black"
										bg="#F5F5F5"
										borderRadius="xl"
										border="1px"
										borderColor="gray.300"
										borderStyle={"solid"}
									>
										<TableContainer>
											<Table size="sm">
												<Thead>
													<Tr>
														<Th>SUPPORT</Th>
														<Th>ACCOUNT</Th>
														<Th>
															OrderID <Icon as={ArrowForwardIcon} /> StockID
														</Th>
														<Th isNumeric>LAST CRAWL</Th>
													</Tr>
												</Thead>
												<Tbody>
													<Tr
														_hover={{
															bg: "gray.100",
															border: "2px",
															borderColor: "gray.400",
															borderStyle: "solid",
														}}
													>
														<Td>Ticketmaster.fr</Td>
														<Td>john.doe@gmail.com</Td>
														<Td>
															<Text fontSize={15} fontWeight={400}>
																#1892154{" "}
																<Icon as={ArrowForwardIcon} color="gray.500" />{" "}
																#5443
															</Text>
															<Text fontSize={15} fontWeight={400}>
																#1892436{" "}
																<Icon as={ArrowForwardIcon} color="gray.500" />{" "}
																#5444
															</Text>
														</Td>
														<Td isNumeric>2023.01.15, 19:20</Td>
													</Tr>
													<Tr
														_hover={{
															bg: "gray.100",
															border: "2px",
															borderColor: "gray.400",
															borderStyle: "solid",
														}}
													>
														<Td>Ticketmaster.fr</Td>
														<Td>john.doe@gmail.com</Td>
														<Td>
															<Text fontSize={15} fontWeight={400}>
																#1892154{" "}
																<Icon as={ArrowForwardIcon} color="gray.500" />{" "}
																#5443
															</Text>
															<Text fontSize={15} fontWeight={400}>
																#1892436{" "}
																<Icon as={ArrowForwardIcon} color="gray.500" />{" "}
																#5444
															</Text>
														</Td>
														<Td isNumeric>2023.01.15, 19:20</Td>
													</Tr>
													<Tr
														_hover={{
															bg: "gray.100",
															border: "2px",
															borderColor: "gray.400",
															borderStyle: "solid",
														}}
													>
														<Td>Ticketmaster.fr</Td>
														<Td>john.doe@gmail.com</Td>
														<Td>
															<Text fontSize={15} fontWeight={400}>
																#1892154{" "}
																<Icon as={ArrowForwardIcon} color="gray.500" />{" "}
																#5443
															</Text>
															<Text fontSize={15} fontWeight={400}>
																#1892436{" "}
																<Icon as={ArrowForwardIcon} color="gray.500" />{" "}
																#5444
															</Text>
														</Td>
														<Td isNumeric>2023.01.15, 19:20</Td>
													</Tr>
												</Tbody>
											</Table>
										</TableContainer>
									</Box>
								</GridItem>

								{/* NEXT ACCOUNT CRAWLED */}
								<GridItem
									color="black"
									p="2"
									colSpan={{
										base: "12",
										sm: "12",
										md: "12",
										lg: "12",
										xl: "5",
										"2xl": "2",
									}}
								>
									<HStack>
										<Box p="2" textAlign={["left"]} w="100%" color="black">
											<Text fontSize={25} fontWeight={800}>
												{T.tixy.NextAccountCrawled}
											</Text>
										</Box>
										<Link to="/dash/carts/new">
											<Button
												size="sm"
												leftIcon={<AddIcon />}
												colorScheme="red"
												variant="solid"
											>
												<Text
													color="white"
													display={{ base: "none", sm: "flex" }}
													fontSize={{
														base: "18",
														sm: "18",
														md: "18",
														lg: "18",
														xl: "18",
														"2xl": "18",
													}}
													fontWeight={500}
												>
													{T.common.Add}
												</Text>
											</Button>
										</Link>
									</HStack>
									<Box
										p="2"
										textAlign={["left"]}
										w="100%"
										minH="100px"
										color="black"
										bg="#F5F5F5"
										borderRadius="xl"
										border="1px"
										borderColor="gray.300"
										borderStyle={"solid"}
									>
										<TableContainer>
											<Table size="sm">
												<Thead>
													<Tr>
														<Th>SUPPORT</Th>
														<Th>ACCOUNT</Th>
														<Th isNumeric>STATE</Th>
													</Tr>
												</Thead>
												<Tbody>
													<Tr
														_hover={{
															bg: "gray.100",
															border: "2px",
															borderColor: "gray.400",
															borderStyle: "solid",
														}}
													>
														<Td>Ticketmaster.fr</Td>
														<Td>john.doe@gmail.com</Td>
														<Td isNumeric>waiting...</Td>
													</Tr>
													<Tr
														_hover={{
															bg: "gray.100",
															border: "2px",
															borderColor: "gray.400",
															borderStyle: "solid",
														}}
													>
														<Td>Ticketmaster.fr</Td>
														<Td>john.doe@gmail.com</Td>
														<Td isNumeric>waiting...</Td>
													</Tr>
													<Tr
														_hover={{
															bg: "gray.100",
															border: "2px",
															borderColor: "gray.400",
															borderStyle: "solid",
														}}
													>
														<Td>Ticketmaster.fr</Td>
														<Td>john.doe@gmail.com</Td>
														<Td isNumeric>waiting...</Td>
													</Tr>
												</Tbody>
											</Table>
										</TableContainer>
									</Box>
								</GridItem>
							</Grid>
						</Box>
					</GridItem>
				</Grid>
			</Center>
		</>
	);

	return content;
};
export default StockEntry;
