import { useState, useEffect } from "react"
import { useGetInventoryWaitQuery, useUpdateCartWaitMutation } from "./cartsApiSlice"
import Cart from "./Cart"
import { Link } from 'react-router-dom'
import useAuth from "../../hooks/useAuth"
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'
import useTitle from "../../hooks/useTitle"
import PulseLoader from 'react-spinners/PulseLoader'

// import `ChakraProvider` component
import { Box, Grid, GridItem, Center, HStack, VStack, Container, Flex, SimpleGrid, FormControl, FormLabel, Textarea, Checkbox, Input, Switch, Badge, Tag, TagLabel, TagCloseButton, Avatar, Toast, Icon, IconButton, Image, Stack, Alert, AlertIcon, Select, Button, useRadioGroup, useRadio, RadioGroup, Radio, Text, useDisclosure, useToast, Spacer } from '@chakra-ui/react'
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer, } from '@chakra-ui/react'

// import `ChakraProvider` icon
import { AddIcon } from '@chakra-ui/icons'

import { Bs0CircleFill, Bs1CircleFill, Bs2CircleFill, BsBoxFill, BsFilePdf, BsGridFill, BsBarChartLineFill } from "react-icons/bs";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationCrosshairs, faSnowman, faList } from "@fortawesome/free-solid-svg-icons"

const InventoryList = () => {
    useTitle('BROKY: Inventory Inventory')

    const { username, isManager, isAdmin } = useAuth()

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    let menuContent
    if (isManager || isAdmin) {
        menuContent = (
            <>
                <Link to="/dash/carts/new">
                    <Button
                        size='sm'
                        leftIcon={<AddIcon />}
                        colorScheme='red'
                        variant='solid'>
                        <Text
                            color='white'
                            display={{ base: 'none', sm: 'flex' }}
                            fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }}
                            fontWeight={600}
                        >
                            {T.common.Add}
                        </Text>
                    </Button>
                </Link>
            </>
        )
    }

    const [hoveredRow, setHoveredRow] = useState(null);

    const handleRowMouseEnter = (productId) => {
        setHoveredRow(productId);
    };

    const handleRowMouseLeave = () => {
        setHoveredRow(null);
    };

    let content
    content = (
        <>
            <Center bg='#45413C' axis='both'>
                <Grid w='95vw' minH='100vh' maxW='95vw' templateColumns='repeat(12, 1fr)'>
                    <GridItem colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>

                        <Grid p='5' gap='5' templateColumns='repeat(12, 1fr)'>
                            <GridItem colSpan={{ base: "6", sm: "6", md: "6", lg: "6", xl: "6", "2xl": "6" }} textAlign={['left']}>
                                <Text color={'white'} fontSize={{ base: "24", sm: "24", md: "24", lg: "24", xl: "28" }} fontWeight={600}><FontAwesomeIcon icon={faLocationCrosshairs} /> {T.tixy.Tixy} : {T.tixy.posMarket}</Text>
                            </GridItem>
                            <GridItem colSpan={{ base: "6", sm: "6", md: "6", lg: "6", xl: "6", "2xl": "6" }} textAlign={['right']}>
                                {menuContent}
                            </GridItem>
                        </Grid>

                        <Box borderRadius='xl' mb='2' p={2} textAlign={['center']} bg='white' _hover={{ bg: 'gray.200', border: '1px', borderRadius: 'xl', borderColor: 'gray.400', borderStyle: 'solid' }}>
                            <Grid gap='2' w='100%' maxW='100vw' templateColumns='repeat(12, 1fr)'>

                                {/* Head */}
                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "2" }}>
                                    <Box p='2' textAlign={['left']} w='100%' minH='100px' color='black'>
                                        <Text fontSize={25} fontWeight={800}>Coldplay</Text>
                                        <Text color='gray.600' fontSize={22} fontWeight={600}>2024.06.12, 19:00</Text>
                                        <Text color='gray.600' fontSize={18} fontWeight={500}>France, Saint-Denis, Stade de France</Text>
                                    </Box>

                                    {/* Button NAV */}
                                    <SimpleGrid columns={4} spacing={2}>

                                        {/* Go DISPATCHER */}
                                        <Link to="/dash/tixy/dispatcher">
                                            <Box p='2' textAlign={['center']} color='black' bg='gray.50' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                <Text color='gray.600' fontSize={14} fontWeight={600}>DISPATCHER</Text>
                                                <IconButton
                                                    isRound={true}
                                                    variant='solid'
                                                    bg='gray.300'
                                                    color='gray.700'
                                                    aria-label='Add'
                                                    fontSize='15px'
                                                    icon={<BsGridFill />}
                                                />
                                            </Box>
                                        </Link>

                                        {/* Go INVENTORY */}
                                        <Link to="/dash/tixy/inventory">
                                            <Box p='2' textAlign={['center']} color='black' bg='gray.50' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                <Text color='gray.600' fontSize={14} fontWeight={600}>INVENTORY</Text>
                                                <IconButton
                                                    isRound={true}
                                                    variant='solid'
                                                    bg='gray.300'
                                                    color='gray.700'
                                                    aria-label='Add'
                                                    fontSize='15px'
                                                    icon={<BsBoxFill />}
                                                />
                                            </Box>
                                        </Link>

                                        {/* Go 1ST MARKET */}
                                        <Link to="/dash/tixy/firstMarket">
                                            <Box p='2' textAlign={['center']} color='black' bg='gray.50' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                <Text color='gray.600' fontSize={14} fontWeight={600}>1ST MARKET</Text>
                                                <IconButton
                                                    isRound={true}
                                                    variant='solid'
                                                    bg='gray.300'
                                                    color='gray.700'
                                                    aria-label='Add'
                                                    fontSize='15px'
                                                    icon={<Bs1CircleFill />}
                                                />
                                            </Box>
                                        </Link>

                                        {/* Go 2ND MARKET */}
                                        <Link to="/dash/tixy/secondMarket">
                                            <Box p='2' textAlign={['center']} color='black' bg='gray.50' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }} cursor={'pointer'}>
                                                <Text color='gray.600' fontSize={14} fontWeight={600}>2ND MARKET</Text>
                                                <IconButton
                                                    isRound={true}
                                                    variant='solid'
                                                    bg='gray.300'
                                                    color='gray.700'
                                                    aria-label='Add'
                                                    fontSize='15px'
                                                    icon={<Bs2CircleFill />}
                                                />
                                            </Box>
                                        </Link>

                                    </SimpleGrid>

                                </GridItem>

                                {/* Availability */}
                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "5" }}>
                                    <Box p='2' textAlign={['center']} w='100%' minH='100px' color='black' bg='gray.50' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }}>
                                        <Grid gap='2' w='100%' maxW='100vw' templateColumns='repeat(12, 1fr)'>
                                            <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                <Box p='0' color='white' bg='gray.400' borderRadius='lg'>
                                                    <Text fontSize={16} fontWeight={700}>AVAILABILITY / PRICE</Text>
                                                </Box>
                                            </GridItem>
                                            <GridItem h='500px' bg='white' color='black' borderRadius='xl' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                GRAPH
                                            </GridItem>
                                            <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                <Flex spacing='10px' color='gray.600'>
                                                    <Box minW='120px' p='1' mr='2' bg='green.50' borderRadius='xl'>
                                                        <Text fontSize={16} fontWeight={700}>CARRE.OR</Text>
                                                        <Checkbox bg='white'></Checkbox>
                                                        <Text mt='-3' color='green.400' fontSize={30} fontWeight={400}>22</Text>
                                                    </Box>
                                                    <Box minW='120px' p='1' mr='2' bg='red.50' borderRadius='xl'>
                                                        <Text fontSize={16} fontWeight={700}>CATEGORY 1</Text>
                                                        <Checkbox bg='white'></Checkbox>
                                                        <Text mt='-3' color='red.400' fontSize={30} fontWeight={400}>-4</Text>
                                                    </Box>
                                                    <Box minW='120px' p='1' mr='2' bg='green.50' borderRadius='xl'>
                                                        <Text fontSize={16} fontWeight={700}>CATEGORY 2</Text>
                                                        <Checkbox bg='white'></Checkbox>
                                                        <Text mt='-3' color='green.400' fontSize={30} fontWeight={400}>10</Text>
                                                    </Box>
                                                    <Box minW='120px' p='1' mr='2' bg='green.50' borderRadius='xl'>
                                                        <Text fontSize={16} fontWeight={700}>CATEGORY 3</Text>
                                                        <Checkbox bg='white'></Checkbox>
                                                        <Text mt='-3' color='green.400' fontSize={30} fontWeight={400}>18</Text>
                                                    </Box>
                                                    <Box minW='120px' p='1' mr='2' bg='red.50' borderRadius='xl'>
                                                        <Text fontSize={16} fontWeight={700}>FOSSE OR</Text>
                                                        <Checkbox bg='white'></Checkbox>
                                                        <Text mt='-3' color='red.400' fontSize={30} fontWeight={400}>-8</Text>
                                                    </Box>
                                                    <Box minW='120px' p='1' mr='2' bg='green.50' borderRadius='xl'>
                                                        <Text fontSize={16} fontWeight={700}>FOSSE</Text>
                                                        <Checkbox bg='white'></Checkbox>
                                                        <Text mt='-3' color='green.400' fontSize={30} fontWeight={400}>6</Text>
                                                    </Box>
                                                </Flex>
                                            </GridItem>

                                            <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                <VStack>
                                                    <TableContainer w='100%'>
                                                        <Table size='sm' variant='simple' bg='#FFF' borderRadius='lg' border='1px' borderColor='gray.300' borderStyle={'solid'} >
                                                            <Tbody>
                                                                <Tr bg='gray.200' color='gray.600'>
                                                                    <Td w='30px'><Text fontSize={13} fontWeight={700}>VAR.</Text></Td>
                                                                    <Td w='25%'><Text fontSize={13} fontWeight={700}>PLACEMENT</Text></Td>
                                                                    <Td><Text fontSize={13} fontWeight={700}>UNIT PRICE</Text></Td>
                                                                    <Td><Text fontSize={13} fontWeight={700}>DATE</Text></Td>
                                                                    <Td><Text fontSize={13} fontWeight={700}>SUPPORT</Text></Td>
                                                                </Tr>
                                                                <Tr
                                                                    key='1'
                                                                    onMouseEnter={() => handleRowMouseEnter(1)}
                                                                    onMouseLeave={handleRowMouseLeave}
                                                                    style={{
                                                                        background: hoveredRow === 1 ? '#F7FAFC' : 'transparent',
                                                                        border: hoveredRow === 1 ? '2px solid #A0AEC0' : '0px',
                                                                    }}
                                                                >
                                                                    <Td bg='green.50' textAlign={['center']}>
                                                                        <Text color='green.400' fontSize={20} fontWeight={700}>+2</Text>
                                                                    </Td>
                                                                    <Td>
                                                                        <Tag pl='5' pr='5' fontSize={20} fontWeight={600} bg='gray.50' border='1px' borderColor='gray.300' borderStyle={'solid'}>Catégory 1</Tag>
                                                                    </Td>
                                                                    <Td>
                                                                        <Text fontSize={18} fontWeight={400}>€ 100.50</Text>
                                                                    </Td>
                                                                    <Td>
                                                                        <Text fontSize={18} fontWeight={400}>2023.10.22, 19:22</Text>
                                                                    </Td>
                                                                    <Td>
                                                                        <Text fontSize={18} fontWeight={400}>Viagogo.com</Text>
                                                                    </Td>
                                                                </Tr>
                                                                <Tr
                                                                    key='2'
                                                                    onMouseEnter={() => handleRowMouseEnter(2)}
                                                                    onMouseLeave={handleRowMouseLeave}
                                                                    style={{
                                                                        background: hoveredRow === 2 ? '#F7FAFC' : 'transparent',
                                                                        border: hoveredRow === 2 ? '2px solid #A0AEC0' : '0px',
                                                                    }}
                                                                >
                                                                    <Td bg='red.50' textAlign={['center']}>
                                                                        <Text color='red.400' fontSize={20} fontWeight={700}>-2</Text>
                                                                    </Td>
                                                                    <Td>
                                                                        <Tag pl='5' pr='5' fontSize={20} fontWeight={600} bg='gray.50' border='1px' borderColor='gray.300' borderStyle={'solid'}>Carre OR</Tag>
                                                                    </Td>
                                                                    <Td>
                                                                        <Text fontSize={18} fontWeight={400}>€ 150.50</Text>
                                                                    </Td>
                                                                    <Td>
                                                                        <Text fontSize={18} fontWeight={400}>2023.10.22, 19:22</Text>
                                                                    </Td>
                                                                    <Td>
                                                                        <Text fontSize={18} fontWeight={400}>Viagogo.com</Text>
                                                                    </Td>
                                                                </Tr>
                                                                <Tr
                                                                    key='3'
                                                                    onMouseEnter={() => handleRowMouseEnter(3)}
                                                                    onMouseLeave={handleRowMouseLeave}
                                                                    style={{
                                                                        background: hoveredRow === 3 ? '#F7FAFC' : 'transparent',
                                                                        border: hoveredRow === 3 ? '2px solid #A0AEC0' : '0px',
                                                                    }}
                                                                >
                                                                    <Td bg='green.50' textAlign={['center']}>
                                                                        <Text color='green.400' fontSize={20} fontWeight={700}>+2</Text>
                                                                    </Td>
                                                                    <Td>
                                                                        <Tag pl='5' pr='5' fontSize={20} fontWeight={600} bg='gray.50' border='1px' borderColor='gray.300' borderStyle={'solid'}>Category 1</Tag>
                                                                    </Td>
                                                                    <Td>
                                                                        <Text fontSize={18} fontWeight={400}>€ 100.50</Text>
                                                                    </Td>
                                                                    <Td>
                                                                        <Text fontSize={18} fontWeight={400}>2023.10.22, 19:22</Text>
                                                                    </Td>
                                                                    <Td>
                                                                        <Text fontSize={18} fontWeight={400}>Ticketmaster.fr</Text>
                                                                    </Td>
                                                                </Tr>
                                                                <Tr>
                                                                    <Td bg='green.50' textAlign={['center']}>
                                                                        <Text color='green.400' fontSize={20} fontWeight={700}>+2</Text>
                                                                    </Td>
                                                                    <Td>
                                                                        <Tag pl='5' pr='5' fontSize={20} fontWeight={600} bg='gray.50' border='1px' borderColor='gray.300' borderStyle={'solid'}>Category 1</Tag>
                                                                    </Td>
                                                                    <Td>
                                                                        <Text fontSize={18} fontWeight={400}>€ 100.50</Text>
                                                                    </Td>
                                                                    <Td>
                                                                        <Text fontSize={18} fontWeight={400}>2023.10.22, 19:22</Text>
                                                                    </Td>
                                                                    <Td>
                                                                        <Text fontSize={18} fontWeight={400}>Ticketmaster.fr</Text>
                                                                    </Td>
                                                                </Tr>
                                                            </Tbody>
                                                            <Tfoot>
                                                                <Tr>
                                                                    <Th></Th>
                                                                    <Th></Th>
                                                                    <Th></Th>
                                                                    <Th></Th>
                                                                    <Th></Th>
                                                                </Tr>
                                                            </Tfoot>
                                                        </Table>
                                                    </TableContainer>
                                                </VStack>
                                            </GridItem>
                                        </Grid>
                                    </Box>
                                </GridItem>

                                {/* Sale */}
                                <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "5" }}>
                                    <Box p='2' textAlign={['center']} w='100%' minH='100px' color='black' bg='gray.50' borderRadius='xl' border='1px' borderColor='gray.300' borderStyle={'solid'} _hover={{ bg: 'gray.100', border: '2px', borderColor: 'gray.400', borderStyle: 'solid' }}>
                                        <Grid gap='2' w='100%' maxW='100vw' templateColumns='repeat(12, 1fr)'>
                                            <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                <Box p='0' color='white' bg='gray.400' borderRadius='lg'>
                                                    <Text fontSize={16} fontWeight={700}>SALES</Text>
                                                </Box>
                                            </GridItem>
                                            <GridItem h='500px' bg='white' color='black' borderRadius='xl' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                GRAPH
                                            </GridItem>
                                            <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                <Flex spacing='10px' color='gray.600'>
                                                    <Box minW='120px' p='1' mr='2' bg='green.50' borderRadius='xl'>
                                                        <Text fontSize={16} fontWeight={700}>CARRE.OR</Text>
                                                        <Checkbox bg='white'></Checkbox>
                                                        <Text mt='-3' color='green.400' fontSize={30} fontWeight={400}>22</Text>
                                                    </Box>
                                                    <Box minW='120px' p='1' mr='2' bg='red.50' borderRadius='xl'>
                                                        <Text fontSize={16} fontWeight={700}>CATEGORY 1</Text>
                                                        <Checkbox bg='white'></Checkbox>
                                                        <Text mt='-3' color='red.400' fontSize={30} fontWeight={400}>-4</Text>
                                                    </Box>
                                                    <Box minW='120px' p='1' mr='2' bg='green.50' borderRadius='xl'>
                                                        <Text fontSize={16} fontWeight={700}>CATEGORY 2</Text>
                                                        <Checkbox bg='white'></Checkbox>
                                                        <Text mt='-3' color='green.400' fontSize={30} fontWeight={400}>10</Text>
                                                    </Box>
                                                    <Box minW='120px' p='1' mr='2' bg='green.50' borderRadius='xl'>
                                                        <Text fontSize={16} fontWeight={700}>CATEGORY 3</Text>
                                                        <Checkbox bg='white'></Checkbox>
                                                        <Text mt='-3' color='green.400' fontSize={30} fontWeight={400}>18</Text>
                                                    </Box>
                                                    <Box minW='120px' p='1' mr='2' bg='red.50' borderRadius='xl'>
                                                        <Text fontSize={16} fontWeight={700}>FOSSE OR</Text>
                                                        <Checkbox bg='white'></Checkbox>
                                                        <Text mt='-3' color='red.400' fontSize={30} fontWeight={400}>-8</Text>
                                                    </Box>
                                                    <Box minW='120px' p='1' mr='2' bg='green.50' borderRadius='xl'>
                                                        <Text fontSize={16} fontWeight={700}>FOSSE</Text>
                                                        <Checkbox bg='white'></Checkbox>
                                                        <Text mt='-3' color='green.400' fontSize={30} fontWeight={400}>6</Text>
                                                    </Box>
                                                </Flex>
                                            </GridItem>

                                            <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                <VStack>
                                                    <TableContainer w='100%'>
                                                        <Table size='sm' variant='simple' bg='#FFF' borderRadius='lg' border='1px' borderColor='gray.300' borderStyle={'solid'} >
                                                            <Tbody>
                                                                <Tr bg='gray.200' color='gray.600'>
                                                                    <Td w='30px'><Text fontSize={13} fontWeight={700}>VAR.</Text></Td>
                                                                    <Td w='25%'><Text fontSize={13} fontWeight={700}>PLACEMENT</Text></Td>
                                                                    <Td><Text fontSize={13} fontWeight={700}>SALE PRICE</Text></Td>
                                                                    <Td><Text fontSize={13} fontWeight={700}>DATE</Text></Td>
                                                                    <Td><Text fontSize={13} fontWeight={700}>SUPPORT</Text></Td>
                                                                </Tr>
                                                                <Tr
                                                                    key='1'
                                                                    onMouseEnter={() => handleRowMouseEnter(1)}
                                                                    onMouseLeave={handleRowMouseLeave}
                                                                    style={{
                                                                        background: hoveredRow === 1 ? '#F7FAFC' : 'transparent',
                                                                        border: hoveredRow === 1 ? '2px solid #A0AEC0' : '0px',
                                                                    }}
                                                                >
                                                                    <Td bg='red.50' textAlign={['center']}>
                                                                        <Text color='red.400' fontSize={20} fontWeight={700}>-2</Text>
                                                                    </Td>
                                                                    <Td>
                                                                        <Tag pl='5' pr='5' fontSize={20} fontWeight={600} bg='gray.50' border='1px' borderColor='gray.300' borderStyle={'solid'}>Catégory 1</Tag>
                                                                    </Td>
                                                                    <Td>
                                                                        <Text fontSize={18} fontWeight={400}>€ 100.50</Text>
                                                                    </Td>
                                                                    <Td>
                                                                        <Text fontSize={18} fontWeight={400}>2023.10.22, 19:22</Text>
                                                                    </Td>
                                                                    <Td>
                                                                        <Text fontSize={18} fontWeight={400}>Viagogo.com</Text>
                                                                    </Td>
                                                                </Tr>
                                                                <Tr
                                                                    key='2'
                                                                    onMouseEnter={() => handleRowMouseEnter(2)}
                                                                    onMouseLeave={handleRowMouseLeave}
                                                                    style={{
                                                                        background: hoveredRow === 2 ? '#F7FAFC' : 'transparent',
                                                                        border: hoveredRow === 2 ? '2px solid #A0AEC0' : '0px',
                                                                    }}
                                                                >
                                                                    <Td bg='red.50' textAlign={['center']}>
                                                                        <Text color='red.400' fontSize={20} fontWeight={700}>-2</Text>
                                                                    </Td>
                                                                    <Td>
                                                                        <Tag pl='5' pr='5' fontSize={20} fontWeight={600} bg='gray.50' border='1px' borderColor='gray.300' borderStyle={'solid'}>Carre OR</Tag>
                                                                    </Td>
                                                                    <Td>
                                                                        <Text fontSize={18} fontWeight={400}>€ 150.50</Text>
                                                                    </Td>
                                                                    <Td>
                                                                        <Text fontSize={18} fontWeight={400}>2023.10.22, 19:22</Text>
                                                                    </Td>
                                                                    <Td>
                                                                        <Text fontSize={18} fontWeight={400}>Viagogo.com</Text>
                                                                    </Td>
                                                                </Tr>
                                                                <Tr
                                                                    key='3'
                                                                    onMouseEnter={() => handleRowMouseEnter(3)}
                                                                    onMouseLeave={handleRowMouseLeave}
                                                                    style={{
                                                                        background: hoveredRow === 3 ? '#F7FAFC' : 'transparent',
                                                                        border: hoveredRow === 3 ? '2px solid #A0AEC0' : '0px',
                                                                    }}
                                                                >
                                                                    <Td bg='red.50' textAlign={['center']}>
                                                                        <Text color='red.400' fontSize={20} fontWeight={700}>-2</Text>
                                                                    </Td>
                                                                    <Td>
                                                                        <Tag pl='5' pr='5' fontSize={20} fontWeight={600} bg='gray.50' border='1px' borderColor='gray.300' borderStyle={'solid'}>Category 1</Tag>
                                                                    </Td>
                                                                    <Td>
                                                                        <Text fontSize={18} fontWeight={400}>€ 100.50</Text>
                                                                    </Td>
                                                                    <Td>
                                                                        <Text fontSize={18} fontWeight={400}>2023.10.22, 19:22</Text>
                                                                    </Td>
                                                                    <Td>
                                                                        <Text fontSize={18} fontWeight={400}>Viagogo.com</Text>
                                                                    </Td>
                                                                </Tr>
                                                                <Tr>
                                                                    <Td bg='red.50' textAlign={['center']}>
                                                                        <Text color='red.400' fontSize={20} fontWeight={700}>-2</Text>
                                                                    </Td>
                                                                    <Td>
                                                                        <Tag pl='5' pr='5' fontSize={20} fontWeight={600} bg='gray.50' border='1px' borderColor='gray.300' borderStyle={'solid'}>Category 1</Tag>
                                                                    </Td>
                                                                    <Td>
                                                                        <Text fontSize={18} fontWeight={400}>€ 100.50</Text>
                                                                    </Td>
                                                                    <Td>
                                                                        <Text fontSize={18} fontWeight={400}>2023.10.22, 19:22</Text>
                                                                    </Td>
                                                                    <Td>
                                                                        <Text fontSize={18} fontWeight={400}>Viagogo.com</Text>
                                                                    </Td>
                                                                </Tr>
                                                            </Tbody>
                                                            <Tfoot>
                                                                <Tr>
                                                                    <Th></Th>
                                                                    <Th></Th>
                                                                    <Th></Th>
                                                                    <Th></Th>
                                                                    <Th></Th>
                                                                </Tr>
                                                            </Tfoot>
                                                        </Table>
                                                    </TableContainer>
                                                </VStack>
                                            </GridItem>
                                        </Grid>
                                    </Box>
                                </GridItem>

                            </Grid>
                        </Box>

                    </GridItem>
                </Grid>
            </Center >
        </>
    )

    return content
}
export default InventoryList