import { useParams } from "react-router-dom";
import EditCartForm from "./EditCartForm";
import { useGetCartsQuery } from "./cartsApiSlice";
import useAuth from "../../hooks/useAuth";
import PulseLoader from "react-spinners/PulseLoader";
import useTitle from "../../hooks/useTitle";

const EditCart = () => {
  useTitle("BROKY: Edit Cart");

  const { id } = useParams();

  const { countryname, isManager, isAdmin } = useAuth();

  const { Cart } = useGetCartsQuery("cartsList", {
    selectFromResult: ({ data }) => ({
      Cart: data?.entities[id],
    }),
  });

  if (!Cart) return <PulseLoader color={"#FFF"} />;

  if (!isManager && !isAdmin) {
    if (Cart.countryname !== countryname) {
      return <p className="errmsg">No access</p>;
    }
  }

  const content = <EditCartForm Cart={Cart} />;

  return content;
};
export default EditCart;
