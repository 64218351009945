import { configureStore } from "@reduxjs/toolkit";
import { apiSliceBroky } from "./api/apiSliceBroky";
import { apiSliceDby } from "./api/apiSliceDby";
import { setupListeners } from "@reduxjs/toolkit/query";
import authReducer from "../features/auth/authSlice";

export const store = configureStore({
	reducer: {
		[apiSliceBroky.reducerPath]: apiSliceBroky.reducer,
		[apiSliceDby.reducerPath]: apiSliceDby.reducer,
		auth: authReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(
			apiSliceBroky.middleware,
			apiSliceDby.middleware
		),
	devTools: false,
});

setupListeners(store.dispatch);
