import { useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";

import useAuth from "../hooks/useAuth";
import useLang from "../hooks/useLang";
import useTranslate from "../hooks/useTranslate";

// import `ChakraProvider` component
import {
  Box,
  Flex,
  Avatar,
  HStack,
  Text,
  IconButton,
  Button,
  Menu,
  MenuGroup,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
} from "@chakra-ui/react";

// import `ChakraProvider` icon
import { HamburgerIcon, CloseIcon, SettingsIcon } from "@chakra-ui/icons";

import {
  BsClipboardFill,
  Bs0CircleFill,
  Bs1CircleFill,
  Bs2CircleFill,
  BsFilePdf,
  BsBoxFill,
  BsGridFill,
  BsBarChartLineFill,
} from "react-icons/bs";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faClipboard,
  faFilePen,
  faGlobe,
  faEarthAmericas,
  faCity,
  faRing,
  faDungeon,
  faUsers,
  faLink,
  faTicket,
  faFolderTree,
  faToolbox,
} from "@fortawesome/free-solid-svg-icons";

import { useSendLogoutMutation } from "../features/auth/authApiSlice";

const Links = ["Mapy", "Sourcy", "Crony"];

const NavLink = (props) => {
  const { children } = props;
  return (
    <Box
      as="a"
      px={2}
      py={1}
      rounded={"md"}
      _hover={{
        textDecoration: "none",
        bg: useColorModeValue("gray.700", "gray.200"),
      }}
      href={"#"}
    >
      {children}
    </Box>
  );
};

const DashHeader = () => {
  const { isManager, isAdmin } = useAuth();

  const { lang } = useLang();
  const { T } = useTranslate(lang);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [sendLogout, { isLoading, isSuccess, isError, error }] =
    useSendLogoutMutation();

  useEffect(() => {
    if (isSuccess) navigate("/");
    window.scrollTo(0, 0);
  }, [isSuccess, navigate]);

  const errClass = isError ? "errmsg" : "offscreen";

  const content = (
    <>
      <Box bg="#1a202c" px={4}>
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <IconButton
            size={"md"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={4} alignItems={"center"}>
            <Box>
              <Link to="/dash">
                <Text
                  fontSize={{
                    base: "28",
                    sm: "28",
                    md: "25",
                    lg: "25",
                    xl: "25",
                  }}
                  fontWeight={600}
                >
                  BROKY.
                </Text>
              </Link>
            </Box>

            <Flex display={{ base: "none", md: "block" }}>
              <Menu>
                <MenuButton m="1" as={Button} colorScheme="red" size={"sm"}>
                  TIXY
                </MenuButton>
                <MenuList bg="#DDDDDD">
                  <Link to="/dash/tixy/board">
                    <MenuItem
                      _hover={{ bg: "#EEEEEE", fontWeight: "700" }}
                      bg="#DDDDDD"
                      color="black"
                      command=" "
                    >
                      <HStack>
                        <BsClipboardFill fontSize={20} />
                        <Text>Board</Text>
                      </HStack>
                    </MenuItem>
                  </Link>
                  <MenuGroup
                    title="TOOL"
                    fontWeight={700}
                    bg="red"
                    color="white"
                    m="2"
                    p="1"
                  >
                    <Link to="/dash/tixy/dispatcher">
                      <MenuItem
                        _hover={{ bg: "#EEEEEE", fontWeight: "700" }}
                        bg="#DDDDDD"
                        color="black"
                        command=" "
                      >
                        <HStack>
                          <BsGridFill fontSize={20} />
                          <Text>Dispatcher</Text>
                        </HStack>
                      </MenuItem>
                    </Link>
                    <Link to="/dash/tixy/inventory">
                      <MenuItem
                        _hover={{ bg: "#EEEEEE", fontWeight: "700" }}
                        bg="#DDDDDD"
                        color="black"
                        command=" "
                      >
                        <HStack>
                          <BsBoxFill fontSize={20} />
                          <Text>Inventory</Text>
                        </HStack>
                      </MenuItem>
                    </Link>
                  </MenuGroup>
                  <MenuGroup
                    title="MARKET"
                    fontWeight={700}
                    bg="red"
                    color="white"
                    m="2"
                    p="1"
                  >
                    <Link to="/dash/tixy/posMarket">
                      <MenuItem
                        _hover={{ bg: "#EEEEEE", fontWeight: "700" }}
                        bg="#DDDDDD"
                        color="black"
                        command=" "
                      >
                        <HStack>
                          <Bs0CircleFill fontSize={20} />
                          <Text>POS Market</Text>
                        </HStack>
                      </MenuItem>
                    </Link>
                    <Link to="/dash/tixy/firstMarket">
                      <MenuItem
                        _hover={{ bg: "#EEEEEE", fontWeight: "700" }}
                        bg="#DDDDDD"
                        color="black"
                        command=" "
                      >
                        <HStack>
                          <Bs1CircleFill fontSize={20} />
                          <Text>1ST Market</Text>
                        </HStack>
                      </MenuItem>
                    </Link>
                    <Link to="/dash/tixy/secondMarket">
                      <MenuItem
                        _hover={{ bg: "#EEEEEE", fontWeight: "700" }}
                        bg="#DDDDDD"
                        color="black"
                        command=" "
                      >
                        <HStack>
                          <Bs2CircleFill fontSize={20} />
                          <Text>2ND Market</Text>
                        </HStack>
                      </MenuItem>
                    </Link>
                  </MenuGroup>
                  <MenuDivider />
                  <MenuGroup
                    title="STOCK"
                    fontWeight={700}
                    bg="red"
                    color="white"
                    m="2"
                    p="1"
                  >
                    <Link to="/dash/tixy/stockEntry">
                      <MenuItem
                        _hover={{ bg: "#EEEEEE", fontWeight: "700" }}
                        bg="#DDDDDD"
                        color="black"
                        command=" "
                      >
                        <HStack>
                          <BsGridFill fontSize={20} />
                          <Text>Entry</Text>
                        </HStack>
                      </MenuItem>
                    </Link>
                    <Link to="/dash/tixy/stockExit">
                      <MenuItem
                        _hover={{ bg: "#EEEEEE", fontWeight: "700" }}
                        bg="#DDDDDD"
                        color="black"
                        command=" "
                      >
                        <HStack>
                          <BsGridFill fontSize={20} />
                          <Text>Exit</Text>
                        </HStack>
                      </MenuItem>
                    </Link>
                  </MenuGroup>
                  <MenuDivider />
                </MenuList>
              </Menu>

              <Menu>
                <MenuButton m="1" as={Button} colorScheme="red" size={"sm"}>
                  CRAWLY
                </MenuButton>
                <MenuList bg="#DDDDDD">
                  <MenuGroup
                    title="MARKET DATA"
                    fontWeight={700}
                    bg="red"
                    color="white"
                    m="2"
                    p="1"
                  >
                    <Link to="/dash/crawly/firstMarket">
                      <MenuItem
                        _hover={{ bg: "#EEEEEE", fontWeight: "700" }}
                        bg="#DDDDDD"
                        color="black"
                        command=" "
                      >
                        <HStack>
                          <Bs1CircleFill fontSize={20} />
                          <Text>1ST Market</Text>
                        </HStack>
                      </MenuItem>
                    </Link>
                    <Link to="/dash/crawly/secondMarket">
                      <MenuItem
                        _hover={{ bg: "#EEEEEE", fontWeight: "700" }}
                        bg="#DDDDDD"
                        color="black"
                        command=" "
                      >
                        <HStack>
                          <Bs2CircleFill fontSize={20} />
                          <Text>2ND Market</Text>
                        </HStack>
                      </MenuItem>
                    </Link>
                  </MenuGroup>
                  <MenuGroup
                    title="ORDER DATA"
                    fontWeight={700}
                    bg="red"
                    color="white"
                    m="2"
                    p="1"
                  >
                    <Link to="/dash/crawly/orderList">
                      <MenuItem
                        _hover={{ bg: "#EEEEEE", fontWeight: "700" }}
                        bg="#DDDDDD"
                        color="black"
                        command=" "
                      >
                        <HStack>
                          <BsGridFill fontSize={20} />
                          <Text>Order list</Text>
                        </HStack>
                      </MenuItem>
                    </Link>
                    <Link to="/dash/crawly/saleList">
                      <MenuItem
                        _hover={{ bg: "#EEEEEE", fontWeight: "700" }}
                        bg="#DDDDDD"
                        color="black"
                        command=" "
                      >
                        <HStack>
                          <BsGridFill fontSize={20} />
                          <Text>Sale list</Text>
                        </HStack>
                      </MenuItem>
                    </Link>
                  </MenuGroup>
                  <MenuGroup
                    title="ACCOUNT"
                    fontWeight={700}
                    bg="red"
                    color="white"
                    m="2"
                    p="1"
                  >
                    <Link to="/dash/tixy/stockEntry">
                      <MenuItem
                        _hover={{ bg: "#EEEEEE", fontWeight: "700" }}
                        bg="#DDDDDD"
                        color="black"
                        command=" "
                      >
                        <HStack>
                          <BsGridFill fontSize={20} />
                          <Text>Stock inventory</Text>
                        </HStack>
                      </MenuItem>
                    </Link>
                  </MenuGroup>
                  <MenuDivider />
                </MenuList>
              </Menu>

              <Menu>
                <MenuButton m="1" as={Button} colorScheme="red" size={"sm"}>
                  CARTY
                </MenuButton>
                <MenuList bg="#DDDDDD">
                  <MenuGroup
                    title="CART"
                    fontWeight={700}
                    bg="red"
                    color="white"
                    m="2"
                    p="1"
                  >
                    <Link to="/dash/carts/CartsManager">
                      <MenuItem
                        _hover={{ bg: "#EEEEEE", fontWeight: "700" }}
                        bg="#DDDDDD"
                        color="black"
                        command=" "
                      >
                        <FontAwesomeIcon fontSize={20} icon={faClipboard} />{" "}
                        Manage
                      </MenuItem>
                    </Link>
                    <Link to="/dash/carts">
                      <MenuItem
                        _hover={{ bg: "#EEEEEE", fontWeight: "700" }}
                        bg="#DDDDDD"
                        color="black"
                        command=" "
                      >
                        <FontAwesomeIcon fontSize={20} icon={faClipboard} />{" "}
                        List
                      </MenuItem>
                    </Link>
                  </MenuGroup>
                  <MenuDivider />
                  <MenuGroup
                    title="TOOLS"
                    fontWeight={700}
                    bg="red"
                    color="white"
                    m="2"
                    p="1"
                  >
                    <Link to="/dash/events/finder">
                      <MenuItem
                        _hover={{ bg: "#EEEEEE", fontWeight: "700" }}
                        bg="#DDDDDD"
                        color="black"
                        command=" "
                      >
                        <FontAwesomeIcon fontSize={20} icon={faToolbox} /> Cart
                        planner
                      </MenuItem>
                    </Link>
                  </MenuGroup>
                </MenuList>
              </Menu>

              <Menu>
                <MenuButton m="1" as={Button} colorScheme="red" size={"sm"}>
                  CAPTCHY
                </MenuButton>
                <MenuList bg="#DDDDDD">
                  <MenuGroup
                    title="MAPS"
                    fontWeight={700}
                    bg="red"
                    color="white"
                    m="2"
                    p="1"
                  >
                    <Link to="/dash/maps/manager">
                      <MenuItem
                        _hover={{ bg: "#EEEEEE", fontWeight: "700" }}
                        bg="#DDDDDD"
                        color="black"
                        command=" "
                      >
                        <FontAwesomeIcon fontSize={20} icon={faClipboard} />{" "}
                        Manage
                      </MenuItem>
                    </Link>
                  </MenuGroup>
                  <MenuDivider />
                  <MenuGroup
                    title="TOOLS"
                    fontWeight={700}
                    bg="red"
                    color="white"
                    m="2"
                    p="1"
                  >
                    <Link to="/dash/maps/svgdefine">
                      <MenuItem
                        _hover={{ bg: "#EEEEEE", fontWeight: "700" }}
                        bg="#DDDDDD"
                        color="black"
                        command=" "
                      >
                        <FontAwesomeIcon fontSize={20} icon={faToolbox} /> SVG
                        define
                      </MenuItem>
                    </Link>
                    <Link to="/dash/maps/heatmap">
                      <MenuItem
                        _hover={{ bg: "#EEEEEE", fontWeight: "700" }}
                        bg="#DDDDDD"
                        color="black"
                        command=" "
                      >
                        <FontAwesomeIcon fontSize={20} icon={faToolbox} /> Heat
                        map
                      </MenuItem>
                    </Link>
                  </MenuGroup>
                  <MenuDivider />
                  <MenuGroup
                    title="ATTRIBUTS"
                    fontWeight={700}
                    bg="red"
                    color="white"
                    m="2"
                    p="1"
                  >
                    <Link to="/dash/maps">
                      <MenuItem
                        _hover={{ bg: "#EEEEEE", fontWeight: "700" }}
                        bg="#DDDDDD"
                        color="black"
                        command=" "
                      >
                        <FontAwesomeIcon fontSize={20} icon={faDungeon} /> Maps
                      </MenuItem>
                    </Link>
                  </MenuGroup>
                </MenuList>
              </Menu>

              <Menu>
                <MenuButton m="1" as={Button} colorScheme="red" size={"sm"}>
                  PROFILY
                </MenuButton>
                <MenuList bg="#DDDDDD">
                  <MenuGroup
                    title="PROFILY"
                    fontWeight={700}
                    bg="red"
                    color="white"
                    m="2"
                    p="1"
                  >
                    <Link to="/dash/profily">
                      <MenuItem
                        _hover={{ bg: "#EEEEEE", fontWeight: "700" }}
                        bg="#DDDDDD"
                        color="black"
                        command=" "
                      >
                        <FontAwesomeIcon fontSize={20} icon={faClipboard} />{" "}
                        Manager
                      </MenuItem>
                    </Link>
                  </MenuGroup>
                  <MenuDivider />
                  <MenuGroup
                    title="PROFILES"
                    fontWeight={700}
                    bg="red"
                    color="white"
                    m="2"
                    p="1"
                  >
                    <Link to="/dash/profily/profile">
                      <MenuItem
                        _hover={{ bg: "#EEEEEE", fontWeight: "700" }}
                        bg="#DDDDDD"
                        color="black"
                        command=" "
                      >
                        <FontAwesomeIcon fontSize={20} icon={faClipboard} />{" "}
                        Profile
                      </MenuItem>
                    </Link>
                  </MenuGroup>
                  <MenuDivider />
                  <MenuGroup
                    title="ACCOUNTS"
                    fontWeight={700}
                    bg="red"
                    color="white"
                    m="2"
                    p="1"
                  >
                    <Link to="/dash/profily/accountBuyer">
                      <MenuItem
                        _hover={{ bg: "#EEEEEE", fontWeight: "700" }}
                        bg="#DDDDDD"
                        color="black"
                        command=" "
                      >
                        <FontAwesomeIcon fontSize={20} icon={faClipboard} />{" "}
                        Buyer
                      </MenuItem>
                    </Link>
                    <Link to="/dash/profily/accountSeller">
                      <MenuItem
                        _hover={{ bg: "#EEEEEE", fontWeight: "700" }}
                        bg="#DDDDDD"
                        color="black"
                        command=" "
                      >
                        <FontAwesomeIcon fontSize={20} icon={faClipboard} />{" "}
                        Seller
                      </MenuItem>
                    </Link>
                  </MenuGroup>
                  <MenuDivider />
                  <MenuGroup
                    title="ATTRIBUTS"
                    fontWeight={700}
                    bg="red"
                    color="white"
                    m="2"
                    p="1"
                  >
                    <Link to="/dash/profily/country">
                      <MenuItem
                        _hover={{ bg: "#EEEEEE", fontWeight: "700" }}
                        bg="#DDDDDD"
                        color="black"
                        command=" "
                      >
                        <FontAwesomeIcon fontSize={20} icon={faLink} /> Email
                      </MenuItem>
                    </Link>
                    <Link to="/dash/profily/country">
                      <MenuItem
                        _hover={{ bg: "#EEEEEE", fontWeight: "700" }}
                        bg="#DDDDDD"
                        color="black"
                        command=" "
                      >
                        <FontAwesomeIcon fontSize={20} icon={faLink} />{" "}
                        Firstname
                      </MenuItem>
                    </Link>
                    <Link to="/dash/profily/country">
                      <MenuItem
                        _hover={{ bg: "#EEEEEE", fontWeight: "700" }}
                        bg="#DDDDDD"
                        color="black"
                        command=" "
                      >
                        <FontAwesomeIcon fontSize={20} icon={faLink} /> City
                      </MenuItem>
                    </Link>
                    <Link to="/dash/profily/country">
                      <MenuItem
                        _hover={{ bg: "#EEEEEE", fontWeight: "700" }}
                        bg="#DDDDDD"
                        color="black"
                        command=" "
                      >
                        <FontAwesomeIcon fontSize={20} icon={faLink} /> Country
                      </MenuItem>
                    </Link>
                  </MenuGroup>
                </MenuList>
              </Menu>

              <Menu>
                <MenuButton m="1" as={Button} colorScheme="red" size={"sm"}>
                  PRICY
                </MenuButton>
                <MenuList bg="#DDDDDD">
                  <MenuGroup
                    title="TASKS"
                    fontWeight={700}
                    bg="red"
                    color="white"
                    m="2"
                    p="1"
                  >
                    <MenuItem
                      _hover={{ bg: "#EEEEEE", fontWeight: "700" }}
                      bg="#DDDDDD"
                      color="black"
                      command=" "
                    >
                      <FontAwesomeIcon fontSize={20} icon={faClipboard} />{" "}
                      Manage
                    </MenuItem>
                  </MenuGroup>
                  <MenuDivider />
                </MenuList>
              </Menu>

              <Menu>
                <MenuButton m="1" as={Button} colorScheme="red" size={"sm"}>
                  CRONY
                </MenuButton>
                <MenuList bg="#DDDDDD">
                  <MenuGroup
                    title="TASKS"
                    fontWeight={700}
                    bg="red"
                    color="white"
                    m="2"
                    p="1"
                  >
                    <MenuItem
                      _hover={{ bg: "#EEEEEE", fontWeight: "700" }}
                      bg="#DDDDDD"
                      color="black"
                      command=" "
                    >
                      <FontAwesomeIcon fontSize={20} icon={faClipboard} />{" "}
                      Manage
                    </MenuItem>
                  </MenuGroup>
                  <MenuDivider />
                </MenuList>
              </Menu>
            </Flex>
          </HStack>
          <Flex alignItems={"center"}>
            {(isManager || isAdmin) && (
              <Menu>
                <MenuButton
                  as={Button}
                  variant={"outline"}
                  leftIcon={<SettingsIcon />}
                  mr="2"
                  colorScheme="red"
                  size={"sm"}
                >
                  Admin
                </MenuButton>
                <MenuList bg="#DDDDDD">
                  <MenuGroup
                    title="SETUP"
                    fontWeight={700}
                    bg="red"
                    color="white"
                    m="2"
                    p="1"
                  >
                    <MenuItem
                      _hover={{ bg: "#EEEEEE", fontWeight: "700" }}
                      bg="#DDDDDD"
                      color="black"
                      command=" "
                    >
                      Section
                    </MenuItem>
                  </MenuGroup>
                  <MenuDivider />
                  <MenuGroup
                    title="MANAGE"
                    fontWeight={700}
                    bg="red"
                    color="white"
                    m="2"
                    p="1"
                  >
                    <Link to="/dash/notes">
                      <MenuItem
                        _hover={{ bg: "#EEEEEE", fontWeight: "700" }}
                        bg="#DDDDDD"
                        color="black"
                        command=" "
                      >
                        <FontAwesomeIcon fontSize={20} icon={faFilePen} />{" "}
                        {T.dash.Notes}
                      </MenuItem>
                    </Link>
                    <Link to="/dash/users">
                      <MenuItem
                        _hover={{ bg: "#EEEEEE", fontWeight: "700" }}
                        bg="#DDDDDD"
                        color="black"
                        command=" "
                      >
                        <FontAwesomeIcon fontSize={20} icon={faUsers} />{" "}
                        {T.dash.Users}
                      </MenuItem>
                    </Link>
                  </MenuGroup>
                </MenuList>
              </Menu>
            )}

            <Menu>
              <MenuButton
                as={Button}
                rounded={"full"}
                variant={"link"}
                cursor={"pointer"}
                minW={0}
              >
                <Avatar
                  size={"sm"}
                  src={
                    "https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9"
                  }
                />
              </MenuButton>
              <MenuList>
                <MenuItem>Profile</MenuItem>
                <MenuItem>Options</MenuItem>
                <MenuDivider />
                <MenuItem onClick={sendLogout}>Logout</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: "none" }}>
            <Stack as={"nav"} spacing={4}>
              {Links.map((link) => (
                <NavLink key={link}>{link}</NavLink>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>
    </>
  );

  return content;
};
export default DashHeader;
