import { useState, useEffect } from "react";
import {
  useGetInventoryWaitQuery,
  useUpdateCartWaitMutation,
} from "./cartsApiSlice";
import Cart from "./Cart";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useLang from "../../hooks/useLang";
import useTranslate from "../../hooks/useTranslate";
import useTitle from "../../hooks/useTitle";
import PulseLoader from "react-spinners/PulseLoader";

// import `ChakraProvider` component
import {
  Box,
  Grid,
  GridItem,
  Center,
  HStack,
  VStack,
  Container,
  Flex,
  SimpleGrid,
  FormControl,
  FormLabel,
  Textarea,
  Checkbox,
  Input,
  Switch,
  Badge,
  Tag,
  TagLabel,
  TagCloseButton,
  Avatar,
  Toast,
  Icon,
  IconButton,
  Image,
  Stack,
  Alert,
  AlertIcon,
  Select,
  Button,
  useRadioGroup,
  useRadio,
  RadioGroup,
  Radio,
  Text,
  useDisclosure,
  useToast,
  Spacer,
} from "@chakra-ui/react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";

// import `ChakraProvider` icon
import { AddIcon } from "@chakra-ui/icons";

import {
  Bs0CircleFill,
  Bs1CircleFill,
  Bs2CircleFill,
  BsBoxFill,
  BsFilePdf,
  BsGridFill,
  BsBarChartLineFill,
} from "react-icons/bs";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationCrosshairs,
  faSnowman,
  faList,
} from "@fortawesome/free-solid-svg-icons";

const InventoryList = () => {
  useTitle("BROKY: Inventory Inventory");

  const { username, isManager, isAdmin } = useAuth();

  const { lang } = useLang();
  const { T } = useTranslate(lang);

  let menuContent;
  if (isManager || isAdmin) {
    menuContent = (
      <>
        <Link to="/dash/carts/new">
          <Button
            size="sm"
            leftIcon={<AddIcon />}
            colorScheme="red"
            variant="solid"
          >
            <Text
              color="white"
              display={{ base: "none", sm: "flex" }}
              fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }}
              fontWeight={600}
            >
              {T.common.Add}
            </Text>
          </Button>
        </Link>
      </>
    );
  }

  const initialTableState = {
    table1: false,
    table2: false,
    // Ajoutez d'autres tables avec leur état initial ici
  };

  const [tableStates, setTableStates] = useState(initialTableState);

  const handleSwitchChange = (tableName) => {
    setTableStates((prevTableStates) => ({
      ...prevTableStates,
      [tableName]: !prevTableStates[tableName],
    }));
  };

  const [hoveredRow, setHoveredRow] = useState(null);

  const handleRowMouseEnter = (productId) => {
    setHoveredRow(productId);
  };

  const handleRowMouseLeave = () => {
    setHoveredRow(null);
  };

  let content;
  content = (
    <>
      <Center bg="#45413C" axis="both">
        <Grid
          w="95vw"
          minH="100vh"
          maxW="95vw"
          templateColumns="repeat(12, 1fr)"
        >
          <GridItem
            colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}
          >
            <Grid p="5" gap="5" templateColumns="repeat(12, 1fr)">
              <GridItem
                colSpan={{
                  base: "6",
                  sm: "6",
                  md: "6",
                  lg: "6",
                  xl: "6",
                  "2xl": "6",
                }}
                textAlign={["left"]}
              >
                <Text
                  color={"white"}
                  fontSize={{
                    base: "24",
                    sm: "24",
                    md: "24",
                    lg: "24",
                    xl: "28",
                  }}
                  fontWeight={600}
                >
                  <FontAwesomeIcon icon={faLocationCrosshairs} /> {T.tixy.Tixy}{" "}
                  : {T.tixy.Board}
                </Text>
              </GridItem>
              <GridItem
                colSpan={{
                  base: "6",
                  sm: "6",
                  md: "6",
                  lg: "6",
                  xl: "6",
                  "2xl": "6",
                }}
                textAlign={["right"]}
              >
                {menuContent}
              </GridItem>
            </Grid>

            {/* Row 01 */}
            <Box
              borderRadius="xl"
              mb="2"
              p={2}
              textAlign={["center"]}
              bg="white"
              _hover={{
                bg: "gray.200",
                border: "1px",
                borderRadius: "xl",
                borderColor: "gray.400",
                borderStyle: "solid",
              }}
            >
              <Grid
                gap="2"
                w="100%"
                maxW="100vw"
                templateColumns="repeat(13, 1fr)"
              >
                {/* Title */}
                <GridItem
                  color="black"
                  colSpan={{
                    base: "12",
                    sm: "12",
                    md: "12",
                    lg: "12",
                    xl: "12",
                    "2xl": "2",
                  }}
                >
                  <Flex>
                    <Box p="2" textAlign={["left"]}>
                      <Switch
                        size="md"
                        checked={tableStates["table1"]}
                        onChange={() => handleSwitchChange("table1")}
                      />
                    </Box>
                    <Box
                      p="2"
                      textAlign={["left"]}
                      w="100%"
                      minH="100px"
                      color="black"
                    >
                      <Text fontSize={25} fontWeight={800}>
                        Coldplay
                      </Text>
                      <Text fontSize={22} fontWeight={600}>
                        2024.06.12, 19:00
                      </Text>
                      <Text fontSize={20} fontWeight={500}>
                        France, Saint-Denis, Stade de France
                      </Text>
                    </Box>
                  </Flex>

                  {/* Button NAV */}
                  {!tableStates["table1"] && (
                    <>
                      <SimpleGrid columns={3} spacing={2} mb="2">
                        {/* Go DISPATCHER */}
                        <Link to="/dash/tixy/dispatcher">
                          <Box
                            p="2"
                            textAlign={["center"]}
                            color="black"
                            bg="gray.50"
                            borderRadius="xl"
                            border="1px"
                            borderColor="gray.300"
                            borderStyle={"solid"}
                            _hover={{
                              bg: "gray.100",
                              border: "2px",
                              borderColor: "gray.400",
                              borderStyle: "solid",
                            }}
                            cursor={"pointer"}
                          >
                            <Text
                              color="gray.600"
                              fontSize={14}
                              fontWeight={600}
                            >
                              DISPATCHER
                            </Text>
                            <IconButton
                              isRound={true}
                              variant="solid"
                              bg="gray.300"
                              color="gray.700"
                              aria-label="Add"
                              fontSize="15px"
                              icon={<BsGridFill />}
                            />
                          </Box>
                        </Link>

                        {/* Go INVENTORY */}
                        <Link to="/dash/tixy/inventory">
                          <Box
                            p="2"
                            textAlign={["center"]}
                            color="black"
                            bg="gray.50"
                            borderRadius="xl"
                            border="1px"
                            borderColor="gray.300"
                            borderStyle={"solid"}
                            _hover={{
                              bg: "gray.100",
                              border: "2px",
                              borderColor: "gray.400",
                              borderStyle: "solid",
                            }}
                            cursor={"pointer"}
                          >
                            <Text
                              color="gray.600"
                              fontSize={14}
                              fontWeight={600}
                            >
                              INVENTORY
                            </Text>
                            <IconButton
                              isRound={true}
                              variant="solid"
                              bg="gray.300"
                              color="gray.700"
                              aria-label="Add"
                              fontSize="15px"
                              icon={<BsBoxFill />}
                            />
                          </Box>
                        </Link>
                      </SimpleGrid>

                      <SimpleGrid columns={3} spacing={2}>
                        {/* Go POS MARKET */}
                        <Link to="/dash/tixy/posMarket">
                          <Box
                            p="2"
                            textAlign={["center"]}
                            color="black"
                            bg="gray.50"
                            borderRadius="xl"
                            border="1px"
                            borderColor="gray.300"
                            borderStyle={"solid"}
                            _hover={{
                              bg: "gray.100",
                              border: "2px",
                              borderColor: "gray.400",
                              borderStyle: "solid",
                            }}
                            cursor={"pointer"}
                          >
                            <Text
                              color="gray.600"
                              fontSize={14}
                              fontWeight={600}
                            >
                              POS MARKET
                            </Text>
                            <IconButton
                              isRound={true}
                              variant="solid"
                              bg="gray.300"
                              color="gray.700"
                              aria-label="Add"
                              fontSize="15px"
                              icon={<Bs0CircleFill />}
                            />
                          </Box>
                        </Link>

                        {/* Go 1ST MARKET */}
                        <Link to="/dash/tixy/firstMarket">
                          <Box
                            p="2"
                            textAlign={["center"]}
                            color="black"
                            bg="gray.50"
                            borderRadius="xl"
                            border="1px"
                            borderColor="gray.300"
                            borderStyle={"solid"}
                            _hover={{
                              bg: "gray.100",
                              border: "2px",
                              borderColor: "gray.400",
                              borderStyle: "solid",
                            }}
                            cursor={"pointer"}
                          >
                            <Text
                              color="gray.600"
                              fontSize={14}
                              fontWeight={600}
                            >
                              1ST MARKET
                            </Text>
                            <IconButton
                              isRound={true}
                              variant="solid"
                              bg="gray.300"
                              color="gray.700"
                              aria-label="Add"
                              fontSize="15px"
                              icon={<Bs1CircleFill />}
                            />
                          </Box>
                        </Link>

                        {/* Go 2ND MARKET */}
                        <Link to="/dash/tixy/secondMarket">
                          <Box
                            p="2"
                            textAlign={["center"]}
                            color="black"
                            bg="gray.50"
                            borderRadius="xl"
                            border="1px"
                            borderColor="gray.300"
                            borderStyle={"solid"}
                            _hover={{
                              bg: "gray.100",
                              border: "2px",
                              borderColor: "gray.400",
                              borderStyle: "solid",
                            }}
                            cursor={"pointer"}
                          >
                            <Text
                              color="gray.600"
                              fontSize={14}
                              fontWeight={600}
                            >
                              2ND MARKET
                            </Text>
                            <IconButton
                              isRound={true}
                              variant="solid"
                              bg="gray.300"
                              color="gray.700"
                              aria-label="Add"
                              fontSize="15px"
                              icon={<Bs2CircleFill />}
                            />
                          </Box>
                        </Link>
                      </SimpleGrid>
                    </>
                  )}
                </GridItem>

                {/* TOOLS */}
                <GridItem
                  color="black"
                  colSpan={{
                    base: "1",
                    sm: "1",
                    md: "1",
                    lg: "1",
                    xl: "1",
                    "2xl": "1",
                  }}
                >
                  <Box
                    p="2"
                    textAlign={["center"]}
                    w="100%"
                    minH="100px"
                    color="black"
                    bg="#F5F5F5"
                    borderRadius="xl"
                    border="1px"
                    borderColor="gray.300"
                    borderStyle={"solid"}
                    _hover={{
                      bg: "gray.100",
                      border: "2px",
                      borderColor: "gray.400",
                      borderStyle: "solid",
                    }}
                  >
                    <Grid
                      gap="2"
                      w="100%"
                      maxW="100vw"
                      templateColumns="repeat(12, 1fr)"
                    >
                      <GridItem
                        color="black"
                        colSpan={{
                          base: "12",
                          sm: "12",
                          md: "12",
                          lg: "12",
                          xl: "12",
                          "2xl": "12",
                        }}
                      >
                        <Box
                          p="0"
                          color="white"
                          bg="gray.400"
                          borderRadius="lg"
                        >
                          <Text fontSize={16} fontWeight={700}>
                            TOOLS
                          </Text>
                        </Box>
                      </GridItem>

                      <GridItem
                        color="black"
                        colSpan={{
                          base: "12",
                          sm: "12",
                          md: "12",
                          lg: "12",
                          xl: "12",
                          "2xl": "12",
                        }}
                      >
                        <VStack>
                          <TableContainer w="100%">
                            <Table
                              size="sm"
                              p="0"
                              m="0"
                              variant="simple"
                              bg="#FFF"
                              borderRadius="lg"
                              border="1px"
                              borderColor="gray.300"
                              borderStyle={"solid"}
                            >
                              <Tbody>
                                <Tr
                                  h="50px"
                                  key="1"
                                  onMouseEnter={() => handleRowMouseEnter(1)}
                                  onMouseLeave={handleRowMouseLeave}
                                  style={{
                                    background:
                                      hoveredRow === 1
                                        ? "#F7FAFC"
                                        : "transparent",
                                    border:
                                      hoveredRow === 1
                                        ? "2px solid #A0AEC0"
                                        : "0px",
                                  }}
                                >
                                  <Td>
                                    <Text fontSize={22} fontWeight={600}>
                                      1426
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text
                                      color="red.400"
                                      fontSize={22}
                                      fontWeight={600}
                                    >
                                      -41
                                    </Text>
                                  </Td>
                                </Tr>
                                <Tr h="20px" bg="gray.200" color="gray.600">
                                  <Td>
                                    <Text fontSize={13} fontWeight={600}>
                                      AVAIL.
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={13} fontWeight={600}>
                                      7J
                                    </Text>
                                  </Td>
                                </Tr>
                                {!tableStates["table1"] && (
                                  <Tr
                                    h="50px"
                                    key="2"
                                    onMouseEnter={() => handleRowMouseEnter(2)}
                                    onMouseLeave={handleRowMouseLeave}
                                    style={{
                                      background:
                                        hoveredRow === 2
                                          ? "#F7FAFC"
                                          : "transparent",
                                      border:
                                        hoveredRow === 2
                                          ? "2px solid #A0AEC0"
                                          : "0px",
                                    }}
                                  >
                                    <Td>
                                      <Text fontSize={18} fontWeight={500}>
                                        844
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={500}
                                      >
                                        -5
                                      </Text>
                                    </Td>
                                  </Tr>
                                )}
                                {!tableStates["table1"] && (
                                  <Tr
                                    h="50px"
                                    key="3"
                                    onMouseEnter={() => handleRowMouseEnter(3)}
                                    onMouseLeave={handleRowMouseLeave}
                                    style={{
                                      background:
                                        hoveredRow === 3
                                          ? "#F7FAFC"
                                          : "transparent",
                                      border:
                                        hoveredRow === 3
                                          ? "2px solid #A0AEC0"
                                          : "0px",
                                    }}
                                  >
                                    <Td>
                                      <Text fontSize={18} fontWeight={500}>
                                        17
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -31
                                      </Text>
                                    </Td>
                                  </Tr>
                                )}
                                {!tableStates["table1"] && (
                                  <Tr
                                    h="50px"
                                    key="4"
                                    onMouseEnter={() => handleRowMouseEnter(4)}
                                    onMouseLeave={handleRowMouseLeave}
                                    style={{
                                      background:
                                        hoveredRow === 4
                                          ? "#F7FAFC"
                                          : "transparent",
                                      border:
                                        hoveredRow === 4
                                          ? "2px solid #A0AEC0"
                                          : "0px",
                                    }}
                                  >
                                    <Td>
                                      <Text fontSize={18} fontWeight={500}>
                                        40
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -31
                                      </Text>
                                    </Td>
                                  </Tr>
                                )}
                                {!tableStates["table1"] && (
                                  <Tr
                                    h="50px"
                                    key="5"
                                    onMouseEnter={() => handleRowMouseEnter(5)}
                                    onMouseLeave={handleRowMouseLeave}
                                    style={{
                                      background:
                                        hoveredRow === 5
                                          ? "#F7FAFC"
                                          : "transparent",
                                      border:
                                        hoveredRow === 5
                                          ? "2px solid #A0AEC0"
                                          : "0px",
                                    }}
                                  >
                                    <Td>
                                      <Text fontSize={18} fontWeight={500}>
                                        50
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -31
                                      </Text>
                                    </Td>
                                  </Tr>
                                )}
                                {!tableStates["table1"] && (
                                  <Tr
                                    h="50px"
                                    key="6"
                                    onMouseEnter={() => handleRowMouseEnter(6)}
                                    onMouseLeave={handleRowMouseLeave}
                                    style={{
                                      background:
                                        hoveredRow === 6
                                          ? "#F7FAFC"
                                          : "transparent",
                                      border:
                                        hoveredRow === 6
                                          ? "2px solid #A0AEC0"
                                          : "0px",
                                    }}
                                  >
                                    <Td>
                                      <Text fontSize={18} fontWeight={500}>
                                        10
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -31
                                      </Text>
                                    </Td>
                                  </Tr>
                                )}
                              </Tbody>
                              <Tfoot>
                                <Tr>
                                  <Th></Th>
                                  <Th></Th>
                                </Tr>
                              </Tfoot>
                            </Table>
                          </TableContainer>
                        </VStack>
                      </GridItem>
                    </Grid>
                  </Box>
                </GridItem>

                {/* INVENTORY */}
                <GridItem
                  color="black"
                  colSpan={{
                    base: "12",
                    sm: "12",
                    md: "12",
                    lg: "12",
                    xl: "6",
                    "2xl": "4",
                  }}
                >
                  <Box
                    p="2"
                    textAlign={["center"]}
                    w="100%"
                    minH="100px"
                    color="black"
                    bg="#F5F5F5"
                    borderRadius="xl"
                    border="1px"
                    borderColor="gray.300"
                    borderStyle={"solid"}
                    _hover={{
                      bg: "gray.100",
                      border: "2px",
                      borderColor: "gray.400",
                      borderStyle: "solid",
                    }}
                  >
                    <Grid
                      gap="2"
                      w="100%"
                      maxW="100vw"
                      templateColumns="repeat(12, 1fr)"
                    >
                      <GridItem
                        color="black"
                        colSpan={{
                          base: "12",
                          sm: "12",
                          md: "12",
                          lg: "12",
                          xl: "12",
                          "2xl": "12",
                        }}
                      >
                        <Box
                          p="0"
                          color="white"
                          bg="gray.400"
                          borderRadius="lg"
                        >
                          <Text fontSize={16} fontWeight={700}>
                            INVENTORY
                          </Text>
                        </Box>
                      </GridItem>

                      <GridItem
                        color="black"
                        colSpan={{
                          base: "12",
                          sm: "12",
                          md: "12",
                          lg: "12",
                          xl: "12",
                          "2xl": "12",
                        }}
                      >
                        <VStack>
                          <TableContainer w="100%">
                            <Table
                              size="sm"
                              variant="simple"
                              bg="#FFF"
                              borderRadius="lg"
                              border="1px"
                              borderColor="gray.300"
                              borderStyle={"solid"}
                            >
                              <Tbody>
                                <Tr
                                  h="50px"
                                  key="1"
                                  onMouseEnter={() => handleRowMouseEnter(1)}
                                  onMouseLeave={handleRowMouseLeave}
                                  style={{
                                    background:
                                      hoveredRow === 1
                                        ? "#F7FAFC"
                                        : "transparent",
                                    border:
                                      hoveredRow === 1
                                        ? "2px solid #A0AEC0"
                                        : "0px",
                                  }}
                                >
                                  <Td>
                                    <Text fontSize={22} fontWeight={700}>
                                      21
                                    </Text>
                                    <Text
                                      color="gray.400"
                                      fontSize={12}
                                      fontWeight={500}
                                    >
                                      € 26 436,90
                                    </Text>
                                  </Td>
                                  <Td></Td>
                                  <Td></Td>
                                  <Td>
                                    <Text fontSize={22} fontWeight={600}>
                                      31
                                    </Text>
                                    <Text
                                      color="gray.400"
                                      fontSize={12}
                                      fontWeight={500}
                                    >
                                      € 12 436,90
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={22} fontWeight={600}>
                                      10
                                    </Text>
                                    <Text
                                      color="gray.400"
                                      fontSize={12}
                                      fontWeight={500}
                                    >
                                      € 26 436,90
                                    </Text>
                                  </Td>
                                </Tr>
                                <Tr bg="gray.200" color="gray.600">
                                  <Td w="40px">
                                    <Text fontSize={13} fontWeight={700}>
                                      STOCK
                                    </Text>
                                  </Td>
                                  <Td w="50%">
                                    <Text fontSize={13} fontWeight={700}>
                                      PLACEMENT
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={13} fontWeight={700}>
                                      UNIT PRICE
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={13} fontWeight={700}>
                                      STOCK IN
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={13} fontWeight={700}>
                                      STOCK OUT
                                    </Text>
                                  </Td>
                                </Tr>
                                {!tableStates["table1"] && (
                                  <Tr
                                    h="50px"
                                    key="2"
                                    onMouseEnter={() => handleRowMouseEnter(2)}
                                    onMouseLeave={handleRowMouseLeave}
                                    style={{
                                      background:
                                        hoveredRow === 2
                                          ? "#F7FAFC"
                                          : "transparent",
                                      border:
                                        hoveredRow === 2
                                          ? "2px solid #A0AEC0"
                                          : "0px",
                                    }}
                                  >
                                    <Td bg="green.50">
                                      <Text
                                        color="green.400"
                                        fontSize={20}
                                        fontWeight={700}
                                      >
                                        +21
                                      </Text>
                                      <Text
                                        color="gray.400"
                                        fontSize={12}
                                        fontWeight={500}
                                      >
                                        € 26 436,90
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Tag
                                        pl="5"
                                        pr="5"
                                        fontSize={20}
                                        fontWeight={600}
                                        bg="gray.50"
                                        border="1px"
                                        borderColor="gray.300"
                                        borderStyle={"solid"}
                                      >
                                        Carre OR
                                      </Tag>
                                    </Td>
                                    <Td>
                                      <Text fontSize={18} fontWeight={500}>
                                        € 100.50
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text fontSize={18} fontWeight={600}>
                                        31
                                      </Text>
                                      <Text
                                        color="gray.400"
                                        fontSize={12}
                                        fontWeight={500}
                                      >
                                        € 12 436,90
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text fontSize={18} fontWeight={600}>
                                        10
                                      </Text>
                                      <Text
                                        color="gray.400"
                                        fontSize={12}
                                        fontWeight={500}
                                      >
                                        € 26 436,90
                                      </Text>
                                    </Td>
                                  </Tr>
                                )}
                                {!tableStates["table1"] && (
                                  <Tr
                                    h="50px"
                                    key="3"
                                    onMouseEnter={() => handleRowMouseEnter(3)}
                                    onMouseLeave={handleRowMouseLeave}
                                    style={{
                                      background:
                                        hoveredRow === 3
                                          ? "#F7FAFC"
                                          : "transparent",
                                      border:
                                        hoveredRow === 3
                                          ? "2px solid #A0AEC0"
                                          : "0px",
                                    }}
                                  >
                                    <Td bg="red.50">
                                      <Text
                                        color="red.400"
                                        fontSize={20}
                                        fontWeight={700}
                                      >
                                        -13
                                      </Text>
                                      <Text
                                        color="gray.400"
                                        fontSize={12}
                                        fontWeight={500}
                                      >
                                        € 26 436,90
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Tag
                                        pl="5"
                                        pr="5"
                                        fontSize={20}
                                        fontWeight={600}
                                        bg="gray.50"
                                        border="1px"
                                        borderColor="gray.300"
                                        borderStyle={"solid"}
                                      >
                                        Category 1
                                      </Tag>
                                    </Td>
                                    <Td>
                                      <Text fontSize={18} fontWeight={500}>
                                        € 100.50
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text fontSize={18} fontWeight={600}>
                                        31
                                      </Text>
                                      <Text
                                        color="gray.400"
                                        fontSize={12}
                                        fontWeight={500}
                                      >
                                        € 12 436,90
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text fontSize={18} fontWeight={600}>
                                        10
                                      </Text>
                                      <Text
                                        color="gray.400"
                                        fontSize={12}
                                        fontWeight={500}
                                      >
                                        € 26 436,90
                                      </Text>
                                    </Td>
                                  </Tr>
                                )}
                                {!tableStates["table1"] && (
                                  <Tr
                                    h="50px"
                                    key="4"
                                    onMouseEnter={() => handleRowMouseEnter(4)}
                                    onMouseLeave={handleRowMouseLeave}
                                    style={{
                                      background:
                                        hoveredRow === 4
                                          ? "#F7FAFC"
                                          : "transparent",
                                      border:
                                        hoveredRow === 4
                                          ? "2px solid #A0AEC0"
                                          : "0px",
                                    }}
                                  >
                                    <Td bg="red.50">
                                      <Text
                                        color="red.400"
                                        fontSize={20}
                                        fontWeight={700}
                                      >
                                        -8
                                      </Text>
                                      <Text
                                        color="gray.400"
                                        fontSize={12}
                                        fontWeight={500}
                                      >
                                        € 26 436,90
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Tag
                                        pl="5"
                                        pr="5"
                                        fontSize={20}
                                        fontWeight={600}
                                        bg="gray.50"
                                        border="1px"
                                        borderColor="gray.300"
                                        borderStyle={"solid"}
                                      >
                                        Category 2
                                      </Tag>
                                    </Td>
                                    <Td>
                                      <Text fontSize={18} fontWeight={500}>
                                        € 100.50
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text fontSize={18} fontWeight={600}>
                                        31
                                      </Text>
                                      <Text
                                        color="gray.400"
                                        fontSize={12}
                                        fontWeight={500}
                                      >
                                        € 12 436,90
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text fontSize={18} fontWeight={600}>
                                        10
                                      </Text>
                                      <Text
                                        color="gray.400"
                                        fontSize={12}
                                        fontWeight={500}
                                      >
                                        € 26 436,90
                                      </Text>
                                    </Td>
                                  </Tr>
                                )}
                                {!tableStates["table1"] && (
                                  <Tr
                                    h="50px"
                                    key="5"
                                    onMouseEnter={() => handleRowMouseEnter(5)}
                                    onMouseLeave={handleRowMouseLeave}
                                    style={{
                                      background:
                                        hoveredRow === 5
                                          ? "#F7FAFC"
                                          : "transparent",
                                      border:
                                        hoveredRow === 5
                                          ? "2px solid #A0AEC0"
                                          : "0px",
                                    }}
                                  >
                                    <Td bg="green.50">
                                      <Text
                                        color="green.400"
                                        fontSize={20}
                                        fontWeight={700}
                                      >
                                        +14
                                      </Text>
                                      <Text
                                        color="gray.400"
                                        fontSize={12}
                                        fontWeight={500}
                                      >
                                        € 26 436,90
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Tag
                                        pl="5"
                                        pr="5"
                                        fontSize={20}
                                        fontWeight={600}
                                        bg="gray.50"
                                        border="1px"
                                        borderColor="gray.300"
                                        borderStyle={"solid"}
                                      >
                                        Fosse OR
                                      </Tag>
                                    </Td>
                                    <Td>
                                      <Text fontSize={18} fontWeight={500}>
                                        € 100.50
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text fontSize={18} fontWeight={600}>
                                        31
                                      </Text>
                                      <Text
                                        color="gray.400"
                                        fontSize={12}
                                        fontWeight={500}
                                      >
                                        € 12 436,90
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text fontSize={18} fontWeight={600}>
                                        10
                                      </Text>
                                      <Text
                                        color="gray.400"
                                        fontSize={12}
                                        fontWeight={500}
                                      >
                                        € 26 436,90
                                      </Text>
                                    </Td>
                                  </Tr>
                                )}
                                {!tableStates["table1"] && (
                                  <Tr
                                    h="50px"
                                    key="6"
                                    onMouseEnter={() => handleRowMouseEnter(6)}
                                    onMouseLeave={handleRowMouseLeave}
                                    style={{
                                      background:
                                        hoveredRow === 6
                                          ? "#F7FAFC"
                                          : "transparent",
                                      border:
                                        hoveredRow === 6
                                          ? "2px solid #A0AEC0"
                                          : "0px",
                                    }}
                                  >
                                    <Td bg="green.50">
                                      <Text
                                        color="green.400"
                                        fontSize={20}
                                        fontWeight={700}
                                      >
                                        +4
                                      </Text>
                                      <Text
                                        color="gray.400"
                                        fontSize={12}
                                        fontWeight={500}
                                      >
                                        € 26 436,90
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Tag
                                        pl="5"
                                        pr="5"
                                        fontSize={20}
                                        fontWeight={600}
                                        bg="gray.50"
                                        border="1px"
                                        borderColor="gray.300"
                                        borderStyle={"solid"}
                                      >
                                        Fosse
                                      </Tag>
                                    </Td>
                                    <Td>
                                      <Text fontSize={18} fontWeight={500}>
                                        € 100.50
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text fontSize={18} fontWeight={600}>
                                        31
                                      </Text>
                                      <Text
                                        color="gray.400"
                                        fontSize={12}
                                        fontWeight={500}
                                      >
                                        € 12 436,90
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text fontSize={18} fontWeight={600}>
                                        10
                                      </Text>
                                      <Text
                                        color="gray.400"
                                        fontSize={12}
                                        fontWeight={500}
                                      >
                                        € 26 436,90
                                      </Text>
                                    </Td>
                                  </Tr>
                                )}
                              </Tbody>
                              <Tfoot>
                                <Tr>
                                  <Th></Th>
                                  <Th></Th>
                                  <Th></Th>
                                  <Th></Th>
                                  <Th></Th>
                                </Tr>
                              </Tfoot>
                            </Table>
                          </TableContainer>
                        </VStack>
                      </GridItem>
                    </Grid>
                  </Box>
                </GridItem>

                {/* POS */}
                <GridItem
                  color="black"
                  colSpan={{
                    base: "6",
                    sm: "6",
                    md: "6",
                    lg: "6",
                    xl: "6",
                    "2xl": "2",
                  }}
                >
                  <Box
                    p="2"
                    textAlign={["center"]}
                    w="100%"
                    minH="100px"
                    color="black"
                    bg="#F5F5F5"
                    borderRadius="xl"
                    border="1px"
                    borderColor="gray.300"
                    borderStyle={"solid"}
                    _hover={{
                      bg: "gray.100",
                      border: "2px",
                      borderColor: "gray.400",
                      borderStyle: "solid",
                    }}
                  >
                    <Grid
                      gap="2"
                      w="100%"
                      maxW="100vw"
                      templateColumns="repeat(12, 1fr)"
                    >
                      <GridItem
                        color="black"
                        colSpan={{
                          base: "12",
                          sm: "12",
                          md: "12",
                          lg: "12",
                          xl: "12",
                          "2xl": "12",
                        }}
                      >
                        <Box
                          p="0"
                          color="white"
                          bg="gray.400"
                          borderRadius="lg"
                        >
                          <Text fontSize={16} fontWeight={700}>
                            POS MARKET
                          </Text>
                        </Box>
                      </GridItem>

                      <GridItem
                        color="black"
                        colSpan={{
                          base: "12",
                          sm: "12",
                          md: "12",
                          lg: "12",
                          xl: "12",
                          "2xl": "12",
                        }}
                      >
                        <VStack>
                          <TableContainer w="100%">
                            <Table
                              size="sm"
                              p="0"
                              m="0"
                              variant="simple"
                              bg="#FFF"
                              borderRadius="lg"
                              border="1px"
                              borderColor="gray.300"
                              borderStyle={"solid"}
                            >
                              <Tbody>
                                <Tr
                                  h="50px"
                                  key="1"
                                  onMouseEnter={() => handleRowMouseEnter(1)}
                                  onMouseLeave={handleRowMouseLeave}
                                  style={{
                                    background:
                                      hoveredRow === 1
                                        ? "#F7FAFC"
                                        : "transparent",
                                    border:
                                      hoveredRow === 1
                                        ? "2px solid #A0AEC0"
                                        : "0px",
                                  }}
                                >
                                  <Td></Td>
                                  <Td>
                                    <Text fontSize={22} fontWeight={600}>
                                      31
                                    </Text>
                                    <Text
                                      color="gray.400"
                                      fontSize={12}
                                      fontWeight={500}
                                    >
                                      € 12 436,90
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={22} fontWeight={600}>
                                      10
                                    </Text>
                                    <Text
                                      color="gray.400"
                                      fontSize={12}
                                      fontWeight={500}
                                    >
                                      € 26 436,90
                                    </Text>
                                  </Td>
                                  <Td></Td>
                                </Tr>
                                <Tr h="20px" bg="gray.200" color="gray.600">
                                  <Td>
                                    <Text fontSize={13} fontWeight={600}>
                                      AVAIL.
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={13} fontWeight={600}>
                                      P.LOW
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={13} fontWeight={600}>
                                      P.HIGH
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={13} fontWeight={600}>
                                      SALE
                                    </Text>
                                  </Td>
                                </Tr>
                                {!tableStates["table1"] && (
                                  <Tr
                                    h="50px"
                                    key="2"
                                    onMouseEnter={() => handleRowMouseEnter(2)}
                                    onMouseLeave={handleRowMouseLeave}
                                    style={{
                                      background:
                                        hoveredRow === 2
                                          ? "#F7FAFC"
                                          : "transparent",
                                      border:
                                        hoveredRow === 2
                                          ? "2px solid #A0AEC0"
                                          : "0px",
                                    }}
                                  >
                                    <Td bg="green.50">
                                      <Text
                                        color="green.400"
                                        fontSize={20}
                                        fontWeight={700}
                                      >
                                        +21
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text fontSize={18} fontWeight={600}>
                                        120.40
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text fontSize={18} fontWeight={600}>
                                        175.10
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text fontSize={18} fontWeight={600}>
                                        32
                                      </Text>
                                    </Td>
                                  </Tr>
                                )}
                                {!tableStates["table1"] && (
                                  <Tr
                                    h="50px"
                                    key="3"
                                    onMouseEnter={() => handleRowMouseEnter(3)}
                                    onMouseLeave={handleRowMouseLeave}
                                    style={{
                                      background:
                                        hoveredRow === 3
                                          ? "#F7FAFC"
                                          : "transparent",
                                      border:
                                        hoveredRow === 3
                                          ? "2px solid #A0AEC0"
                                          : "0px",
                                    }}
                                  >
                                    <Td bg="green.50">
                                      <Text
                                        color="green.400"
                                        fontSize={20}
                                        fontWeight={700}
                                      >
                                        +21
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text fontSize={18} fontWeight={600}>
                                        120.40
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text fontSize={18} fontWeight={600}>
                                        175.10
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text fontSize={18} fontWeight={600}>
                                        43
                                      </Text>
                                    </Td>
                                  </Tr>
                                )}
                                {!tableStates["table1"] && (
                                  <Tr
                                    h="50px"
                                    key="4"
                                    onMouseEnter={() => handleRowMouseEnter(4)}
                                    onMouseLeave={handleRowMouseLeave}
                                    style={{
                                      background:
                                        hoveredRow === 4
                                          ? "#F7FAFC"
                                          : "transparent",
                                      border:
                                        hoveredRow === 4
                                          ? "2px solid #A0AEC0"
                                          : "0px",
                                    }}
                                  >
                                    <Td bg="red.50">
                                      <Text
                                        color="red.400"
                                        fontSize={20}
                                        fontWeight={700}
                                      >
                                        -15
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text fontSize={18} fontWeight={600}>
                                        120.40
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text fontSize={18} fontWeight={600}>
                                        175.10
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text fontSize={18} fontWeight={600}>
                                        18
                                      </Text>
                                    </Td>
                                  </Tr>
                                )}
                                {!tableStates["table1"] && (
                                  <Tr
                                    h="50px"
                                    key="5"
                                    onMouseEnter={() => handleRowMouseEnter(5)}
                                    onMouseLeave={handleRowMouseLeave}
                                    style={{
                                      background:
                                        hoveredRow === 5
                                          ? "#F7FAFC"
                                          : "transparent",
                                      border:
                                        hoveredRow === 5
                                          ? "2px solid #A0AEC0"
                                          : "0px",
                                    }}
                                  >
                                    <Td bg="red.50">
                                      <Text
                                        color="red.400"
                                        fontSize={20}
                                        fontWeight={700}
                                      >
                                        -15
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text fontSize={18} fontWeight={600}>
                                        120.40
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text fontSize={18} fontWeight={600}>
                                        175.10
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text fontSize={18} fontWeight={600}>
                                        20
                                      </Text>
                                    </Td>
                                  </Tr>
                                )}
                                {!tableStates["table1"] && (
                                  <Tr
                                    h="50px"
                                    key="6"
                                    onMouseEnter={() => handleRowMouseEnter(6)}
                                    onMouseLeave={handleRowMouseLeave}
                                    style={{
                                      background:
                                        hoveredRow === 6
                                          ? "#F7FAFC"
                                          : "transparent",
                                      border:
                                        hoveredRow === 6
                                          ? "2px solid #A0AEC0"
                                          : "0px",
                                    }}
                                  >
                                    <Td bg="green.50">
                                      <Text
                                        color="green.400"
                                        fontSize={20}
                                        fontWeight={700}
                                      >
                                        +18
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text fontSize={18} fontWeight={600}>
                                        120.40
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text fontSize={18} fontWeight={600}>
                                        175.10
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text fontSize={18} fontWeight={600}>
                                        8
                                      </Text>
                                    </Td>
                                  </Tr>
                                )}
                              </Tbody>
                              <Tfoot>
                                <Tr>
                                  <Th></Th>
                                  <Th></Th>
                                  <Th></Th>
                                </Tr>
                              </Tfoot>
                            </Table>
                          </TableContainer>
                        </VStack>
                      </GridItem>
                    </Grid>
                  </Box>
                </GridItem>

                {/* 2ND MARKET */}
                <GridItem
                  color="black"
                  colSpan={{
                    base: "6",
                    sm: "6",
                    md: "6",
                    lg: "6",
                    xl: "6",
                    "2xl": "2",
                  }}
                >
                  <Box
                    p="2"
                    textAlign={["center"]}
                    w="100%"
                    minH="100px"
                    color="black"
                    bg="#F5F5F5"
                    borderRadius="xl"
                    border="1px"
                    borderColor="gray.300"
                    borderStyle={"solid"}
                    _hover={{
                      bg: "gray.100",
                      border: "2px",
                      borderColor: "gray.400",
                      borderStyle: "solid",
                    }}
                  >
                    <Grid
                      gap="2"
                      w="100%"
                      maxW="100vw"
                      templateColumns="repeat(12, 1fr)"
                    >
                      <GridItem
                        color="black"
                        colSpan={{
                          base: "12",
                          sm: "12",
                          md: "12",
                          lg: "12",
                          xl: "12",
                          "2xl": "12",
                        }}
                      >
                        <Box
                          p="0"
                          color="white"
                          bg="gray.400"
                          borderRadius="lg"
                        >
                          <Text fontSize={16} fontWeight={700}>
                            2ND MARKET
                          </Text>
                        </Box>
                      </GridItem>

                      <GridItem
                        color="black"
                        colSpan={{
                          base: "12",
                          sm: "12",
                          md: "12",
                          lg: "12",
                          xl: "12",
                          "2xl": "12",
                        }}
                      >
                        <VStack>
                          <TableContainer w="100%">
                            <Table
                              size="sm"
                              p="0"
                              m="0"
                              variant="simple"
                              bg="#FFF"
                              borderRadius="lg"
                              border="1px"
                              borderColor="gray.300"
                              borderStyle={"solid"}
                            >
                              <Tbody>
                                <Tr
                                  h="50px"
                                  key="1"
                                  onMouseEnter={() => handleRowMouseEnter(1)}
                                  onMouseLeave={handleRowMouseLeave}
                                  style={{
                                    background:
                                      hoveredRow === 1
                                        ? "#F7FAFC"
                                        : "transparent",
                                    border:
                                      hoveredRow === 1
                                        ? "2px solid #A0AEC0"
                                        : "0px",
                                  }}
                                >
                                  <Td>
                                    <Text fontSize={22} fontWeight={600}>
                                      1426
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text
                                      color="red.400"
                                      fontSize={22}
                                      fontWeight={600}
                                    >
                                      -41
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text
                                      color="red.400"
                                      fontSize={22}
                                      fontWeight={600}
                                    >
                                      -22
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text
                                      color="red.400"
                                      fontSize={22}
                                      fontWeight={600}
                                    >
                                      -22
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text
                                      color="red.400"
                                      fontSize={22}
                                      fontWeight={600}
                                    >
                                      -66
                                    </Text>
                                  </Td>
                                </Tr>
                                <Tr h="20px" bg="gray.200" color="gray.600">
                                  <Td>
                                    <Text fontSize={13} fontWeight={600}>
                                      AVAIL.
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={13} fontWeight={600}>
                                      7J
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={13} fontWeight={600}>
                                      14J
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={13} fontWeight={600}>
                                      30J
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={13} fontWeight={600}>
                                      TOTAL
                                    </Text>
                                  </Td>
                                </Tr>
                                {!tableStates["table1"] && (
                                  <Tr
                                    h="50px"
                                    key="2"
                                    onMouseEnter={() => handleRowMouseEnter(2)}
                                    onMouseLeave={handleRowMouseLeave}
                                    style={{
                                      background:
                                        hoveredRow === 2
                                          ? "#F7FAFC"
                                          : "transparent",
                                      border:
                                        hoveredRow === 2
                                          ? "2px solid #A0AEC0"
                                          : "0px",
                                    }}
                                  >
                                    <Td>
                                      <Text fontSize={18} fontWeight={500}>
                                        844
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={500}
                                      >
                                        -5
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -12
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -31
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -543
                                      </Text>
                                    </Td>
                                  </Tr>
                                )}
                                {!tableStates["table1"] && (
                                  <Tr
                                    h="50px"
                                    key="3"
                                    onMouseEnter={() => handleRowMouseEnter(3)}
                                    onMouseLeave={handleRowMouseLeave}
                                    style={{
                                      background:
                                        hoveredRow === 3
                                          ? "#F7FAFC"
                                          : "transparent",
                                      border:
                                        hoveredRow === 3
                                          ? "2px solid #A0AEC0"
                                          : "0px",
                                    }}
                                  >
                                    <Td>
                                      <Text fontSize={18} fontWeight={500}>
                                        17
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -31
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -10
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -10
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -432
                                      </Text>
                                    </Td>
                                  </Tr>
                                )}
                                {!tableStates["table1"] && (
                                  <Tr
                                    h="50px"
                                    key="4"
                                    onMouseEnter={() => handleRowMouseEnter(4)}
                                    onMouseLeave={handleRowMouseLeave}
                                    style={{
                                      background:
                                        hoveredRow === 4
                                          ? "#F7FAFC"
                                          : "transparent",
                                      border:
                                        hoveredRow === 4
                                          ? "2px solid #A0AEC0"
                                          : "0px",
                                    }}
                                  >
                                    <Td>
                                      <Text fontSize={18} fontWeight={500}>
                                        40
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -31
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -10
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -10
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -326
                                      </Text>
                                    </Td>
                                  </Tr>
                                )}
                                {!tableStates["table1"] && (
                                  <Tr
                                    h="50px"
                                    key="5"
                                    onMouseEnter={() => handleRowMouseEnter(5)}
                                    onMouseLeave={handleRowMouseLeave}
                                    style={{
                                      background:
                                        hoveredRow === 5
                                          ? "#F7FAFC"
                                          : "transparent",
                                      border:
                                        hoveredRow === 5
                                          ? "2px solid #A0AEC0"
                                          : "0px",
                                    }}
                                  >
                                    <Td>
                                      <Text fontSize={18} fontWeight={500}>
                                        50
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -31
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -10
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -10
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -471
                                      </Text>
                                    </Td>
                                  </Tr>
                                )}
                                {!tableStates["table1"] && (
                                  <Tr
                                    h="50px"
                                    key="6"
                                    onMouseEnter={() => handleRowMouseEnter(6)}
                                    onMouseLeave={handleRowMouseLeave}
                                    style={{
                                      background:
                                        hoveredRow === 6
                                          ? "#F7FAFC"
                                          : "transparent",
                                      border:
                                        hoveredRow === 6
                                          ? "2px solid #A0AEC0"
                                          : "0px",
                                    }}
                                  >
                                    <Td>
                                      <Text fontSize={18} fontWeight={500}>
                                        10
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -31
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -10
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -10
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -250
                                      </Text>
                                    </Td>
                                  </Tr>
                                )}
                              </Tbody>
                              <Tfoot>
                                <Tr>
                                  <Th></Th>
                                  <Th></Th>
                                  <Th></Th>
                                  <Th></Th>
                                  <Th></Th>
                                </Tr>
                              </Tfoot>
                            </Table>
                          </TableContainer>
                        </VStack>
                      </GridItem>
                    </Grid>
                  </Box>
                </GridItem>

                {/* 1ST MARKET */}
                <GridItem
                  color="black"
                  colSpan={{
                    base: "6",
                    sm: "6",
                    md: "6",
                    lg: "6",
                    xl: "6",
                    "2xl": "2",
                  }}
                >
                  <Box
                    p="2"
                    textAlign={["center"]}
                    w="100%"
                    minH="100px"
                    color="black"
                    bg="#F5F5F5"
                    borderRadius="xl"
                    border="1px"
                    borderColor="gray.300"
                    borderStyle={"solid"}
                    _hover={{
                      bg: "gray.100",
                      border: "2px",
                      borderColor: "gray.400",
                      borderStyle: "solid",
                    }}
                  >
                    <Grid
                      gap="2"
                      w="100%"
                      maxW="100vw"
                      templateColumns="repeat(12, 1fr)"
                    >
                      <GridItem
                        color="black"
                        colSpan={{
                          base: "12",
                          sm: "12",
                          md: "12",
                          lg: "12",
                          xl: "12",
                          "2xl": "12",
                        }}
                      >
                        <Box
                          p="0"
                          color="white"
                          bg="gray.400"
                          borderRadius="lg"
                        >
                          <Text fontSize={16} fontWeight={700}>
                            1ST MARKET
                          </Text>
                        </Box>
                      </GridItem>

                      <GridItem
                        color="black"
                        colSpan={{
                          base: "12",
                          sm: "12",
                          md: "12",
                          lg: "12",
                          xl: "12",
                          "2xl": "12",
                        }}
                      >
                        <VStack>
                          <TableContainer w="100%">
                            <Table
                              size="sm"
                              p="0"
                              m="0"
                              variant="simple"
                              bg="#FFF"
                              borderRadius="lg"
                              border="1px"
                              borderColor="gray.300"
                              borderStyle={"solid"}
                            >
                              <Tbody>
                                <Tr
                                  h="50px"
                                  key="1"
                                  onMouseEnter={() => handleRowMouseEnter(1)}
                                  onMouseLeave={handleRowMouseLeave}
                                  style={{
                                    background:
                                      hoveredRow === 1
                                        ? "#F7FAFC"
                                        : "transparent",
                                    border:
                                      hoveredRow === 1
                                        ? "2px solid #A0AEC0"
                                        : "0px",
                                  }}
                                >
                                  <Td>
                                    <Text fontSize={22} fontWeight={600}>
                                      1426
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text
                                      color="red.400"
                                      fontSize={22}
                                      fontWeight={600}
                                    >
                                      -41
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text
                                      color="red.400"
                                      fontSize={22}
                                      fontWeight={600}
                                    >
                                      -22
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text
                                      color="red.400"
                                      fontSize={22}
                                      fontWeight={600}
                                    >
                                      -22
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text
                                      color="red.400"
                                      fontSize={22}
                                      fontWeight={600}
                                    >
                                      -66
                                    </Text>
                                  </Td>
                                </Tr>
                                <Tr h="20px" bg="gray.200" color="gray.600">
                                  <Td>
                                    <Text fontSize={13} fontWeight={600}>
                                      AVAIL.
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={13} fontWeight={600}>
                                      7J
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={13} fontWeight={600}>
                                      14J
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={13} fontWeight={600}>
                                      30J
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={13} fontWeight={600}>
                                      TOTAL
                                    </Text>
                                  </Td>
                                </Tr>
                                {!tableStates["table1"] && (
                                  <Tr
                                    h="50px"
                                    key="2"
                                    onMouseEnter={() => handleRowMouseEnter(2)}
                                    onMouseLeave={handleRowMouseLeave}
                                    style={{
                                      background:
                                        hoveredRow === 2
                                          ? "#F7FAFC"
                                          : "transparent",
                                      border:
                                        hoveredRow === 2
                                          ? "2px solid #A0AEC0"
                                          : "0px",
                                    }}
                                  >
                                    <Td>
                                      <Text fontSize={18} fontWeight={500}>
                                        844
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={500}
                                      >
                                        -5
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -12
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -31
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -543
                                      </Text>
                                    </Td>
                                  </Tr>
                                )}
                                {!tableStates["table1"] && (
                                  <Tr
                                    h="50px"
                                    key="3"
                                    onMouseEnter={() => handleRowMouseEnter(3)}
                                    onMouseLeave={handleRowMouseLeave}
                                    style={{
                                      background:
                                        hoveredRow === 3
                                          ? "#F7FAFC"
                                          : "transparent",
                                      border:
                                        hoveredRow === 3
                                          ? "2px solid #A0AEC0"
                                          : "0px",
                                    }}
                                  >
                                    <Td>
                                      <Text fontSize={18} fontWeight={500}>
                                        17
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -31
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -10
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -10
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -432
                                      </Text>
                                    </Td>
                                  </Tr>
                                )}
                                {!tableStates["table1"] && (
                                  <Tr
                                    h="50px"
                                    key="4"
                                    onMouseEnter={() => handleRowMouseEnter(4)}
                                    onMouseLeave={handleRowMouseLeave}
                                    style={{
                                      background:
                                        hoveredRow === 4
                                          ? "#F7FAFC"
                                          : "transparent",
                                      border:
                                        hoveredRow === 4
                                          ? "2px solid #A0AEC0"
                                          : "0px",
                                    }}
                                  >
                                    <Td>
                                      <Text fontSize={18} fontWeight={500}>
                                        40
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -31
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -10
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -10
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -326
                                      </Text>
                                    </Td>
                                  </Tr>
                                )}
                                {!tableStates["table1"] && (
                                  <Tr
                                    h="50px"
                                    key="5"
                                    onMouseEnter={() => handleRowMouseEnter(5)}
                                    onMouseLeave={handleRowMouseLeave}
                                    style={{
                                      background:
                                        hoveredRow === 5
                                          ? "#F7FAFC"
                                          : "transparent",
                                      border:
                                        hoveredRow === 5
                                          ? "2px solid #A0AEC0"
                                          : "0px",
                                    }}
                                  >
                                    <Td>
                                      <Text fontSize={18} fontWeight={500}>
                                        50
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -31
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -10
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -10
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -471
                                      </Text>
                                    </Td>
                                  </Tr>
                                )}
                                {!tableStates["table1"] && (
                                  <Tr
                                    h="50px"
                                    key="6"
                                    onMouseEnter={() => handleRowMouseEnter(6)}
                                    onMouseLeave={handleRowMouseLeave}
                                    style={{
                                      background:
                                        hoveredRow === 6
                                          ? "#F7FAFC"
                                          : "transparent",
                                      border:
                                        hoveredRow === 6
                                          ? "2px solid #A0AEC0"
                                          : "0px",
                                    }}
                                  >
                                    <Td>
                                      <Text fontSize={18} fontWeight={500}>
                                        10
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -31
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -10
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -10
                                      </Text>
                                    </Td>
                                    <Td>
                                      <Text
                                        color="red.400"
                                        fontSize={18}
                                        fontWeight={600}
                                      >
                                        -250
                                      </Text>
                                    </Td>
                                  </Tr>
                                )}
                              </Tbody>
                              <Tfoot>
                                <Tr>
                                  <Th></Th>
                                  <Th></Th>
                                  <Th></Th>
                                  <Th></Th>
                                  <Th></Th>
                                </Tr>
                              </Tfoot>
                            </Table>
                          </TableContainer>
                        </VStack>
                      </GridItem>
                    </Grid>
                  </Box>
                </GridItem>
              </Grid>
            </Box>

            {/* Row 01 */}
            <Box
              borderRadius="xl"
              mb="2"
              p={2}
              textAlign={["center"]}
              bg="white"
              _hover={{
                bg: "gray.200",
                border: "1px",
                borderRadius: "xl",
                borderColor: "gray.400",
                borderStyle: "solid",
              }}
            >
              <Grid
                gap="2"
                w="100%"
                maxW="100vw"
                templateColumns="repeat(12, 1fr)"
              >
                {/* Title */}
                <GridItem
                  color="black"
                  colSpan={{
                    base: "12",
                    sm: "12",
                    md: "12",
                    lg: "12",
                    xl: "12",
                    "2xl": "2",
                  }}
                >
                  <Flex>
                    <Box p="2" textAlign={["left"]}>
                      <Switch size="md" />
                    </Box>
                    <Box
                      p="2"
                      textAlign={["left"]}
                      w="100%"
                      minH="100px"
                      color="black"
                    >
                      <Text fontSize={25} fontWeight={800}>
                        Coldplay
                      </Text>
                      <Text fontSize={22} fontWeight={600}>
                        2024.06.12, 19:00
                      </Text>
                      <Text fontSize={20} fontWeight={500}>
                        France, Saint-Denis, Stade de France
                      </Text>
                    </Box>
                  </Flex>
                </GridItem>

                {/* INVENTORY */}
                <GridItem
                  color="black"
                  colSpan={{
                    base: "12",
                    sm: "12",
                    md: "12",
                    lg: "12",
                    xl: "6",
                    "2xl": "4",
                  }}
                >
                  <Box
                    p="2"
                    textAlign={["center"]}
                    w="100%"
                    minH="100px"
                    color="black"
                    bg="#F5F5F5"
                    borderRadius="xl"
                    border="1px"
                    borderColor="gray.300"
                    borderStyle={"solid"}
                    _hover={{
                      bg: "gray.100",
                      border: "2px",
                      borderColor: "gray.400",
                      borderStyle: "solid",
                    }}
                  >
                    <Grid
                      gap="2"
                      w="100%"
                      maxW="100vw"
                      templateColumns="repeat(12, 1fr)"
                    >
                      <GridItem
                        color="black"
                        colSpan={{
                          base: "12",
                          sm: "12",
                          md: "12",
                          lg: "12",
                          xl: "12",
                          "2xl": "12",
                        }}
                      >
                        <Box
                          p="1"
                          color="white"
                          bg="gray.400"
                          borderRadius="lg"
                        >
                          <Text fontSize={16} fontWeight={700}>
                            INVENTORY
                          </Text>
                        </Box>
                      </GridItem>

                      <GridItem
                        color="black"
                        colSpan={{
                          base: "12",
                          sm: "12",
                          md: "12",
                          lg: "12",
                          xl: "12",
                          "2xl": "12",
                        }}
                      >
                        <VStack>
                          <TableContainer w="100%">
                            <Table
                              size="sm"
                              variant="simple"
                              bg="#FFF"
                              borderRadius="lg"
                              border="1px"
                              borderColor="gray.300"
                              borderStyle={"solid"}
                            >
                              <Tbody>
                                <Tr
                                  h="50px"
                                  key="1"
                                  onMouseEnter={() => handleRowMouseEnter(1)}
                                  onMouseLeave={handleRowMouseLeave}
                                  style={{
                                    background:
                                      hoveredRow === 1
                                        ? "#F7FAFC"
                                        : "transparent",
                                    border:
                                      hoveredRow === 1
                                        ? "2px solid #A0AEC0"
                                        : "0px",
                                  }}
                                >
                                  <Td>
                                    <Text fontSize={22} fontWeight={700}>
                                      21
                                    </Text>
                                    <Text
                                      color="gray.400"
                                      fontSize={12}
                                      fontWeight={500}
                                    >
                                      € 26 436,90
                                    </Text>
                                  </Td>
                                  <Td></Td>
                                  <Td></Td>
                                  <Td>
                                    <Text fontSize={22} fontWeight={600}>
                                      31
                                    </Text>
                                    <Text
                                      color="gray.400"
                                      fontSize={12}
                                      fontWeight={500}
                                    >
                                      € 12 436,90
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={22} fontWeight={600}>
                                      10
                                    </Text>
                                    <Text
                                      color="gray.400"
                                      fontSize={12}
                                      fontWeight={500}
                                    >
                                      € 26 436,90
                                    </Text>
                                  </Td>
                                </Tr>
                                <Tr bg="gray.200" color="gray.600">
                                  <Td w="40px">
                                    <Text fontSize={13} fontWeight={700}>
                                      STOCK
                                    </Text>
                                  </Td>
                                  <Td w="50%">
                                    <Text fontSize={13} fontWeight={700}>
                                      PLACEMENT
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={13} fontWeight={700}>
                                      UNIT PRICE
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={13} fontWeight={700}>
                                      STOCK IN
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={13} fontWeight={700}>
                                      STOCK OUT
                                    </Text>
                                  </Td>
                                </Tr>
                              </Tbody>
                              <Tfoot>
                                <Tr>
                                  <Th></Th>
                                  <Th></Th>
                                  <Th></Th>
                                  <Th></Th>
                                  <Th></Th>
                                </Tr>
                              </Tfoot>
                            </Table>
                          </TableContainer>
                        </VStack>
                      </GridItem>
                    </Grid>
                  </Box>
                </GridItem>

                {/* POS */}
                <GridItem
                  color="black"
                  colSpan={{
                    base: "6",
                    sm: "6",
                    md: "6",
                    lg: "6",
                    xl: "6",
                    "2xl": "2",
                  }}
                >
                  <Box
                    p="2"
                    textAlign={["center"]}
                    w="100%"
                    minH="100px"
                    color="black"
                    bg="#F5F5F5"
                    borderRadius="xl"
                    border="1px"
                    borderColor="gray.300"
                    borderStyle={"solid"}
                    _hover={{
                      bg: "gray.100",
                      border: "2px",
                      borderColor: "gray.400",
                      borderStyle: "solid",
                    }}
                  >
                    <Grid
                      gap="2"
                      w="100%"
                      maxW="100vw"
                      templateColumns="repeat(12, 1fr)"
                    >
                      <GridItem
                        color="black"
                        colSpan={{
                          base: "12",
                          sm: "12",
                          md: "12",
                          lg: "12",
                          xl: "12",
                          "2xl": "12",
                        }}
                      >
                        <Box
                          p="1"
                          color="white"
                          bg="gray.400"
                          borderRadius="lg"
                        >
                          <Text fontSize={16} fontWeight={700}>
                            POINT OF SALE
                          </Text>
                        </Box>
                      </GridItem>

                      <GridItem
                        color="black"
                        colSpan={{
                          base: "12",
                          sm: "12",
                          md: "12",
                          lg: "12",
                          xl: "12",
                          "2xl": "12",
                        }}
                      >
                        <VStack>
                          <TableContainer w="100%">
                            <Table
                              size="sm"
                              p="0"
                              m="0"
                              variant="simple"
                              bg="#FFF"
                              borderRadius="lg"
                              border="1px"
                              borderColor="gray.300"
                              borderStyle={"solid"}
                            >
                              <Tbody>
                                <Tr
                                  h="50px"
                                  key="1"
                                  onMouseEnter={() => handleRowMouseEnter(1)}
                                  onMouseLeave={handleRowMouseLeave}
                                  style={{
                                    background:
                                      hoveredRow === 1
                                        ? "#F7FAFC"
                                        : "transparent",
                                    border:
                                      hoveredRow === 1
                                        ? "2px solid #A0AEC0"
                                        : "0px",
                                  }}
                                >
                                  <Td></Td>
                                  <Td>
                                    <Text fontSize={22} fontWeight={600}>
                                      31
                                    </Text>
                                    <Text
                                      color="gray.400"
                                      fontSize={12}
                                      fontWeight={500}
                                    >
                                      € 12 436,90
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={22} fontWeight={600}>
                                      10
                                    </Text>
                                    <Text
                                      color="gray.400"
                                      fontSize={12}
                                      fontWeight={500}
                                    >
                                      € 26 436,90
                                    </Text>
                                  </Td>
                                </Tr>
                                <Tr h="20px" bg="gray.200" color="gray.600">
                                  <Td>
                                    <Text fontSize={13} fontWeight={600}>
                                      AVAIL.
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={13} fontWeight={600}>
                                      P.LOW
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={13} fontWeight={600}>
                                      P.HIGH
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={13} fontWeight={600}>
                                      SALE
                                    </Text>
                                  </Td>
                                </Tr>
                              </Tbody>
                              <Tfoot>
                                <Tr>
                                  <Th></Th>
                                  <Th></Th>
                                  <Th></Th>
                                </Tr>
                              </Tfoot>
                            </Table>
                          </TableContainer>
                        </VStack>
                      </GridItem>
                    </Grid>
                  </Box>
                </GridItem>

                {/* 2ND MARKET */}
                <GridItem
                  color="black"
                  colSpan={{
                    base: "6",
                    sm: "6",
                    md: "6",
                    lg: "6",
                    xl: "6",
                    "2xl": "2",
                  }}
                >
                  <Box
                    p="2"
                    textAlign={["center"]}
                    w="100%"
                    minH="100px"
                    color="black"
                    bg="#F5F5F5"
                    borderRadius="xl"
                    border="1px"
                    borderColor="gray.300"
                    borderStyle={"solid"}
                    _hover={{
                      bg: "gray.100",
                      border: "2px",
                      borderColor: "gray.400",
                      borderStyle: "solid",
                    }}
                  >
                    <Grid
                      gap="2"
                      w="100%"
                      maxW="100vw"
                      templateColumns="repeat(12, 1fr)"
                    >
                      <GridItem
                        color="black"
                        colSpan={{
                          base: "12",
                          sm: "12",
                          md: "12",
                          lg: "12",
                          xl: "12",
                          "2xl": "12",
                        }}
                      >
                        <Box
                          p="1"
                          color="white"
                          bg="gray.400"
                          borderRadius="lg"
                        >
                          <Text fontSize={16} fontWeight={700}>
                            2ND MARKET
                          </Text>
                        </Box>
                      </GridItem>

                      <GridItem
                        color="black"
                        colSpan={{
                          base: "12",
                          sm: "12",
                          md: "12",
                          lg: "12",
                          xl: "12",
                          "2xl": "12",
                        }}
                      >
                        <VStack>
                          <TableContainer w="100%">
                            <Table
                              size="sm"
                              p="0"
                              m="0"
                              variant="simple"
                              bg="#FFF"
                              borderRadius="lg"
                              border="1px"
                              borderColor="gray.300"
                              borderStyle={"solid"}
                            >
                              <Tbody>
                                <Tr
                                  h="50px"
                                  key="1"
                                  onMouseEnter={() => handleRowMouseEnter(1)}
                                  onMouseLeave={handleRowMouseLeave}
                                  style={{
                                    background:
                                      hoveredRow === 1
                                        ? "#F7FAFC"
                                        : "transparent",
                                    border:
                                      hoveredRow === 1
                                        ? "2px solid #A0AEC0"
                                        : "0px",
                                  }}
                                >
                                  <Td>
                                    <Text fontSize={22} fontWeight={600}>
                                      10
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={22} fontWeight={600}>
                                      31
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={22} fontWeight={600}>
                                      10
                                    </Text>
                                  </Td>
                                </Tr>
                                <Tr h="20px" bg="gray.200" color="gray.600">
                                  <Td>
                                    <Text fontSize={13} fontWeight={600}>
                                      7J
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={13} fontWeight={600}>
                                      14J
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={13} fontWeight={600}>
                                      30J
                                    </Text>
                                  </Td>
                                </Tr>
                              </Tbody>
                              <Tfoot>
                                <Tr>
                                  <Th></Th>
                                  <Th></Th>
                                  <Th></Th>
                                </Tr>
                              </Tfoot>
                            </Table>
                          </TableContainer>
                        </VStack>
                      </GridItem>
                    </Grid>
                  </Box>
                </GridItem>

                {/* 1ST MARKET */}
                <GridItem
                  color="black"
                  colSpan={{
                    base: "6",
                    sm: "6",
                    md: "6",
                    lg: "6",
                    xl: "6",
                    "2xl": "2",
                  }}
                >
                  <Box
                    p="2"
                    textAlign={["center"]}
                    w="100%"
                    minH="100px"
                    color="black"
                    bg="#F5F5F5"
                    borderRadius="xl"
                    border="1px"
                    borderColor="gray.300"
                    borderStyle={"solid"}
                    _hover={{
                      bg: "gray.100",
                      border: "2px",
                      borderColor: "gray.400",
                      borderStyle: "solid",
                    }}
                  >
                    <Grid
                      gap="2"
                      w="100%"
                      maxW="100vw"
                      templateColumns="repeat(12, 1fr)"
                    >
                      <GridItem
                        color="black"
                        colSpan={{
                          base: "12",
                          sm: "12",
                          md: "12",
                          lg: "12",
                          xl: "12",
                          "2xl": "12",
                        }}
                      >
                        <Box
                          p="1"
                          color="white"
                          bg="gray.400"
                          borderRadius="lg"
                        >
                          <Text fontSize={16} fontWeight={700}>
                            1ST MARKET
                          </Text>
                        </Box>
                      </GridItem>

                      <GridItem
                        color="black"
                        colSpan={{
                          base: "12",
                          sm: "12",
                          md: "12",
                          lg: "12",
                          xl: "12",
                          "2xl": "12",
                        }}
                      >
                        <VStack>
                          <TableContainer w="100%">
                            <Table
                              size="sm"
                              p="0"
                              m="0"
                              variant="simple"
                              bg="#FFF"
                              borderRadius="lg"
                              border="1px"
                              borderColor="gray.300"
                              borderStyle={"solid"}
                            >
                              <Tbody>
                                <Tr
                                  h="50px"
                                  key="1"
                                  onMouseEnter={() => handleRowMouseEnter(1)}
                                  onMouseLeave={handleRowMouseLeave}
                                  style={{
                                    background:
                                      hoveredRow === 1
                                        ? "#F7FAFC"
                                        : "transparent",
                                    border:
                                      hoveredRow === 1
                                        ? "2px solid #A0AEC0"
                                        : "0px",
                                  }}
                                >
                                  <Td>
                                    <Text fontSize={22} fontWeight={600}>
                                      1426
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text
                                      color="red.400"
                                      fontSize={22}
                                      fontWeight={600}
                                    >
                                      -41
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text
                                      color="red.400"
                                      fontSize={22}
                                      fontWeight={600}
                                    >
                                      -22
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text
                                      color="red.400"
                                      fontSize={22}
                                      fontWeight={600}
                                    >
                                      -22
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text
                                      color="red.400"
                                      fontSize={22}
                                      fontWeight={600}
                                    >
                                      -66
                                    </Text>
                                  </Td>
                                </Tr>
                                <Tr h="20px" bg="gray.200" color="gray.600">
                                  <Td>
                                    <Text fontSize={13} fontWeight={600}>
                                      AVAIL.
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={13} fontWeight={600}>
                                      7J
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={13} fontWeight={600}>
                                      14J
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={13} fontWeight={600}>
                                      30J
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={13} fontWeight={600}>
                                      TOTAL
                                    </Text>
                                  </Td>
                                </Tr>
                              </Tbody>
                              <Tfoot>
                                <Tr>
                                  <Th></Th>
                                  <Th></Th>
                                  <Th></Th>
                                  <Th></Th>
                                  <Th></Th>
                                </Tr>
                              </Tfoot>
                            </Table>
                          </TableContainer>
                        </VStack>
                      </GridItem>
                    </Grid>
                  </Box>
                </GridItem>
              </Grid>
            </Box>
          </GridItem>
        </Grid>
      </Center>
    </>
  );

  return content;
};
export default InventoryList;
