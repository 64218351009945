import { useState, useEffect } from "react"
import { useGetCartsWaitQuery, useUpdateCartWaitMutation } from "./cartsApiSlice"
import Cart from "./Cart"
import { Link } from 'react-router-dom'
import useAuth from "../../hooks/useAuth"
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'
import useTitle from "../../hooks/useTitle"
import PulseLoader from 'react-spinners/PulseLoader'

// import `ChakraProvider` component
import { Box, Grid, GridItem, Center, HStack, VStack, Container, Flex, FormControl, FormLabel, Textarea, Input, Switch, Badge, Tag, TagLabel, TagCloseButton, Avatar, Toast, Icon, IconButton, Image, Stack, Alert, AlertIcon, Select, Button, useRadioGroup, useRadio, RadioGroup, Radio, Text, useDisclosure, useToast } from '@chakra-ui/react'

// import `ChakraProvider` icon
import { AddIcon } from '@chakra-ui/icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationCrosshairs, faSnowman, faList } from "@fortawesome/free-solid-svg-icons"

const CartsList = () => {
    useTitle('BROKY: Carts Manager')

    const { username, isManager, isAdmin } = useAuth()

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    let menuContent
    if (isManager || isAdmin) {
        menuContent = (
            <>
                <Link to="/dash/carts/new">
                    <Button
                        size='sm'
                        leftIcon={<AddIcon />}
                        colorScheme='red'
                        variant='solid'>
                        <Text
                            color='white'
                            display={{ base: 'none', sm: 'flex' }}
                            fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }}
                            fontWeight={600}
                        >
                            {T.common.Add}
                        </Text>
                    </Button>
                </Link>
            </>
        )
    }

    let content
    content = (
        <>
            <Center bg='#45413C' p='0' color='white' axis='both'>
                <Grid w='95vw' h='100vh' maxW='1500' gap={5} p='5' templateColumns='repeat(12, 1fr)'>
                    <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>
                        <HStack color='white'>
                            <Container textAlign={['left']} >
                                <Text fontSize={{ base: "24", sm: "24", md: "24", lg: "24", xl: "28" }} fontWeight={600}><FontAwesomeIcon icon={faLocationCrosshairs} /> {T.carts.CartsManager}</Text><br />
                            </Container>
                            <Container textAlign={['right']}>
                                {menuContent}
                            </Container>
                        </HStack>
                        <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' textAlign={['center']} borderColor='black' bg='white' w="100%">
                            <br />
                            <br />
                            <br />
                            <br />
                        </Box>
                    </GridItem>
                </Grid>
            </Center>
        </>
    )

    return content
}
export default CartsList